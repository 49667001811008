<template>
  <div
    v-show="!IpList"
    class="popup"
    tabindex="0"
    @keydown.esc="closePopup"
    @click="modalOverlayClose"
  >
    <div
      ref="modalOverlay"
      class="popup-block"
    >
      <div class="popup-block-form">
        <div class="popup-block-form_header">
          <div
            v-if="Profile.role === 'partner'"
            class="popup-block-form-title"
          >
            Partner info
          </div>
          <div
            v-if="Profile.role === 'admin'"
            class="popup-block-form-title"
          >
            Admin info
          </div>

          <div
            class="close"
            @click="closePopup"
          >
            <img
              src="../../../assets/img/close.svg"
              alt=""
            >
          </div>
        </div>
        <div class="popup-block-form_info">
          <Avatar :img="Profile.avatar" />

          <div class="popup-block-form_info--profile">
            <div class="popup-block-form_info--profile_title">
              <div class="text">
                {{ Profile.login }}
              </div>
              <div
                class="ip_button"
                @click="ipListSet(Profile.id)"
              >
                <ip-false v-if="Profile.isIpList === false" />
                <ip-true v-if="Profile.isIpList === true" />
                <!--        <ip-inactive v-if="ipListItem.isActive === 3" />-->
              </div>
            </div>
            <div
              v-if="Profile.partnerProfile"
              class="popup-block-form_info--profile_block"
            >
              <div class="column-wrapper">
                <div class="popup-block-form-info-profile-item">
                  <div class="item-title">
                    Email
                  </div>
                  <div>
                    {{ Profile.email || Profile.partnerProfile.email || empty }}
                  </div>
                </div>
                <div class="popup-block-form-info-profile-item">
                  <div class="item-title">
                    Telegram
                  </div>
                  <div>
                    {{ Profile.partnerProfile.telegramm || empty }}
                  </div>
                </div>
              </div>
              <div class="column-wrapper">
                <div class="popup-block-form-info-profile-item">
                  <div class="item-title">
                    BTC Address
                  </div>
                  <div>
                    {{ Profile.partnerProfile.address || empty }}
                  </div>
                </div>
                <div class="popup-block-form-info-profile-item">
                  <div class="item-title">
                    Min pay
                  </div>
                  <div>
                    {{ Profile.partnerProfile.minPayment || empty }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!Profile.partnerProfile"
              class="popup-block-form_info--profile_block"
            >
              <div class="popup-block-form-info-profile-item">
                <div class="item-title">
                  Email
                </div>
                {{ Profile.email || empty }}
              </div>
              <div class="popup-block-form-info-profile-item">
                <div class="item-title">
                  Telegram
                </div>
                {{ Profile.telegramm || empty }}
              </div>
              <div class="popup-block-form-info-profile-item">
                <div class="item-title">
                  BTC Address
                </div>
                {{ Profile.address || empty }}
              </div>
              <div class="popup-block-form-info-profile-item">
                <div class="item-title">
                  Min pay
                </div>
                {{ empty }}
              </div>
            </div>
            <div
              v-if="Profile.roles.includes('partner')"
              class="popup-block-form-info-profile-item popup-block-form-info-profile-item--long"
            >
              <div class="item-title">
                Partner Id
              </div>
              {{ Profile.partnerId || empty }}
            </div>
          </div>
        </div>
        <div
          v-if="UserActivity && UserActivity.length"
          class="popup-block-form_logs"
        >
          <div class="popup-block-form_logs_title">
            Logs
          </div>
          <div class="popup-block-form_logs_table">
            <table class="table">
              <thead>
                <tr class="table-headers">
                  <th
                    v-for="(item, index) in tableTh"
                    :key="index"
                    scope="col"
                    :class="{
                      'table-index': item.key === 'index',
                      'table-createDate': item.key === 'createDate',
                      'table-userAgent': item.key === 'userAgent',
                      'table-ipAddress': item.key === 'ipAddress',
                    }"
                  >
                    <div
                      class="text"
                      @mouseover="currentIndex = index"
                      @mouseleave="currentIndex = null"
                    >
                      {{ item.name }}
                      <sort-passive v-if="!item.sort && item.sort !== 'none' " />
                      <sort-a-z v-if="item.sort === 'asc'" />
                      <sort-z-a v-if="item.sort === 'desc'" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="table-content">
                <UserActivityItem
                  :user-activity-list="UserActivity"
                />
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="Profile.roles.includes('partner')"
          class="popup-block-form-info-profile-button"
        >
          <button
            class="login"
            @click="editUser(Profile.id)"
          >
            Edit partner
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import UserService from "@/services/user.service";
import SortZA from "@/icons/sorting/sort-z-a";
import SortPassive from "@/icons/sorting/sort-passive";
import SortAZ from "@/icons/sorting/sort-a-z";
import UserActivityItem from "@/pages/users/partials/UserActivityItem";
import {TABLE_TH_ACTIVITY} from "@/pages/users/userData";
import IpFalse from "@/icons/ipFalse";
import IpTrue from "@/icons/ipTrue";
import Avatar from "@/components/admin/AvatarComponent.vue";

export default {
  name: "ProfilePopUp",
  components: {Avatar, IpTrue, IpFalse, UserActivityItem, SortAZ, SortPassive, SortZA},
  data() {
    return {
      empty: '-',
      tableTh: TABLE_TH_ACTIVITY,
      currentIndex: null,
      userActivityData: null,
      timer: ''
    }
  },
  computed: {
    ...mapGetters({
      Profile: 'getProfile',
      UserActivity: 'getUserActivity',
      IpList: 'getIpList'
    }),
  },
  methods: {
    closePopup() {
      this.$store.commit('setProfile', null);
      this.$store.commit('setUserActivity', null);
    },
    ipListSet(id) {
      UserService.getUserIpList(id).then((res) => {
        this.$store.commit('setIpList', res.data.result);
        if (!this.Profile) {
          this.closePopup();
        }
      })
    },
    editUser(id) {
      UserService.getUserById(id).then((res) => {
        this.emitter.emit('edit-user-popup', res.data.result)
        this.closePopup();
      })

    },
    modalOverlayClose(event) {
      if (event.target === this.$refs.modalOverlay) {
        this.closePopup();
      }
    }
  },

}
</script>

<style lang="scss">
 @import "../../../assets/styles/pages/users/user-profile";
</style>
