<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>List of exchanges</h2>
        <div class="layout-header_tabs">
          <router-link
            :to="{ name: 'Exchanges retro' }"
            class="layout-header_tabs-item "
          >
            Binance
          </router-link>
          <router-link
            :to="{ name: 'Exchanges 1inch' }"
            class="layout-header_tabs-item"
          >
            1inch
          </router-link>
          <div class="layout-header_tabs-item layout-header_tabs-item--active">
            Multi
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div
        v-if="exchangesData"
        class="exchangesList main-block"
      >
        <loading
          v-if="!exchangesData.length && isLoading"
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="#0A68F7"
        />
        <div class="table-block">
          <div class="table-block-header table-block-header-withFilters">
            <div class="table-block-header-title">
              Multi - exchanges
            </div>
            <div class="table-block-header-filters-container">
              <div class="table-block-header-filters">
                <div class="table-block-header-filters-filter">
                  <div
                    class="table-block-header-filters-filter-search"
                  >
                    <div class="input input-id">
                      <input
                        v-model="uidSearch"
                        type="text"
                        :placeholder="searchPlaceHolder"
                        :style="{ minWidth: `35ch`, maxWidth: 'unset' }"
                        @keyup.enter="searchLoginHandler(uidSearch)"
                      >
                      <span
                        v-if="uidSearch.length > 1"
                        class="input-button save"
                        @click="searchLoginHandlerButton(uidSearch)"
                      >
                        OK
                      </span>
                    </div>
                  </div>
                </div>
                <div class="table-block-header-filters-delimeter" />
                <div class="table-block-header-filters-filter">
                  <div
                    class="table-block-header-filters-filter-btn eventBtn"
                    @click="searchStatusToggle"
                  >
                    <filter-icon class="filter" />
                    Status filter
                  </div>
                  <div
                    v-if="isStatusSearch && !isMobile()"
                    class="table-block-header-filters-dropsearch eventBlock"
                  >
                    <div
                      class="searchlist"
                    >
                      <div
                        v-for="(item, index) in ExchangeStatusList"
                        :key="index"
                        class="table-block-header-filters-dropsearch-login"
                      >
                        <div class="table-block-header-filters-dropsearch-login-block">
                          <div
                            class="item"
                            @click="statusHandler(item.status)"
                          >
                            <div
                              class="circle"
                              :class="{
                                remaining: getExchangeStatus(item.status).className === 'remaining',
                                pay: getExchangeStatus(item.status).className === 'pay',
                                exchange: getExchangeStatus(item.status).className === 'exchange',
                                completed: getExchangeStatus(item.status).className === 'completed',
                                overdue: getExchangeStatus(item.status).className === 'overdue',
                                returned: getExchangeStatus(item.status).className === 'returned',
                                hold: getExchangeStatus(item.status).className === 'hold',
                                profit: getExchangeStatus(item.status).className === 'profit',
                              }"
                            />
                            <span class="item-text">
                              {{ getExchangeStatus(item.status).name }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="table-block-header-filters-filter-search"
                  >
                    <div class=" input-partner">
                      <div
                        class="input-partner_button"
                        @click="isPartnerSearch = !isPartnerSearch"
                      >
                        <partner-icon />
                        <span>
                          {{ searchPartnerPlaceHolder }}
                        </span>
                      </div>
                      <div
                        v-if="isPartnerSearch"
                        class="searchlist"
                      >
                        <div class="search">
                          <search-icon />
                          <input
                            v-model="partnerSearchValue"
                            type="text"
                            placeholder="Login search"
                            @keyup="searchPartnerHandler"
                            @keyup.enter="searchPartnerHandler"
                          >
                        </div>
                        <div class="block">
                          <div
                            v-for="(item, index) in partnersList"
                            :key="index"
                            class="table-block-header-filters-dropsearch-login"
                          >
                            <div class="table-block-header-filters-dropsearch-login-block">
                              <div
                                class="item"
                                @click="searchPartnerHandlerButton(item)"
                              >
                                {{ item.login }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <VueDatePicker
                    v-model="startDateData"
                    auto-apply
                    placeholder="Start date"
                    :enable-time-picker="false"
                    @update:model-value="handleDate('start')"
                    @cleared="clearDate()"
                    @open="tabsHandler()"
                  />
                  <VueDatePicker
                    v-model="endDateData"
                    auto-apply
                    placeholder="End date"
                    :enable-time-picker="false"
                    @update:model-value="handleDate('end')"
                    @cleared="clearDate()"
                    @open="tabsHandler"
                  />
                </div>
              </div>
              <div
                ref="timer"
                class="table-block-header-filters-timer"
              >
                <div class="table-block-header-filters-timer_block">
                  <div
                    class="icon"
                    @click="timerRefresh()"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4068_2960)">
                        <path
                          d="M9.16667 6.66667V10.8333L12.7083 12.9333L13.35 11.8667L10.4167 10.125V6.66667H9.16667ZM17.5 8.33333V2.5L15.3 4.7C13.95 3.34167 12.075 2.5 10 2.5C5.85833 2.5 2.5 5.85833 2.5 10C2.5 14.1417 5.85833 17.5 10 17.5C14.1417 17.5 17.5 14.1417 17.5 10H15.8333C15.8333 13.2167 13.2167 15.8333 10 15.8333C6.78333 15.8333 4.16667 13.2167 4.16667 10C4.16667 6.78333 6.78333 4.16667 10 4.16667C11.6083 4.16667 13.0667 4.825 14.125 5.875L11.6667 8.33333H17.5Z"
                          fill="#0A68F7"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4068_2960">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div
                    class="minutes"
                    @click="timerHandler()"
                  >
                    {{ selectedTimer.name }}
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.40372 4.19995H8.59628C8.95551 4.19995 9.13512 4.65889 8.88084 4.92785L6.28556 7.67508C6.12815 7.84158 5.87185 7.84158 5.71444 7.67508L3.11916 4.92785C2.86488 4.65889 3.0445 4.19995 3.40372 4.19995Z"
                        fill="#9FA2B4"
                      />
                    </svg>
                  </div>
                </div>
                <div class="timer-progress">
                  <LvProgressBar
                    v-if="selectedTimer.interval"
                    :value="timerValue"
                    :show-value="false"
                    color="#C7C9DA"
                  />
                </div>
                <div
                  v-if="timerHandle"
                  class="dropdown"
                >
                  <div
                    v-for="(item, index) in dropdownItems"
                    :key="index"
                    class="dropdown-item"
                    @click="selectTimerValue(item)"
                  >
                    {{ item.name }}

                    <svg
                      v-if="selectedTimer.name === item.name"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4068_2939)">
                        <path
                          d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                          fill="#0A68F7"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4068_2939">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="partnerId || uid || startDate || endDate || statuses.length"
            class="table-block-filtersBlock"
          >
            <div v-if="uid">
              <div
                class="table-block-header-filters-selected"
              >
                {{ uid }}
                <div class="table-block-header-filters-selected-close">
                  <close-icon @click="uidHandler()" />
                </div>
              </div>
            </div>
            <div v-if="partnerId">
              <div
                class="table-block-header-filters-selected"
              >
                <span>Partner login: </span> {{ partnerLogin }}
                <div class="table-block-header-filters-selected-close">
                  <close-icon @click="partnerHandler()" />
                </div>
              </div>
            </div>

            <div v-if="startDate">
              <div
                class="table-block-header-filters-selected"
              >
                <span>Start date: </span> {{ dataFormatting(startDate) }}
                <div class="table-block-header-filters-selected-close">
                  <close-icon @click="clearDate()" />
                </div>
              </div>
            </div>

            <div v-if="endDate">
              <div
                class="table-block-header-filters-selected"
              >
                <span>End date: </span> {{ dataFormatting(endDate) }}
                <div class="table-block-header-filters-selected-close">
                  <close-icon @click="clearDate()" />
                </div>
              </div>
            </div>

            <div
              v-if="statuses.length"
              class="table-block-header-filters-block"
            >
              <div
                v-for="(status, index) in statuses"
                :key="index"
                class="table-block-header-filters-selected"
                @click="clearStatuses()"
              >
                <span>Status:</span>{{ getExchangeStatus(status).name }}
                <div class="table-block-header-filters-selected-close">
                  <close-icon />
                </div>
              </div>
            </div>

            <div
              v-if="partnerId || uid || startDate || endDate || statuses.length"
              class="clear-all"
              @click="closeAllFilters"
            >
              <clear-all-icon />
              Clear all
            </div>
          </div>
          <div
            ref="dropdown"
            class="table-block-table"
          >
            <Transition name="committee">
              <div
                class="list"
              >
                <div class="list-headers">
                  <div
                    class="list-headers_cell list-info"
                  >
                    Info
                  </div>
                  <div
                    v-for="(item, index) in tableTh"
                    :id="item.id"
                    :key="index"
                    class="list-headers_cell"
                    :class="'list-' + item.key"
                  >
                    <div
                      class="text"
                      @click="sortData(item)"
                      @mouseover="tokenTo = index"
                      @mouseleave="tokenTo = null"
                    >
                      {{ item.name }}
                      <span v-if="item.isSortable">
                        <sort-passive v-if="!item.sort" />
                        <sort-a-z v-if="item.sort === 'ASC'" />
                        <sort-z-a v-if="item.sort === 'DESC'" />
                        <transition name="fade">
                          <hover-text
                            v-if="tokenTo === index"
                            :option="item.description"
                          />
                        </transition>
                      </span>
                    </div>
                  </div>
                </div>
                <ExchangesListMultiItem
                  v-if="exchangesData && exchangesData.length"
                  :exchanges-list="exchangesData"
                />
              </div>
            </Transition>
            <Transition name="committee">
              <no-data-block v-if="!exchangesData.length && !isLoading" />
            </Transition>
          </div>
        </div>
        <paged-pagination
          v-if="exchangesData && exchangesData.length && !isLoading"
          :total="exchangeMaxPage"
          :current="page"
          @change-page="pageHandler"
          @prev-page="pageHandler"
          @next-page="pageHandler"
        />
      </div>
      <ExchangeInfoMulti
        v-if="exchangeData && backPath"
        :data="exchangeData"
        :back-path="backPath"
      />
      <CopyNotification
        v-if="isSuccessCopy"
        :text="copyText"
      />
      <exchange-info
        v-if="ExchangeData"
        :data="ExchangeData"
        :logs="StatusActivity"
        route-to-page="Exchanges Multi"
        :route-to="backPath"
      />
      <transition name="committee">
        <CopyNotification
          v-if="isUpdate"
          :text="updateText"
        />
      </transition>
    </template>
  </AccountLayout>
</template>

<script>

import Loading from 'vue-loading-overlay';
import filterMixin from "@/mixins/filter.mixin";
import helperMixin from "@/mixins/helper.mixin";
import OrderService from "@/services/order.service";
import AccountLayout from "@/components/admin/AccountLayout.vue";
import SortPassive from "@/icons/sorting/sort-passive";
import SortAZ from "@/icons/sorting/sort-a-z";
import SortZA from "@/icons/sorting/sort-z-a";
import HoverText from "@/pages/coins/partials/hoverText";
import PagedPagination from "@/components/admin/PagedPagiantion.vue";
import {TABLE_TH_MULTI, STATUSES} from "@/pages/exchanges/sub-pages/multi/exchangeMultiData";
import ExchangesListMultiItem from "@/pages/exchanges/sub-pages/multi/partials/ExchangesListMultiItem";
import ExchangeInfoMulti from "@/pages/exchanges/sub-pages/multi/partials/ExchangeInfoMulti";
import CloseIcon from "@/icons/closeIcon";
import CopyNotification from "@/pages/notification/CopyNotification.vue";
import {mapGetters} from "vuex";
import ExchangeInfo from "@/pages/exchanges/partials/ExchangeInfo.vue";
import FilterIcon from "@/icons/filterIcon.vue";
import PartnerIcon from "@/icons/partnerIcon.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import dayjs from "dayjs";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import UserService from "@/services/user.service";
import SearchIcon from "@/icons/searchIcon.vue";
import ClearAllIcon from "@/assets/img/icons/clearAllIcon.vue";
import LvProgressBar from 'lightvue/progress-bar';

export default {
  name: "ExchangesListMulti",
  components: {
    ClearAllIcon,
    SearchIcon,
    NoDataBlock,
    PartnerIcon,
    VueDatePicker,
    FilterIcon,
    ExchangeInfo,
    CopyNotification,
    CloseIcon,
    ExchangeInfoMulti,
    ExchangesListMultiItem,
    PagedPagination,
    HoverText,
    SortZA,
    SortAZ,
    Loading,
    SortPassive,
    AccountLayout,
    LvProgressBar: LvProgressBar,
  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      exchangesData: [],
      exchangeData: null,
      exchangeMaxPage: null,
      partnersList: null,
      partnersListOrigin: null,
      tableTh: TABLE_TH_MULTI,
      ExchangeStatusList: STATUSES,
      tokenTo: false,
      statuses: [],
      uid: '',
      uidSearch: '',
      partnerIdSearch: '',
      partnerSearchValue: '',
      partnerId: '',
      page: 1,
      date: null,
      startDate: null,
      endDate: null,
      startDateData: null,
      endDateData: null,
      fullPage: true,
      searchPlaceHolder: 'Search UID/BinanceID/SwapzoneID/DepositHash',
      searchPartnerPlaceHolder: 'Partner login',
      isStatusSearch: false,
      isShowUid: false,
      isExchangeInfo: false,
      isSearch: false,
      isSuccessCopy: false,
      isPartnerSearch: false,
      isLoading: false,
      copyText: 'Copied to clipboard!',
      dateFormat: 'YYYY-MM-DD',
      queryUrl: {},
      dropdownItems: [
        {name: "Off", interval: null},
        {name: "30s", interval: 30000},
        {name: "1m", interval: 60000},
        {name: "2m", interval: 120000},
        {name: "5m", interval: 300000},
        {name: "15m", interval: 900000}
      ],
      selectedTimer: {name: "Off", interval: null},
      timerId: null,
      timerValue: 0,
      timerHandle: false,
      backPath: null,
      updateText: '',
      isUpdate: false,
      partnerLogin: ''
    }
  },
  computed: {
    ...mapGetters({
      ExchangeData: 'getExchangeData',
      ExchangeIpList: 'getIpList',
      IsExchangeModal: 'getIsExchangeModal',
      StatusActivity: 'getOrderStatusActivityData'
    }),
  },
  watch: {
    ExchangeData() {
      this.emitter.on('exchanges-list-refresh', (data) => {
        this.getList(data);
      })
    }
  },
  created() {
    this.getPartnerList();
  },
  mounted() {
    this.mountList();
    this.mountSort();
    this.mountStatus();
    this.hideDropDownWhenClick();
    document.addEventListener('click', this.timerClickOutside);
    if (this.$route.params.id) {
      this.getOrderAdminById(this.$route.params.id);
    }
    this.emitter.on('exchange-info-event', (data) => {
      this.exchangeData = data
      this.isExchangeInfo = true
    });
    this.emitter.on('copy-uid', (status) => {
      this.isSuccessCopy = status
    })
    this.emitter.on('exchange-multi-info', (data) => {
      this.backPath = data;
    });
  },
  beforeUnmount() {
    clearInterval(this.timerId);
    document.removeEventListener('click', this.timerClickOutside);
  },
  methods: {
    getPartnerList() {
      UserService.getUserFavorite().then((res) => {
        this.partnersList = res.data.result;
        this.partnersListOrigin = res.data.result;

        if (this.$route.query.idPartner && this.partnersList) {
          const idPartner = Number(this.$route.query.idPartner);

          const partner = this.partnersList.find(item => item.id === idPartner);

          if (partner) {
            this.partnerLogin = partner.login;
          }
        }
      });
    },
    getPartnerById(id) {
      if (this.partnersList) {
        const partner = this.partnersList.find(item => item.id === id);
        if (partner) {
          return partner.login
        }
      }
    },
    getOrderAdminById(el) {
      OrderService.getOrderAdminById(el).then((res) => {
        this.$store.commit('setExchangeData', res.data.result);
        this.$router.push({
          path: `/exchanges/multi/${el}`,
        })
      })

      OrderService.getOrderActivity(el).then((res) => {
        this.$store.commit('setOrderStatusActivityData', res.data.result);
      })
      this.emitter.emit('exchange-binance-timer', false)
    },
    onUpdateHandler() {
      this.updateText = 'Pages refresh';

      this.isUpdate = true;

      setTimeout(() => {
        this.isUpdate = false
      }, 2000);
    },
    getList(query) {
      this.endProgress();
      if (!this.exchangesData) {
        this.isLoading = true;
      }

      OrderService.getOrderListMulti(query).then((res) => {
        this.exchangesData = res.data.result.result;
        this.exchangeMaxPage = res.data.result.maxPage;
        this.isLoading = false;
        if (this.selectedTimer.interval) {
          this.timerValue = 0;
          this.startProgress()
        }
      });
    },
    getStatuses() {
      let status = this.$route.query.status;

      if (!status) {
        return null;
      }

      return Array.isArray(status) ? status : [status];
    },
    selectTimerValue(item) {
      this.selectedTimer = item;
      clearInterval(this.timerId);

      if (item.interval) {
        this.startProgress();
        this.timerId = setInterval(this.timerRefresh, item.interval);
      } else {
        this.timerValue = 0
        this.endProgress();
      }
      this.timerHandler();
    },
    startProgress() {
      this.timerValue = 0;
      this.interval = setInterval(() => {
        this.timerValue += (100 / (this.selectedTimer.interval / 1000));
        if (this.timerValue >= 100) {
          this.timerValue = 0;
          clearInterval(this.interval);
        }
      }, 1000);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    clearStatuses() {
      this.statuses = [];
      this.page = 1;

      let query = {page: this.page};

      if (this.$route.query.uid) {
        query.uid = this.uid;
      }

      if (this.$route.query.StartDate) {
        this.startDateData = query.StartDate
        query.StartDate = this.startDate;
      }

      if (this.$route.query.EndDate) {
        this.endDateData = query.EndDate
        query.EndDate = this.endDate;
      }

      if (this.$route.query.idPartner) {
        query.idPartner = this.uidSearch;
        this.partnerId = query.idPartner;
      }

      if (this.$route.query.idBinance) {
        query.idBinance = this.$route.query.idBinance;
        this.uid = query.idBinance;
      }

      if (this.$route.query.depositHash) {
        query.DepositHash = this.$route.query.DepositHash;
        this.uid = query.DepositHash
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
    },
    statusHandler(status) {
      this.isStatusSearch = false;
      this.page = 1;
      let query = {page: this.page};

      this.statuses.push(status);

      query = {page: this.page, status: status};

      if (this.$route.query.uid) {
        query.uid = this.uid;
      }

      if (this.$route.query.StartDate) {
        this.startDateData = query.StartDate
        query.StartDate = this.startDate;
      }

      if (this.$route.query.EndDate) {
        this.endDateData = query.EndDate
        query.EndDate = this.endDate;
      }

      if (this.$route.query.idPartner) {
        query.idPartner = this.$route.query.idPartner;
        this.partnerId = query.idPartner;
      }

      if (this.$route.query.idBinance) {
        query.idBinance = this.$route.query.idBinance;
        this.uid = query.idBinance;
      }

      if (this.$route.query.depositHash) {
        query.DepositHash = this.$route.query.DepositHash;
        this.uid = query.DepositHash
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
        this.mountStatus()
      });
    },
    tabsHandler() {
      this.isStatusSearch = false;
      this.isPartnerSearch = false;
    },
    handleDate(action) {
      const startDateFormat = this.dataFormatting(this.startDateData);
      const endDateFormat = this.dataFormatting(this.endDateData);

      let query = {page: 1};

      if (action === 'start' && this.startDateData) {
        query.StartDate = startDateFormat;
        this.startDate = startDateFormat;
      }

      if (action === 'end' && this.endDateData) {
        query.EndDate = endDateFormat;
        this.endDate = endDateFormat;
      }

      if (this.startDate && this.endDate) {
        query.StartDate = this.startDate;
        query.EndDate = this.endDate;
      }

      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      if (this.$route.query.uid) {
        this.uid = this.$route.query.Uid;
        this.isShowUid = true;
      }

      if (this.$route.query.idPartner) {
        query.idPartner = this.uidSearch;
        this.partnerId = query.idPartner;
      }

      if (this.$route.query.idBinance) {
        query.idBinance = this.$route.query.idBinance;
        this.uid = query.idBinance;
      }

      if (this.$route.query.depositHash) {
        query.DepositHash = this.$route.query.DepositHash;
        this.uid = query.DepositHash
      }


      this.startDateData = null;
      this.endDateData = null;

      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
    },
    clearDate() {
      this.startDate = null;
      this.endDate = null;

      let query = {page: 1};

      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      if (this.$route.query.uid) {
        this.uid = this.$route.query.Uid;
        this.isShowUid = true;
      }

      if (this.$route.query.idPartner) {
        query.idPartner = this.$route.query.idPartner;
        this.uid = query.idPartner;
      }

      if (this.$route.query.idBinance) {
        query.idBinance = this.$route.query.idBinance;
        this.uid = query.idBinance;
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
    },
    dataFormatting(value) {
      return dayjs(value).format(this.dateFormat)
    },
    timerClickOutside(event) {
      if (this.$refs.timer && !this.$refs.timer.contains(event.target)) {
        this.timerHandle = false
      }
    },
    pageHandler(page) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      this.page = page;

      const query = {page: page};

      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      if (this.$route.query.startDate) {
        this.startDateData = query.StartDate
        query.StartDate = this.startDate;
      }

      if (this.$route.query.endDate) {
        this.endDateData = query.EndDate
        query.EndDate = this.endDate;
      }

      if (this.$route.query.uid) {
        this.uid = this.$route.query.Uid;
        this.isShowUid = true;
      }
      if (this.$route.query.idBinance) {
        query.idBinance = this.$route.query.idBinance;
        this.uid = query.idBinance;
      }
      if (this.$route.query.depositHash) {
        query.DepositHash = this.$route.query.DepositHash;
        this.uid = query.DepositHash
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
    },
    uidHandler() {
      this.uid = null;
      this.isShowUid = false;

      this.page = 1;
      let query = {page: this.page};

      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      if (this.$route.query.startDate) {
        this.startDateData = query.StartDate
        query.StartDate = this.startDate;
      }

      if (this.$route.query.endDate) {
        this.endDateData = query.EndDate
        query.EndDate = this.endDate;
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
      this.uidSearch = '';

    },
    partnerHandler() {
      this.partnerId = null;

      this.page = 1;
      let query = {page: this.page};

      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      if (this.$route.query.startDate) {
        this.startDateData = query.StartDate
        query.StartDate = this.startDate;
      }

      if (this.$route.query.endDate) {
        this.endDateData = query.EndDate
        query.EndDate = this.endDate;
      }

      if (this.$route.query.uid) {
        this.uid = this.$route.query.Uid;
        this.isShowUid = true;
      }
      if (this.$route.query.idBinance) {
        query.idBinance = this.$route.query.idBinance;
        this.uid = query.idBinance;
      }
      if (this.$route.query.depositHash) {
        query.DepositHash = this.$route.query.DepositHash;
        this.uid = query.DepositHash
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });

    },
    closeAllFilters() {
      this.partnerId = null;
      this.uid = null;
      this.startDate = null;
      this.endDate = null;
      this.isShowUid = false;
      this.statuses = [];
      let query = {page: this.page};

      this.$router.push({query: {page: 1}}).then(() => {
        this.getList(query);
        this.isSearch = false
      });
    },
    searchStatusToggle() {
      this.isStatusSearch = !this.isStatusSearch;
      this.isPartnerSearch = false
    },
    searchLoginHandler(el) {
      let query = {page: 1};
      if (el) {
        if (isNaN(el)) {
          if (Array.from(el.toString())[0] === '6') {
            query.SwapzoneId = el;
          } else {
            query.uid = el;
          }
        } else {
          if (el.length > 24) {
            query.DepositHash = el
          } else {
            query.idBinance = el;
          }
        }
        this.uidSearch = el;
        this.isSearch = true;
        this.uid = el;
        this.isShowUid = true;
        el = ''
        this.uidSearch = ''
      } else {
        this.isSearch = false
      }

      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      if (this.$route.query.idPartner) {
        query.idPartner = this.$route.query.idPartner;
      }

      if (this.$route.query.idBinance) {
        query.idBinance = this.$route.query.idBinance;
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
    },
    searchLoginHandlerButton(el) {
      let query = {page: 1};

      if (el) {
        if (isNaN(el)) {
          if (Array.from(el.toString())[0] === '6') {
            query.SwapzoneId = el;
          } else {
            query.uid = el;
          }
        } else {
          if (el.length > 24) {
            query.DepositHash = el
          } else {
            query.idBinance = el;
          }
        }
        this.uidSearch = el;
        this.isSearch = true;
        this.uid = el;
        this.isShowUid = true;

      } else {
        this.isSearch = false
      }


      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      if (this.$route.query.idPartner) {
        query.idPartner = this.uidSearch;
      }

      if (this.$route.query.idBinance) {
        query.idBinance = this.uidSearch;
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
      this.uidSearch = '';
    },
    searchPartnerHandler(el) {
      let query = {page: 1};

      if (el.target.value) {
        this.partnersList = this.partnersList.filter((partner) => {
          const login = partner.login.toLowerCase().indexOf(el.target.value.toLowerCase()) > -1;

          this.isSearch = true
          this.partnerId = login
          return login;
        });
      } else {
        this.partnersList = this.partnersListOrigin;
        this.isSearch = false
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
    },
    searchPartnerHandlerButton(el) {
      let query = {
        idPartner: null,
      };

      if (el) {
        query.idPartner = el.id;
        this.partnerIdSearch = el.id;
        this.partnerId = el.id
        this.partnerLogin = el.login
        this.isSearch = true;
        el = ''
        this.partnerIdSearch = ''

        this.isPartnerSearch = false

        if (this.$route.query.status) {
          query.status = this.statuses[0];
        }

        if (this.$route.query.uid) {
          query.status = this.uid;
        }

        query.page = 1;

        this.$router.push({query: query}).then(() => {
          this.getList(query);
        });

      } else {
        this.isSearch = false;
      }
    },
    mountSort() {
      if (this.$route.query.orderMode) {
        this.toggleSort({
          key: this.$route.query.orderColumn,
          sort: this.$route.query.orderMode === 'ASC' ? 'DESC' : 'ASC',
        });
      }
    },
    mountList() {
      const query = this.$route.query;

      if (query.uid) {
        this.uid = query.uid;
        this.isShowUid = true;
      }

      if (query.page) {
        this.page = parseInt(query.page);
      } else {
        const query = this.$route.query;
        query.page = 1;
      }

      if (query.idPartner) {
        this.partnerId = query.idPartner;
      }

      if (query.status) {
        this.statuses[0] = query.status;
      }

      if (query.depositHash) {
        query.DepositHash = this.$route.query.DepositHash;
        this.uid = query.DepositHash
      }

      if (query.StartDate) {
        this.startDate = query.StartDate;
        this.startDateData = query.StartDate;
      }

      if (query.EndDate) {
        this.endDate = query.EndDate;
        this.endDateData = query.EndDate
      }

      if (query.idBinance) {
        this.uid = query.idBinance
        this.isShowUid = true;
      }

      if (query.orderMode) {
        this.toggleSort({
          key: this.$route.query.orderColumn,
          sort: this.$route.query.orderMode === 'ASC' ? 'DESC' : 'ASC',
        });
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
    },
    mountStatus() {
      let status = this.getStatuses();

      if (status) {
        this.statuses = status.map((e) => parseInt(e));
      }
    },
    hideDropDownWhenClick() {
      document.addEventListener('click', (event) => {
        const el = document.querySelector('.eventBlock');
        const elBtn = document.querySelector('.eventBtn');

        if (el && elBtn) {
          const isEl = el.contains(event.target);
          const isELBtn = elBtn.contains(event.target);
          if (!isEl && !isELBtn) {
            this.isStatusSearch = false;
          }
        }
      });
    },
    timerHandler() {
      this.timerHandle = !this.timerHandle
    },
    timerRefresh() {
      this.exchangesData = [];
      this.page = 1;

      this.$router.push({query: this.$route.query}).then(() => {
        this.getList(this.$route.query);
        this.onUpdateHandler();
      });
    }
  },
}
</script>

<style lang="scss">
//@import "../../../../assets/styles/pages/exchanges/exchanges";
//@import "../../../../assets/styles/components/filter-dropdown";

.table-block-header-filters-filter-search {
  input {
    //max-width: 100% !important;
  }
}

.main-block .table-block .table-headers th .text {
  padding-bottom: 8px;
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1400px;
  margin-bottom: 25px;
  position: relative;

  &-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
    position: relative;

    &_block {
      cursor: pointer;
      padding: 0 10px;
      box-sizing: border-box;

      &:nth-child(2n) {
        background: #F8FAFB;
      }
    }

    &_details {
      display: flex;
      flex-direction: column;

      .swapzone, .binance, .inch {
        width: 100%;
      }
    }
  }

  &-cell {
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.2px;
  }

  &-info {
    width: 32px;
    margin-left: 12px;
    box-sizing: border-box;

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      z-index: 9;

      &-refresh {
        background: rgba(10, 104, 247, 0.06);
        border-radius: 8px;
        width: 32px;
        height: 32px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: rgba(10, 104, 247, 0.12);
        }

        svg {
          margin-left: 3px;
        }
      }

      &-deposit {
        background: rgba(10, 104, 247, 0.06);
        border-radius: 8px;
        width: 32px;
        height: 32px;
        font-size: 14px;
        line-height: 20px;
        color: #0A68F7;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all .3s ease-in-out;

        &:hover {
          background: rgba(10, 104, 247, 0.12);
        }
      }
    }
  }

  &-id {
    width: 48px;
    box-sizing: border-box;
    font-weight: 700;
  }

  &-uid {
    width: 94px;
    box-sizing: border-box;

    font-weight: 400;
  }

  &-idBinance {
    min-width: 90px;
    width: 6%;
  }

  &-id1inch {
    min-width: 90px;
  }

  &-from {
    position: relative;
    width: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #9FA2B4;
    }

    .hover-info {
      top: 90%;
      z-index: 999;
    }
  }

  &-to {
    width: 56px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #9FA2B4;
    }

    .hover-info {
      top: 90%;
      z-index: 999;
    }
  }

  &-mode {
    width: 56px;
  }

  &-modeRate {
    width: 56px;

  }

  &-partner {
    min-width: 90px;
    width: 7%;
  }

  &-amount {
    width: 104px;
  }

  &-companyFee {
    width: 104px;
  }

  &-partnerFee {
    width: 104px;
  }

  &-userAmount {
    width: 104px;
  }

  &-date {
    width: 72px;
  }

  &-status {
    min-width: 110px;
    margin-right: 12px;
    //z-index: 9;
    //position: relative;

    .buttons {
      display: flex;
      align-items: center;
      width: 110px;
      position: relative;
      justify-content: space-between;

      .dropdown {
        cursor: pointer;
        transition: all .3s ease-in-out;

        svg {
          path {
            transition: all .3s ease-in-out;
          }
        }

        &:hover {
          svg {
            path {
              fill: #0A68F7;
              fill-opacity: 1;
            }
          }
        }

        &-open {
          transform: rotate(-180deg);

          svg {
            path {
              fill: #0A68F7;
              fill-opacity: 1;
            }
          }
        }
      }

      .custom-select {
        width: auto !important;
      }
    }

  }

  &-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid transparent;
    padding-bottom: 12px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    &_cell {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: #9FA2B4;

      & + .list-info {
        padding-left: 0;
      }
    }
  }
}

.lastRows {
  .select-popup-item {
    .items {
      bottom: 0 !important;
    }
  }
}
</style>
