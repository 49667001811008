<script setup>

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4292_494)">
      <g clip-path="url(#clip1_4292_494)">
        <path
          d="M15.8333 2.5H12.35C12.35 2.5 8.73333 2.5 7.65 2.5H4.16667C4.05 2.5 3.94167 2.50833 3.83333 2.53333C3.50833 2.6 3.21667 2.76667 2.99167 2.99167C2.84167 3.14167 2.71667 3.325 2.63333 3.525C2.55 3.71667 2.5 3.93333 2.5 4.16667V15.8333C2.5 16.0583 2.55 16.2833 2.63333 16.4833C2.71667 16.6833 2.84167 16.8583 2.99167 17.0167C3.21667 17.2417 3.50833 17.4083 3.83333 17.475C3.94167 17.4917 4.05 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM9.16667 11.8083L7.99167 12.9917L5 10L7.99167 7.00833L9.16667 8.19167L7.35833 10L9.16667 11.8083ZM12.0083 12.9917L10.8333 11.8083L12.6417 10L10.8333 8.19167L12.0083 7.00833L15 10L12.0083 12.9917Z"
          fill="white"
          fill-opacity="0.54"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4292_494">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_4292_494">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(2 2)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
