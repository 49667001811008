<script setup>

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4241_71796)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66667 2.5H18.3333C19.25 2.5 20 3.25 20 4.16667L19.9917 15.8333C19.9917 16.75 19.25 17.5 18.3333 17.5H1.66667C0.75 17.5 0 16.75 0 15.8333V4.16667C0 3.25 0.75 2.5 1.66667 2.5ZM9.16667 7.5C9.16667 6.11667 8.05 5 6.66667 5C5.28333 5 4.16667 6.11667 4.16667 7.5C4.16667 8.88333 5.28333 10 6.66667 10C8.05 10 9.16667 8.88333 9.16667 7.5ZM1.66667 14.1667V15H11.6667V14.1667C11.6667 12.5 8.33333 11.5833 6.66667 11.5833C5 11.5833 1.66667 12.5 1.66667 14.1667ZM16.0664 13.2029V14H14.9414V13.1707C14.4657 13.055 13.405 12.6757 13 11.2679L14.0607 10.8371C14.0993 10.9786 14.4336 12.1807 15.6036 12.1807C16.2014 12.1807 16.8764 11.8721 16.8764 11.1457C16.8764 10.5286 16.4264 10.2071 15.4107 9.84071C15.3698 9.8262 15.3264 9.81115 15.2809 9.79538C14.5409 9.53883 13.2571 9.09371 13.2571 7.71286C13.2571 7.64857 13.2636 6.17 14.9414 5.81V5H16.0664V5.79714C17.2493 6.00286 17.68 6.94786 17.7764 7.23071L16.7607 7.66143C16.69 7.43643 16.3814 6.8 15.5393 6.8C15.0893 6.8 14.3757 7.03786 14.3757 7.69357C14.3757 8.30429 14.9286 8.53571 16.0729 8.915C17.6157 9.44857 18.0079 10.2329 18.0079 11.1329C18.0079 12.8236 16.4007 13.145 16.0664 13.2029Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4241_71796">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
