<script setup>

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4241_71779)">
      <path
        d="M3.32598 9.84798L0.162173 13.0232C-0.0540578 13.2508 -0.0540578 13.6036 0.162173 13.8312L3.32598 17.0064C3.67878 17.3706 4.29333 17.1088 4.29333 16.6081V14.5596H11.1331C11.759 14.5596 12.2711 14.0474 12.2711 13.4215C12.2711 12.7956 11.759 12.2834 11.1331 12.2834H4.29333V10.2463C4.29333 9.73418 3.67878 9.48381 3.32598 9.84798ZM19.8392 6.19481L16.6754 3.01963C16.3226 2.65545 15.7081 2.9172 15.7081 3.41795V5.45508H8.85695C8.23102 5.45508 7.71889 5.9672 7.71889 6.59314C7.71889 7.21907 8.23102 7.73119 8.85695 7.73119H15.6967V9.76832C15.6967 10.2804 16.3112 10.5308 16.664 10.1666L19.8278 6.99146C20.0555 6.77523 20.0555 6.41105 19.8392 6.19481Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4241_71779">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
