<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import RateCorrectionList from "@/pages/rate-correction/partials/RateCorrectionList.vue";
import RateCorrectionCreate from "@/pages/rate-correction/partials/RateCorrectionCreate.vue";
import RateCorrectionEdit from "@/pages/rate-correction/partials/RateCorrectionEdit.vue";
import FakeService from "@/services/fake.service";

export default {
  name: 'RateCorrection',
  components: {RateCorrectionEdit, RateCorrectionCreate, RateCorrectionList, AccountLayout},
  data() {
    return {
      rateCreate: false,
      rateData: null,
      isArchive: false
    }
  },
  watch: {
    '$route.params.id': 'getFakeByIdRoute',
  },
  mounted() {
    this.emitter.on('create-new-correction', (status) => {
      this.rateCreate = status
    });

    this.emitter.on('edit-correction', (data) => {
      this.rateData = data;


      if (data === null) {
        this.emitter.emit('update-rate-correction');
      }
    });
  },
  methods: {
    getFakeById(id) {
      FakeService.getFakeById(id).then((res) => {

        this.emitter.emit('edit-correction', res.data.result);
      })
    },

    getFakeByIdRoute() {
      const id = this.$route.params.id
      if (id) {
        FakeService.getFakeById(id).then((res) => {
          this.$router.push({name: 'Rate correction edit', params: {id: id}})
          this.emitter.emit('edit-correction', res.data.result);
        })
      } else {
        this.$router.push({name: 'Rate correction'});
        this.emitter.emit('edit-correction', null);
      }
    },
    changeTab(data) {
      if (data === 'archive') {
        this.isArchive = true
        this.emitter.emit('rate-correction-archive', true);
      } else {
        this.isArchive = false
        this.emitter.emit('rate-correction-archive', false);
      }
    }
  }
}
</script>

<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>Rate correction</h2>

        <div class="layout-header_tabs">
          <div
            class="layout-header_tabs-item"
            :class="{'layout-header_tabs-item--active': !isArchive}"
            @click="changeTab('default')"
          >
            Current
          </div>
          <div
            class="layout-header_tabs-item"
            :class="{'layout-header_tabs-item--active': isArchive}"
            @click="changeTab('archive')"
          >
            Archive
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <rate-correction-list />

      <rate-correction-create v-if="rateCreate" />
      <rate-correction-edit
        v-if="rateData"
        :data="rateData"
      />
    </template>
  </AccountLayout>
</template>

<style lang="scss">
@import "~@vueform/toggle/themes/default.css";

.rate-correction {

  .simplebar-scrollbar::before {
    opacity: 1;
    background-color: rgba(223, 224, 235, 0.70);
  }

  .toggle {
    width: var(--toggle-width, 22px);
    height: var(--toggle-height, 9px);
    outline: none;

    &-container {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .toggle-on {
    background: var(--toggle-bg-on, #02C076);
    border-color: var(--toggle-border-on, #02C076);
  }

  .toggle-handle-on {
    transform: translateX(-110%);
  }

  .toggle-handle {
    width: 7px;
    height: 7px;
    top: 1px;
  }

  .header {
    padding: 30px 31px 23px 31px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      color: #1B1A1C;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
    }

    .ctrl-panel {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .filters {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        .switcher, &_buttons {
          display: flex;
          flex-direction: row;
          gap: 8px;
        }

        .item {
          padding: 9px 11px;
          border-radius: 8px;
          border: 1px solid #F0F1F7;
          color: var(--buton-accent, #0A68F7);
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          background: #FCFDFE;
          cursor: pointer;
          transition: all .3s ease-in-out;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 8px;
          position: relative;

          svg {
            path {
              transition: all .3s ease-in-out;
            }
          }

          &--active {
            background: #0A68F7;
            color: #FFF;
            border: 1px solid #0A68F7;

            svg {
              path {
                fill: white;
              }
            }
          }

          &:hover {
            border: 1px solid #0A68F7;
          }
        }



        &_buttons {
          .item {
            padding-right: 20px;
          }

          &--item {
            position: relative;

            .dropdown {
              width: 264px;
              top: calc(100% + 4px);
              position: absolute;
              z-index: 9;
              border-radius: 12px;
              background: var(--grayscale-white, #FFF);
              box-shadow: 0px 4px 24px 0px rgba(15, 0, 58, 0.12);
              display: flex;
              flex-direction: column;

              .search {
                width: 100%;
                position: relative;
                padding: 11px;
                border-bottom: 1px solid #DFE0EB;
                box-sizing: border-box;

                svg {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto 0;
                  left: 20px;
                }

                input {
                  width: 100%;
                  box-sizing: border-box;
                  padding: 10px 10px 10px 45px;
                  border-radius: 8px;
                  border: 1px solid #F0F1F7;
                  background: #FCFDFE;
                }
              }

              &_list {
                overflow: auto;
                max-height: 376px;
                &--item {
                  padding: 8px 20px;
                  color: #1B1A1C;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; /* 171.429% */
                  cursor: pointer;
                  transition: all .3s ease-in-out;

                  &:hover {
                    background: rgba(27, 26, 28, 0.03);
                  }
                }

              }
            }
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .create {
          padding: 10px 24px;
          border-radius: 8px;
          border: 1px solid #F0F1F7;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          background: #0A68F7;
          color: #FFF;
          cursor: pointer;
        }

        .other {
          padding: 9px;
          border-radius: 8px;
          border: 1px solid #F0F1F7;
          background: #FCFDFE;
          display: flex;
          cursor: pointer;
          transition: all .3s ease-in-out;

          svg {
            path {
              transition: all .3s ease-in-out;
            }
          }

          &:hover {
            border: 1px solid #0A68F7;
          }
        }
      }
    }

    .filtersBlock {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .clear-all {
        padding: 6px 12px 6px 8px;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FF783E;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 6px;
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid #F0F1F7;
        transition: all .3s ease-in-out;

        &:hover {
          border: 1px solid #ff783e;
        }
      }

      .filters-selected {
        border-radius: 8px;
        border: 1px solid #F0F1F7;
        background: #FCFDFE;
        color: #1B1A1C;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 6px;
        padding: 6px 6px 6px 12px;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
          background: #f3f4f5;
        }

        &-close {
          display: flex;
        }

        span {
          color: #9FA2B4;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }

  .container {
    .corrections {
      &__item {
        border-top: 1px solid var(--grayscale-divider, #DFE0EB);


        .head {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .title {
            padding: 23px 0 24px 31px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            margin-bottom: 1px;
            cursor: pointer;
            user-select: none;




            &-text {
              color: #1B1A1C;
              font-family: 'Inter', sans-serif;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 120% */
              transition: all .3s ease-in-out;
            }

            .num {
              margin-top: 1px;
              height: 20px;
              border-radius: 4px;
              background: #02C076;
              min-width: 22px;
              display: grid;
              place-items: center;
              color: #FFF;
              text-align: center;
              font-family: 'Inter', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 900;
              line-height: 14px; /* 116.667% */
            }

            &:hover {
              .title {
                &-text {
                  color: #0A68F7;
                }
              }
            }
          }

          .buttons {
            padding-right: 29px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            .more {
              display: grid;
              place-items: center;
              width: 36px;
              height: 36px;
              cursor: pointer;

              svg {
                transition: all .3s ease-in-out;
              }
            }

            .switcher {
              height: 100%;
              width: 36px;
              display: grid;
              place-items: center;
            }
          }
        }

        &--active {
          .more {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }

      &_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
        padding: 0 29px 31px 31px;

        .rate_item {
          width: 342px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          border: 1px solid #DFE0EB;
          background: #FFF;

          .main {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 11px 15px 12px 15px;

            .name {
              width: 100%;
              cursor: pointer;

              .title {
                color: var(--text, #131521);
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                transition: all .3s ease-in;
              }

              .desc {
                color: #131521;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
                transition: all .3s ease-in;
              }

              &:hover {
                .title, .desc {
                  color: #0A68F7;
                }
              }
            }

            .buttons {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;

              &-archive, &-toggle {
                display: grid;
                place-items: center;
                width: 36px;
                height: 36px;
              }

              &-archive {
                cursor: pointer;
                svg {
                  path {
                    transition: all .3s ease-in-out;
                  }
                }

                &:hover {
                  svg {
                    path {
                      fill: #0A68F7;
                    }
                  }
                }
              }
            }
          }

          &-list {
            border-top: 1px solid #DFE0EB;
            padding: 16px 20px 15px 15px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 8px;

            &_block {
              display: flex;
              flex-direction: column;
              gap: 12px;
            }

            &--item {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              gap: 8px;

              .info {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                .num {
                  min-width: 36px;
                  padding: 0 4px;
                  box-sizing: border-box;
                  height: 36px;
                  border-radius: 8px;
                  opacity: 0.8;
                  color: #DFE0EB;
                  background: #FAFAFC;
                  text-align: center;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 18px; /* 128.571% */
                  display: grid;
                  place-items: center;

                  &-up {
                    background: #D6F5E9;
                    color: #02C076;
                  }

                  &-down {
                    background: rgba(255, 120, 62, 0.20);
                    color: #FF783E;
                  }
                }

                .text {
                  color: #131521;
                  font-family: 'Inter', sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 128.571% */
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                }
              }

              &_disabled {
                .info {
                  .text {
                    color: #7C86A0;
                  }
                }
              }
            }

            .show-more {
              transform: translateY(2px);
              padding: 4px 6px 0 6px;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;
              color: #0A68F7;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 142.857% */
              cursor: pointer;
            }
          }

          &-disabled {
            .main {
              .name {
                .title, .desc {
                  color: #7C86A0;
                }
              }
            }

            .rate_item-list {
              &--item {
                .info {
                  .text {
                    color: #7C86A0;
                  }
                }
              }
            }
          }


        }
      }
    }
  }
}
</style>
