import service from '../plugins/request';

class FakeService {

    getFakeList(query) {
        return service.get('/api/fake/list' + query)
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getFakeById(id) {
        return service.get(`/api/fake/${id}`)
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    createFake(query) {
        return service.post('/api/fake/create', query)
    }

    getFakeRowById(id) {
        return service.get(`/api/fake/row/${id}`)
    }

    updateFakeRowById(id, query) {
        return service.patch(`/api/fake/row/${id}`, query)
    }

    updateFake(query) {
        return service.patch('/api/fake/row', query)
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getFakeLogsById(id) {
        return service.get(`/api/fake/${id}/Logs/`)
    }

    updateFakeStatusById(id, query) {
        return service.patch(`/api/fake/status/${id}` + query)
    }

    updateRowStatusById(rowId, query) {
        return service.patch(`/api/fake/row/status/${rowId}` + query)
    }

    addNewRulesById(itemId, query) {
        return service.post(`/api/fake/${itemId}/row/create`, query)
    }

    deleteRuleById(rowId) {
        return service.delete(`/api/fake/row/${rowId}`)
    }

    archiveFake(itemId, query) {
        return service.patch(`/api/fake/archive/${itemId}` + query)
    }

    validateFake(query) {
        return service.post('/api/fake/validate', query)
    }
}

export default new FakeService();
