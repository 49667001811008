export const CHART_DATA = {
    chart: {
        zoomType: 'x',
        renderTo: 'container',
        resetZoomButton: {
            theme: {
                fill: 'white',
                stroke: 'RGB(252, 253, 254)',
                strokeWidth: 5,
                r: 6,
                lineWidth: 4,
                states: {
                    hover: {
                        fill: '#448AFF',
                        style: {
                            color: 'white'
                        }
                    }
                }
            }
        }
    },
    series: [
        {
            name: 'Company',
            type: 'area',
            animation: true,
            marker: {
                symbol: 'circle',
                lineWidth: 6,
                fillColor: '#FFFFFF',
                lineColor: null
            },
            lineWidth: 0,
            fillColor: {
                linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                stops: [
                    [0, 'rgba(68, 138, 255, 0.22)'], // start
                    [1, 'rgba(68, 138, 255, 0.08)'] // end
                ]
            },
            data: [],
        },
        {
            name: 'Partners',
            type: 'area',
            animation: true,
            marker: {
                symbol: 'circle',
                fillColor: '#FFFFFF',
                lineWidth: 6,
                lineColor: null
            },
            data: [],
            lineWidth: 0,
            fillColor: {
                linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                stops: [
                    [0, 'rgba(68, 138, 255, 0.22)'], // start
                    [1, 'rgba(68, 138, 255, 0.08)'] // end
                ]
            },
        },
        {
            name: 'Total',
            type: 'area',
            marker: {
                symbol: 'circle',
                lineWidth: 6,
                fillColor: '#FFFFFF',
                lineColor: null
            },
            data: [],
            lineWidth: 0,
            fillColor: {
                linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                stops: [
                    [0, 'rgba(2, 192, 118, 0.22)'], // start
                    [1, 'rgba(68, 138, 255, 0.08)'] // end
                ]
            },
        }
    ],
    title: '',
    credits: {
        enabled: false
    },
    colors: ['#448AFF', '#448AFF'],
    xAxis: {
        categories: [],
        type: 'datetime',
        title: true,
        labels: {
            format: '{value:,.0f}',
            style: {
                color: '#9FA2B4',
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px'
            }
        },
        minPadding: 0,
        maxPadding: 0,
        minRange: 0,
        visible: true,
        gridLineWidth: 0,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
    },
    yAxis: [
        {
            categories: [],
            type: 'datetime',
            title: true,
            tickInterval: 10,
            labels: {
                format: '{value:,.0f}',
                style: {
                    color: '#9FA2B4',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '12px'
                }
            },
            visible: true,
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            minPadding: 0,
            maxPadding: 0,
            minRange: 0,
            showFirstLabel: false
        },
    ],
    legend: {
        enabled: false
    },
    tooltip: {
        animation: true,
        backgroundColor: '#1B1A1C',
        borderRadius: 6,
        borderColor: '#1B1A1C',
        shadow: false,
        useHTML: true,
        headerFormat: '<table><tr><th colspan="2" style="color: white; font-size: 14px; font-weight: 800; ">{point.key}</th></tr>',
        pointFormat: '<tr><td style="color: white; font-weight: 300; padding-right: 15px">{series.name} </td>' +
            '<td style="text-align: right; font-weight: 300;"><b>{point.y} <span style="color: rgba(255, 255, 255, 0.6);" >BTC</span></b></td></tr>',
        footerFormat: '</table>',
        style: {
            color: 'white',
            fontWeight: 'bold',
            fontFamily: 'Inter, sans-serif',
            fontSize: '14px'
        },
    },
    plotOptions: {
        series: {
            states: {
                inactive: {
                    enabled: true,
                    opacity: 1,
                    lineWidth: 0,
                },
                hover: {
                    enabled: true,
                    lineWidth: 4,
                    opacity: 1,
                }
            },
            marker: {
                enabled: false,
                radius: .2,
                states: {
                    hover: {
                        radius: 2
                    }
                }
            },
        }
    },
};

export const FILTER_DATA = {
    day: 'day',
    today: 0,
    week: 'week',
    month: 'month',
    total: 99999,
    year: 'year',
};

export const FILTER_DATA_TOP = {
    day: 'day',
    today: 0,
    week: 'week',
    month: 'month',
    total: 99999,
    year: 'year',
};


