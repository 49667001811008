<template>
  <div class="partner-profit">
    <div class="main-block">
      <div class="table-block">
        <div class="table-block-header">
          <div class="table-block-header-title">
            Volume and profit by partners, BTC
          </div>
        </div>
        <div class="filters filters_margin">
          <div class="dates_range">
            <date-picker
              v-model:value="date"
              :clearable="false"
              :editable="false"
              default-panel="day"
              separator=" - "
              :format="dateFormat"
              placeholder="Reporting period"
              :month-format="'MMMM'"
              range
              input-class="dates-item"
              :class="{'dates_filled': date && currentDate === 'period', 'dates_range_active': currentDate === 'period'}"
              @change="setDateHandlerCustom('period')"
            >
              {{ dateText }}
              <template #icon-calendar>
                <calendar-icon />
              </template>
            </date-picker>
          </div>
          <div class="separator" />
          <div class="filters_block">
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'day'}"
              @click="setDateHandler('day'); date = ''"
            >
              Today / Yesterday / Week / Month
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'quarters'}"
              @click="setDateHandler('quarters')"
            >
              Q1 / Q2 / Q3 / Q4
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'year'}"
              @click="setDateHandler('year')"
            >
              Year
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'total'}"
              @click="setDateHandler('total')"
            >
              Total
            </div>
          </div>
        </div>
        <div
          v-if="dashboardStatsData"
          class="table-block-table"
        >
          <table class="table">
            <thead>
              <tr>
                <th
                  key="date"
                  scope="col"
                  class="table-cell-first table-cell-dates"
                >
                  <div class="text">
                    Partner
                  </div>
                </th>
                <th
                  v-for="(day, index) in datesSetter"
                  :key="index"
                  scope="col"
                  class="table-cell-dates table-cell-dates_centered"
                >
                  <div class="text">
                    {{ day }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(partner, index) in uniqueLogins"
                :key="index"
                class="table-row"
              >
                <td class="table-cell-first table-cell_partner">
                  <div class="text">
                    <b>{{ partner.login }}</b>
                    <span>Partner profit</span>
                    <span>Company profit</span>
                  </div>
                </td>
                <td
                  v-for="(date, id) in datesSetter"
                  :key="id"
                  class="table-cell_profits"
                >
                  <div
                    v-if="findObjectByLogin(date, partner.login)"
                    class="text"
                  >
                    <div class="text-value">
                      <div>
                        <b>{{
                          turnoverFormatter(findObjectByLogin(date, partner.login).turnover)
                        }}</b>
                      </div>
                      <div>
                        <span v-if="findObjectByLogin(date, partner.login).parnerProfit">{{
                          findObjectByLogin(date, partner.login).parnerProfit.toFixed(6)
                        }}</span>
                        <span v-else>-</span>
                      </div>
                      <div>
                        <span v-if="findObjectByLogin(date, partner.login).companyProfit">{{
                          findObjectByLogin(date, partner.login).companyProfit.toFixed(6)
                        }}</span>
                        <span v-else>-</span>
                      </div>
                    </div>
                    <div class="text-percentage">
                      <span>{{ findObjectByLogin(date, partner.login).cnt }} tr. <br></span>
                      <span>{{
                        calculatePartnerProfit(findObjectByLogin(date, partner.login))
                      }}</span>
                      <span>{{
                        calculateCompanyProfit(findObjectByLogin(date, partner.login))
                      }}</span>
                    </div>
                  </div>
                  <div
                    v-else
                    class="text text-empty"
                  >
                    <div class="text-value">
                      <span>—</span>
                      <span>—</span>
                      <span>—</span>
                    </div>
                    <div class="text-percentage">
                      <span>—</span>
                      <span>—</span>
                      <span>—</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="table-cell-first table-cell_partner table-cell_profits-total">
                  <div class="text">
                    <span class="total">Total:</span>
                    <span>Partner profit</span>
                    <span>Company profit</span>
                  </div>
                </td>
                <td
                  v-for="(data, id) in datesSetter"
                  :key="id"
                  class="table-cell_profits table-cell_profits-total"
                >
                  <div class="text">
                    <div class="text-value">
                      <span class="total">{{ calculateTurnOverSum(data) }}</span>
                      <span>{{ calculatePartnerProfitSum(data) }}</span>
                      <span>{{ calculateCompanyProfitSum(data) }}</span>
                    </div>
                    <div class="text-percentage">
                      <span class="total-percentage">{{ calculateCntSum(data) }} </span>
                      <span>{{ calculatePartnerProfitPercentage(data) }}</span>
                      <span>{{ calculateCompanyProfitPercentage(data) }}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import DatePicker from "vue-datepicker-next";
import CalendarIcon from "@/icons/calendarIcon.vue";
import OrderService from "@/services/order.service";

export default {
    name: "VolumeProfitTable",
    components: {
        CalendarIcon, DatePicker,
    },
    data() {
        return {
            isLoading: false,
            date: '',
            transformedDate: [],
            currentDate: '',
            datesSetter: '',
            dateFormat: 'DD.MM.YYYY',
            dateFormatUrl: 'YYYY-MM-DD',
            daysNames: ["Today", "Yesterday", "Last week", "Last month"],
            quarterNames: ["Q1 Jan - Mar", "Q2 Apr - Jun", "Q3 Jul - Sep", "Q4 Oct - Dec"],
            yearDates: ["Year"],
            totalDates: ["Total"],
            customDates: this.date,
            dashboardStatsData: null,
            uniqueLogins: null,
        }
    },
    computed: {
        dateText() {
            if (this.date) {
                return this.date;
            } else {
                return "Custom date";
            }
        },
    },
    watch: {
        currentDate(val) {
            switch (val) {
                case 'period':
                    let period = this.dataFormatting(this.date[0]) + '-' + this.dataFormatting(this.date[1]);
                    this.datesSetter = [period]
                    this.getCustomStatPartner();
                    break;
                case 'day':
                    this.datesSetter = this.daysNames;
                    this.getStatPartner();
                    break;
                case 'quarters':
                    this.datesSetter = this.quarterNames;
                    this.getStatPartner();
                    break;
                case 'year':
                    this.datesSetter = this.yearDates;
                    this.getStatPartner();
                    break;
                case 'total':
                    this.datesSetter = this.totalDates;
                    this.getStatPartner();
                    break;
                default:
                    break;
            }
        }
    },
    mounted() {
        this.currentDate = 'day'
        this.getStatPartner();
    },
    methods: {
        getStatPartner() {
            OrderService.getDashboardStatPartner().then((res) => {
                this.dashboardStatsData = res.data.result;
                this.uniqueLogins = this.extractUniqueLogins(this.dashboardStatsData);
            })
        },
        getCustomStatPartner() {
            const start = this.dateFormatter(this.date[0]);
            const end = this.dateFormatter(this.date[1]);

            let period = this.dataFormatting(this.date[0]) + '-' + this.dataFormatting(this.date[1]);

            this.transformedDate = [period]

            OrderService.getCustomDashboardStatPartner('/?start=' + start + '&end=' + end).then((res) => {
                this.dashboardStatsData = this.transformArrayToObject(res.data.result);
                this.uniqueLogins = this.extractUniqueLogins(this.dashboardStatsData);
            })
        },
        transformArrayToObject(data) {
            return {
                [this.transformedDate]: data
            };
        },
        daysSwitcher(date) {
            let period = this.dataFormatting(this.date[0]) + '-' + this.dataFormatting(this.date[1]);

            if (date === period) {
                return period;
            } else if (date === 'Today') {
                return 'today';
            } else if (date === 'Yesterday') {
                return 'yesterday';
            } else if (date === 'Last week') {
                return 'week';
            } else if (date === 'Last month') {
                return 'mounth';
            } else if (date === 'Year') {
                return 'year';
            } else if (date === 'Total') {
                return 'total';
            }

            return null;
        },
        findObjectByLogin(date, login) {
            const obj = this.dashboardStatsData;
            const dateKey = this.daysSwitcher(date);

            if (login === 'Direct') {
                if (obj.hasOwnProperty(dateKey)) {
                    const arr = obj[dateKey];
                    for (const item of arr) {
                        if (item.login === null) {
                            return item;
                        }
                    }
                }
            } else {
                if (obj.hasOwnProperty(dateKey)) {
                    const arr = obj[dateKey];
                    for (const item of arr) {
                        if (item.login === login) {
                            return item;
                        }
                    }
                }
            }
            return null;
        },
        extractUniqueLogins(data) {
            const uniqueLogins = [];
            for (const key in data) {
                if (Array.isArray(data[key])) {
                    const arr = data[key];
                    for (const item of arr) {
                        const login = item.login;
                        const isLoginNotNull = login !== null;
                        const isUniqueLogin = !uniqueLogins.some(obj => obj.login === login);
                        const isDirectiveLogin = login === null && !uniqueLogins.some(obj => obj.login === "Direct");

                        if (isLoginNotNull && isUniqueLogin) {
                            uniqueLogins.push({login: login});
                        } else if (isDirectiveLogin) {
                            uniqueLogins.push({login: "Direct"});
                        }
                    }
                }
            }
            return uniqueLogins;
        },
        calculatePartnerProfit(obj) {
            const result = (obj.parnerProfit / obj.turnover * 100).toFixed(2)
            if (result > 0) {
                return result + '%'
            } else {
                return '-'
            }
        },
        calculateCompanyProfit(obj) {
            const result = (obj.companyProfit / obj.turnover * 100).toFixed(2)
            if (result > 0) {
                return result + '%'
            } else {
                return '-'
            }
        },
        calculateCntSum(key) {
            let sum = 0;
            const obj = this.dashboardStatsData
            const dateKey = this.daysSwitcher(key)

            if (obj.hasOwnProperty(dateKey)) {
                const arr = obj[dateKey];
                for (const item of arr) {
                    sum += item.cnt;
                }
            }

            if (sum) {
                return sum + ' tr.';
            } else {
                return '-'
            }
        },
        calculateTurnOverSum(key) {
            let sum = 0;
            const obj = this.dashboardStatsData
            const dateKey = this.daysSwitcher(key)

            if (obj.hasOwnProperty(dateKey)) {
                const arr = obj[dateKey];
                for (const item of arr) {
                    const num = parseFloat(item.turnover);
                    sum += num;
                }
            }

            if (sum) {
                return sum.toFixed(6);
            } else {
                return '-'
            }
        },
        calculatePartnerProfitPercentage(key) {
            let profitSum = 0;
            let turnoverSum = 0;
            const obj = this.dashboardStatsData
            const dateKey = this.daysSwitcher(key)

            if (obj.hasOwnProperty(dateKey)) {
                const arr = obj[dateKey];
                for (const item of arr) {
                    profitSum += item.parnerProfit;
                    turnoverSum += parseFloat(item.turnover);
                }
            }

            if (profitSum && turnoverSum) {
                return ((profitSum / turnoverSum) * 100).toFixed(2) + '%';
            } else {
                return '-'
            }
        },
        calculateCompanyProfitPercentage(key) {
            let profitSum = 0;
            let turnoverSum = 0;
            const obj = this.dashboardStatsData
            const dateKey = this.daysSwitcher(key)

            if (obj.hasOwnProperty(dateKey)) {
                const arr = obj[dateKey];
                for (const item of arr) {
                    profitSum += item.companyProfit;
                    turnoverSum += parseFloat(item.turnover);
                }
            }

            if (profitSum && turnoverSum) {
                return ((profitSum / turnoverSum) * 100).toFixed(2) + '%';
            } else {
                return '-'
            }
        },
        calculatePartnerProfitSum(key) {
            let sum = 0;
            const obj = this.dashboardStatsData
            const dateKey = this.daysSwitcher(key)

            if (obj.hasOwnProperty(dateKey)) {
                const arr = obj[dateKey];
                for (const item of arr) {
                    sum += item.parnerProfit;
                }
            }

            if (sum) {
                return sum.toFixed(6);
            } else {
                return '-'
            }
        },
        calculateCompanyProfitSum(key) {
            let sum = 0;
            const obj = this.dashboardStatsData
            const dateKey = this.daysSwitcher(key)

            if (obj.hasOwnProperty(dateKey)) {
                const arr = obj[dateKey];
                for (const item of arr) {
                    sum += item.companyProfit;
                }
            }

            if (sum) {
                return sum.toFixed(6);
            } else {
                return '-'
            }
        },
        setDateHandler(dates) {
            this.currentDate = dates
        },
        setDateHandlerCustom(dates) {
            if (this.currentDate !== 'period') {
                this.currentDate = dates
            } else {
                let period = this.dataFormatting(this.date[0]) + '-' + this.dataFormatting(this.date[1]);
                this.datesSetter = [period]
                this.getCustomStatPartner();
            }
        },
        turnoverFormatter(val) {
            const num = parseFloat(val);
            if (num > 0 || num < 0) {
                return num.toFixed(6)
            } else {
                return '-'
            }
        },
        dataFormatting(value) {

            return dayjs(value).format(this.dateFormat)
        },
        dateFormatter(value) {
            return dayjs(value).format(this.dateFormatUrl)
        },
    }
}

</script>


<style lang="scss">
.partner-profit {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .table-block {

    .filters {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      padding: 0 32px;
      box-sizing: border-box;

      &_margin {
        margin-bottom: 24px;
      }

      .dates {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 4px;
        @media (max-width: 1079px) {
          overflow: auto;
          scrollbar-width: none;
        }

        &-item {
          padding: 6px 12px;
          font-size: 14px;
          font-weight: 500;
          background: #FCFDFE;
          border: 1px solid #F0F1F7;
          box-sizing: border-box;
          border-radius: 6px;
          color: #0A68F7;
          cursor: pointer;
          transition: all .3s ease-in-out;

          &:hover {
            border: 1px solid #0A68F7;
          }
        }

        .active {
          color: #FCFDFE;
          background: #0A68F7;
        }

        &_filled {
          .mx-input-wrapper {
            input {
              width: 23ch !important;
              height: 40px;
            }
          }

            .mx-icon-calendar {
                svg {
                    path {
                        fill: white !important;
                        fill-opacity: 100%;
                    }
                }
            }
        }

        &_range {
          background: none !important;

          .mx {
            &-datepicker {
              width: unset;

              &-popup, &-main {
                box-shadow: 0 4px 24px rgba(55, 49, 72, 0.12);
                border-radius: 12px;
                overflow: hidden;
                border: none;
              }
            }

            &-icon-calendar, &-icon-clear {
              left: 9px;
              right: unset;

            }

            &-datepicker svg {
              fill: none;
            }

            &-input-wrapper {
              input {
                &::placeholder {
                  color: #0A68F7;
                  opacity: 1;
                }

                width: 17ch !important;
              }

              .dates-item {
                padding-left: 30px !important;
              }

              .mx-icon-calendar {
                svg {
                  path {
                    fill: white;
                    fill-opacity: 100%;
                  }
                }
              }
            }
          }


          .dates-item {
            padding: 6px 8px;
          }

          &_active {
            .dates-item {
              color: #FCFDFE;
              background: #0A68F7;
            }

            .mx {
              &-icon-calendar, &-icon-clear {
              }

              &-datepicker svg path {
                fill: white !important;
              }

              &-input-wrapper {
                input {
                  &::placeholder {
                    color: white;
                    opacity: 1;
                  }

                  width: 16ch;
                }
              }
            }

            .mx-icon-calendar {
              svg {
                path {
                  fill: white;
                  fill-opacity: 100%;
                }
              }
            }
          }
        }
      }

      .separator {
        width: 1px;
        height: 20px;
        background: #DFE0EB;
        border-radius: 4px;
      }

      &_block {
        display: flex;
        align-items: center;
        gap: 9px;
      }

      &--item {
        user-select: none;
        padding: 10px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0A68F7;
        background: #FCFDFE;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
          border: 1px solid #0A68F7;
        }

        &-active {
          background: #0A68F7;
          border: 1px solid #0A68F7;
          color: white;
          cursor: default;
        }
      }
    }

    &-table {
      max-height: 100% !important;
      overflow: auto;

      &_chart {
        padding: 24px;
        box-sizing: border-box;

        .chart-block {
          width: 100%;
        }
      }

      thead {
        th {
          padding-bottom: 12px;
        }
      }

      tbody {
        position: relative;

        .table-row-fixed_last {
          height: 96px;
          background: white;
          position: sticky;
          bottom: 0;
          z-index: 1;

          td {
            font-weight: 700 !important;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2px;
            color: #1B1A1C !important;
          }
        }
      }

      .table {
        position: relative;
        margin-bottom: 0;

        &-row {
          border-bottom: 1px solid #DFE0EB;
          height: 80px;
        }

        &-cell-first {
          max-width: 130px;
          padding-left: 32px;
          color: #9FA2B4 !important;
          text-align: start;

          .text {

          }
        }

        &-cell {
          &_partner {
            .text {
              display: flex;
              flex-direction: column;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.2px;

              b {
                color: #1B1A1C;
              }
            }

            &-total {
              .text {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #1B1A1C;

                .total {
                  font-size: 20px;
                  line-height: 26px;
                  margin-bottom: 4px;
                }
              }
            }
          }

          &-dates {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2px;
            color: #9FA2B4;

            &_centered {
              text-align: center;
            }
          }

          &_profits {
            vertical-align: baseline;
            padding: 10px 0;

            .text {
              display: flex;
              flex-direction: row;
              justify-content: center;
              max-width: 208px;
              gap: 24px;
              margin: 0 auto;

              &-value {
                width: 100%;
                display: flex;
                flex-direction: column;

                div {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                  font-size: 14px;
                  line-height: 20px;

                  span {
                    text-align: end;
                  }
                }
              }

              &-percentage {
                width: 100%;
                display: flex;
                flex-direction: column;
                font-size: 14px;
                line-height: 20px;
              }

              &-empty {
                .text-value {
                  text-align: end;
                }

                .text-percentage {
                  text-align: start;
                }

                span {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 0.2px;
                  color: #9FA2B4;
                }
              }
            }

            &-total {
              height: 102px;
              vertical-align: middle;

              .text {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #1B1A1C;

                .total {
                  font-size: 20px;
                  line-height: 26px;
                  margin-bottom: 4px;

                  &-percentage {
                    height: 26px;
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 4px;
                  }
                }

                &-value {
                  span {
                    text-align: end;
                  }
                }
              }
            }
          }
        }

        .cell-partners {
          width: 20%;

          .text {
            font-weight: 700 !important;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2px;
            color: #1B1A1C !important;
          }
        }
      }
    }
  }

  .highcharts-legend-item_custom {
    background: #FCFDFE;
    border: 1px solid #F0F1F7;
    border-radius: 6px;
    padding: 4px 6px 4px 12px;

    &--text {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #1B1A1C;
    }
  }

  .highcharts-legend-item-hidden {
    .highcharts-legend-item_custom {
      color: #9FA2B4;
      background: none;
      border: 1px solid transparent;
    }
  }
}
</style>
