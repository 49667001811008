<script>
import AdminShift from "@/pages/dashboard/partials/AdminShift.vue";
import AccountLayout from "@/components/admin/AccountLayout.vue";
import RateRequestTable from "@/pages/rate-request/partials/RateRequestTable.vue";
import RateRequestChart from "@/pages/rate-request/partials/RateRequestChart.vue";
import RateRequestTableNotFound from "@/pages/rate-request/sub-pages/not-found/RateRequestTableNotFound.vue";
export default {
  name: 'RateRequestCoins',
  components: {RateRequestTableNotFound, AccountLayout},
}

</script>

<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>Rate request</h2>

        <div class="layout-header_tabs">
          <router-link
            :to="{ name: 'Rate request' }"
            class="layout-header_tabs-item"
          >
            By date
          </router-link>
          <router-link
            :to="{ name: 'Rate request pairs' }"
            class="layout-header_tabs-item"
          >
            By pairs
          </router-link>
          <div class="layout-header_tabs-item layout-header_tabs-item--active">
            Not found
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <RateRequestTableNotFound />
    </template>
  </AccountLayout>
</template>

<style lang="scss">

</style>
