<template>
  <div
    class="main-block exchangesListNew deposits-list"
  >
    <div
      ref="tableBlockRef"
      class="table-block"
    >
      <div
        ref="stickyBlock"
        class="table-block-header-exchange"
        :class="{ stickyHeader: isSticky }"
      >
        <div>
          <div class="filters-component-container">
            <div class="filters-component-left-side">
              <h2>Exchanges</h2>
            </div>
            <Timer
              :handle-timer-refresh="timerRefresh"
              :handle-select-timer-value="handleSelectTimerValue"
              :timer-handler="timerHandler"
              :timer-handle="timerHandle"
              :selected-timer="selectedTimer"
              :timer-value="timerValue"
              @click-outside="handleClickOutside"
            />
          </div>
        </div>
        <table class="sticky-table-head">
          <thead>
            <tr>
              <th
                v-for="(item, index) in tableTh"
                :id="item.id"
                :key="index"
                :class="'table-' + item.key"
              >
                <div
                  class="text"
                  @click="sortData(item)"
                  @mouseover="tokenTo = index"
                  @mouseleave="tokenTo = null"
                >
                  {{ item.name }}
                  <span v-if="item.isSortable">
                    <sort-a-z v-if="item.sort === 'ASC' && currentSortRow === item.key" />
                    <sort-z-a v-if="item.sort === 'DESC' && currentSortRow === item.key" />
                    <sort-passive v-if="currentSortRow !== item.key" />
                  </span>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        id="tableBlock"
        class="table-block-table"
        :class="[
          { 'without-height': isExchangesLoading || parsedExchangeData.length === 0 },
          { 'sticky': isSticky }
        ]"
      >
        <Transition name="committee">
          <table class="table">
            <thead>
              <tr class="table-headers">
                <th
                  v-for="(item, index) in tableTh"
                  :id="item.id"
                  :key="index"
                  scope="col"
                  :class="'table-' + item.key"
                >
                  <div
                    class="text"
                    @click="sortData(item)"
                    @mouseover="tokenTo = index"
                    @mouseleave="tokenTo = null"
                  >
                    {{ item.name }}
<!--                    <span v-if="item.isSortable">
                      <sort-a-z v-if="item.sort === 'ASC' && currentSortRow === item.key" />
                      <sort-z-a v-if="item.sort === 'DESC' && currentSortRow === item.key" />
                      <sort-passive v-if="currentSortRow !== item.key" />
                    </span>-->
                  </div>
                </th>
              </tr>
            </thead>
            <tbody
              v-if="!isExchangesLoading"
            >
              <ExchangesListItem
                v-for="(exchange) in parsedExchangeData"
                :key="exchange.id"
                :item="exchange"
                :is-open="openDropdownId === exchange.id"
                @toggle-dropdown="handleToggleDropdown"
                @close-dropdown="handleCloseDropdown"
              />
            </tbody>
          </table>
        </Transition>
        <span
          v-if="isExchangesLazyLoading"
          class="trade-loader-lazy-wrapper"
        >
          <trade-loader />
        </span>
      </div>
    <!--  paginator was here   -->
    </div>
    <trade-loader v-if="isExchangesLoading" />
    <TradeNoData v-if="!parsedExchangeData?.length && !isExchangesLoading" />
    <transition name="committee">
      <CopyNotification
        v-if="isUpdate"
        :text="updateText"
      />
    </transition>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SortZA from '@/icons/sorting/sort-z-a.vue';
import SortAZ from '@/icons/sorting/sort-a-z.vue';
import TradeNoData from '@/components/trade/components/TradeNoData.vue';
import SortPassive from '@/icons/sorting/sort-passive.vue';
import TradeLoader from '@/components/trade/components/TradeLoader.vue';
import ExchangesListItem from '@/pages/deposits/partials/ExchangesListItem.vue';
import {TABLE_TH} from '@/pages/deposits/exchangeData';
import Timer from '@/components/admin/Timer.vue';
import CopyNotification from '@/pages/notification/CopyNotification.vue';

export default {
  name: "ExchangesList",
  components: {
    CopyNotification,
    Timer,
    ExchangesListItem,
    TradeLoader,
    SortPassive,
    TradeNoData,
    SortAZ,
    SortZA,
  },
  props: {
    exchangesData: {
      type: Object,
      required: true,
    },
    isExchangesLoading: {
      type: Boolean,
      required: true,
    },
    isExchangesLazyLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggleDropdown', 'closeDropdown', 'update-is-exchanges-loading','update-is-exchanges-lazy-loading', 'update-list'],
  data() {
    return {
      tableTh: TABLE_TH,
      tokenTo: false,
      currentSortRow: null,
      // sticky header
      isSticky: false,
      parsedExchangeData: [],
      openDropdownId: null,
      // timer
      selectedTimer: {name: "Off", interval: null},
      timerValue: 0,
      timerHandle: false,
      timerId: null,
      isUpdate: false,
      updateText: '',
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  watch: {
    exchangesData: {
      handler: 'parsingExchangeData',
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // sticky header
    window.addEventListener('scroll', this.handleStickyScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleStickyScroll);
  },
  methods: {
    handleToggleDropdown(itemId) {
      if (this.openDropdownId === itemId) {
        this.openDropdownId = null;
      } else {
        this.openDropdownId = itemId;
      }
    },
    // sticky header
    handleStickyScroll() {
     /* const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      this.isSticky = scrollTop >= 105 && window.innerWidth > 819*/
    },
    parsingExchangeData(newData) {
      if (newData) {
        this.parsedExchangeData = newData
        this.$emit('update-is-exchanges-loading', false);
        this.$emit('update-is-exchanges-lazy-loading', false);
      } else {
        this.parsedExchangeData = []
      }
    },
    // sort
    /*sortData(type) {
      switch (type.key) {
        case 'id':
        case 'date':
        case 'status':
        case 'partner':

          this.$emit('update-is-exchanges-loading', true);
          this.toggleSort(type);

          let query = {...this.$route.query, page: this.page, orderMode: type.sort, orderColumn: type.key};

          this.$router.push({
            name: 'Exchanges',
            query: query
          }).then(() => {
            this.getList(query);
          });
          break;

        default:
          break;
      }

      this.parsedExchangeData = this.exchangesData?.result;
    },
    toggleSort(currentSort = {}) {
      this.tableTh = this.tableTh.map((item) => {
        if (currentSort.key === item.key) {
          this.currentSortRow = item.key
          item.sort = currentSort.sort === 'ASC' ? 'DESC' : 'ASC';
        }

        return item;
      });
    },*/
    handleCloseDropdown() {
      this.openDropdownId = null;
    },
    // auto update
    timerRefresh() {
      if (this.$route.name !== 'Deposits' || this.$store.state.shouldTimerStopped) {
        this.endProgress();
        this.startProgress()
        return false
      }
      this.endProgress();

      this.$emit('update-list')
      this.refreshPageKey = Date.now();
      if (this.selectedTimer.interval) {
        this.timerValue = 0;
        this.startProgress()
      }
      this.onUpdateHandler();
    },
    handleSelectTimerValue(item) {
      this.selectedTimer = item;
      clearInterval(this.timerId);

      if (item.interval) {
        this.startProgress();
        this.timerId = setInterval(this.timerRefresh, item.interval);
      } else {
        this.timerValue = 0
        this.endProgress();
      }
      this.timerHandler();
    },
    timerHandler() {
      this.timerHandle = !this.timerHandle
    },
    handleClickOutside() {
      this.timerHandle = false;
    },
    startProgress() {
      this.timerValue = 0;
      this.interval = setInterval(() => {
        this.timerValue += (100 / (this.selectedTimer.interval / 1000));
        if (this.timerValue >= 100) {
          this.timerValue = 0;
          clearInterval(this.interval);
        }
      }, 1000);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    onUpdateHandler() {
      this.updateText = 'List refreshed';

      this.isUpdate = true;

      setTimeout(() => {
        this.isUpdate = false
      }, 2000);
    },
}}
</script>

<style lang="scss">
@import '@/assets/styles/variables';

.deposits-list {
  min-height: auto;
  margin-top: 0;

  .table-block .table {
    margin-bottom: 16px;
  }

  .table-block .table tbody {
    tr:last-child {
      border-bottom: none;
    }
  }

  @media (max-width: 1079px) {
    /*margin-bottom: 33px;*/
  }
  }

.trade-loader-lazy-wrapper {
  >div {
    height: 176px;
  }
}
</style>
