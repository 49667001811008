<template>
  <div
    v-if="selectedCoin"
    class="coin-select input"
    @blur="isShowSelect = !isShowSelect"
  >
    <div @click="openList">
      <div class="selected-default selected">
        <img
          :src="domain + selectedCoin.image"
          alt=""
        >
        {{ selectedCoin.name }} - {{ selectedCoin.shotName }}
        <select-arrow />
      </div>
    </div>

    <div
      v-if="isShowSelect"
      class="dropdown"
    >
      <div class="search">
        <div class="input-search">
          <search-icon class="input-search_icon" />
          <input
            ref="search"
            v-model="search"
            type="text"
            :placeholder="'Search'"
          >
        </div>
      </div>
      <div class="list-coins">
        <div class="selected-coin">
          <div class="title-head">
            Selected
          </div>
          <div
            class="selected"
            @click="openList"
          >
            <img
              :src="domain + selectedCoin.image"
              alt=""
            >
            {{ selectedCoin.name }} - {{ selectedCoin.shotName }}

            <svg
              class="selected_icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_864_4286)">
                <path
                  d="M9.00012 16.2003L5.50012 12.7003C5.11012 12.3103 4.49012 12.3103 4.10012 12.7003C3.71012 13.0903 3.71012 13.7103 4.10012 14.1003L8.29012 18.2903C8.68012 18.6803 9.31012 18.6803 9.70012 18.2903L20.3001 7.70031C20.6901 7.31031 20.6901 6.69031 20.3001 6.30031C19.9101 5.91031 19.2901 5.91031 18.9001 6.30031L9.00012 16.2003Z"
                  fill="#0A68F7"
                />
              </g>
              <defs>
                <clipPath id="clip0_864_4286">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="all-coins">
          <div class="title-head">
            All coins
          </div>
          <div
            v-for="(item, index) in computedSelectData"
            :key="index"
            class="all-coins__item"
            @click="selectCoin(item)"
          >
            <img
              :src="domain + item.image"
              alt=""
            >

            {{ item.name }} - {{ item.shotName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SelectArrow from "@/icons/selectArrow.vue";
import SearchIcon from "@/icons/searchIcon.vue";
import {MAIN_DOMAIN} from "@/store/config";

export default {
    name: "CoinSelect",
    components: {SearchIcon, SelectArrow},
    props: {
        selectedCoin: {
            type: [Object, String],
        },
        data: {
            type: Array,
            required: true
        },
        defaultCoin: {
            type: String
        }
    },
    data() {
        return {
            isShowSelect: false,
            search: '',
            coinsLength: 100,
            domain: MAIN_DOMAIN
        }
    },
    computed: {
        computedSelectData() {
            if (this.search) {
                return this.data.filter(item => {
                    if (item.name.toLowerCase().indexOf(this.search.toLowerCase()) === 0 || item.shotName
                        .toLowerCase().indexOf(this.search.toLowerCase()) === 0) return item;
                });
            } else {
                return this.data.filter((item, index) => index < this.coinsLength && item.shotName !== this.selectedCoin.shotName);
            }
        },
    },
    mounted() {
        this.mountDefault()
    },
    methods: {
        mountDefault() {
            const defaultCoin = this.data.find(item => item.name === 'ETH')
            this.$emit('update:select', defaultCoin);
        },
        openList() {
            this.isShowSelect = !this.isShowSelect
        },
        selectCoin(coin) {
            this.$emit('update:select', coin);
            this.isShowSelect = false
        }
    }
}
</script>

<style lang="scss">
.coin-select {
  position: relative;
  padding: 0 !important;

  .selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: #1B1A1C;
    position: relative;

    img {
      max-width: 20px;
    }

    &-default {
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      color: #1B1A1C;

      img {
        max-width: 20px;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    &_icon {
      position: absolute;
      right: 0px !important;
      top: 0 !important;
      bottom: 0 !important;
      margin: auto 0;
    }
  }

  .dropdown {
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    background: white;
    z-index: 9;
    overflow: hidden;
    box-shadow: 0 4px 24px rgba(15, 0, 58, 0.12);
    border-radius: 12px;
    width: 250px;

    .search {
      padding: 12px;

      .input-search {
        position: relative;
        border-radius: 4px;
        background: #FFFFFF;
        border: 1px solid #DFE0EB;

        input {
          width: 100%;
          border: none;
          border-radius: 4px;
          padding: 11px 16px 11px 42px;
        }

        &_icon {
          position: absolute;
          top: 8px;
          left: 10px;

        }
      }
    }

    .list-coins {
      height: 320px;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: rgba(223, 224, 235, 0.7) transparent;
      display: flex;
      flex-direction: column;

      .title-head {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #9FA2B4;
        margin-bottom: 8px;
        padding: 0 12px;
      }

      .selected-coin {
        border-bottom: 1px solid #DFE0EB;
        width: 100%;
        padding-bottom: 8px;

        .selected {
          padding: 8px 16px;
        }
      }

      .all-coins {
        padding-top: 8px;

        &__item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 6px;
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #1B1A1C;
          padding: 8px 16px;
          transition: all .3s ease-in-out;

          img {
            max-width: 20px;
            border-radius: 50%;
            overflow: hidden;
          }

          &:hover {
            background: rgba(103, 61, 253, 0.03);
          }
        }
      }
    ;
    }
  }
}
</style>
