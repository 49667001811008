export const TABLE_TH_MULTI = [
    {
        name: 'ID',
        sort: 'DESC',
        id: 'list-id',
        key: 'id',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'UID',
        sort: 'DESC',
        id: 'list-uid',
        key: 'uid',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    // {
    //     name: 'ID Binance',
    //     sort: 'DESC',
    //     id: 'list-uid',
    //     key: 'idBinance',
    //     description: 'Trade round is the latest round of trade negotiations',
    //     isSortable: false,
    // },
    // {
    //     name: 'ID 1inch',
    //     sort: 'DESC',
    //     id: 'list-uid',
    //     key: 'id1inch',
    //     description: 'Trade round is the latest round of trade negotiations',
    //     isSortable: false,
    // },
    {
        name: 'From',
        sort: 'DESC',
        key: 'from',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'To',
        sort: 'DESC',
        key: 'to',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Mode',
        sort: 'DESC',
        key: 'mode',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Mode rate',
        sort: 'DESC',
        key: 'modeRate',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Partner',
        sort: 'DESC',
        key: 'partner',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Amount',
        sort: 'DESC',
        key: 'amount',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'User Amount',
        sort: 'DESC',
        key: 'userAmount',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Company fee',
        sort: '',
        key: 'companyFee',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Partner fee',
        sort: '',
        key: 'partnerFee',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Date',
        sort: 'DESC',
        key: 'date',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
    {
        name: 'Status',
        sort: 'DESC',
        key: 'status',
        description: 'Trade round is the latest round of trade negotiations',
        isSortable: false,
    },
];

export const STATUSES = [
    {
        name: 'Remaining',
        status: 0,
        cssClass: 'status-empty',
    },
    {
        name: 'Pay',
        status: 1,
        cssClass: 'status-pay',
    },
    {
        name: 'Exchange',
        status: 2,
        cssClass: 'status-exchange',
    },
    {
        name: 'Withdrawal',
        status: 5,
        cssClass: 'status-withdrawal',
    },
    {
        name: 'Completed',
        status: 6,
        cssClass: 'status-completed',
    },
    {
        name: 'Overdue',
        status: 10,
        cssClass: 'status-overdue',
    },
    {
        name: 'Returned',
        status: 11,
        cssClass: 'status-returned',
    },
    {
        name: 'Hold',
        status: 12,
        cssClass: 'status-hold',
    },
    {
        name: 'Profit',
        status: 13,
        cssClass: 'status-profit',
    },
];

export const ALLOW_STATUS = [1, 2, 3, 4, 5];
