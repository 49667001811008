<script>
export default {
  name: "RateCorrectionIcon"
}
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4686_84734)">
      <path
        d="M11.72 8.28L10 7.5L11.72 6.72L12.5 5L13.28 6.72L15 7.5L13.28 8.28L12.5 10L11.72 8.28ZM3.5 12L4.28 10.28L6 9.5L4.28 8.72L3.5 7L2.72 8.72L1 9.5L2.72 10.28L3.5 12ZM7 8L7.94 5.94L10 5L7.94 4.06L7 2L6.06 4.06L4 5L6.06 5.94L7 8ZM17.88 6.5L12.27 12.81L9 9.54L3 15.55L4.06 16.61L9 11.67L12.33 15L19 7.5L17.88 6.5Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4686_84734">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
