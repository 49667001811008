<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="28"
      cy="28"
      r="28"
      fill="#9FA2B4"
      fill-opacity="0.2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.2339 10.2363C35.2977 9.5916 36.6606 10.3402 36.687 11.5839L36.8474 19.1218L44.657 20.4681C45.8829 20.6794 46.4203 22.1385 45.6243 23.0944L40.4276 29.336L44.3741 32.5069C45.3438 33.286 45.1064 34.8227 43.9468 35.2729L36.8153 38.0418L37.6895 42.4491C37.9315 43.6693 36.7619 44.6939 35.5842 44.2934L30.497 42.5635C24.695 45.3811 17.7437 43.9203 13.5523 39.1396C19.7604 40.9319 24.7363 39.0584 27.0981 37.1219C27.6732 36.6504 27.7572 35.8019 27.2856 35.2268C26.8141 34.6517 25.9656 34.5677 25.3905 35.0393C23.43 36.6468 18.0752 38.7597 11.1436 35.3133C8.39218 28.881 10.7222 21.2391 16.8777 17.5084C19.5862 15.8668 22.6234 15.2323 25.5529 15.4977L34.2339 10.2363ZM21.6692 29.2795C20.3971 30.0504 17.7341 28.9758 16.9631 27.7038C16.1922 26.4318 16.5984 24.7756 17.8704 24.0047C19.1424 23.2337 23.6702 21.2255 24.4411 22.4975C25.2121 23.7695 22.9412 28.5085 21.6692 29.2795Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "EmptyAvatarCoin1inch"
}
</script>

<style scoped>

</style>
