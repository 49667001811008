<template>
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.32714 6L0.672861 6C0.0741587 6 -0.225192 5.2351 0.198608 4.78684L4.52406 0.208124C4.78642 -0.0693736 5.21358 -0.0693737 5.47593 0.208124L9.80139 4.78684C10.2252 5.2351 9.92584 6 9.32714 6Z"
      fill="#02C076"
    />
  </svg>
</template>

<script>
    export default {
        name: "UpIconBig"
    }
</script>

<style scoped>

</style>