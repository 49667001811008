import service from "@/plugins/request";

class RateService {

    getRateByPartner(query) {
        return service.get('/api/admin/report/reportByPartnerPair' + query)
    }


    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    requestByPartner(query) {
        return service.get('/api/admin/report/requestByPartner' + query)
    }

    requestByCoins(query) {
        return service.get('/api/admin/report/notFoundCoins' + query)
    }


}


export default new RateService;
