export const TABLE_TH = [
    {
        name: 'ID',
        sort: '',
        key: 'id',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Name',
        sort: '',
        key: 'name',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Is active',
        sort: '',
        key: 'isActive',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Is busy',
        sort: '',
        key: 'isBusy',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Last Used',
        sort: '',
        key: 'lastUpdateDate',
        description: 'Trade round is the latest round of trade negotiations'
    },
];
