<template>
  <trade-popup-layout :is-header="false">
    <template #tradePopupSlot>
      <TradeHeaderLayout />
      <div class="collect-profit">
        <div class="text-block">
          <div class="title">
            Do you really want to collect all the profit?
          </div>
          <div class="desc">
            USDT balances of all sub-accounts of the selected exchange will be transferred to one sub-account
          </div>
          <div
            v-if="UserAccList && UserAcc"
            class="acc"
          >
            <span v-html="logos[UserAcc.modeAccType]" />
            <div class="text">
              <div class="acc-name">
                {{ UserAcc.modeAccType }}
              </div>
              <div class="acc-length">
                {{ accLength }} sub-accounts
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="UserAcc"
          class="buttons-block"
        >
          <div

            class="button"
            @click="collectHandler(UserAcc.modeAccType)"
          >
            Collect {{ UserAcc.modeAccType }} profit
          </div>
          <div
            class="button button_black"
            @click="returnPage()"
          >
            Cancel
          </div>
        </div>
      </div>
    </template>
  </trade-popup-layout>
</template>

<script>

import TradePopupLayout from "@/layouts/trade/TradePopupLayout.vue";
import TradeHeaderLayout from "@/components/trade/TradeHeaderLayout.vue";
import serviceLogos from "@/assets/img/servicesLogos/serviceLogos";
import TradeService from "@/services/trade.service";
import MultiExchangeService from "@/services/multiExchange.service";

export default {
  name: 'CollectProfit',
  components: {TradeHeaderLayout, TradePopupLayout},
  data() {
    return {
      accLength: 0,
      logos: serviceLogos,
    }
  },
  computed: {
    UserAcc() {
      return this.$store.state.userAcc
    },
    UserAccList() {
      return this.$store.state.userAccList;
    }
  },
  watch: {
    '$route.params.id': 'calcAccList'
  },
  mounted() {
    this.calcAccList();
  },
  methods: {
    calcAccList() {
      if (this.UserAccList && this.UserAcc) {
        this.accLength = this.UserAccList.filter((item) => {
          return item.modeAccType === this.UserAcc.modeAccType
        }).length
      } else if (!this.UserAccList && !this.UserAcc) {
        MultiExchangeService.getAccountsList().then((res) => {
          const users = res.data.result;
          const idToFind = parseFloat(this.$route.params.id);
          const foundUserAcc = users.find(userAcc => userAcc.id === idToFind);

          if (foundUserAcc) {
            this.accLength = users.filter((item) => {
              return item.modeAccType === foundUserAcc.modeAccType
            }).length
          }
        });
      }
    },
    collectHandler(acc) {
      const query = '?modeAccType=' + acc
      TradeService.collectProfit(query).then((res) => {
        if (res.status === 200) {
          this.$router.push({
            name: 'Trade orders',
            params: {
              id: this.$route.params.id,
            }
          })
        }
      })
    },
    returnPage() {
      this.$router.push({
        name: 'Trade orders',
        params: {
          id: this.$route.params.id,
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .collect-profit {
      border-radius: 20px;
      background: #FFF;
      box-shadow: 0 4px 48px 0 rgba(6, 59, 122, 0.08), 0px 1px 4px 0px rgba(6, 59, 122, 0.06);
      display: flex;
      flex-direction: column;
      max-width: 360px;
      gap: 32px;
      margin: 0 auto;
      padding: 28px 32px 32px;
      box-sizing: border-box;

      .text-block {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
          color: #1A242B;
          font-family: 'Rubik',sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 116.667% */
          letter-spacing: -0.4px;
        }

        .desc {
          color: var(--trade-trade-gray, #527AA1);
          font-family: 'Rubik',sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }

        .acc {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;

          .text {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .acc-name {
              color: var(--trade-trade-black, #1A242B);
              text-overflow: ellipsis;
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px; /* 114.286% */
            }

            .acc-length {
              overflow: hidden;
              color: var(--trade-trade-gray, #527AA1);
              text-overflow: ellipsis;
              font-family: 'Rubik', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
            }
          }
        }
      }

      .buttons-block {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .button {
          padding: 14px 0;
          width: 100%;
          border-radius: 6px;
          border: 1px solid rgba(130, 170, 200, 0.24);
          background: var(--trade-trade-blue, #007AFF);
          color: #FFF;
          font-family: 'Rubik', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.2px;
          text-align: center;
          cursor: pointer;

          &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), #007AFF;
          }

          &_black {
            background: var(--trade-trade-black, #1A242B);

            &:hover {
              background: linear-gradient(0deg, #0E1418 0%, #0E1418 100%), #1A242B;
            }
          }
        }

      }
    }
</style>
