<template>
  <trade-popup-layout title="Transfer">
    <template #tradePopupSlot>
      <div class="create-order create-order_transfer">
        <div class="create-order_tab create-order_inputs">
          <div class="title">
            From
          </div>
          <!--switchers-->
          <div
            class="switchers"
          >
            <div
              v-for="(mode, index) in switchersMode"
              :key="index"
              class="switchers--item"
              :class="{'switchers--item_active': mode.name === selectedModeFrom.name}"
              @click="switchModeFrom(mode)"
            >
              {{ mode.name }}
            </div>
          </div>
          <!--inputs-->
          <div class="order">
            <div
              v-if="selectedCoinFrom"
              class="inputs"
            >
              <!--Selected Account from-->
              <div
                v-if="selectedAccFrom"
                ref="accountListFrom"
                class="inputs--item inputs--item_select"
              >
                <div
                  class="selected"
                  @click="isDropAccFrom = !isDropAccFrom"
                >
                  <div class="selected_coin_info">
                    <div
                      class="selected_coin"
                    >
                      <span v-html="logos[UserAcc.modeAccType]" />
                      <div class="selected_coin-text">
                        <div class="selected_coin-text--coin">
                          {{ selectedAccFrom.name }}
                        </div>
                        <div class="selected_coin-text--fullName">
                          {{ UserAcc.modeAccType }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="arrow"
                  @click="isDropAccFrom = !isDropAccFrom"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3860_5848)">
                      <path
                        d="M7 10L12 15L17 10H7Z"
                        fill="#1A242B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3860_5848">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(0 1 -1 0 24 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div
                  v-if="isDropAccFrom"
                  class="inputs--item_select--dropdown"
                >
                  <div class="list-searchBlock">
                    <div class="list--search">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4217_51319)">
                          <path
                            d="M16.3243 15.2488L12.7386 11.6483C13.6605 10.5902 14.1657 9.25887 14.1657 7.8729C14.1657 4.63474 11.4369 2.00018 8.08284 2.00018C4.72882 2.00018 2 4.63474 2 7.8729C2 11.1111 4.72882 13.7456 8.08284 13.7456C9.34199 13.7456 10.5419 13.379 11.5678 12.6829L15.1807 16.3107C15.3317 16.4621 15.5349 16.5456 15.7525 16.5456C15.9585 16.5456 16.154 16.4698 16.3023 16.3319C16.6176 16.0391 16.6277 15.5534 16.3243 15.2488ZM8.08284 3.5322C10.562 3.5322 12.5789 5.47939 12.5789 7.8729C12.5789 10.2664 10.562 12.2136 8.08284 12.2136C5.60369 12.2136 3.58683 10.2664 3.58683 7.8729C3.58683 5.47939 5.60369 3.5322 8.08284 3.5322Z"
                            fill="#527AA1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4217_51319">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <input
                        v-model="searchAccFrom"
                        type="text"
                        placeholder="Search"
                      >
                    </div>
                  </div>
                  <div
                    v-for="(acc, index) in filteredAccountListFrom"
                    :key="index"
                    class="list--item"
                    :class="{'list--item-selected': selectedAccFrom && selectedAccFrom.name === acc.name}"
                    @click="selectAccFromHandler(acc)"
                  >
                    <div class="info">
                      <span v-html="logos[acc.modeAccType]" />
                      <div class="info_text">
                        <div class="info_text--coin">
                          {{ acc.name }}
                        </div>
                        <div class="info_text--fullName">
                          {{ acc.modeAccType }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--Selected Coin from-->
              <div
                ref="coinsListFrom"
                class="inputs--item inputs--item_select"
                :class="{'focus': !!isDropCoinsFrom}"
                @click="isDropCoinsFrom = !isDropCoinsFrom"
              >
                <div class="selected">
                  <div class="selected_coin_info">
                    <div
                      class="selected_coin"
                    >
                      <img
                        :src="apiUrl + selectedCoinFrom.coin.image"
                        alt=""
                      >
                      <div class="selected_coin-text">
                        <div class="selected_coin-text--coin">
                          {{ selectedCoinFrom.coin.symbol }}
                        </div>
                        <div class="selected_coin-text--fullName">
                          {{ selectedCoinFrom.coin.fullName }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="selected_coin_balance">
                    {{ selectedCoinFrom.balance }} <br>
                    <span>
                      USDT {{ selectedCoinFrom.balanceUsdt }}
                    </span>
                  </div>
                </div>
                <div class="arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3860_5848)">
                      <path
                        d="M7 10L12 15L17 10H7Z"
                        fill="#1A242B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3860_5848">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(0 1 -1 0 24 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <simplebar
                  v-if="isDropCoinsFrom"
                  ref="coinsListFrom"
                  class="inputs--item_select--dropdown"
                  data-simplebar-auto-hide="false"
                >
                  <div
                    v-for="(coin, index) in coinListFrom"
                    :key="index"
                    class="list--item"
                    :class="{'list--item-selected': selectedCoinFrom && selectedCoinFrom.coin.symbol === coin.coin.symbol}"
                    @click="selectCoinHandler(coin)"
                  >
                    <div class="info">
                      <img
                        :src="apiUrl + coin.coin.image"
                        alt=""
                      >
                      <div class="info_text">
                        <div class="info_text--coin">
                          {{ coin.coin.symbol }}
                        </div>
                        <div class="info_text--fullName">
                          {{ coin.coin.fullName }}
                        </div>
                      </div>
                    </div>

                    <div class="list--item_balance">
                      {{ coin.balance }} <br>
                      <span>
                        USDT {{ coin.balanceUsdt }}
                      </span>
                    </div>
                  </div>
                </simplebar>
                <simplebar
                  v-if="isDropCoinsFrom && selectedModeFrom.name === 'Spot'"
                  ref="coinsListFrom"
                  class="inputs--item_select--dropdown"
                  data-simplebar-auto-hide="false"
                >
                  <div
                    v-for="(coin, index) in coinListFrom"
                    :key="index"
                    class="list--item"
                    :class="{'list--item-selected': selectedCoinFrom && selectedCoinFrom.coin.symbol === coin.coin.symbol}"
                    @click="selectCoinHandler(coin)"
                  >
                    <div class="info">
                      <img
                        :src="apiUrl + coin.coin.image"
                        alt=""
                      >
                      <div class="info_text">
                        <div class="info_text--coin">
                          {{ coin.coin.symbol }}
                        </div>
                        <div class="info_text--fullName">
                          {{ coin.coin.fullName }}
                        </div>
                      </div>
                    </div>

                    <div class="list--item_balance">
                      {{ coin.balance }} <br>
                      <span>
                        USDT {{ coin.balanceUsdt }}
                      </span>
                    </div>
                  </div>
                </simplebar>
                <simplebar
                  v-if="isDropCoinsFrom && selectedModeFrom.name === 'Funding'"
                  ref="coinsListFrom"
                  class="inputs--item_select--dropdown"
                  data-simplebar-auto-hide="false"
                >
                  <div
                    v-for="(coin, index) in coinListFrom"
                    :key="index"
                    class="list--item"
                    :class="{'list--item-selected': selectedModeFrom && selectedModeFrom.coin.symbol === coin.coin.symbol}"
                    @click="selectCoinHandler(coin)"
                  >
                    <div class="info">
                      <img
                        :src="apiUrl + coin.coin.image"
                        alt=""
                      >
                      <div class="info_text">
                        <div class="info_text--coin">
                          {{ coin.coin.symbol }}
                        </div>
                        <div class="info_text--fullName">
                          {{ coin.coin.fullName }}
                        </div>
                      </div>
                    </div>

                    <div class="list--item_balance">
                      {{ coin.balance }} <br>
                      <span>
                        USDT {{ coin.balanceUsdt }}
                      </span>
                    </div>
                  </div>
                </simplebar>
              </div>
              <div class="item-error">
                <div
                  class="inputs--item inputs--item_amount"
                  :class="{'focus': !!amount, 'error': amount > selectedCoinFrom.balance}"
                  @click="focusOn('amount')"
                >
                  <div class="input">
                    <div class="desc">
                      Amount
                    </div>
                    <input
                      ref="amount"
                      v-model="amount"
                      type="text"
                    >
                  </div>
                  <div class="coin">
                    {{ selectedCoinFrom.coin.symbol }}
                  </div>
                </div>
                <div
                  v-if="amount > selectedCoinFrom.balance"
                  class="error-text"
                >
                  Insufficient funds
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="create-order_tab create-order_inputs">
          <div class="title">
            To
          </div>
          <!--switchers-->
          <div
            class="switchers"
          >
            <div
              v-for="(mode, index) in switchersMode"
              :key="index"
              class="switchers--item"
              :class="{'switchers--item_active': mode.name === selectedModeTo.name}"
              @click="switchModeTo(mode)"
            >
              {{ mode.name }}
            </div>
          </div>
          <!--inputs-->
          <div class="order">
            <div
              v-if="selectedCoinTo"
              class="inputs"
            >
              <!--Selected Account from-->
              <div
                v-if="selectedAccTo"
                ref="accountListTo"
                class="inputs--item inputs--item_select"
              >
                <div
                  class="selected"
                  @click="isDropAccTo = !isDropAccTo"
                >
                  <div class="selected_coin_info">
                    <div
                      class="selected_coin"
                    >
                      <span v-html="logos[UserAcc.modeAccType]" />
                      <div class="selected_coin-text">
                        <div class="selected_coin-text--coin">
                          {{ selectedAccTo.name }}
                        </div>
                        <div class="selected_coin-text--fullName">
                          {{ UserAcc.modeAccType }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="arrow"
                  @click="isDropAccTo = !isDropAccTo"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3860_5848)">
                      <path
                        d="M7 10L12 15L17 10H7Z"
                        fill="#1A242B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3860_5848">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(0 1 -1 0 24 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  v-if="isDropAccTo"
                  class="inputs--item_select--dropdown"
                >
                  <div class="list-searchBlock">
                    <div class="list--search">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4217_51319)">
                          <path
                            d="M16.3243 15.2488L12.7386 11.6483C13.6605 10.5902 14.1657 9.25887 14.1657 7.8729C14.1657 4.63474 11.4369 2.00018 8.08284 2.00018C4.72882 2.00018 2 4.63474 2 7.8729C2 11.1111 4.72882 13.7456 8.08284 13.7456C9.34199 13.7456 10.5419 13.379 11.5678 12.6829L15.1807 16.3107C15.3317 16.4621 15.5349 16.5456 15.7525 16.5456C15.9585 16.5456 16.154 16.4698 16.3023 16.3319C16.6176 16.0391 16.6277 15.5534 16.3243 15.2488ZM8.08284 3.5322C10.562 3.5322 12.5789 5.47939 12.5789 7.8729C12.5789 10.2664 10.562 12.2136 8.08284 12.2136C5.60369 12.2136 3.58683 10.2664 3.58683 7.8729C3.58683 5.47939 5.60369 3.5322 8.08284 3.5322Z"
                            fill="#527AA1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4217_51319">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <input
                        v-model="searchAccTo"
                        type="text"
                        placeholder="Search"
                      >
                    </div>
                  </div>
                  <div class="list_items">
                    <div
                      v-for="(acc, index) in filteredAccountListTo"
                      :key="index"
                      class="list--item"
                      :class="{'list--item-selected': selectedAccTo && selectedAccTo.name === acc.name}"
                      @click="selectAccToHandler(acc)"
                    >
                      <div class="info">
                        <span v-html="logos[acc.modeAccType]" />
                        <div class="info_text">
                          <div class="info_text--coin">
                            {{ acc.name }}
                          </div>
                          <div class="info_text--fullName">
                            {{ acc.modeAccType }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ref="coinsList"
                class="inputs--item inputs--item_select"
              >
                <div class="selected">
                  <div class="selected_coin_info">
                    <div
                      class="selected_coin"
                    >
                      <img
                        :src="apiUrl + selectedCoinTo.coin.image"
                        alt=""
                      >
                      <div class="selected_coin-text">
                        <div class="selected_coin-text--coin">
                          {{ selectedCoinTo.coin.symbol }}
                        </div>
                        <div class="selected_coin-text--fullName">
                          {{ selectedCoinTo.coin.fullName }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="selected_coin_balance">
                    {{ selectedCoinTo.balance }} <br>
                    <span>
                      USDT {{ selectedCoinTo.balanceUsdt }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="inputs--item inputs--item_amount inputs--item_amount-disabled"
                :class="{'focus': amount}"
              >
                <div class="input">
                  <div class="desc">
                    Amount
                  </div>
                  <input
                    v-model="amount"
                    readonly
                    type="text"
                  >
                </div>
                <div class="coin">
                  {{ selectedCoinTo.coin.symbol }}
                </div>
              </div>
            </div>
            <div
              v-if="!selectedCoinTo && selectedCoinFrom"
              ref="accountListTo"
              class="inputs"
            >
              <div
                v-if="selectedAccTo"
                class="inputs--item inputs--item_select"
              >
                <div
                  class="selected"
                  @click="isDropAccTo = !isDropAccTo"
                >
                  <div class="selected_coin_info">
                    <div
                      class="selected_coin"
                    >
                      <span v-html="logos[UserAcc.modeAccType]" />
                      <div class="selected_coin-text">
                        <div class="selected_coin-text--coin">
                          {{ selectedAccTo.name }}
                        </div>
                        <div class="selected_coin-text--fullName">
                          {{ UserAcc.modeAccType }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="arrow"
                  @click="isDropAccTo = !isDropAccTo"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3860_5848)">
                      <path
                        d="M7 10L12 15L17 10H7Z"
                        fill="#1A242B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3860_5848">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(0 1 -1 0 24 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  v-if="isDropAccTo"
                  class="inputs--item_select--dropdown"
                >
                  <div class="list-searchBlock">
                    <div class="list--search">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4217_51319)">
                          <path
                            d="M16.3243 15.2488L12.7386 11.6483C13.6605 10.5902 14.1657 9.25887 14.1657 7.8729C14.1657 4.63474 11.4369 2.00018 8.08284 2.00018C4.72882 2.00018 2 4.63474 2 7.8729C2 11.1111 4.72882 13.7456 8.08284 13.7456C9.34199 13.7456 10.5419 13.379 11.5678 12.6829L15.1807 16.3107C15.3317 16.4621 15.5349 16.5456 15.7525 16.5456C15.9585 16.5456 16.154 16.4698 16.3023 16.3319C16.6176 16.0391 16.6277 15.5534 16.3243 15.2488ZM8.08284 3.5322C10.562 3.5322 12.5789 5.47939 12.5789 7.8729C12.5789 10.2664 10.562 12.2136 8.08284 12.2136C5.60369 12.2136 3.58683 10.2664 3.58683 7.8729C3.58683 5.47939 5.60369 3.5322 8.08284 3.5322Z"
                            fill="#527AA1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4217_51319">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <input
                        v-model="searchAccTo"
                        type="text"
                        placeholder="Search"
                      >
                    </div>
                  </div>
                  <div
                    v-for="(acc, index) in filteredAccountListTo"
                    :key="index"
                    class="list--item"
                    :class="{'list--item-selected': selectedAccTo && selectedAccTo.name === acc.name}"
                  >
                    <div class="info">
                      <span v-html="logos[acc.modeAccType]" />
                      <div class="info_text">
                        <div class="info_text--coin">
                          {{ acc.name }}
                        </div>
                        <div class="info_text--fullName">
                          {{ acc.modeAccType }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref="coinsList"
                class="inputs--item inputs--item_select"
                :class="{'focus': !!isDropCoinsTo}"
                @click="isDropCoinsTo = !isDropCoinsTo"
              >
                <div class="selected">
                  <div class="selected_coin_info">
                    <div
                      class="selected_coin"
                    >
                      <img
                        :src="apiUrl + selectedCoinFrom.coin.image"
                        alt=""
                      >
                      <div class="selected_coin-text">
                        <div class="selected_coin-text--coin">
                          {{ selectedCoinFrom.coin.symbol }}
                        </div>
                        <div class="selected_coin-text--fullName">
                          {{ selectedCoinFrom.coin.fullName }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="selected_coin_balance">
                    0 <br>
                    <span>
                      USDT 0
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="inputs--item inputs--item_amount inputs--item_amount-disabled"
                :class="{'focus': amount}"
              >
                <div class="input">
                  <div class="desc">
                    Amount
                  </div>
                  <input
                    v-model="amount"
                    readonly
                    type="text"
                  >
                </div>
                <div class="coin">
                  {{ selectedCoinFrom.coin.symbol }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="selectedCoinFrom && selectedModeTo"
            class="button"
            @click="createTransfer()"
          >
            Transfer {{ selectedCoinFrom.coin.symbol }} to {{ selectedModeTo.name }}
          </div>
        </div>
      </div>
    </template>
  </trade-popup-layout>
</template>
<script>
import TradePopupLayout from "@/layouts/trade/TradePopupLayout.vue";
import {MAIN_DOMAIN} from "@/store/config";
import TradeService from "@/services/trade.service";
import UserService from "@/services/user.service";
import serviceLogos from "@/assets/img/servicesLogos/serviceLogos";
import MultiExchangeService from "@/services/multiExchange.service";
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';

export default {
  name: 'CreateTransfer',
  components: {
    simplebar,
    TradePopupLayout
  },
  data() {
    return {
      switchersMode: [
        {
          name: 'Spot',
          key: 'trade'
        },
        {
          name: 'Funding',
          key: 'funding'
        }
      ],
      selectedModeFrom: {
        name: 'Spot',
        key: 'trade'
      },
      selectedModeTo: {
        name: 'Funding',
        key: 'funding'
      },
      isDropCoinsFrom: false,
      isDropCoinsTo: false,
      apiUrl: MAIN_DOMAIN,
      selectedCoinFrom: null,
      selectedCoinTo: null,
      amount: '',
      coinsList: null,
      coinListFrom: null,
      defaultCoin: '',
      accountList: null,
      logos: serviceLogos,
      selectedAccFrom: null,
      selectedAccTo: null,
      isDropAccTo: false,
      isDropAccFrom: false,
      searchAccFrom: '',
      searchAccTo: '',
      UserAcc: null,
      UserAccList: null,
    }
  },
  computed: {
    filteredAccountListFrom() {
      const searchText = this.searchAccFrom.toLowerCase();
      return this.accountList.filter((acc) =>
          acc.name.toLowerCase().includes(searchText)
      );
    },
    filteredAccountListTo() {
      const searchText = this.searchAccTo.toLowerCase();
      return this.accountList.filter((acc) =>
          acc.name.toLowerCase().includes(searchText)
      );
    },
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newValue) {
        this.getCoinsList(newValue);
      }
    }
  },
  created() {
    this.accListLoader();
    this.setupSelectedMode()
  },
  mounted() {
    document.addEventListener('click', this.handleClickCoinsFrom);
    document.addEventListener('click', this.handleClickCoinsTo);
    document.addEventListener('click', this.handleClickAccListFrom);
    document.addEventListener('click', this.handleClickAccListTo);
  },
  methods: {
    accListLoader() {
      MultiExchangeService.getAccountsList().then((res) => {
        this.UserAccList = res.data.result;
        const idToFind = parseFloat(this.$route.params.id);
        this.UserAcc = this.UserAccList.find(userAcc => userAcc.id === idToFind)

        this.accountList = this.UserAccList.filter(item => item.modeAccType === this.UserAcc.modeAccType);
        this.selectedAccFrom = this.UserAcc;
        this.selectedAccTo = this.selectedAccFrom;
      });
      this.getCoinsList();
    },
    getProfile() {
      UserService.getProfile().then((res) => {
        this.userProfile = res.data.result;
      })
    },
    getCoinsList() {
      TradeService.getBalanceAcc(this.$route.params.id, '?mode=' + this.selectedModeTo.key + '&isNetworks=true').then((res) => {
        this.selectedCoinTo = res.data.result.find((item) => item.coin.symbol === this.$route.params.coin)
      })

      TradeService.getBalanceAcc(this.$route.params.id, '?mode=' + this.selectedModeFrom.key + '&isNetworks=true').then((res) => {
        this.selectedCoinFrom = res.data.result.find((item) => item.coin.symbol === this.$route.params.coin)
        this.coinListFrom = res.data.result
      })
    },
    switchModeFrom(el) {
      this.selectedModeFrom = el

      TradeService.getBalanceAcc(this.selectedAccFrom.id, '?mode=' + el.key + '&isNetworks=true').then((res) => {
        this.selectedCoinFrom = res.data.result.find((item) => item.coin.symbol === this.$route.params.coin)
        this.coinListFrom = res.data.result
      })

      if (this.selectedAccTo.name === this.selectedAccFrom.name) {
        this.selectedModeTo = this.switchersMode.find((item) => item.name !== el.name)
        TradeService.getBalanceAcc(this.selectedAccTo.id, '?mode=' + this.selectedModeTo.key + '&isNetworks=true').then((res) => {
          this.selectedCoinTo = res.data.result.find((item) => item.coin.symbol === this.$route.params.coin)
        })
      }

      this.$router.push({
        name: 'Create transfer',
        params: {
          id: this.$route.params.id,
          coin: this.selectedCoinFrom.coin.symbol,
          mode: this.selectedModeFrom.name
        }
      })
    },
    switchModeTo(el) {
      this.selectedModeTo = el

      TradeService.getBalanceAcc(this.selectedAccTo.id, '?mode=' + el.key + '&isNetworks=true').then((res) => {
        this.selectedCoinTo = res.data.result.find((item) => item.coin.symbol === this.$route.params.coin)
        this.coinListTo = res.data.result
      })

      if (this.selectedAccTo.name === this.selectedAccFrom.name) {
        this.selectedModeFrom = this.switchersMode.find((item) => item.name !== el.name)
        TradeService.getBalanceAcc(this.selectedAccTo.id, '?mode=' + this.selectedModeFrom.key + '&isNetworks=true').then((res) => {
          this.selectedCoinFrom = res.data.result.find((item) => item.coin.symbol === this.$route.params.coin)
          this.coinListFrom = res.data.result
        })
      }
    },
    setupSelectedMode() {
      this.selectedModeFrom = this.switchersMode.find((item) => item.name === this.$route.params.mode)
      if (this.selectedModeFrom.name === 'Spot') {
        this.selectedModeTo = this.switchersMode.find((item) => item.name === 'Funding')
      } else {
        this.selectedModeTo = this.switchersMode.find((item) => item.name === 'Spot')
      }
    },
    selectAccToHandler(acc) {
      TradeService.getBalanceAcc(acc.id, '?mode=' + this.selectedModeTo.key + '&isNetworks=true').then((res) => {
        if (res.data.result) {
          this.selectedCoinTo = res.data.result.find((item) => item.coin.symbol === this.$route.params.coin)

        } else {
          this.selectedCoinTo = null
        }
      })

      this.selectedAccTo = acc;
      this.isDropAccTo = false;
    },
    selectAccFromHandler(acc) {
      TradeService.getBalanceAcc(acc.id, '?mode=' + this.selectedModeFrom.key + '&isNetworks=true').then((res) => {
        if (res.data.result) {
          this.selectedCoinFrom = res.data.result.find((item) => item.coin.symbol === this.$route.params.coin)
          this.coinListFrom = res.data.result;
        } else {
          this.selectedCoinFrom = null
        }
      })
      this.selectedAccFrom = acc;
      this.isDropAccFrom = false;
    },
    createTransfer() {
      const query = '?token=' + this.selectedCoinFrom.coin.symbol + '&from=' + this.selectedModeFrom.key + '&to=' + this.selectedModeTo.key + '&fromAcc=' + this.selectedAccFrom.name + '&toAcc=' + this.selectedAccTo.name + '&amount=' + this.amount
// disable button
      TradeService.createTransfer(this.$route.params.id, query).then((res) => {
        if (res) {
          this.$router.push({
            name: 'Trade balance',
            params: {
              id: this.$route.params.id,
            }
          })
        }
      })
    },
    selectCoinHandler(token) {
      this.$router.push({
        name: 'Create transfer',
        params: {
          id: this.$route.params.id,
          coin: token.coin.symbol,
          mode: this.selectedModeFrom.name
        }
      })

      this.defaultCoin = token.coin.symbol;
      this.getCoinsList();
    },
    handleClickCoinsFrom(event) {
      if (this.$refs.coinsListFrom && !this.$refs.coinsListFrom.contains(event.target)) {
        this.isDropCoinsFrom = false
      }
    },
    handleClickCoinsTo(event) {
      if (this.$refs.coinsListTo && !this.$refs.coinsListTo.contains(event.target)) {
        this.isDropCoinsTo = false
      }
    },
    handleClickAccListFrom(event) {
      if (this.$refs.accountListFrom && !this.$refs.accountListFrom.contains(event.target)) {
        this.isDropAccFrom = false
      }
    },
    handleClickAccListTo(event) {
      if (this.$refs.accountListTo && !this.$refs.accountListTo.contains(event.target)) {
        this.isDropAccTo = false
      }
    },
    focusOn(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].focus();
      }
    },
  }
}
</script>

<style lang="scss">
.create-order_transfer {
  max-width: 740px;
  width: 100%;

  .create-order_inputs {
    padding: 24px 32px 32px;

    &:first-child {
      border-right: 1px solid #E0E9F4;
    }

    .switchers--item {
      width: 100%;
    }

    .order {
      margin-top: 12px;

      .inputs {
        gap: 12px 0;
      }
    }
  }

  .title {
    color: #1A242B;
    font-family: 'Rubik', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.4px;
    margin-bottom: 16px;
  }

  .button {
    padding: 14px 0;
    width: 100%;
    border-radius: 6px;
    border: 1px solid rgba(130, 170, 200, 0.24);
    background: var(--trade-trade-blue, #007AFF);
    color: #FFF;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.2px;
    text-align: center;
    margin-top: 24px;
    cursor: pointer;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), #007AFF;
    }
  }

  .inputs {
    display: flex;
    flex-direction: column !important;

    .selected {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 8px;
      box-sizing: border-box;

      &_coin {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: 100%;

        img {
          max-width: 32px;
        }

        &-text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          &--coin {
            color: var(--trade-trade-black, #1A242B);
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }

          &--fullName {
            color: var(--trade-trade-gray, #527AA1);
            font-family: 'Rubik', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }
        }

        &_balance {
          color: var(--black, #1A242B);
          text-align: right;
          font-family: 'Rubik', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;

          span {
            color: var(--trade-trade-gray, #527AA1);
            text-align: right;
            font-family: 'Rubik', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }
    }

    &--item {
      width: 100% !important;
      position: relative;

      .input {
        width: 100%;

        input {
          width: 100%;
          max-width: 100%;
        }
      }

      &--error {
        border: 1px solid #FF603D;
      }

      &_select {
        align-items: center !important;
        position: relative;

        .simplebar-scrollbar::before {
          background: #EAF0F8;
          opacity: 1;
        }

        &--dropdown {
          position: absolute;
          top: calc(100% + 2px);
          z-index: 9;
          left: 0;
          width: 100%;
          border-radius: 6px;
          background: #FFF;
          box-shadow: 0px 1px 6px 0px rgba(6, 59, 122, 0.16);
          outline: 1px solid white;
          height: 460px;
          max-width: 359px;
          padding: 6px 0;

          .list {
            &_items {
              overflow: auto;
            }

            &--item {
              width: 100%;
              max-width: 100%;
              padding: 12px;
              height: unset;
              transition: all .3s ease-in-out;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              box-sizing: border-box;

              &-selected {
                .item-name {
                  color: #007AFF !important;
                }

                .info_text--coin {
                  color: #007AFF !important;
                }
              }

              &:hover {
                background: var(--trade-hover, rgba(0, 122, 255, 0.06));
              }

              .info {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                img {
                  max-width: 32px;
                }

                &_text {
                  display: flex;
                  flex-direction: column;
                  gap: 2px;

                  &--coin {
                    color: var(--trade-trade-black, #1A242B);
                    font-family: 'Rubik', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                  }

                  &--fullName {
                    color: var(--trade-trade-gray, #527AA1);
                    font-family: 'Rubik', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                  }
                }
              }

              &_balance {
                color: var(--black, #1A242B);
                text-align: right;
                font-family: 'Rubik', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;

                span {
                  color: var(--trade-trade-gray, #527AA1);
                  text-align: right;
                  font-family: 'Rubik', sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 14px;
                }
              }
            }

            &-searchBlock {
              padding: 12px;
              box-sizing: border-box;
              position: sticky;
              top: 0;
              left: 0;
              background: white;
            }

            &--search {
              width: 100%;
              max-width: 100%;
              border-radius: 6px;
              border: 1px solid rgba(124, 163, 210, 0.24);
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 12px;
              padding: 9px 12px 9px 8px;
              box-sizing: border-box;

              input {
                width: 100%;
                padding: 0;
                margin: 0;
                color: var(--trade-trade-gray, #527AA1);
                font-family: 'Rubik', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */

                &::placeholder {
                  opacity: 1;
                }
              }
            }
          }

          .search {
            padding: 12px;
            box-sizing: border-box;
            width: 100%;

            input {
              padding: 9px;
              width: 100%;
              max-width: 100%;
              border-radius: 6px;
              color: var(--trade-trade-black, #1A242B);
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 157.143% */

              &::placeholder {
                font-weight: 400 !important;
                color: var(--trade-trade-gray, #527AA1);
              }

              &:focus {
                outline: 2px solid var(--trade-trade-black, #1A242B);
              }
            }
          }
        }

        .select-input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          &_network {
            color: var(--trade-trade-black, #1A242B);
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }

          &_desc {
            transform: none;
            color: var(--trade-trade-gray, #527AA1);
            font-family: 'Rubik', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }

          &--dropdown {
            position: absolute;
            top: calc(100% + 2px);
            z-index: 9;
            left: 0;
            width: 100%;
            border-radius: 6px;
            background: #FFF;
            box-shadow: 0px 1px 6px 0px rgba(6, 59, 122, 0.16);
            outline: 1px solid white;
            padding: 6px 0;

            .item {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 10px 10px 10px 12px;
              transition: all .3s ease-in-out;

              &:hover {
                background: var(--trade-hover, rgba(0, 122, 255, 0.06));
              }

              &-name {
                color: var(--trade-trade-black, #1A242B);
                font-family: 'Rubik', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
              }
            }
          }
        }
      }

      &_amount {
        margin-top: 12px;

        &-disabled {
          .input {
            .desc {
              color: #527AA1 !important;
            }

            input {
              color: #527AA1;
            }
          }
        }
      }
    }

    .item-error {
      width: 100%;

      .error-text {
        color: var(--trade-trade-red, #FF603D);
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        padding-left: 12px;
        margin-top: 6px;
      }

      .error {
        outline: 2px solid var(--trade-trade-red, #FF603D);

        .input {
          .desc {
            color: var(--trade-trade-red, #FF603D);
            font-family: 'Rubik', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }
    }
  }
}

</style>
