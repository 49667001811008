<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99935 0.666748C4.39935 0.666748 0.666016 4.40008 0.666016 9.00008C0.666016 13.6001 4.39935 17.3334 8.99935 17.3334C13.5993 17.3334 17.3327 13.6001 17.3327 9.00008C17.3327 4.40008 13.5993 0.666748 8.99935 0.666748ZM5.12435 14.4167C6.21602 13.6334 7.54935 13.1667 8.99935 13.1667C10.4493 13.1667 11.7827 13.6334 12.8743 14.4167C11.7827 15.2001 10.4493 15.6667 8.99935 15.6667C7.54935 15.6667 6.21602 15.2001 5.12435 14.4167ZM14.116 13.2667C12.7077 12.1667 10.9327 11.5001 8.99935 11.5001C7.06602 11.5001 5.29102 12.1667 3.88268 13.2667C2.91602 12.1084 2.33268 10.6251 2.33268 9.00008C2.33268 5.31675 5.31602 2.33341 8.99935 2.33341C12.6827 2.33341 15.666 5.31675 15.666 9.00008C15.666 10.6251 15.0827 12.1084 14.116 13.2667Z"
      fill="#0A68F7"
    />
    <path
      d="M8.9987 4C7.39036 4 6.08203 5.30833 6.08203 6.91667C6.08203 8.525 7.39036 9.83333 8.9987 9.83333C10.607 9.83333 11.9154 8.525 11.9154 6.91667C11.9154 5.30833 10.607 4 8.9987 4ZM8.9987 8.16667C8.30703 8.16667 7.7487 7.60833 7.7487 6.91667C7.7487 6.225 8.30703 5.66667 8.9987 5.66667C9.69036 5.66667 10.2487 6.225 10.2487 6.91667C10.2487 7.60833 9.69036 8.16667 8.9987 8.16667Z"
      fill="#0A68F7"
    />
  </svg>
</template>

<script>
export default {
  name: "PartnerIcon"
}
</script>

<style scoped>

</style>
