<script>
export default {
  name: "UsersMenuIcon"
}
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4686_84639)">
      <path
        d="M10 2C5.58 2 2 5.58 2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2ZM10 5.5C11.66 5.5 13 6.84 13 8.5C13 10.16 11.66 11.5 10 11.5C8.34 11.5 7 10.16 7 8.5C7 6.84 8.34 5.5 10 5.5ZM10 16.5C7.95 16.5 6.13 15.55 4.93 14.06C6.38 13.08 8.12 12.5 10 12.5C11.88 12.5 13.62 13.08 15.07 14.06C13.87 15.55 12.05 16.5 10 16.5Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4686_84639">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
