<template>
  <div class="rate-request-chart">
    <div class="table-block">
      <div class="table-block-header">
        <div class="table-block-header-title">
          Requests chart {{ dataFormatting(date[0]) }} - {{ dataFormatting(date[1]) }}
        </div>
      </div>
      <div class="filters-block">
        <div class="filters">
          <div class="dates_range">
            <date-picker
              v-model:value="date"
              :clearable="false"
              :editable="false"
              default-panel="day"
              separator=" - "
              value-type="format"
              :format="dateFormatUrl"
              placeholder="Reporting period"
              :month-format="'MMMM'"
              range
              input-class="dates-item"
              :class="{'dates_filled': date}"
              @change="getPartnerVolumeList"
            >
              {{ dateText }}
              <template #icon-calendar>
                <calendar-icon />
              </template>
            </date-picker>
          </div>
          <div class="separator" />
          <div class="filters_block">
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'day'}"
              @click="setDateHandler('day')"
            >
              Today
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'yesterday'}"
              @click="setDateHandler('yesterday')"
            >
              Yesterday
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'week'}"
              @click="setDateHandler('week')"
            >
              Week
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'month'}"
              @click="setDateHandler('month')"
            >
              Month
            </div>
          </div>
          <div class="separator" />
          <div class="filters_block">
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'q1'}"
              @click="setDateHandler('q1')"
            >
              Q1
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'q2'}"
              @click="setDateHandler('q2')"
            >
              Q2
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'q3'}"
              @click="setDateHandler('q3')"
            >
              Q3
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'q4'}"
              @click="setDateHandler('q4')"
            >
              Q4
            </div>
          </div>
          <div class="separator" />
          <div class="filters_block">
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'year'}"
              @click="setDateHandler('year')"
            >
              Year
            </div>
          </div>
        </div>
      </div>
      <div class="table-block-table table-block-table_chart">
        <div class="chart-block">
          <vue-highcharts
            v-if="partnerVolumeData && partnerVolumeData.length"
            :options="chartOptions"
          />
        </div>
        <no-data-block v-if="!partnerVolumeData || !partnerVolumeData.length" />
      </div>
    </div>
  </div>
</template>

<script>
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import CalendarIcon from "@/icons/calendarIcon.vue";
import VueHighcharts from "vue3-highcharts/src/vue3-highcharts";
import RateService from "@/services/rate.service";
import {RATE_CHART} from "@/pages/rate-request/partials/rateChartData";

export default {
  name: "RateRequestChart",
  components: {
    CalendarIcon,
    NoDataBlock,
    DatePicker,
    VueHighcharts
  },
  data() {
    return {
      isLoading: false,
      partnerVolumeData: null,
      partnerVolume: null,
      partners: null,
      date: '',
      dates: null,
      arrDate: [],
      dateFormat: 'DD.MM.YYYY',
      dateFormatUrl: 'YYYY-MM-DD',
      chartDateFormat: 'DD.MM.YYYY',
      currentDate: '',
      chartOptions: RATE_CHART
    }
  },
  computed: {
    dateText() {
      if (this.date) {
        return this.date;
      } else {
        return "Custom date";
      }
    },
  },
  watch: {
    currentDate(val) {
      switch (val) {
        case 'day':
          this.date = [dayjs().format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'yesterday':
          this.date = [dayjs().subtract(1, 'day').format(this.dateFormatUrl), dayjs().subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'week':
          this.date = [dayjs().startOf('week').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'month':
          this.date = [dayjs().startOf('month').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'year':
          this.date = [dayjs().startOf('year').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'q1':
          this.date = [dayjs().startOf('year').format(this.dateFormatUrl), dayjs().startOf('year').add(3, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q2':
          this.date = [dayjs().startOf('year').add(3, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(6, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q3':
          this.date = [dayjs().startOf('year').add(6, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(9, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q4':
          this.date = [dayjs().startOf('year').add(9, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(11, 'month').endOf('month').format(this.dateFormatUrl)];
          break;
        default:
          break;
      }
      this.getPartnerVolumeList();
    }
  },
  mounted() {
    this.currentDate = 'week'
  },
  methods: {
    setDateHandler(date) {
      this.currentDate = date
    },
    getPartnerVolumeList() {
      this.chartOptions.xAxis.categories = [];
      this.chartOptions.series = []

      const query = '?start=' + this.dateFormatter(this.date[0]) + '&stop=' + this.dateFormatter(this.date[1]);

      RateService.requestByPartner(query).then((res) => {
        this.partnerVolumeData = res.data.result
        this.dates = this.getUniqueDates(this.partnerVolumeData)

        this.dates.sort(function (a, b) {
          return new Date(a.createDate) - new Date(b.createDate);
        });

        this.partners = [...new Set(this.partnerVolumeData.map((item) => item.partnerLogin))];

        if (this.partnerVolumeData) {
          this.filterItemsNew();
        }
      })
    },
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).format(this.dateFormat)
    },
    dateFormatter(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).format(this.dateFormatUrl)
    },
    getUniqueDates(data) {
      return data.filter((obj, index, self) =>
          index === self.findIndex((t) => t.createDate === obj.createDate)
      ).map(({createDate}) => ({createDate}));
    },
    filterItemsNew() {
      const result = {};

      for (const dateItem of this.dates) {
        for (const partner of this.partners) {
          const login = partner;
          const volume = dateItem.createDate;

          const resultItem = this.partnerVolumeData.find((element) => element.partnerLogin === login && element.createDate === volume);

          if (!result[login]) {
            result[login] = [];
          }

          result[login].push(resultItem ? resultItem.cnt : 0);
        }
      }

      const profitArrays = Object.entries(result).map(([partnerLogin, cnt]) => ({partnerLogin, cnt}));

      profitArrays.forEach((item) => {

        const series = {
          name: item.partnerLogin,
          data: item.cnt,
          animation: true,
          lineWidth: 2,
        };

        this.chartOptions.series.push(series);
      });

      const profits = this.partnerVolumeData.map(obj => obj.cnt);
      const minValue = Math.min(...profits);
      const maxValue = Math.max(...profits);

      this.chartOptions.yAxis.min = minValue;
      this.chartOptions.yAxis.max = maxValue;
      this.chartOptions.tooltip.pointFormat = '<tr><td style="color: white; font-weight: 300; padding-right: 15px" :class="{`active-tooltip`: }"><div style="display: flex; align-items: center; gap: 4px"><div style="border-radius: 50%; width: 8px; height: 8px; background: {series.color};"></div>{series.name}: </div> </td>' +
          '<td style="text-align: right; font-weight: 300;"><b>' +
          '{point.y:.6f} ' +
          '<span style="color: rgba(255, 255, 255, 0.6);"> BTC</span></b></td></tr>';
      this.chartOptions.xAxis.categories = this.dates.map(obj => dayjs(obj.createDate).format(this.chartDateFormat));
    },
  }
}
</script>

<style lang="scss">

.rate-request-chart {
  margin-bottom: 40px;
  .table-block {
    border-radius: 16px;
    border: 1px solid var(--grayscale-divider, #DFE0EB);
    background: var(--grayscale-white, #FFF);

    .filters {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      padding: 0 32px;
      box-sizing: border-box;

      @media (max-width: 1079px) {
        overflow: auto;
        scrollbar-width: none;
      }

      .dates {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 4px;

        @media (max-width: 1079px) {
          overflow: auto;
          scrollbar-width: none;
        }

        &-item {
          padding: 6px 12px;
          font-size: 14px;
          font-weight: 500;
          background: #FCFDFE;
          border: 1px solid #F0F1F7;
          box-sizing: border-box;
          border-radius: 6px;
          color: #0A68F7;
          cursor: pointer;
          transition: all .3s ease-in-out;

          &:hover {
            border: 1px solid #0A68F7;
          }
        }

        .active {
          color: #FCFDFE;
          background: #0A68F7;
        }

        &_filled {
          .mx-input-wrapper {
            input {
              width: 25ch !important;
              height: 40px;
            }
          }
        }

        &_range {
          background: none !important;

          .mx {
            &-datepicker {
              width: unset;

              &-popup, &-main {
                box-shadow: 0 4px 24px rgba(55, 49, 72, 0.12);
                border-radius: 12px;
                overflow: hidden;
                border: none;
              }
            }

            &-icon-calendar, &-icon-clear {
              left: 9px;
              right: unset;

            }

            &-datepicker svg {
              fill: none;
            }

            &-input-wrapper {
              input {
                &::placeholder {
                  color: #0A68F7;
                  opacity: 1;
                }

                width: 14ch;
              }

              .dates-item {
                padding-left: 30px !important;
              }

              .mx-icon-calendar {
                svg {
                  path {
                    fill: #0A68F7;
                    fill-opacity: 100%;
                  }
                }
              }
            }
          }


          .dates-item {
            padding: 6px 8px;
          }

          &_active {
            .dates-item {
              color: #FCFDFE;
              background: #0A68F7;
            }

            .mx {
              &-icon-calendar, &-icon-clear {
              }

              &-datepicker svg path {
                fill: white !important;
              }

              &-input-wrapper {
                input {
                  &::placeholder {
                    color: white;
                    opacity: 1;
                  }

                  width: 14ch;
                }
              }
            }

            .mx-icon-calendar {
              svg {
                fill: white;
                fill-opacity: 100%;
              }
            }
          }
        }
      }

      .separator {
        width: 1px;
        height: 20px;
        background: #DFE0EB;
        border-radius: 4px;
      }

      &_block {
        display: flex;
        align-items: center;
        gap: 9px;
      }

      &--item {
        user-select: none;
        padding: 10px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0A68F7;
        background: #FCFDFE;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
          border: 1px solid #0A68F7;
        }

        &-active {
          background: #0A68F7;
          border: 1px solid #0A68F7;
          color: white;
          cursor: default;
        }
      }
    }

    &-header {
      padding: 32px 0 20px 32px;
      box-sizing: border-box;
      color: #1B1A1C;
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
    }

    &-table {
      max-height: 850px;
      overflow: auto;

      &_chart {
        padding: 24px;
        box-sizing: border-box;

        .chart-block {
          width: 100%;
        }
      }

      tbody {
        position: relative;

        .table-row-fixed_last {
          height: 96px;
          background: white;
          position: sticky;
          bottom: 0;
          z-index: 1;

          td {
            font-weight: 700 !important;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2px;
            color: #1B1A1C !important;
          }
        }
      }

      .table {
        position: relative;
        margin-bottom: 0;

        &-cell-first {
          padding-left: 32px;
          color: #9FA2B4 !important;

          .text {

          }
        }

        .cell-partners {
          width: 20%;

          .text {
            font-weight: 700 !important;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2px;
            color: #1B1A1C !important;
          }
        }
      }
    }
  }



  .highcharts-legend-item {
    position: relative;
    span {
      position: relative !important;
      left: unset;
      top: unset;
      width: max-content;
    }
  }

  .legend-custom {
    z-index: -1 !important;


    div {
      position: relative !important;
      left: unset !important;
      top: unset !important;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      align-items: flex-start;
    }
  }

  .highcharts-legend-item_custom {
    background: #FCFDFE;
    border: 1px solid #F0F1F7;
    border-radius: 6px;
    padding: 4px 6px 4px 12px;
    z-index: 0;
    position: relative;
    left: unset;
    bottom: unset;
    top: unset;
    right: unset;


    &--text {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #1B1A1C;
    }
  }

  .highcharts-root {
    z-index: -1 !important;
    position: relative;
  }

  .highcharts-tooltip {
    z-index:999999999 !important;
  }

  .highcharts-legend-item-hidden {
    .highcharts-legend-item_custom {
      color: #9FA2B4;
      background: none;
      border: 1px solid transparent;
    }
  }

}



</style>
