<script>
export default {
  name: "BlockOrderReportsIcon"
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.83073 13.334C6.06684 13.334 6.26476 13.2541 6.42448 13.0944C6.5842 12.9347 6.66406 12.7368 6.66406 12.5007C6.66406 12.2645 6.5842 12.0666 6.42448 11.9069C6.26476 11.7472 6.06684 11.6673 5.83073 11.6673C5.59462 11.6673 5.3967 11.7472 5.23698 11.9069C5.07726 12.0666 4.9974 12.2645 4.9974 12.5007C4.9974 12.7368 5.07726 12.9347 5.23698 13.0944C5.3967 13.2541 5.59462 13.334 5.83073 13.334ZM4.9974 10.834H6.66406V6.66732H4.9974V10.834ZM8.33073 12.5007H14.9974V10.834H8.33073V12.5007ZM8.33073 9.16732H14.9974V7.50065H8.33073V9.16732ZM3.33073 16.6673C2.8724 16.6673 2.48003 16.5041 2.15365 16.1777C1.82726 15.8513 1.66406 15.459 1.66406 15.0007V5.00065C1.66406 4.54232 1.82726 4.14996 2.15365 3.82357C2.48003 3.49718 2.8724 3.33398 3.33073 3.33398H16.6641C17.1224 3.33398 17.5148 3.49718 17.8411 3.82357C18.1675 4.14996 18.3307 4.54232 18.3307 5.00065V15.0007C18.3307 15.459 18.1675 15.8513 17.8411 16.1777C17.5148 16.5041 17.1224 16.6673 16.6641 16.6673H3.33073ZM3.33073 15.0007H16.6641V5.00065H3.33073V15.0007Z"
      fill="white"
      fill-opacity="0.54"
    />
  </svg>
</template>

<style scoped lang="scss">

</style>
