<template>
  <div
    class="exchange-info"
    tabindex="0"
  >
    <div class="exchange-info_popup">
      <div class="exchange-info_popup-header">
        <div class="id_status">
          <div class="id">
            ID {{ data.uid }}
          </div>
          <div class="status">
            <select-status-popup-exchange
              v-if="statuses"
              :options="statuses"
              :value-input="data.status"
              class="select select-popup"
              :data="data"
              @change-select="changeStatus"
            />
          </div>
        </div>
        <div
          v-if="data.createDate"
          class="date"
        >
          {{ dataFormatting(data.createDate) }}
        </div>
        <div class="close">
          <close-icon @click="closePopup" />
        </div>
      </div>
      <div class="exchange-info-form-info-block">
        <div class="exchange-info-form-info-block_items">
          <div class="exchange-info-form-info-block-item">
            <div class="exchange-info-form-info-block-item-main coin">
              <div class="desc">
                From
              </div>
              <div class="data">
                {{ data.from }} <span v-if="data.addressFromNetwork">({{ data.addressFromNetwork }})</span>
              </div>
            </div>
            <div class="arrow">
              <arrow-exchange />
            </div>
            <div class="exchange-info-form-info-block-item-main">
              <div class="desc">
                To
              </div>
              <div class="data">
                {{ data.to }} <span v-if="data.addressToNetwork">({{ data.addressToNetwork }})</span>
              </div>
            </div>
          </div>
          <div
            class="exchange-info-form-info-block-item"
          >
            <div class="exchange-info-form-info-block-item-main">
              <div class="desc">
                Amount
              </div>
              <div
                v-if="data.amountIn"
                class="data inputs"
              >
                {{ data.amountIn }}
              </div>
              <div
                v-else
                class="data inputs"
              >
                {{ data.amount }}
              </div>
            </div>
            <div
              class="exchange-info-form-info-block-item-main amount-result"
            >
              <div
                class="desc"
              >
                Amount Result
              </div>
              <div
                class="data inputs"
                @mouseover="currentAmountResult = data.amountResult"
                @mouseleave="currentAmountResult = null"
              >
                {{ numbersFormatting(data.amountResult) }}

                <transition name="fade">
                  <hover-text
                    v-if="currentAmountResult === data.amountResult"
                    :option="data.amountResult"
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="exchange-info-form-info-block">
        <div
          v-if="data.addressTo"
          class="exchange-info-form-info-block-item"
        >
          <div class="exchange-info-form-info-block-item-block">
            <div class="desc">
              Address to
            </div>
            <div
              v-if="data.addressTo !== null"
              v-clipboard:copy="data.addressTo"
              v-clipboard:success="onCopyAddress"
              class="data address"
              @mouseover="currentAddress = data.addressTo"
              @mouseleave="currentAddress = null"
            >
              {{ formattingData(data.addressTo) }}
              <copy-icon />
              <transition name="fade">
                <hover-text
                  v-if="currentAddress === data.addressTo"
                  :option="data.addressTo"
                />
              </transition>
            </div>
            <div
              v-if="data.addressTo === null"
              class="data"
            >
              Empty
            </div>
          </div>
        </div>
        <div
          v-if="data.addressFrom"
          class="exchange-info-form-info-block-item"
          style="margin-top: 10px"
        >
          <div class="exchange-info-form-info-block-item-block">
            <div class="desc">
              Address from
            </div>
            <div
              v-if="data.addressFrom !== null"
              v-clipboard:copy="data.addressFrom"
              v-clipboard:success="onCopyAddress"
              class="data address"
              @mouseover="currentAddress = data.addressFrom"
              @mouseleave="currentAddress = null"
            >
              {{ formattingData(data.addressFrom) }}
              <copy-icon />
              <transition name="fade">
                <hover-text
                  v-if="currentAddress === data.addressFrom"
                  :option="data.addressFrom"
                />
              </transition>
            </div>
            <div
              v-if="data.addressFrom === null"
              class="data"
            >
              Empty
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="data.depositTransactionID"
        class="exchange-info-form-info-block"
      >
        <div
          class="exchange-info-form-info-block-item"
        >
          <div class="exchange-info-form-info-block-item-block">
            <div class="desc">
              Deposit
            </div>
            <div
              v-if="data.depositTransactionID !== null"
              v-clipboard:copy="data.depositTransactionID"
              v-clipboard:success="onCopyAddress"
              class="data address"
              @mouseover="currentAddress = data.depositTransactionID"
              @mouseleave="currentAddress = null"
            >
              {{ formattingData(data.depositTransactionID) }}
              <transition name="fade">
                <hover-text
                  v-if="currentAddress === data.depositTransactionID"
                  :option="data.depositTransactionID"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="data.withdrawalTransactionID"
        class="exchange-info-form-info-block"
      >
        <div
          class="exchange-info-form-info-block-item"
        >
          <div class="exchange-info-form-info-block-item-block">
            <div class="desc">
              Withdrawal
            </div>
            <div
              v-if="data.withdrawalTransactionID !== null"
              v-clipboard:copy="data.withdrawalTransactionID"
              v-clipboard:success="onCopyAddress"
              class="data address"
              @mouseover="currentAddress = data.withdrawalTransactionID"
              @mouseleave="currentAddress = null"
            >
              {{ formattingData(data.withdrawalTransactionID) }}
              <transition name="fade">
                <hover-text
                  v-if="currentAddress === data.withdrawalTransactionID"
                  :option="data.withdrawalTransactionID"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CopyNotification
      v-if="isSuccessCopy"
      :text="copyText"
    />
  </div>
</template>

<script>

import CloseIcon from "@/icons/closeIcon";
import {STATUSES} from "@/pages/exchanges/exchangeData";
import moment from "moment";
import ArrowExchange from "@/icons/arrowExchange";
import HoverText from "@/pages/coins/partials/hoverText";
import CopyNotification from "@/pages/notification/CopyNotification";
import CopyIcon from "@/icons/copyIcon";
import OrderService from "@/services/order.service";
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import SelectStatusPopupExchange from "@/pages/exchanges/partials/SelectStatusPopupExchange.vue";

export default {
  name: "ExchangeInfoMulti",
  components: {
    SelectStatusPopupExchange,
    CopyIcon,
    CopyNotification,
    HoverText,
    ArrowExchange,
    CloseIcon
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    backPath: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      exchangeData: null,
      moment: moment,
      statuses: STATUSES,
      dateFormat: 'DD.MM.YY HH:mm',
      currentAddress: null,
      currentMemo: null,
      currentCurs1: null,
      currentCurs2: null,
      currentPartnerFee: null,
      currentCompanyFee: null,
      currentAmountResult: null,
      maxNumberLength: 7,
      maxTextLength: 30,
      copyText: '',
      isSuccessCopy: true,
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.exchangeData = this.data;
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closePopup()
      }
    });

  },
  beforeUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closePopup()
      }
    });
  },
  methods: {
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    closePopup() {
      this.emitter.emit('exchange-info-event', null)
      this.emitter.emit('exchange-multi-timer', true)
      this.$router.push({
        name: 'Exchanges Multi',
        query: this.backPath
      })
      document.body.classList.remove('modal-open');
    },
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormat)
    },
    numbersFormatting(data) {
      return data.toFixed(this.maxNumberLength) + '...';
    },
    formattingData(data) {
      const text = data;

      if (text.length >= this.maxTextLength) {
        return text.slice(0, this.maxTextLength) + '...';
      }

      return text;
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    changeStatus(data) {
      this.setNotification({
        name: 'ID ' + data.data.id + ', status has been changed from ' + data.option.name,
        value: data.option.name
      });
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.updateOrderMultiStatusById(data.data.id, data.option.status).then(() => {
            this.currentIndex = data.data.id;
            this.statusClass = 'status-success';
            setTimeout(() => {
              this.statusClass = '';
            }, 3000);
            this.setNotification({});
          }).catch(() => {
            this.statusClass = 'status-error';
            this.setNotification({name: 'Error', value: 'status: ' + data.option.name});
          });
        }
      });
      this.emitter.all.clear()
    },
  }
}
</script>

<style lang="scss">
.exchange-info-form-info-block-item-block {
  width: 100%;
}

.address {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

@import "../../../../../assets/styles/pages/exchanges/exchanges-1inch";
</style>
