<template>
  <AccountLayoutNew>
    <template #title>
      <div class="layout-header">
        <h2>
          <DepositsIcon />
          Deposits
        </h2>
      </div>
    </template>
    <template #content>
      <deposit-summary-list
        :is-deposit-summary-loading="isDepositSummaryLoading"
        :deposit-summary-data="depositSummaryData"
        :selected-coins-and-statuses="selectedCoinsAndStatuses"
        :selected-date="selectedDate"
        :date-handler="dateHandler"
        @update-selected="updateSelectedCoinsAndStatuses"
        @update-is-deposit-summary-loading="(value) => isDepositSummaryLoading = value"
      />

      <exchanges-list
        :is-exchanges-loading="isExchangesLoading"
        :is-exchanges-lazy-loading="isExchangesLazyLoading"
        :exchanges-data="exchangesData"
        @update-is-exchanges-loading="(value) => isExchangesLoading = value"
        @update-is-exchanges-lazy-loading="(value) => isExchangesLazyLoading = value"
        @update-list="updateExchangeList"
      />

      <transition name="committee">
        <CopyNotification
          v-if="isUpdate"
          :text="updateText"
        />
      </transition>
    </template>
  </AccountLayoutNew>
</template>

<script>
import {defineComponent} from 'vue';
import AccountLayoutNew from '@/components/admin/AccountLayoutNew.vue';
import filterMixin from '@/mixins/filter.mixin';
import helperMixin from '@/mixins/helper.mixin';
import CopyNotification from '@/pages/notification/CopyNotification.vue';
import DepositsIcon from '@/assets/img/icons/menu/depositsIcon.vue';
import OrderService from '@/services/order.service';
import ExchangesList from '@/pages/deposits/partials/ExchangesList.vue';
import dayjs from 'dayjs';
import DepositSummaryList from '@/pages/deposits/partials/DepositSummaryList.vue';

export default defineComponent({
  components: {
    DepositSummaryList,
    ExchangesList,
    DepositsIcon,
    CopyNotification,
    AccountLayoutNew},
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      depositSummaryData: [],
      exchangesData: [],
      isDepositSummaryLoading: true,
      isExchangesLoading: false,
      isExchangesLazyLoading: false,
      tokenTo: false,
      isUpdate: false,
      updateText: '',
      openDropdownId: null,
      selectedCoinsAndStatuses: [],
      // date picker
      isDatesOpen: true,
      selectedDate: null,
      defaultFromDate: null,
      defaultToDate: null,
      // autoloader
      exchangeTotalPage: 1,
      exchangeCurrentPage: 1,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.lazyLoadingHandler);
    this.mountList()
    this.defaultFromDate = dayjs.utc().subtract(30, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    this.defaultToDate = dayjs.utc().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.lazyLoadingHandler);
  },
  methods: {
    lazyLoadingHandler() {
      const scrollPosition = window.innerHeight + window.scrollY + 5;
      const bottomPosition = document.documentElement.offsetHeight;

      if (scrollPosition >= bottomPosition
        && this.exchangesData.length !== 0
        && this.isExchangesLazyLoading !== true
        && this.exchangeTotalPage > this.exchangeCurrentPage) {
        this.isExchangesLazyLoading = true

        this.exchangeCurrentPage = this.exchangeCurrentPage+1;
        const fromDate = this.selectedDate !== null ? this.selectedDate[0] : this.defaultFromDate
        const toDate = this.selectedDate !== null ? this.selectedDate[1] : this.defaultToDate

        const queryParams = 'Page=' + this.exchangeCurrentPage + this.selectedCoinsAndStatuses
          .map(item => `&include_status=${item.replace(/([a-z])([A-Z])/g, "$1 $2")}`)
          .join('')+'&fromDate='+fromDate+'&toDate='+toDate;


        OrderService.getDeposits(queryParams).then((res) => {
          if (res.status === 200) {
            this.exchangesData = [...this.exchangesData, ...res.data.result.result]
          }
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    updateExchangeList() {
      this.exchangeTotalPage = 1;
      this.exchangeCurrentPage = 1;
      const fromDate = this.selectedDate !== null ? this.selectedDate[0] : this.defaultFromDate
      const toDate = this.selectedDate !== null ? this.selectedDate[1] : this.defaultToDate

      const queryParams = 'Page=' + this.exchangeTotalPage + this.selectedCoinsAndStatuses
        .map(item => `&include_status=${item.replace(/([a-z])([A-Z])/g, "$1 $2")}`)
        .join('')+'&fromDate='+fromDate+'&toDate='+toDate;

      OrderService.getDeposits(queryParams).then((res) => {
        if (res.status === 200) {
          this.exchangesData = res.data.result.result
          this.isExchangesLoading = false

          this.exchangeTotalPage = res.data.result.maxPage;
          this.exchangeCurrentPage = res.data.result.currentPage;
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    updateSelectedCoinsAndStatuses({ status, coin }) {
      const key = `${status}_${coin}`;
      const index = this.selectedCoinsAndStatuses.indexOf(key);
      if (index !== -1) {
        this.selectedCoinsAndStatuses.splice(index, 1);
      } else {
        this.selectedCoinsAndStatuses.push(key);
      }

      /*start loading exchange list*/
      this.isExchangesLoading = true
      this.exchangeTotalPage = 1;
      this.exchangeCurrentPage = 1;
      const fromDate = this.selectedDate !== null ? this.selectedDate[0] : this.defaultFromDate
      const toDate = this.selectedDate !== null ? this.selectedDate[1] : this.defaultToDate

      const queryParams = 'Page=' + this.exchangeTotalPage + this.selectedCoinsAndStatuses
        .map(item => `&include_status=${item.replace(/([a-z])([A-Z])/g, "$1 $2")}`)
        .join('')+'&fromDate='+fromDate+'&toDate='+toDate;

      OrderService.getDeposits(queryParams).then((res) => {
        if (res.status === 200) {
          this.exchangesData = res.data.result.result
          this.isExchangesLoading = true

          this.exchangeTotalPage = res.data.result.maxPage;
          this.exchangeCurrentPage = res.data.result.currentPage;
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    handleToggleDropdown(itemId) {
      if (this.openDropdownId === itemId) {
        this.openDropdownId = null;
      } else {
        this.openDropdownId = itemId;
      }
    },
    handleCloseDropdown() {
      this.openDropdownId = null;
    },
    mountList() {
      let query = this.$route.query;

      if (query.fromDate) {
        this.selectedDate = [query.fromDate, query.toDate];
      } else {
        query = null
      }

      this.getListByQuery(query);
    },
    getListByQuery(query) {
      this.$router.push({query: query}).then(() => {
        this.getDepositSummary(query);
      });
    },
    getDepositSummary(query) {
      const queryParams = query
        ? `fromDate=${query.fromDate}&toDate=${query.toDate}`
        : `fromDate=${this.defaultFromDate}&toDate=${this.defaultToDate}`;
      this.isDepositSummaryLoading = true
      this.isExchangesLoading = true
      OrderService.getDepositSummary(queryParams).then((res) => {
        if (res.status === 200) {
          this.depositSummaryData = res.data.result.result.summary
          this.updateExchangeList()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    // date picker
    dateHandler(date) {
      this.selectedDate = date
      this.isDepositSummaryLoading = true
      let query

      if (date === null) {
        query = null;
      } else {
        query = {...this.$route.query, fromDate: this.selectedDate[0], toDate: this.selectedDate[1]};
      }
      this.$router.push({
        name: 'Deposits',
        query: query
      }).then(() => {
        this.selectedCoinsAndStatuses = []
        this.getDepositSummary(query);
      });
    },
  }
})
</script>

<style lang="scss">
@import "../../assets/styles/pages/deposits/deposits";
@import "../../assets/styles/components/filters";

.exchangesListNew {

  @media (max-width: 1079px) {
    margin-top: 16px;
    min-height: 100vh;
    background: white;
    overflow: auto;
    border-radius: 16px 16px 0 0;
  }
}

.exchangesListNew .table-block-table.sticky {
  padding-top: 88px;
  @media (max-width: 1252px) {
    padding-top: 78px;
    @media (max-width: 1172px) {
      padding-top: 140px;
    }
  }
}

.exchangesListNew .table-block-table.without-height {
  height: auto;
  overflow: hidden;
}

.exchangesListNew {
  .trade-nodata {
    height: 45vh;
  }

  .trade-loader {
    height: 45vh;
  }
  .table-block-table {

    @media (max-width: 819px) {
      overflow: auto;
      height: 100vh;
    }

    .table {
      @media (max-width: 1079px) {
        width: 100%;

        @media (max-width: 819px) {
          width: 819px;
        }
      }
    }
  }

  .table-block-header-exchange {

    .sticky-table-head {
      display: none;
    }
  }

  .table-block-header-exchange > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 28px 32px 28px;
    gap: 20px;
    @media (max-width: 1290px) {
      flex-direction: column !important;

      @media (max-width: 1079px) {
        padding: 16px 12px;
        gap: 16px 12px;
      }
    }
  }

  .table-block-header-exchange.stickyHeader {
    position: fixed;
    padding-bottom: 0;
    top: 0;
    background: #fff;
    width: calc(100% - 322px);
    z-index: 10;
    border-bottom: 1px solid #EAEFF4;


    @media (max-width: 1079px) {
      left: 0;
      top: 72px;
      width: calc(100%);
    }

    .sticky-table-head {
      display: table;
      width: 100%;
      background: #fff;

      thead {
        border: none;
      }

      th {
        text-align: left;
        vertical-align: baseline;
        padding-bottom: 8px;
      }

      th div {
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: $gray-color;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

      th.table-run-bot {
        padding-right: 16px;
        padding-left: 32px;
        width: 12%;

        @media (max-width: 1079px) {
          padding-left: 12px;
          padding-right: 12px;
          @media (max-width: 819px) {
            width: 8%;
          }
        }
      }

      th.table-date {
        padding-right: 16px;
        width: 13.5%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-id {
        padding-right: 16px;
        width: 15%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-partner {
        padding-right: 16px;
        width: 14%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-pair {
        padding-right: 16px;
        width: 16%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-amount {
        padding-right: 16px;
        width: 18%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-aml {
        padding-right: 16px;
        width: 5.7%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-status {
        padding-right: 32px;
        width: 5.8%;
        min-width: 88px;

        @media (max-width: 1079px) {
          padding-right: 12px;
          min-width: 64px;
        }
      }
    }
  }

  .table-block-header-title {
    display: flex;
    align-items: center;
    align-self: flex-start;
    height: 32px;
    min-width: fit-content !important;
  }
}
</style>
