<template>
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="57"
      height="57"
      fill="#EBEDF0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.3561 14.1985C35.2497 13.657 36.3945 14.2858 36.4167 15.3304L36.5515 21.6622L43.1114 22.793C44.1411 22.9705 44.5925 24.1961 43.9239 24.9991L39.5587 30.242L42.8737 32.9055C43.6882 33.5599 43.4888 34.8507 42.5148 35.2289L36.5244 37.5547L37.2587 41.2568C37.462 42.2817 36.4796 43.1423 35.4903 42.8059L31.2172 41.3528C26.3436 43.7196 20.5046 42.4925 16.9839 38.4768C22.1986 39.9823 26.3782 38.4087 28.3621 36.782C28.8452 36.3859 28.9157 35.6732 28.5197 35.1901C28.1236 34.7071 27.4109 34.6365 26.9278 35.0326C25.281 36.3829 20.7831 38.1578 14.9606 35.2628C12.6495 29.8598 14.6066 23.4407 19.7772 20.307C22.0523 18.928 24.6035 18.395 27.0642 18.618L34.3561 14.1985ZM23.8019 30.1945C22.7334 30.842 20.4965 29.9394 19.8489 28.8709C19.2013 27.8025 19.5425 26.4113 20.611 25.7637C21.6795 25.1162 25.4827 23.4292 26.1303 24.4977C26.7779 25.5662 24.8704 29.5469 23.8019 30.1945Z"
      fill="#C4C4C4"
    />
  </svg>
</template>

<script>
    export default {
        name: "EmptyLogo"
    }
</script>

<style scoped>

</style>