import axios from 'axios';
import {API_URL} from '@/store/config';
import { handleResponseError } from "@/exception/auth.exception";

// create an axios instance
const service = axios.create({
    baseURL: API_URL,
    timeout: 5000,
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        if (localStorage.token) {
            config.headers.Authorization = 'Bearer ' + localStorage.token;
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
        }
        return config;
    },
    (error) => {
        return handleResponseError(error);
    },
);

// response interceptor
service.interceptors.response.use(
    (response) => response,
    (error) => {
        return handleResponseError(error);
    },
);

export default service;

// const service = axios.create({
//     baseURL: API_URL,
//     timeout: 5000,
// });
//
// export const useAxios = () => {
//     const config = {
//         baseURL: API_URL,
//         timeout: 5000,
//         headers: {
//             Authorization: "Bearer " + localStorage.token,
//             "X-Requested-With": "XMLHttpRequest",
//         },
//     };
//
//     const client = axios.create(config);
//
//     client.interceptors.request.use(
//         (config) => config,
//         (error) => {
//             return Promise.reject(error);
//         }
//     );
//
//     client.interceptors.response.use(
//         (response) => response,
//         (error) => {
//             return handleResponseError(error);
//         }
//     );
//
//     return client;
// };
