<template>
  <tr
    v-for="(coin, index) in coinsList"
    :key="index"
    class="table-row"
  >
    <td class="table-cell table-img">
      <img
        :src="domain + coin.image"
        alt=""
      >
    </td>
    <td class="table-cell table-id">
      {{ coin.id }}
    </td>
    <td class="table-cell table-name">
      {{ coin.name }}
    </td>
    <td class="table-cell table-shortName">
      {{ coin.shotName }}
    </td>
    <td class="table-cell table-isTrue">
      <img
        v-if="coin.isActive === true"
        src="../../../../assets/img/icons/true.svg"
        alt=""
      >
      <img
        v-if="coin.isActive === false"
        src="../../../../assets/img/icons/false.svg"
        alt=""
      >
    </td>
    <td class="table-cell table-networks">
      <div
        v-for="(item, i) in coin.network"
        :key="i"
        class="table-networks_item"
      >
        {{ item.shotName }}
      </div>
    </td>
    <td class="table-cell table-edit">
      <div class="info">
        <div
          class="info-edit"
          @click="getCoinDataById(coin.id)"
        >
          <img
            src="../../../../assets/img/icons/edit-icon.svg"
            alt=""
          >
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import {MAIN_DOMAIN} from "@/store/config";
import CoinsService from "@/services/coins.service";

export default {
  name: "Coin1inchListItem",
  props: {
    coinsList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isPopUp: null,
      search: null,
      domain: MAIN_DOMAIN
    }
  },
  mounted() {
    this.scrollHandler();
  },
  methods: {
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-id');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 40) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    getCoinDataById(coinId) {
      CoinsService.getCoin1inchById(coinId).then((res) => {
        this.emitter.emit('coin-info-event', res.data.result)
        this.$router.push({
          path: `/coins/1inch/${coinId}`,
        })
      })
    }
  },
}
</script>

<style lang="scss">
.table-networks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &_item {
    margin-right: 3px;

    &:after {
      content: ',';
    }
    &:last-child {
      margin-right: 0;

      &:after {
        content: none;
      }
    }
  }
}
</style>
