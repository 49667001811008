<template>
  <div class="trade-header">
    <div class="trade-header_top">
      <div
        class="account"
      >
        <div
          v-if="UserAcc"
          class="account-info"
        >
          <span v-html="logos[UserAcc.modeAccType]" />
          <div
            class="text"
          >
            <div class="service">
              {{ UserAcc.modeAccType }}
            </div>
            <div class="name">
              {{ UserAcc.name }}
            </div>
          </div>
        </div>
        <div class="account-buttons">
          <router-link
            :to="{name: 'Create order', params: {id: UserAcc?.id, coin: 'BTC'}}"
            class="trade-button trade-button_blue"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3831_52788)">
                <path
                  d="M5.0026 9.99984H7.5026L4.16927 6.6665L0.835938 9.99984H3.33594C3.33594 13.6832 6.31927 16.6665 10.0026 16.6665C11.3109 16.6665 12.5276 16.2832 13.5526 15.6332L12.3359 14.4165C11.6443 14.7915 10.8443 14.9998 10.0026 14.9998C7.24427 14.9998 5.0026 12.7582 5.0026 9.99984ZM6.4526 4.3665L7.66927 5.58317C8.36927 5.21651 9.16094 4.99984 10.0026 4.99984C12.7609 4.99984 15.0026 7.2415 15.0026 9.99984H12.5026L15.8359 13.3332L19.1693 9.99984H16.6693C16.6693 6.3165 13.6859 3.33317 10.0026 3.33317C8.69427 3.33317 7.4776 3.7165 6.4526 4.3665Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3831_52788">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
            Create new order
          </router-link>
          <router-link
            :to="{name: 'Create deposit', params: {id: UserAcc?.id}}"
            class="trade-button trade-button_black"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3831_52776)">
                <path
                  d="M9.16406 5.83333L7.9974 7L10.1641 9.16667H1.66406V10.8333H10.1641L7.9974 13L9.16406 14.1667L13.3307 10L9.16406 5.83333ZM16.6641 15.8333H6.9974V17.5H16.6641C17.5807 17.5 18.3307 16.75 18.3307 15.8333V4.16667C18.3307 3.25 17.5807 2.5 16.6641 2.5H6.9974V4.16667H16.6641V15.8333Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3831_52776">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
            Deposit
          </router-link>
          <router-link
            :to="{name: 'Create withdrawal', params: {id: UserAcc?.id}}"
            class="trade-button trade-button_black"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3831_52783)">
                <path
                  d="M14.1641 5.83333L12.9891 7.00833L15.1391 9.16667H6.66406V10.8333H15.1391L12.9891 12.9833L14.1641 14.1667L18.3307 10L14.1641 5.83333ZM3.33073 4.16667H11.9974V2.5H3.33073C2.41406 2.5 1.66406 3.25 1.66406 4.16667V15.8333C1.66406 16.75 2.41406 17.5 3.33073 17.5H11.9974V15.8333H3.33073V4.16667Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3831_52783">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
            Withdrawal
          </router-link>
        </div>
      </div>
      <UserProfile />
    </div>
    <!--    <div class="trade-header_bottom">-->
    <!--      <div class="total">-->
    <!--        <div class="text">-->
    <!--          <div class="desc">-->
    <!--            Total balance-->
    <!--          </div>-->
    <!--          <div class="value">-->
    <!--            <span>$</span>-->
    <!--            19,521.48-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="chart">-->
    <!--          <svg-->
    <!--            width="64"-->
    <!--            height="24"-->
    <!--            viewBox="0 0 64 24"-->
    <!--            fill="none"-->
    <!--            xmlns="http://www.w3.org/2000/svg"-->
    <!--          >-->
    <!--            <path-->
    <!--              d="M53.3333 0H64V24H0V9.64286L10.6667 2.66667L21.3333 16L32 6.85714H42.6667L53.3333 0Z"-->
    <!--              fill="url(#paint0_linear_3831_52756)"-->
    <!--            />-->
    <!--            <defs>-->
    <!--              <linearGradient-->
    <!--                id="paint0_linear_3831_52756"-->
    <!--                x1="32"-->
    <!--                y1="0"-->
    <!--                x2="32"-->
    <!--                y2="24"-->
    <!--                gradientUnits="userSpaceOnUse"-->
    <!--              >-->
    <!--                <stop-->
    <!--                  stop-color="#00B18B"-->
    <!--                  stop-opacity="0.4"-->
    <!--                  offset=""-->
    <!--                />-->
    <!--                <stop-->
    <!--                  offset="1"-->
    <!--                  stop-color="#00B18B"-->
    <!--                  stop-opacity="0"-->
    <!--                />-->
    <!--              </linearGradient>-->
    <!--            </defs>-->
    <!--          </svg>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="trade-pairs">-->
    <!--        <div-->
    <!--          v-for="(pair, index) in pairs"-->
    <!--          :key="index"-->
    <!--          class="trade-pairs__item"-->
    <!--        >-->
    <!--          <div class="head">-->
    <!--            <div class="pair">-->
    <!--              {{ pair.coin1 }} / {{ pair.coin2 }}-->
    <!--            </div>-->
    <!--            <div-->
    <!--              class="percentage"-->
    <!--              :class="{'percentage-up': pair.percent > 0, 'percentage-down': pair.percent < 0}"-->
    <!--            >-->
    <!--              <span v-if="pair.percent > 0">+</span>-->
    <!--              {{ formatNumber(pair.percent) }} %-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="value">-->
    <!--            {{ formatNumber(pair.value) }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import UserProfile from "@/components/UserProfile.vue";
import {mapGetters} from "vuex";
import serviceLogos from "@/assets/img/servicesLogos/serviceLogos";

export default {
  name: "TradeHeaderLayout",
  components: {UserProfile},
  props: {
    isShowAccounts: {
      type: Boolean,
      default: true
    },
    titleProp: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      logos: serviceLogos,
      pairs: [
        {
          coin1: 'XMR',
          coin2: 'SOL',
          percent: 28117.6,
          value: 67.8109
        },
        {
          coin1: 'BTC',
          coin2: 'ETH',
          percent: -72.3,
          value: 69044.30
        },
        {
          coin1: 'XMR',
          coin2: 'SOL',
          percent: 28117.6,
          value: 67.8109
        },
        {
          coin1: 'BTC',
          coin2: 'ETH',
          percent: -72.3,
          value: 69044.30
        },
        {
          coin1: 'XMR',
          coin2: 'SOL',
          percent: 28117.6,
          value: 67.8109
        },
        {
          coin1: 'BTC',
          coin2: 'ETH',
          percent: -72.3,
          value: 69044.30
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      UserAcc: 'getUserAcc',
    }),
  },
  methods: {
    formatNumber(number) {
      const parts = number.toString().split(".");
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.length > 1 ? integerPart + "." + parts[1] : integerPart;
    }
  }
}
</script>

<style lang="scss">
.trade-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;


  &_top {
    min-width: 1120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    .account {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      &-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

        svg {
          width: 40px;
          height: 40px;
        }

        .text {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 17px;

          .service {
            color: var(--trade-trade-black, #1A242B);
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px; /* 125% */
            letter-spacing: -0.4px;
          }

          .name {
            color: var(--trade-trade-gray, #527AA1);
            font-size: 32px;
            font-style: normal;
            font-weight: 300;
            line-height: 40px;
            letter-spacing: -0.4px;
          }
        }
      }

      &-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;

        .trade-button {
          display: flex;
          flex-direction: row;
          padding: 10px 16px 10px 12px;
          border-radius: 6px;
          gap: 8px;
          align-items: center;
          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.2px;
          cursor: pointer;

          &_black {
            background: var(--trade-trade-black, #1A242B);
          }

          &_blue {
            background: var(--trade-trade-blue, #007AFF);
          }
        }
      }
    }

    .title-button {
      display: flex;
      flex-direction: row;
      gap: 24px;

      .title {
        color: var(--trade-trade-black, #1A242B);
        font-family: 'Rubik', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px; /* 125% */
        letter-spacing: -0.4px;
      }

      .button {
        border-radius: 8px;
        background: var(--trade-trade-blue, #007AFF);
        padding: 10px 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.2px;
        cursor: pointer;
        transition: all .3s ease-in-out;

        &:hover {
          background: #0073F0;
        }

        span {
          margin-right: 8px;
        }
      }
    }
  }

  &_bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .total {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 12px;
      padding: 4px 8px;

      .text {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .desc {
          color: var(--trade-trade-gray, #527AA1);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }

        .value {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 6px;
          color: var(--trade-trade-black, #1A242B);
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          span {
            color: var(--trade-trade-gray, #527AA1);
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 100% */
          }
        }
      }
    }

    .trade-pairs {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      &__item {
        padding: 4px 8px;
        display: flex;
        flex-direction: column;
        gap: 2px;
        cursor: pointer;
        border-radius: 6px;
        transition: all .3s ease-in-out;

        &:hover {
          background: rgba(0, 122, 255, 0.06);
        }

        .head {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 6px;

          .pair {
            color: var(--trade-trade-gray, #527AA1);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }

          .percentage {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            &-up {
              color: var(--trade-trade-green, #00B18B);
            }

            &-down {
              color: #FF603D;
            }
          }
        }

        .value {
          color: var(--trade-trade-black, #1A242B);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
        }
      }
    }
  }


}
</style>
