<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2246_27250)">
      <path
        d="M4.66732 0.583252H11.6673C12.309 0.583252 12.834 1.10825 12.834 1.74992V9.91658H11.6673V1.74992H4.66732V0.583252ZM2.91732 2.91659H9.33398C9.97565 2.91659 10.5007 3.44159 10.5007 4.08325V12.2499C10.5007 12.8916 9.97565 13.4166 9.33398 13.4166H2.91732C2.27565 13.4166 1.75065 12.8916 1.75065 12.2499V4.08325C1.75065 3.44159 2.27565 2.91659 2.91732 2.91659ZM2.91732 12.2499H9.33398V4.08325H2.91732V12.2499Z"
        fill="#0A68F7"
      />
    </g>
    <defs>
      <clipPath id="clip0_2246_27250">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="matrix(-1 0 0 1 14 0)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CopyAddressIcon"
}
</script>

<style scoped>

</style>
