export default {
    data() {
        return {
            batch: [],
        }
    },
    methods: {
        /**
         * @param status
         * @returns {{name: string, className: string}}
         */
        getExchangeStatus(status) {
            let name = 'Exchange';

            switch (status) {
                case 0 :
                    name = 'Remaining';
                    break;
                case 1 :
                    name = 'Pay';
                    break;
                case 2:
                case 3:
                case 4:
                    name = 'Exchange';
                    break;
                case 5 :
                    name = 'Withdrawal';
                    break;
                case 6 :
                    name = 'Completed';
                    break;
                case 10 :
                    name = 'Overdue';
                    break;
                case 11 :
                    name = 'Returned';
                    break;
                case 12 :
                    name = 'Hold';
                    break;
                case 13 :
                    name = 'Profit';
                    break;
            }

            return {
                name: name,
                className: name.toLowerCase(),
            }
        },
        statusHandler(status = null) {
            this.page = 1;
            let query = {page: this.page};

            if (status !== null) {
                this.statuses = this.statuses.filter((item) => item !== status);
            }

            if (this.statuses.length) {
                query = {page: this.page, status: this.statuses}
            }

            this.$router.push({query: query}).then(() => {
                this.getList(this.$route.query);
            });
        },
        batchData(data, perPage) {
            this.batch = data.map((e, i) => {
                return i % perPage === 0 ? data.slice(i, i + perPage) : null;
            }).filter(e => e);

            return {
                total: this.batch,
                current: this.batch[0],
            };
        },
    }
}
