<script>

export default {
    name: 'CheckButtonIcon',
    props: {
        status: {
            type: Boolean,
            default: false
        }
    }
}

</script>

<template>
  <svg
    v-if="!status"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="3"
      width="18"
      height="18"
      rx="9"
      fill="white"
    />
    <rect
      x="3.5"
      y="3.5"
      width="17"
      height="17"
      rx="8.5"
      stroke="#1E2231"
      stroke-opacity="0.12"
    />
  </svg>
  <svg
    v-if="status"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4007_1885)">
      <rect
        x="3"
        y="3"
        width="18"
        height="18"
        rx="9"
        fill="#0A68F7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5303 8.46967C16.8232 8.76256 16.8232 9.23744 16.5303 9.53033L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.46967 12.5303C7.17678 12.2374 7.17678 11.7626 7.46967 11.4697C7.76256 11.1768 8.23744 11.1768 8.53033 11.4697L10.5 13.4393L15.4697 8.46967C15.7626 8.17678 16.2374 8.17678 16.5303 8.46967Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4007_1885">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
