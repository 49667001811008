<script>
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import Toggle from '@vueform/toggle'
import FakeService from "@/services/fake.service";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import LogsChangeableIcon from "@/components/admin/rate-correction/LogsChangeableIcon.vue";
import {mapGetters} from "vuex";

export default {
  name: 'RateCorrectionEdit',
  components: {
    LogsChangeableIcon,
    simplebar,
    Toggle
  },
  props: {
    data: {
      type: Object,
      default: null,
      required: true,
    }
  },
  data() {
    return {
      rateData: null,
      rateDataOrigin: null,
      isShowLogs: false,
      logsData: null,
      toggle: true,
      dateFormatUrl: 'DD.MM.YY - HH:mm',
      rules: [],
      rulesNew: [],
      invalidObjectsIndexes: []
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.rateData = JSON.parse(JSON.stringify(this.data));
    this.rateDataOrigin = JSON.parse(JSON.stringify(this.rateData));
    this.rules = this.rateData.rows;

    this.getFakeLogsById(this.rateData.id);

  },
  methods: {
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);

    },
    addNewRule() {
      this.rulesNew.push(
          {
            minAmount: 0,
            maxAmount: 0,
            percent: 0,
            isActive: true
          }
      )
    },

    addRule(id, obj) {
      FakeService.addNewRulesById(id, obj)
    },
    archiveItem(id, status) {
      const query = '?state=' + status;

      let statusText = '';

      if (status === false) {
        statusText = 'Archived'
      } else {
        statusText = 'Unarchived'
      }

      FakeService.archiveFake(id, query).then((res) => {
        if (res) {
          this.getFakeById();
        }
      })
          .catch((error) => {
          });
    },
    saveChangesHandler() {
      this.invalidObjectsIndexes = [];
      const updatedRulesIndexes = [];
      let hasChangesDetected = false;

      if (this.rulesNew.length > 0) {
        this.rulesNew.forEach((rule, index) => {
          const values = Object.values(rule);
          const hasZeroValue = values.some(value => value === 0);

          if (hasZeroValue) {
            this.invalidObjectsIndexes.push(index);
          } else {
            this.addRule(this.rateData.id, rule);
          }
        });
      }

      this.rules.forEach((rule, index) => {
        const hasChanges = Object.keys(rule).some(key => {
          return rule[key] !== this.data.rows[index][key];
        });

        if (hasChanges) {
          hasChangesDetected = true;

          this.updateFake(rule);
          updatedRulesIndexes.push(index);
        }
      });

      if (hasChangesDetected || this.rulesNew.length > 0) {
        this.successPopup();
        this.closePopup();
      } else {
        this.errorPopup('No changes detected');
      }

      this.successPopup();
      this.closePopup();
    },

    updateFake(item) {
      return FakeService.updateFake(item);
    },

    successPopup() {
      this.$store.commit('setSuccess', {
        name: 'Success',
        value: 'Changes saved for row ID:' + this.rateData.id
      });
    },
    errorPopup(val) {
      if (!val) {
        this.$store.commit('setError', {
          name: 'Changes error',
        });
      } else {
        this.$store.commit('setError', {
          name: val,
        });
      }
    },

    descriptionFormatter(desc) {
      let formattedDescription = desc.replace(/\n/g, '<br>');
      return formattedDescription.replace(/\n/g, '<br>');
    },
    closePopup() {
      this.emitter.emit('edit-correction', null)
      this.emitter.emit('update-rate-correction');
      this.$router.push({name: 'Rate correction'})
    },
    getFakeLogsById(id) {
      this.logsData = null;
      FakeService.getFakeLogsById(id).then((res) => {
        this.logsData = res.data.result;

      })
    },

    getFakeById() {
      FakeService.getFakeById(this.rateDataOrigin.id).then((res) => {
        this.rateData = res.data.result;

        this.emitter.emit('edit-correction', res.data.result);
        this.rules = this.rateData.rows;
        this.rulesNew = [];
      })
    },

    removeRuleId(id) {
      this.setNotification({name: 'Rule ID:' + id + ' ' + 'removed'});

      setTimeout(() => {
        if (this.$store.state.notification?.name) {
          FakeService.deleteRuleById(id).then((res) => {
            if (res.data.result) {

              this.getFakeById()
              this.setNotification({});
            }
          }).catch(() => {
            this.setNotification({name: 'Error', value: 'status: '});
          })
        }

      }, 3000);
    },

    dateFormatter(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormatUrl)
    },
    updateFakeStatus(id, status) {
      const query = '?state=' + status

      FakeService.updateFakeStatusById(id, query);

      let statusText = '';

      if (status === false) {
        statusText = 'deactivated'
      } else {
        statusText = 'activated'
      }

      this.setNotification({name: 'Item ID:' + id + ' ' + statusText});

      FakeService.getFakeLogsById(this.rateData.id).then((res) => {
        this.logsData = res.data.result;
      })
    },

    updateRowStatusById(id, status) {
      const query = '?state=' + status

      FakeService.updateRowStatusById(id, query);

      let statusText = '';

      if (status === false) {
        statusText = 'deactivated'
      } else {
        statusText = 'activated'
      }

      this.setNotification({name: 'Rule ID:' + id + ' ' + statusText});

      FakeService.getFakeLogsById(this.rateData.id).then((res) => {
        this.logsData = res.data.result;
      })
    },
    isInvalid(index) {
      return this.invalidObjectsIndexes.includes(index);
    },
    removeRule(index) {
      if (index >= 0 && index < this.rulesNew.length) {
        this.rulesNew.splice(index, 1);
      }
    },
    incrementPercent(index) {
      const currentPercent = parseFloat(this.rulesNew[index].percent);
      if (!isNaN(currentPercent)) {
        this.rulesNew[index].percent = (Math.round((currentPercent + 0.1) * 100) / 100).toFixed(2);
      }
    },
    decrementPercent(index) {
      const currentPercent = parseFloat(this.rulesNew[index].percent);
      if (!isNaN(currentPercent) && currentPercent >= 0.1) {
        this.rulesNew[index].percent = (Math.round((currentPercent - 0.1) * 100) / 100).toFixed(2);
      }
    },
    incrementPercentFake(index) {
      const currentPercent = parseFloat(this.rules[index].percent);
      if (!isNaN(currentPercent)) {
        this.rules[index].percent = (Math.round((currentPercent + 0.1) * 100) / 100).toFixed(2);
      }
    },
    decrementPercentFake(index) {
      const currentPercent = parseFloat(this.rules[index].percent);
      if (!isNaN(currentPercent) && currentPercent >= 0.1) {
        this.rules[index].percent = (Math.round((currentPercent - 0.1) * 100) / 100).toFixed(2);
      }
    },

  }
}

</script>

<template>
  <div class="modal">
    <div
      v-if="rateData"
      class="popup"
      :class="{'popup_not-active': !rateData.isActive}"
    >
      <div class="head">
        <div class="login">
          {{ rateData.partnerLogin }}
        </div>
        <div
          v-if="rateData.coinType === 'pair'"
          class="title"
        >
          {{ rateData.coinFromName }}/{{ rateData.coinToName }}
        </div>
        <div
          v-else
          class="title"
        >
          {{ rateData.coinFromName }}
        </div>
        <div class="buttons">
          <div
            class="buttons-logs"
            @click="isShowLogs = !isShowLogs"
          >
            <span v-if="!isShowLogs">
              Show log
            </span>
            <span v-else>
              Hide log
            </span>
          </div>
          <div class="buttons-archive">
            <svg
              v-if="rateData.isRemove"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="archiveItem(rateData.id, false)"
            >
              <path
                d="M11 14V25H25V14H11ZM11 27C10.45 27 9.97917 26.8042 9.5875 26.4125C9.19583 26.0208 9 25.55 9 25V12.525C9 12.2917 9.0375 12.0667 9.1125 11.85C9.1875 11.6333 9.3 11.4333 9.45 11.25L10.7 9.725C10.8833 9.49167 11.1125 9.3125 11.3875 9.1875C11.6625 9.0625 11.95 9 12.25 9H23.75C24.05 9 24.3375 9.0625 24.6125 9.1875C24.8875 9.3125 25.1167 9.49167 25.3 9.725L26.55 11.25C26.7 11.4333 26.8125 11.6333 26.8875 11.85C26.9625 12.0667 27 12.2917 27 12.525V25C27 25.55 26.8042 26.0208 26.4125 26.4125C26.0208 26.8042 25.55 27 25 27H11ZM11.4 12H24.6L23.75 11H12.25L11.4 12Z"
                fill="#DFE0EB"
              />
              <path
                d="M14 19L18 15L22 19L20.6 20.4L19 18.8V23H17V18.8L15.4 20.4L14 19Z"
                fill="#DFE0EB"
              />
            </svg>

            <svg
              v-if="!rateData.isRemove"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="archiveItem(rateData.id,true)"
            >
              <path
                d="M18 24L22 20L20.6 18.6L19 20.2V16H17V20.2L15.4 18.6L14 20L18 24ZM11 14V25H25V14H11ZM11 27C10.45 27 9.97917 26.8042 9.5875 26.4125C9.19583 26.0208 9 25.55 9 25V12.525C9 12.2917 9.0375 12.0667 9.1125 11.85C9.1875 11.6333 9.3 11.4333 9.45 11.25L10.7 9.725C10.8833 9.49167 11.1125 9.3125 11.3875 9.1875C11.6625 9.0625 11.95 9 12.25 9H23.75C24.05 9 24.3375 9.0625 24.6125 9.1875C24.8875 9.3125 25.1167 9.49167 25.3 9.725L26.55 11.25C26.7 11.4333 26.8125 11.6333 26.8875 11.85C26.9625 12.0667 27 12.2917 27 12.525V25C27 25.55 26.8042 26.0208 26.4125 26.4125C26.0208 26.8042 25.55 27 25 27H11ZM11.4 12H24.6L23.75 11H12.25L11.4 12Z"
                fill="#DFE0EB"
              />
            </svg>
          </div>
          <div class="buttons-toggle">
            <Toggle
              v-model="rateData.isActive"
              @change="updateFakeStatus(rateData.id, rateData.isActive)"
            />
          </div>
        </div>
        <div
          class="close"
          @click="closePopup"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4300_72996)">
              <path
                d="M18.2987 5.7107C17.9087 5.3207 17.2787 5.3207 16.8887 5.7107L11.9988 10.5907L7.10875 5.7007C6.71875 5.3107 6.08875 5.3107 5.69875 5.7007C5.30875 6.0907 5.30875 6.7207 5.69875 7.1107L10.5888 12.0007L5.69875 16.8907C5.30875 17.2807 5.30875 17.9107 5.69875 18.3007C6.08875 18.6907 6.71875 18.6907 7.10875 18.3007L11.9988 13.4107L16.8887 18.3007C17.2787 18.6907 17.9087 18.6907 18.2987 18.3007C18.6887 17.9107 18.6887 17.2807 18.2987 16.8907L13.4087 12.0007L18.2987 7.1107C18.6787 6.7307 18.6787 6.0907 18.2987 5.7107V5.7107Z"
                fill="#9FA2B4"
              />
            </g>
            <defs>
              <clipPath id="clip0_4300_72996">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div class="inputs">
        <simplebar
          class="rules"
          data-simplebar-auto-hide="false"
        >
          <div
            v-for="(rule, index) in rules"
            :key="index"
            class="rules--item"
          >
            <div class="rules--item_head">
              <div class="rules--item_head-rule">
                <div
                  class="num"
                  :class="{'num-down': rule.percent < 0 && rateData.isActive, 'num-up': rule.percent > 0 && rateData.isActive}"
                >
                  {{ rule.percent }}%
                </div>
                <div class="rules--item_head-title">
                  Rule {{ index + 1 }} <span>(ID:{{ rule.id }})</span>
                </div>
              </div>
              <div class="rules--item_head-buttons">
                <div
                  class="delete"
                  @click="removeRuleId(rule.id)"
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 27C12.45 27 11.9792 26.8042 11.5875 26.4125C11.1958 26.0208 11 25.55 11 25V12H10V10H15V9H21V10H26V12H25V25C25 25.55 24.8042 26.0208 24.4125 26.4125C24.0208 26.8042 23.55 27 23 27H13ZM23 12H13V25H23V12ZM15 23H17V14H15V23ZM19 23H21V14H19V23Z"
                      fill="#DFE0EB"
                    />
                  </svg>
                </div>
                <div class="rules--item_head-buttons--toggle">
                  <Toggle
                    v-model="rule.isActive"
                  />
                </div>
              </div>
            </div>
            <div class="rules--item_inputs">
              <div class="rules--item_inputs--item">
                <div class="name">
                  Min amount
                </div>
                <div class="input">
                  <input
                    v-model="rule.minAmount"
                    type="number"
                    placeholder="0.00"
                  >
                </div>
              </div>
              <div class="rules--item_inputs--item">
                <div class="name">
                  Max amount
                </div>
                <div class="input">
                  <input
                    v-model="rule.maxAmount"
                    type="number"
                    placeholder="0.00"
                  >
                </div>
              </div>
              <div class="rules--item_inputs--item rules--item_inputs--item-correction">
                <div class="name">
                  Correction %
                </div>
                <div class="input">
                  <div
                    class="input-correction input-correction_minus"
                    @click="decrementPercentFake(index)"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="28"
                        height="28"
                        rx="2"
                        transform="matrix(1 0 0 -1 0 28)"
                        fill="#9FA2B4"
                        fill-opacity="0.1"
                      />
                      <g clip-path="url(#clip0_4300_72964)">
                        <path
                          d="M19.8346 14.8327H8.16797V13.166H19.8346V14.8327Z"
                          fill="#9FA2B4"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4300_72964">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(4 4)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <input
                    v-model="rule.percent"
                    type="number"
                    placeholder="0"
                  >
                  <div
                    class="input-correction input-correction_plus"
                    @click="incrementPercentFake(index)"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="28"
                        height="28"
                        rx="2"
                        fill="#9FA2B4"
                        fill-opacity="0.1"
                      />
                      <g clip-path="url(#clip0_4300_72958)">
                        <path
                          d="M19.8346 14.8327H14.8346V19.8327H13.168V14.8327H8.16797V13.166H13.168V8.16602H14.8346V13.166H19.8346V14.8327Z"
                          fill="#9FA2B4"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4300_72958">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(4 4)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(rule, index) in rulesNew"
            :key="index"
            class="rules--item"
            :class="{'rules--item_not-valid': isInvalid(index)}"
          >
            <div class="rules--item_head">
              <div class="rules--item_head-rule">
                <div
                  class="num"
                  :class="{'num-down': rule.percent < 0 && rateData.isActive || isInvalid(index), 'num-up': rule.percent > 0 && rateData.isActive}"
                >
                  {{ rule.percent }}%
                </div>
                <div class="rules--item_head-title">
                  Rule {{ rules.length + 1 + index }}
                </div>
              </div>
              <div class="rules--item_head-buttons">
                <div
                  class="delete"
                  @click="removeRule(index)"
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 27C12.45 27 11.9792 26.8042 11.5875 26.4125C11.1958 26.0208 11 25.55 11 25V12H10V10H15V9H21V10H26V12H25V25C25 25.55 24.8042 26.0208 24.4125 26.4125C24.0208 26.8042 23.55 27 23 27H13ZM23 12H13V25H23V12ZM15 23H17V14H15V23ZM19 23H21V14H19V23Z"
                      fill="#DFE0EB"
                    />
                  </svg>
                </div>
                <div class="rules--item_head-buttons--toggle">
                  <Toggle
                    v-model="rule.isActive"
                  />
                </div>
              </div>
            </div>
            <div class="rules--item_inputs">
              <div class="rules--item_inputs--item">
                <div class="name">
                  Min amount
                </div>
                <div class="input">
                  <input
                    v-model="rule.minAmount"
                    type="number"
                    placeholder="0.00"
                  >
                </div>
              </div>
              <div class="rules--item_inputs--item">
                <div class="name">
                  Max amount
                </div>
                <div class="input">
                  <input
                    v-model="rule.maxAmount"
                    type="number"
                    placeholder="0.00"
                  >
                </div>
              </div>
              <div class="rules--item_inputs--item rules--item_inputs--item-correction">
                <div class="name">
                  Correction %
                </div>
                <div class="input">
                  <div
                    class="input-correction input-correction_minus"
                    @click="decrementPercent(index)"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="28"
                        height="28"
                        rx="2"
                        transform="matrix(1 0 0 -1 0 28)"
                        fill="#9FA2B4"
                        fill-opacity="0.1"
                      />
                      <g clip-path="url(#clip0_4300_72964)">
                        <path
                          d="M19.8346 14.8327H8.16797V13.166H19.8346V14.8327Z"
                          fill="#9FA2B4"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4300_72964">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(4 4)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <input
                    v-model="rule.percent"
                    type="number"
                    placeholder="0"
                  >
                  <div
                    class="input-correction input-correction_plus"
                    @click="incrementPercent(index)"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="28"
                        height="28"
                        rx="2"
                        fill="#9FA2B4"
                        fill-opacity="0.1"
                      />
                      <g clip-path="url(#clip0_4300_72958)">
                        <path
                          d="M19.8346 14.8327H14.8346V19.8327H13.168V14.8327H8.16797V13.166H13.168V8.16602H14.8346V13.166H19.8346V14.8327Z"
                          fill="#9FA2B4"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4300_72958">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(4 4)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="add-new"
            @click="addNewRule"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4300_73003)">
                <path
                  d="M15.0013 10.8327H10.8346V14.9994C10.8346 15.4577 10.4596 15.8327 10.0013 15.8327C9.54297 15.8327 9.16797 15.4577 9.16797 14.9994V10.8327H5.0013C4.54297 10.8327 4.16797 10.4577 4.16797 9.99935C4.16797 9.54102 4.54297 9.16602 5.0013 9.16602H9.16797V4.99935C9.16797 4.54102 9.54297 4.16602 10.0013 4.16602C10.4596 4.16602 10.8346 4.54102 10.8346 4.99935V9.16602H15.0013C15.4596 9.16602 15.8346 9.54102 15.8346 9.99935C15.8346 10.4577 15.4596 10.8327 15.0013 10.8327Z"
                  fill="#0A68F7"
                />
              </g>
              <defs>
                <clipPath id="clip0_4300_73003">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>

            Add new
          </div>
        </simplebar>
        <simplebar
          v-if="isShowLogs"
          class="logs"
        >
          <div
            v-for="(log, index) in logsData"
            :key="index"
            class="logs--item"
          >
            <div class="logs--item_info">
              {{ dateFormatter(log.createdAt) }}
              <span>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4300_73638)">
                    <path
                      d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 4.5C10.4475 4.5 11.625 5.6775 11.625 7.125C11.625 8.5725 10.4475 9.75 9 9.75C7.5525 9.75 6.375 8.5725 6.375 7.125C6.375 5.6775 7.5525 4.5 9 4.5ZM9 15C7.4775 15 5.6775 14.385 4.395 12.84C5.6625 11.85 7.26 11.25 9 11.25C10.74 11.25 12.3375 11.85 13.605 12.84C12.3225 14.385 10.5225 15 9 15Z"
                      fill="#DFE0EB"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4300_73638">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                {{ log.partnerLogin }}
              </span>
              <span>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4300_73645)">
                    <path
                      d="M15.75 3H2.25C1.425 3 0.75 3.675 0.75 4.5V14.25C0.75 15.075 1.425 15.75 2.25 15.75H15.75C16.575 15.75 17.25 15.075 17.25 14.25V4.5C17.25 3.675 16.575 3 15.75 3ZM5.25 9V10.5H3.75V9H5.25ZM3.75 7.5V6H5.25V7.5H3.75ZM8.25 9V10.5H6.75V9H8.25ZM6.75 7.5V6H8.25V7.5H6.75ZM12 12V12.75H6V12H12ZM11.25 9V10.5H9.75V9H11.25ZM9.75 7.5V6H11.25V7.5H9.75ZM12.75 10.5V9H14.25V10.5H12.75ZM14.25 7.5H12.75V6H14.25V7.5Z"
                      fill="#DFE0EB"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4300_73645">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                {{ log.ipActor }}
              </span>
            </div>
            <div class="logs--item_title">
              <logs-changeable-icon :status="log.category" />
              {{ log.title }}
            </div>
            <div
              class="logs--item_description"
              v-html="descriptionFormatter(log.description) "
            />
          </div>
        </simplebar>
      </div>
      <div class="footer">
        <div class="buttons">
          <div
            class="buttons--item buttons--item_cancel"
            @click="closePopup"
          >
            Cancel
          </div>
          <div
            class="buttons--item buttons--item_create"
            @click="saveChangesHandler"
          >
            Save changes
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.toggle {
  width: var(--toggle-width, 22px);
  height: var(--toggle-height, 9px);
  outline: none;

  &-container {
    &:focus {
      box-shadow: none;
    }
  }
}

.toggle-on {
  background: var(--toggle-bg-on, #02C076);
  border-color: var(--toggle-border-on, #02C076);
}

.toggle-handle-on {
  transform: translateX(-110%);
}

.toggle-handle {
  width: 7px;
  height: 7px;
  top: 1px;
}
</style>
