<template>
  <div
    ref="partnerFilter"
    class="partner-filter"
    @click="partnerSearchToggle"
  >
    <partner-icon
      class="filter"
      height="20px"
    />
    Partner
    <perfect-scrollbar
      v-if="partnersList.length && isPartnerSearch"
      class="dropdown"
    >
      <div
        v-for="(partnerItem) in partnersList"
        :key="partnerItem.id"
        class="dropdown-item"
        @click="partnerSearchHandler(partnerItem)"
      >
        <div>
          {{ partnerItem.login }}
        </div>
        <svg
          v-if="selectedPartner && selectedPartner.id === partnerItem.id"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4068_2939)">
            <path
              d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
              fill="#0A68F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_4068_2939">
              <rect
                width="20"
                height="20"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>

import PartnerIcon from '@/icons/partnerIcon.vue';
import {onBeforeUnmount, onMounted, ref} from 'vue';
import UserService from '@/services/user.service';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';

export default {
  name: "PartnerFilter",
  components: {
    PartnerIcon,
    PerfectScrollbar
  },
  props: {
    isPartnerSearch: {
      type: Boolean,
      required: true
    },
    partnerSearchToggle: {
      type: Function,
      required: true
    },
    partnerSearchHandler: {
      type: Function,
      required: true
    },
    selectedPartner: {
      type: Object,
      required: true
    }
  },
  emits: ['handlePartnerSearchClickOutside'],
  setup(props, { emit }) {
    const partnerFilter = ref(null);

    const handleClickOutside = (event) => {
      if (partnerFilter.value && !partnerFilter.value.contains(event.target)) {
        emit('handlePartnerSearchClickOutside');
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      partnerFilter,
    };
  },
  data() {
    return {
      partnersList: [],
    }
  },
  mounted() {
    this.getPartners()
  },
  methods: {
    getPartners() {
      UserService.getUserFavorite().then((res) => {
        if (res.status === 200) {
          this.partnersList = res.data.result;
        }
      }).catch((error) => {
        console.log(error.response.data.message)
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

.partner-filter {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px 12px 6px 6px;
  height: 32px;
  min-width: fit-content;
  font-size: 12px;
  color: $blue-color;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  background: $light-gray-color;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: $light-gray-hover-color;
  }

  .dropdown {
    position: absolute;
    background: white;
    top: 37px;
    left: -10px;
    width: 180px;
    height: 320px;
    z-index: 999;
    border-radius: 8px;
    padding: 8px 0;
    box-shadow: 0 1px 4px 0 rgba(6, 59, 122, 0.12), 0 4px 24px 0 rgba(6, 59, 122, 0.08);
    box-sizing: border-box;

    .dropdown-item {
      padding: 6px 12px;
      color: $black-color1;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: $hover-color;
      }

      .status-label {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        width: 18px;
        height: 18px;
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.12px;
        border-radius: 4px;

        $status-empty: #A1BBD9;
        $status-pay: #00A3FF;
        $status-exchange: #0065FF;
        $status-withdrawal: #B441EA;
        $status-completed: #02C076;
        $status-overdue: #FFA63D;
        $status-returned: #FF603D;
        $status-hold: #FF603D;
        $status-profit: #FF603D;

        &.status-empty {
          background: $status-empty;
        }
        &.status-pay {
          background: $status-pay;
        }
        &.status-exchange {
          background: $status-exchange;
        }
        &.status-withdrawal {
          background: $status-withdrawal;
        }
        &.status-completed {
          background: $status-completed;
        }
        &.status-overdue {
          background: $status-overdue;
        }
        &.status-returned {
          background: $status-returned;
        }
        &.status-hold {
          background: $status-hold;
        }
        &.status-profit {
          background: $status-profit;
        }
      }
    }
  }
}
</style>
