<script>
import TradePopupLayout from "@/layouts/trade/TradePopupLayout.vue";
import {Chart} from 'vue-tradingview-widgets'
import UserService from "@/services/user.service";
import TradeService from "@/services/trade.service";
import CoinsService from "@/services/coins.service";
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import Avatar from "@/components/admin/AvatarComponent.vue";

export default {
  name: 'CreateOrder',
  components: {Avatar, simplebar, Chart, TradePopupLayout},
  data() {
    return {
      pairSearch: '',
      listBalance: null,
      userProfile: null,
      isSelectMode: false,
      isSelectType: false,
      isAddComment: false,
      preSavedComment: '',
      comment: '',
      selectedMode: 'Limit',
      selectedType: 'Profit',
      switchersMode: ['Limit', 'Market'],
      switchersType: ['Order', 'Profit', 'Other'],
      priceBuy: '',
      priceSell: '',
      amountBuy: '',
      amountSell: '',
      availableBalanceFrom: null,
      availableBalanceTo: null,
      totalBuy: '',
      totalSell: '',
      coinsList: null,
      defaultCoins: [
        {
          shortName: 'USDT'
        },
        {
          shortName: 'BTC'
        }
      ],
      coinTo: 'USDT',
      coinFrom: 'BTC',
      coinToData: null,
      coinFromData: null,
      chartKey: 0,
      marketBuySelected: 'Total',
      switchersMarketBuy: ['Total', 'Amount'],
      market2Selected: 'Total',
      switchersMarketSell: ['Total', 'Amount'],
      currentMarket: ''
    }
  },
  computed: {
    UserAcc() {
      return this.$store.state.userAcc
    },
    Coins: {
      get() {
        return this.$store.state.coins;
      },
      set(value) {
        this.$store.commit('setCoins', value);
      }
    },
    filteredPairs() {
      const searchText = this.pairSearch.toLowerCase();
      const pairs = [];

      for (const defaultCoin of this.defaultCoins) {
        for (const coin of this.coinsList) {
          if (
              defaultCoin.shortName.toLowerCase() === searchText ||
              coin.name.toLowerCase().includes(searchText) ||
              `${defaultCoin.shortName}/${coin.name}`.toLowerCase() === searchText ||
              `${coin.name}/${defaultCoin.shortName}`.toLowerCase() === searchText
          ) {
            pairs.push({
              coinFrom: coin.name,
              coinTo: defaultCoin.shortName
            });
          }
        }
      }

      return pairs;
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.getCoinsListData();
        this.getProfile();
        if (this.UserAcc) {
          this.marketHandler();
        }
      }
    }
  },
  created() {
    this.defaultCoinHandler();
    this.getCoinsListData();
    this.getProfile();
    if (this.UserAcc) {
      this.marketHandler();
    }
  },

  methods: {
    defaultCoinHandler() {
      this.coinFrom = this.$route.params.coin
      if (this.$route.params.coin === 'BTC') {
        this.coinTo = 'USDT'
      }
      if (this.$route.params.coin === 'USDT') {
        this.coinTo = 'BTC'
      }
    },
    marketHandler() {
      if (this.UserAcc.modeAccType === 'okxSub' || this.UserAcc.modeAccType === 'okx') {
        this.currentMarket = 'OKX'
      }
      if (this.UserAcc.modeAccType === 'binance' || this.UserAcc.modeAccType === 'binance') {
        this.currentMarket = 'BINANCE'
      }
    },
    focusOn(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].focus();
      }
    },
    switchMode(el) {
      this.selectedMode = el
    },
    switchType(el) {
      this.selectedType = el
      this.isSelectType = false
    },
    getCoinsListData() {
      this.isLoading = true
      this.getListBalance();
      CoinsService.getCoinsList().then((res) => {
        this.coinsList = res.data.result
      })
    },
    selectPair(coinFromShort, coinToShort) {
      this.$router.push({
        name: 'Create order',
        params: {
          id: this.$route.params.id,
          coin: coinFromShort
        }
      })
      this.coinFrom = coinFromShort;
      this.coinTo = coinToShort;
      this.chartKey += 1;
      this.setAvailableBalance();
    },
    getProfile() {
      UserService.getProfile().then((res) => {
        this.userProfile = res.data.result;
      })
    },
    cancelComment() {
      this.isAddComment = false;
      this.comment = '';
    },
    buyOrder() {
      const order = {
        accountId: this.UserAcc.id,
        userId: this.userProfile.id,
        ipAddress: this.userProfile.ipAddress,
        userAgent: navigator.userAgent || "Unknown User Agent",
        coinFrom: this.coinFrom,
        coinTo: this.coinTo,
        mode: this.selectedMode,
        side: 'Buy',
        type: this.selectedType,
        price: parseFloat(this.priceBuy),
        amount: parseFloat(this.amountBuy),
        comment: this.comment
      }

      if (order.amount && order.price && order.amount !== 0 && order.price !== 0) {
        TradeService.createExchange(order.accountId, order).then((res) => {
          if (res?.status === 200) {
            this.$router.push({
              name: 'Trade orders',
              params: {
                id: this.$route.params.id
              }
            })
          }
        })
      } else {
        console.log('not created Buy');
      }
    },
    sellOrder() {
      const order = {
        accountId: this.UserAcc.id,
        userId: this.userProfile.id,
        ipAddress: this.userProfile.ipAddress,
        userAgent: navigator.userAgent || "Unknown User Agent",
        coinFrom: this.coinFrom,
        coinTo: this.coinTo,
        mode: this.selectedMode,
        side: 'Sell',
        type: this.selectedType,
        price: parseFloat(this.priceSell),
        amount: parseFloat(this.amountSell),
        comment: this.comment
      }

      if (order.amount && order.price && order.amount !== 0 && order.price !== 0) {
        TradeService.createExchange(order.accountId, order).then((res) => {
          if (res?.status === 200) {
            this.$router.push({
              name: 'Trade orders',
              params: {
                id: this.$route.params.id
              }
            })
          }
        })
      } else {
        console.log('not created Sell');
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    addCommentHandler() {
      this.isAddComment = !this.isAddComment;
    },
    handleButtonClick() {
      if (!this.isAddComment) {
        this.addCommentHandler();
      }
    },
    saveComment() {
      this.comment = this.preSavedComment;
    },
    getListBalance() {
      const query = '?mode=trade&isNetworks=false'

      TradeService.getBalanceAcc(this.$route.params.id, query).then((res) => {
        if (res) {
          this.listBalance = res.data.result
          this.setAvailableBalance();
        }
      })
    },
    setAvailableBalance() {
      if (this.listBalance) {
        const foundCoinFrom = this.listBalance.find(item => item.coin.symbol === this.coinFrom);
        const foundCoinTo = this.listBalance.find(item => item.coin.symbol === this.coinTo);

        if (foundCoinFrom) {
          this.availableBalanceFrom = foundCoinFrom.balance;
        } else {
          this.availableBalanceFrom = null;
          this.priceBuy = '';
        }
        if (foundCoinTo) {
          this.availableBalanceTo = foundCoinTo.balance;


        } else {
          this.availableBalanceTo = null;
          this.priceSell = '';
          this.priceBuy = '';
        }

        if (foundCoinTo.coin.name === 'USDT') {
          this.priceSell = foundCoinFrom.price;
          this.priceBuy = foundCoinFrom.price;
        }

        if (foundCoinTo.coin.name === 'BTC') {
          this.priceSell = foundCoinFrom.price / foundCoinTo.price;
          this.priceBuy = foundCoinFrom.price / foundCoinTo.price;
        }

      }
    },
    adjustTextareaHeight() {
      const textarea = this.$refs.commentTextarea;

      textarea.style.height = 'auto';

      if (this.preSavedComment.length > 40) {
        textarea.style.height = `70px`;
      }

      if (this.preSavedComment.length > 120) {
        textarea.style.height = `100px`;
      }
    },
    formatNumber(number) {
      const parts = number.toString().split(".");
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.length > 1 ? integerPart + "." + parts[1] : integerPart;
    },
    totalBuyCalculate(price, amount) {
      const value = amount * price

      if (value !== 0) {
        this.totalBuy = value
      }

      if (!value) {
        this.totalBuy = '0'
      }
    },
    amountBuyCalculate(price, total) {
      const value = total / price

      if (value !== 0) {
        this.amountBuy = value
      }

      if (!value) {
        this.amountBuy = '0'
      }
    },
    totalSellCalculate(price, amount) {
      const value = amount * price

      if (value !== 0) {
        this.totalSell = value
      }

      if (!value) {
        this.totalSell = '0'
      }
    },
    amountSellCalculate(price, total) {
      const value = total / price

      if (value !== 0) {
        this.amountSell = value
      }

      if (!value) {
        this.amountSell = '0'
      }
    },
  }
}

</script>

<template>
  <trade-popup-layout title="New order">
    <template #tradePopupSlot>
      <div class="create-order">
        <div class="create-order_tab create-order_search">
          <input
            v-model="pairSearch"
            type="text"
            placeholder="Search"
          >
          <simplebar
            v-if="coinsList && filteredPairs"
            class="searchList"
            data-simplebar-auto-hide="false"
          >
            <div
              v-for="(pair, index) in filteredPairs"
              :key="index"
              class="searchList--item"
              @click="selectPair(pair.coinFrom, pair.coinTo)"
            >
              <div :class="{'searchList--item_pair': pairSearch === pair.coinFrom}">
                {{ pair.coinFrom }}
              </div>
              <span>/</span>
              <div :class="{'searchList--item_pair': pairSearch === pair.coinTo}">
                {{ pair.coinTo }}
              </div>
            </div>
          </simplebar>
        </div>
        <div class="create-order_tab create-order_coins">
          <div class="head">
            <div class="head_pair">
              <div class="pair">
                {{ coinFrom }}<span>/</span>{{ coinTo }}
              </div>
              <div class="link">
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3860_6198)">
                    <path
                      d="M18.6667 18.6667H9.33333V9.33333H14V8H9.33333C8.59333 8 8 8.6 8 9.33333V18.6667C8 19.4 8.59333 20 9.33333 20H18.6667C19.4 20 20 19.4 20 18.6667V14H18.6667V18.6667ZM15.3333 8V9.33333H17.7267L11.1733 15.8867L12.1133 16.8267L18.6667 10.2733V12.6667H20V8H15.3333Z"
                      fill="#527AA1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3860_6198">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(6 6)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div class="value">
              <!--              <div class="value_big">-->
              <!--                19,521.48-->
              <!--              </div>-->
              <!--              <div class="value_sm">-->
              <!--                0.00000038 +3.31%-->
              <!--              </div>-->
            </div>
          </div>
          <div class="chart">
            <Chart
              :key="chartKey"
              :options="{
                theme: 'light',
                autosize: true,
                symbol: currentMarket + ':' + coinFrom + coinTo,
                interval: '1',
                timezone: 'Etc/UTC',
                style: '8',
                locale: 'en',
                enable_publishing: false,
                save_image: false,
                withdateranges: true,
                hide_legend: true,
                hide_side_toolbar: true,
                container_id: 'tradingview-chart'
              }"
            />
          </div>
          <!--          <div class="volumes">-->
          <!--            <div class="volumes&#45;&#45;item">-->
          <!--              <div class="desc">-->
          <!--                24H High-->
          <!--              </div>-->
          <!--              <div class="value">-->
          <!--                0.34000038-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="volumes&#45;&#45;item">-->
          <!--              <div class="desc">-->
          <!--                24H Volume {{ coinFrom }}-->
          <!--              </div>-->
          <!--              <div class="value">-->
          <!--                12,456,780,566.38-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="volumes&#45;&#45;item">-->
          <!--              <div class="desc">-->
          <!--                24H Low-->
          <!--              </div>-->
          <!--              <div class="value">-->
          <!--                0.34000038-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="volumes&#45;&#45;item">-->
          <!--              <div class="desc">-->
          <!--                24H Volume {{ coinTo }}-->
          <!--              </div>-->
          <!--              <div class="value">-->
          <!--                98,120,456,780,566.38-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="create-order_tab create-order_inputs">
          <div class="head">
            <div
              class="switchers"
            >
              <div
                v-for="(mode, index) in switchersMode"
                :key="index"
                class="switchers--item"
                :class="{'switchers--item_active': mode === selectedMode}"
                @click="switchMode(mode)"
              >
                {{ mode }}
              </div>
            </div>
            <div class="type">
              <div
                class="select-input"
                @click="isSelectType = !isSelectType"
              >
                {{ selectedType }}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3860_6222)">
                    <path
                      d="M7 10L12 15L17 10H7Z"
                      fill="#1A242B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3860_6222">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="matrix(0 1 -1 0 24 0)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                v-if="isSelectType"
                class="select-input_dropmenu"
              >
                <div
                  v-for="(type, index) in switchersType"
                  :key="index"
                  class="item"
                  @click="switchType(type)"
                >
                  {{ type }}

                  <svg
                    v-if="type === selectedType"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3860_8139)">
                      <path
                        d="M7.50208 13.4749L4.02708 9.99987L2.84375 11.1749L7.50208 15.8332L17.5021 5.8332L16.3271 4.6582L7.50208 13.4749Z"
                        fill="#007AFF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3860_8139">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>

            <div
              class="add-comment"
              :class="{'add-comment_disabled': isAddComment}"
              @click="addCommentHandler()"
              @click.stop="handleButtonClick"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_3860_8125)">
                  <path
                    d="M16.6641 1.6665H3.33073C2.41406 1.6665 1.6724 2.4165 1.6724 3.33317L1.66406 18.3332L4.9974 14.9998H16.6641C17.5807 14.9998 18.3307 14.2498 18.3307 13.3332V3.33317C18.3307 2.4165 17.5807 1.6665 16.6641 1.6665ZM16.6641 13.3332H4.30573L3.81406 13.8248L3.33073 14.3082V3.33317H16.6641V13.3332ZM9.16406 9.99984H10.8307V11.6665H9.16406V9.99984ZM9.16406 4.99984H10.8307V8.33317H9.16406V4.99984Z"
                    fill="#527AA1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3860_8125">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>
                Add comment
              </span>
            </div>
          </div>
          <div class="order order-create">
            <div class="inputs">
              <div>
                <div
                  class="inputs--item inputs--item_price"
                  :class="{'focus': !!priceBuy}"
                  @click="focusOn('priceBuy')"
                >
                  <div class="input">
                    <div class="desc">
                      Price
                    </div>
                    <input
                      ref="priceBuy"
                      v-model="priceBuy"
                      type="text"
                      @input="totalBuyCalculate(priceBuy, amountBuy)"
                    >
                  </div>
                  <div class="coin">
                    {{ coinTo }}
                  </div>
                </div>
                <div class="avlb">
                  Avlb <span v-if="availableBalanceTo">{{ availableBalanceTo }}</span> <span v-else>0</span> {{
                    coinTo
                  }}
                </div>
              </div>

              <div>
                <div
                  class="inputs--item inputs--item_price"
                  :class="{'focus': !!priceSell}"
                  @click="focusOn('priceSell')"
                >
                  <div class="input">
                    <div class="desc">
                      Price
                    </div>
                    <input
                      v-if="selectedMode === 'Market'"
                      ref="priceSell"
                      v-model="priceSell"
                      type="text"
                      readonly
                    >
                    <input
                      v-else
                      ref="priceSell"
                      v-model="priceSell"
                      type="text"
                      @input="totalSellCalculate(priceSell, amountSell)"
                    >
                  </div>
                  <div class="coin">
                    {{ coinTo }}
                  </div>
                </div>
                <div class="avlb">
                  Avlb <span v-if="availableBalanceFrom">{{ availableBalanceFrom }}</span> <span v-else>0</span>
                  {{ coinFrom }}
                </div>
              </div>

              <div v-if="selectedMode === 'Limit'">
                <div
                  class="inputs--item inputs--item_amount"
                  :class="{'focus': !!amountBuy}"
                  @click="focusOn('amountBuy')"
                >
                  <div class="input">
                    <div class="desc">
                      Amount
                    </div>
                    <input
                      ref="amountBuy"
                      v-model="amountBuy"
                      type="text"
                      @input="totalBuyCalculate(priceBuy, amountBuy)"
                    >
                  </div>
                  <div class="coin">
                    {{ coinFrom }}
                  </div>
                </div>
              </div>

              <div v-if="selectedMode === 'Limit'">
                <div
                  class="inputs--item inputs--item_amount"
                  :class="{'focus': !!amountSell}"
                  @click="focusOn('amountSell')"
                >
                  <div class="input">
                    <div class="desc">
                      Amount
                    </div>
                    <input
                      ref="amountSell"
                      v-model="amountSell"
                      type="text"
                      @input="totalSellCalculate(priceSell, amountSell)"
                    >
                  </div>
                  <div class="coin">
                    {{ coinTo }}
                  </div>
                </div>
              </div>

              <div v-if="selectedMode === 'Limit'">
                <div
                  class="inputs--item inputs--item_total"
                  :class="{'focus': !!totalBuy}"
                  @click="focusOn('totalBuy')"
                >
                  <div class="input">
                    <div class="desc">
                      Total
                    </div>
                    <input
                      ref="totalBuy"
                      v-model="totalBuy"
                      type="text"
                      :class="{'focus': !!totalBuy}"
                      @input="amountBuyCalculate(priceBuy, totalBuy)"
                    >
                  </div>
                  <div class="coin">
                    {{ coinTo }}
                  </div>
                </div>
              </div>

              <div v-if="selectedMode === 'Limit'">
                <div
                  class="inputs--item inputs--item_total"
                  :class="{'focus': !!totalSell}"
                  @click="focusOn('totalSell')"
                >
                  <div class="input">
                    <div class="desc">
                      Total
                    </div>
                    <input
                      ref="totalSell"
                      v-model="totalSell"
                      type="text"
                      @input="amountSellCalculate(priceSell, totalSell)"
                    >
                  </div>
                  <div class="coin">
                    {{ coinFrom }}
                  </div>
                </div>
              </div>

              <div
                v-if="selectedMode === 'Market'"
                class="inputs--item_switchers"
              >
                <div
                  class="switchers"
                >
                  <div
                    v-for="(mode, index) in switchersMarketBuy"
                    :key="index"
                    class="switchers--item"
                    :class="{'switchers--item_active': mode === marketBuySelected}"
                    @click="marketBuySelected = mode"
                  >
                    {{ mode }}
                  </div>
                </div>
                <div
                  v-if="market2Selected === 'Total'"
                  class="inputs--item inputs--item_amount"
                  :class="{'focus': !!totalBuy}"
                  @click="focusOn('totalBuy')"
                >
                  <div class="input">
                    <div class="desc">
                      Total
                    </div>
                    <input
                      ref="totalBuy"
                      v-model="totalBuy"
                      type="text"
                    >
                  </div>
                  <div class="coin">
                    {{ coinTo }}
                  </div>
                </div>
                <div
                  v-if="market2Selected === 'Amount'"
                  class="inputs--item inputs--item_amount"
                  :class="{'focus': !!amountBuy}"
                  @click="focusOn('amountBuy')"
                >
                  <div class="input">
                    <div class="desc">
                      Amount
                    </div>
                    <input
                      ref="amountBuy"
                      v-model="amountBuy"
                      type="text"
                    >
                  </div>
                  <div class="coin">
                    {{ coinTo }}
                  </div>
                </div>
              </div>

              <div
                v-if="selectedMode === 'Market'"
                class="inputs--item_switchers"
              >
                <div
                  class="switchers"
                >
                  <div
                    v-for="(mode, index) in switchersMarketSell"
                    :key="index"
                    class="switchers--item"
                    :class="{'switchers--item_active': mode === market2Selected}"
                    @click="market2Selected = mode"
                  >
                    {{ mode }}
                  </div>
                </div>
                <div
                  v-if="market2Selected === 'Total'"
                  class="inputs--item inputs--item_amount"
                  :class="{'focus': !!totalSell}"
                  @click="focusOn('totalSell')"
                >
                  <div class="input">
                    <div class="desc">
                      Total
                    </div>
                    <input
                      ref="amountSell"
                      v-model="totalSell"
                      type="text"
                    >
                  </div>
                  <div class="coin">
                    {{ coinTo }}
                  </div>
                </div>
                <div
                  v-if="market2Selected === 'Amount'"
                  class="inputs--item inputs--item_amount"
                  :class="{'focus': !!amountSell}"
                  @click="focusOn('amountSell')"
                >
                  <div class="input">
                    <div class="desc">
                      Amount
                    </div>
                    <input
                      ref="amountSell"
                      v-model="amountSell"
                      type="text"
                    >
                  </div>
                  <div class="coin">
                    {{ coinTo }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <div
              class="buttons--item buttons--item_buy"
              @click="buyOrder()"
            >
              Buy {{ coinFrom }}
            </div>
            <div
              class="buttons--item buttons--item_sell"
              @click="sellOrder()"
            >
              Sell {{ coinTo }}
            </div>
          </div>
          <div
            v-if="isAddComment"
            class="comment"
            :class="{'comment_disabled': comment.length}"
          >
            <div class="author-avatar">
              <avatar :img="userProfile.avatar" />
            </div>

            <div class="author">
              <div class="author-name">
                {{ userProfile.login }}
              </div>
              <textarea
                ref="commentTextarea"
                v-model="preSavedComment"
                :disabled="comment.length"
                @input="adjustTextareaHeight"
              />
            </div>
            <div
              v-if="!comment.length"
              class="buttons"
            >
              <div
                class="item item_send"
                @click="saveComment"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3860_8944)">
                    <path
                      d="M1.6724 17.5L19.1641 10L1.6724 2.5L1.66406 8.33333L14.1641 10L1.66406 11.6667L1.6724 17.5Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3860_8944">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Send
              </div>
              <div
                class="item"
                @click="cancelComment"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </trade-popup-layout>
</template>

<style lang="scss">
.create-order {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 4px 48px 0px rgba(6, 59, 122, 0.08), 0px 1px 4px 0px rgba(6, 59, 122, 0.06);
  display: flex;
  flex-direction: row;
  max-width: 1120px;
  margin: 0 auto;

  &_tab {
    width: 100%;
    padding: 24px 24px 32px 24px;
    box-sizing: border-box;
  }

  &_search {
    max-width: 186px;

    input {
      padding: 4px 8px;
      border-radius: 4px;
      outline: 1px solid rgba(124, 163, 210, 0.24);
      max-width: 100%;
      box-sizing: border-box;
      font-family: 'Rubik', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: -0.2px;
      border: none;
      transition: all .2s ease-in-out;

      &::placeholder {
        color: var(--trade-trade-lightgray, #7CA3D2);
        transition: all .2s ease-in-out;
      }

      &:focus {
        border: none;
        outline: 2px solid var(--trade-trade-black, #1A242B);

        &:hover {
          background: none;
        }

        &::placeholder {
          color: #1A242B;
          opacity: 1;
        }
      }

      &:hover {
        cursor: pointer;
        background: rgba(0, 122, 255, 0.06);

        &::placeholder {
          color: #1A242B;
          opacity: 1;
        }
      }
    }

    .simplebar-scrollbar::before {
      background: #EAF0F8;
      opacity: 1;
    }

    .searchList {
      margin-top: 12px;
      max-height: 372px;

      &--item {
        width: 100%;
        padding: 4px 8px;
        color: var(--trade-trade-black, #1A242B);
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;

        &_pair {
          font-weight: 500;
        }

        &:hover {
          cursor: pointer;
          border-radius: 4px;
          background: var(--trade-hover, rgba(0, 122, 255, 0.06));
        }

        span {
          color: var(--trade-trade-gray, #527AA1);
          font-family: 'Rubik', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }

  &_coins {
    border-left: 1px solid #E0E9F4;
    border-right: 1px solid #E0E9F4;
    max-width: 348px;
    min-width: 340px;

    .head {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 16px;

      &_pair {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .pair {
          color: var(--trade-trade-black, #1A242B);
          font-family: 'Rubik', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 116.667% */
          letter-spacing: -0.4px;

          span {
            color: var(--trade-trade-gray, #527AA1);
            font-family: 'Rubik', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: -0.4px;
          }
        }

        .link {
          cursor: pointer;
          transition: all .3s ease-in-out;

          &:hover {
            border-radius: 6px;
            background: rgba(0, 122, 255, 0.06);

            svg {
              path {
                fill: rgba(26, 36, 43, 1)
              }
            }
          }
        }
      }

      .value {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &_big {
          color: var(--trade-trade-red, #FF603D);
          font-family: 'Rubik', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 116.667% */
        }

        &_sm {
          color: var(--trade-trade-red, #FF603D);
          text-align: right;
          font-family: Rubik;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }

    .chart {
      width: 100%;
      height: 250px;
      margin-bottom: 20px;

      #tradingview-chart {
        width: 100%;

        .chart-toolbar .chart-controls-bar {
          display: none;
        }
      }
    }

    .volumes {
      display: flex;
      flex-direction: row;
      gap: 16px 0;
      flex-wrap: wrap;
      width: 100%;

      &--item {
        max-width: 142px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2px;

        .desc {
          color: var(--trade-trade-gray, #527AA1);
          font-family: 'Rubik', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }

        .value {
          color: var(--trade-trade-black, #1A242B);
          font-family: 'Rubik', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }
  }

  &_inputs {
    display: flex;
    flex-direction: column;
    max-width: 584px;
    width: 100%;

    .switchers {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      padding: 2px;
      border-radius: 6px;
      background: rgba(124, 163, 210, 0.12);

      &--item {
        cursor: pointer;
        border-radius: 4px;
        transition: all .2s ease-in-out;
        color: var(--trade-trade-gray, #527AA1);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 114.286% */
        padding: 6px 12px;


        &_active {
          background: #FFF;
          box-shadow: 0 1px 3px 0 #DFE2F3;
          color: var(--trade-trade-black, #1A242B);
        }
      }
    }

    .head {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;


      .add-comment {
        padding: 6px 8px 6px 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        color: var(--trade-trade-gray, #527AA1);
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.2px;
        cursor: pointer;
        border-radius: 6px;
        transition: all .3s ease-in-out;

        svg {
          path {
            transition: all .3s ease-in-out;
          }
        }

        &:hover {
          background: rgba(0, 122, 255, 0.06);
          color: #1A242B;

          svg {
            path {
              fill: #1A242B;
            }
          }


        }

        &_disabled {
          &:hover {
            background: none;
            cursor: default;
          }

          span {
            color: #BACAD9;
          }

          svg {
            path {
              fill: #BACAD9 !important;
            }
          }
        }
      }

      .type {
        position: relative;

        .select-input {
          width: 213px;
          border-radius: 6px;
          border: 1px solid rgba(130, 170, 200, 0.24);
          padding: 0 4px 0 12px;
          height: 32px;
          box-sizing: border-box;
          color: var(--trade-trade-black, #1A242B);
          font-family: 'Rubik', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          transition: all .3s ease-in-out;
          cursor: pointer;

          &:hover {
            background: var(--trade-hover, rgba(0, 122, 255, 0.06));
          }

          &_dropmenu {
            position: absolute;
            top: calc(100% + 4px);
            left: 0;
            border-radius: 6px;
            padding: 6px 0;
            background: #FFF;
            box-shadow: 0px 1px 6px 0px rgba(6, 59, 122, 0.16);
            width: 100%;
            z-index: 9;

            .item {
              padding: 6px 12px;
              color: var(--trade-trade-black, #1A242B);
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              height: 32px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              transition: all .3s ease-in-out;

              &:hover {
                background: var(--trade-hover, rgba(0, 122, 255, 0.06));
              }
            }
          }
        }
      }
    }

    .order {
      height: 100%;
      margin-top: 24px;
      width: 100%;

      .inputs {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 12px 0;

        &--item {
          width: 252px;
          border-radius: 6px;
          outline: 1px solid rgba(124, 163, 210, 0.24);
          padding: 6px 12px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          cursor: pointer;
          transition: all .2s ease-in-out;
          border: none;
          height: 50px;

          &:hover {
            background: var(--trade-hover, rgba(0, 122, 255, 0.06));

            .desc {
              color: #1A242B;
            }
          }

          .input {
            position: relative;
            height: 100%;
          }


          .desc {
            color: var(--trade-trade-gray, #527AA1);
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            transform: translateY(8px);
            transition: all .2s ease-in-out;
          }

          input {
            background: none;
            border: none;
            outline: none;
            color: var(--trade-trade-black, #1A242B);
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            max-width: 188px;
            cursor: pointer;
          }

          .coin {
            color: var(--trade-trade-gray, #527AA1);
            text-align: right;
            font-family: 'Rubik', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 183.333% */
          }

          &_switchers {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-top: 8px;

            .switchers {
              &--item {
                width: 100%;
                padding: 0;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 166.667% */
                letter-spacing: -0.2px;
                transition: all .3s ease-in-out;

                &_active {
                  font-weight: 500;
                }
              }
            }
          }

          &:focus-within {
            border: none;
            outline: 2px solid var(--trade-trade-black, #1A242B);

            .desc {
              font-size: 12px;
              line-height: 14px;
              color: #1A242B;
              transform: translateY(0);
            }

            &:hover {
              background: none;
            }
          }
        }

        .focus {
          .desc {
            transform: translateY(0);
            font-size: 12px;
            line-height: 14px;
            color: #1A242B;
          }
        }

        .avlb {
          margin-left: 12px;
          margin-top: 6px;
          margin-bottom: 4px;
          color: var(--trade-trade-gray, #527AA1);
          font-family: 'Rubik', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 116.667% */
        }
      }

      &-create {
        min-width: 536px;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 24px;

      &--item {
        border-radius: 6px;
        border: 1px solid rgba(130, 170, 200, 0.24);
        color: #FFF;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.2px;
        height: 48px;
        display: grid;
        place-items: center;
        max-width: 252px;
        width: 100%;

        &_buy {
          background: var(--trade-trade-green, #00B18B);

          &:hover {
            cursor: pointer;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), #00B18B;
          }
        }

        &_sell {
          background: var(--red, #FF603D);

          &:hover {
            cursor: pointer;
            background: #FF603D;
          }
        }
      }
    }

    .comment {
      margin-top: 20px;
      position: relative;
      width: 100%;
      border-radius: 6px;
      border: 2px solid var(--trade-trade-black, #1A242B);
      padding: 8px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 12px;

      .author {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-avatar {
          width: 40px;
          height: 40px;


          svg, img {
            overflow: hidden;
            border-radius: 50%;
            width: 100%;
            height: auto;
          }
        }

        &-name {
          color: var(--trade-trade-black, #1A242B);
          font-family: 'Rubik', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 116.667% */
        }

        textarea {
          width: 100%;
          resize: none;
          color: var(--trade-trade-black, #1A242B);
          font-family: 'Rubik', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          overflow: hidden;
          border: none;
          outline: none;
        }
      }

      .buttons {
        position: absolute;
        bottom: 8px;
        right: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .item {
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: center;
          padding: 10px 12px;
          color: var(--trade-trade-gray, #527AA1);
          font-family: 'Rubik', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.2px;
          cursor: pointer;
          transition: all .3s ease-in-out;

          &:hover {
            background: var(--trade-hover, rgba(0, 122, 255, 0.06));
          }

          &_send {
            border-radius: 6px;
            background: var(--trade-trade-black, #1A242B);
            color: #FFF;
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: -0.2px;
            transition: all .3s ease-in-out;

            &:hover {
              background: #0E1418;
            }
          }
        }
      }

      &_disabled {
        border: 1px solid rgba(130, 170, 200, 0.24);
      }
    }
  }
}
</style>
