<template>
  <TradeLayout>
    <template #tradeSlot>
      <trade-table-layout
        title="Balance"
        :is-buttons="true"
        :switchers="switchers"
        :current-switch="currentSwitch"
        :table-th="tableTh"
        :user-data="UserAcc"
      >
        <template
          v-if="tableData"
          #table
        >
          <tr
            v-for="(tableTd, index) in tableData"
            :key="index"
          >
            <td class="table-coin">
              <div class="table-coin_info">
                <div class="table-coin_info-img">
                  <img
                    v-if="tableTd.coin.image"
                    :src="apiUrl + tableTd.coin.image"
                    alt=""
                  >
                </div>
                <div class="table-coin_info-text">
                  <div class="table-coin-name">
                    {{ tableTd.coin.symbol }}
                  </div>
                  <div class="table-coin-full_name">
                    {{ tableTd.coin.fullName }}
                  </div>
                </div>
              </div>
            </td>
            <td class="table-balance">
              <div class="table-balance_td">
                <span>{{ formatNumber(tableTd.balance) }}</span>
                <span>USDT {{ formatNumber(tableTd.balanceUsdt) }}</span>
              </div>
            </td>
            <td class="table-buttons">
              <div class="buttons">
                <div
                  v-if="UserAcc.modeAccType === 'okxSub' && currentSwitch.key === 'funding'"
                  class="buttons__item buttons__item_disabled"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3831_52797)">
                      <path
                        d="M5.0026 9.99984H7.5026L4.16927 6.6665L0.835938 9.99984H3.33594C3.33594 13.6832 6.31927 16.6665 10.0026 16.6665C11.3109 16.6665 12.5276 16.2832 13.5526 15.6332L12.3359 14.4165C11.6443 14.7915 10.8443 14.9998 10.0026 14.9998C7.24427 14.9998 5.0026 12.7582 5.0026 9.99984ZM6.4526 4.3665L7.66927 5.58317C8.36927 5.21651 9.16094 4.99984 10.0026 4.99984C12.7609 4.99984 15.0026 7.2415 15.0026 9.99984H12.5026L15.8359 13.3332L19.1693 9.99984H16.6693C16.6693 6.3165 13.6859 3.33317 10.0026 3.33317C8.69427 3.33317 7.4776 3.7165 6.4526 4.3665Z"
                        fill="#1A242B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3831_52797">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  Trade
                </div>
                <router-link
                  v-else
                  :to="{name: 'Create order', params: {id: $route.params.id, coin: tableTd.coin.symbol}}"
                  class="buttons__item"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3831_52797)">
                      <path
                        d="M5.0026 9.99984H7.5026L4.16927 6.6665L0.835938 9.99984H3.33594C3.33594 13.6832 6.31927 16.6665 10.0026 16.6665C11.3109 16.6665 12.5276 16.2832 13.5526 15.6332L12.3359 14.4165C11.6443 14.7915 10.8443 14.9998 10.0026 14.9998C7.24427 14.9998 5.0026 12.7582 5.0026 9.99984ZM6.4526 4.3665L7.66927 5.58317C8.36927 5.21651 9.16094 4.99984 10.0026 4.99984C12.7609 4.99984 15.0026 7.2415 15.0026 9.99984H12.5026L15.8359 13.3332L19.1693 9.99984H16.6693C16.6693 6.3165 13.6859 3.33317 10.0026 3.33317C8.69427 3.33317 7.4776 3.7165 6.4526 4.3665Z"
                        fill="#1A242B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3831_52797">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  Trade
                </router-link>
                <div class="buttons-sm">
                  <!--deposit-->

                  <div
                    class="buttons-sm_block"
                    @mouseover="isHoverDeposit = index"
                    @mouseleave="isHoverDeposit = false"
                  >
                    <router-link
                      v-if="UserAcc.modeAccType === 'okxSub' && currentSwitch.key === 'funding'"
                      :to="{name: 'Create deposit by', params: {id: $route.params.id, coin: tableTd.coin.symbol}}"
                      class="buttons__item"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3831_52811)">
                          <path
                            d="M9.16406 5.83333L7.9974 7L10.1641 9.16667H1.66406V10.8333H10.1641L7.9974 13L9.16406 14.1667L13.3307 10L9.16406 5.83333ZM16.6641 15.8333H6.9974V17.5H16.6641C17.5807 17.5 18.3307 16.75 18.3307 15.8333V4.16667C18.3307 3.25 17.5807 2.5 16.6641 2.5H6.9974V4.16667H16.6641V15.8333Z"
                            fill="#1A242B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3831_52811">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </router-link>
                    <div
                      v-else
                      class="buttons__item buttons__item_disabled"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3831_52811)">
                          <path
                            d="M9.16406 5.83333L7.9974 7L10.1641 9.16667H1.66406V10.8333H10.1641L7.9974 13L9.16406 14.1667L13.3307 10L9.16406 5.83333ZM16.6641 15.8333H6.9974V17.5H16.6641C17.5807 17.5 18.3307 16.75 18.3307 15.8333V4.16667C18.3307 3.25 17.5807 2.5 16.6641 2.5H6.9974V4.16667H16.6641V15.8333Z"
                            fill="#1A242B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3831_52811">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                      v-if="isHoverDeposit === index"
                      class="hovered hovered-last"
                    >
                      Deposit
                    </div>
                  </div>


                  <!--transfer-->
                  <div
                    class="buttons-sm_block"
                    @mouseover="isHoverTransfer = index"
                    @mouseleave="isHoverTransfer = false"
                  >
                    <router-link
                      v-if="UserAcc.modeAccType !== 'binance'"
                      :to="{name: 'Create transfer', params: {id: UserAcc?.id, coin: tableTd.coin.symbol, mode: currentSwitch.name}}"
                      class="buttons__item"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3831_52807)">
                          <path
                            d="M5.825 9.1665L2.5 12.4998L5.825 15.8332V13.3332H11.6667V11.6665H5.825V9.1665ZM17.5 7.49984L14.175 4.1665V6.6665H8.33333V8.33317H14.175V10.8332L17.5 7.49984Z"
                            fill="#527AA1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3831_52807">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </router-link>
                    <div
                      v-else
                      class="buttons__item buttons__item_disabled"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3831_52807)">
                          <path
                            d="M5.825 9.1665L2.5 12.4998L5.825 15.8332V13.3332H11.6667V11.6665H5.825V9.1665ZM17.5 7.49984L14.175 4.1665V6.6665H8.33333V8.33317H14.175V10.8332L17.5 7.49984Z"
                            fill="#527AA1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3831_52807">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                      v-if="isHoverTransfer === index"
                      class="hovered"
                    >
                      Transfer
                    </div>
                  </div>


                  <!--withdrawal-->

                  <div
                    class="buttons-sm_block"
                    @mouseover="isHoverWithdrawal = index"
                    @mouseleave="isHoverWithdrawal = false"
                  >
                    <router-link
                      v-if="UserAcc.modeAccType === 'okxSub' && currentSwitch.key === 'funding' || UserAcc.modeAccType === 'binance'"
                      class="buttons__item"
                      :to="{name: 'Create withdrawal by', params: {
                        id: UserAcc.id,
                        coin: tableTd.coin.symbol
                      }}"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3831_52803)">
                          <path
                            d="M14.1641 5.83333L12.9891 7.00833L15.1391 9.16667H6.66406V10.8333H15.1391L12.9891 12.9833L14.1641 14.1667L18.3307 10L14.1641 5.83333ZM3.33073 4.16667H11.9974V2.5H3.33073C2.41406 2.5 1.66406 3.25 1.66406 4.16667V15.8333C1.66406 16.75 2.41406 17.5 3.33073 17.5H11.9974V15.8333H3.33073V4.16667Z"
                            fill="#527AA1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3831_52803">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </router-link>
                    <div
                      v-else
                      class="buttons__item buttons__item_disabled"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3831_52803)">
                          <path
                            d="M14.1641 5.83333L12.9891 7.00833L15.1391 9.16667H6.66406V10.8333H15.1391L12.9891 12.9833L14.1641 14.1667L18.3307 10L14.1641 5.83333ZM3.33073 4.16667H11.9974V2.5H3.33073C2.41406 2.5 1.66406 3.25 1.66406 4.16667V15.8333C1.66406 16.75 2.41406 17.5 3.33073 17.5H11.9974V15.8333H3.33073V4.16667Z"
                            fill="#527AA1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3831_52803">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                    <div
                      v-if="isHoverWithdrawal === index"
                      class="hovered hovered-last"
                    >
                      Withdrawal
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template #loader>
          <trade-loader v-if="isLoading" />
        </template>
        <template #noData>
          <TradeNoData v-if="!hasData && !isLoading" />
        </template>
      </trade-table-layout>
    </template>
  </TradeLayout>
</template>

<script>
import TradeLayout from "@/layouts/trade/TradeLayout.vue";
import TradeTableLayout from "@/components/trade/TradeTableLyout.vue";
import {TRADE_BALANCE_TABLE_TH} from "@/pages/admin-trade/balance/trade-balance.headers";
import {mapGetters} from "vuex";
import TradeService from "@/services/trade.service";
import {MAIN_DOMAIN} from "@/store/config";
import TradeNoData from "@/components/trade/components/TradeNoData.vue";
import TradeLoader from "@/components/trade/components/TradeLoader.vue";

export default {
  name: "TradeBalancePage",
  components: {TradeLoader, TradeNoData, TradeTableLayout, TradeLayout},
  data() {
    return {
      currentSwitch: {
        name: 'Spot',
        key: 'trade'
      },
      isHoverWithdrawal: false,
      switchers: [
        {
          name: 'Spot',
          key: 'trade'
        },
        {
          name: 'Funding',
          key: 'funding'
        }
      ],
      tableTh: TRADE_BALANCE_TABLE_TH,
      tableData: [],
      apiUrl: MAIN_DOMAIN,
      isLoading: true,
      isHoverTransfer: false,
      isHoverDeposit: false
    }
  },
  computed: {
    ...mapGetters({
      UserAcc: 'getUserAcc',
    }),
    hasData() {
      return Array.isArray(this.tableData) && this.tableData.length > 0; // Додали перевірку на масив
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        this.getListBalance(newId);
      }
    }
  },
  mounted() {
    this.emitter.on('switch-button', data => {
      this.currentSwitch = data
      this.getListBalance(this.$route.params.id)
    })

    this.getListBalance(this.$route.params.id)

  },
  methods: {
    formatNumber(number) {
      const parts = number.toString().split(".");
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.length > 1 ? integerPart + "." + parts[1] : integerPart;
    },
    getListBalance(id) {
      this.isLoading = true;
      this.tableData = null;
      const query = '?mode=' + this.currentSwitch.key + '&isNetworks=false';
      TradeService.getBalanceAcc(id, query).then((res) => {
        if (res) {
          this.tableData = res.data.result;
          this.isLoading = false;
        }
        else {
          this.hasData = false;
        }
      })
    }
  }
}
</script>

<style lang="scss">
.trade-layout {
  .table {
    &-coin {
      text-align: start;

      &_info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        &-img {
          img {
            max-width: 32px;
          }
        }
      }
    }

    &-balance {
      text-align: right;

      &_td {
        display: flex;
        flex-direction: column;
        gap: 2px;

        span {
          color: var(--black, #1A242B);
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
          &:not(:first-child) {
            color: var(--trade-trade-gray, #527AA1);
            text-align: right;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 116.667% */
          }
        }
      }
    }

    &-buttons {
      width: 300px;

      .buttons {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
}
</style>
