export const RATE_CHART = {
    chart: {
        renderTo: 'container',
        height: 700
    },
    series: [],
    title: {
        text: null,
    },
    credits: {
        enabled: false,
    },
    colors: ['#3D5AFE', '#00B0FF', '#4CAF50', '#00BFA0', '#C0CA33', '#FDD835', '#FFA000', '#FF5722', '#EC407A', '#AB47BC'],
    xAxis: {
        categories: [],
        type: 'datetime',
        title: true,
        labels: {
            format: '{value:,.0f}',
            style: {
                color: '#9FA2B4',
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px'
            }
        },
        minPadding: 0,
        maxPadding: 0,
        minRange: 0,
        visible: true,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
    },
    yAxis: {
        gridLineColor: 'transparent',
        title: {
            enabled: false,
        },
        endOnTick: false,
        startOnTick: false,
        min: 0
    },
    legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        y: 10,
        className: 'legend-custom',
        useHTML: true,
        itemStyle: {
            color: '#1B1A1C',
            fontWeight: 'normal',
            fontFamily: 'Inter, sans-serif',
            fontSize: '12px'
        },
        itemDistance: 3,
        symbolPadding: 0,
        symbolWidth: 0,
        symbolHeight: 0,
        squareSymbol: false,
        labelFormatter: function() {
            return '<div class="highcharts-legend-item_custom" style="display: flex; align-items: center;">' +
                '<div style="width: 16px; height: 3px; background-color: ' + this.color + '; margin-right: 4px;"></div>' +
                '<div class="highcharts-legend-item_custom--text">' + this.name + '</div>' +
                '</div>';
        }
    },
    tooltip: {
        shared: true,
        crosshairs: true,
        backgroundColor: '#1B1A1C',
        borderRadius: 6,
        borderColor: '#1B1A1C',
        shadow: false,
        useHTML: true,
        headerFormat: '<table><tr><th colspan="2" style="color: white; font-size: 14px; font-weight: 800; ">{point.key}</th></tr>',
        footerFormat: '</table>',
        style: {
            color: 'white',
            fontWeight: 'bold',
            fontFamily: 'Inter, sans-serif',
            fontSize: '12px',
            zIndex: 9999999
        },
        pointFormatter: function() {
            if (this.y !== 0) {
                return `<tr><td style="color: white; font-weight: 300; padding-right: 15px" :class="{\`active-tooltip\`: }"><div style="display: flex; align-items: center; gap: 4px"><div style="border-radius: 50%; width: 8px; height: 8px; background: ${this.series.color};"></div>${this.series.name}: </div> </td> <td style="text-align: right; font-weight: 300;"><b> ${this.y}</b></td></tr>`
            }
        }
    },
    plotOptions: {
        series: {
            states: {
                inactive: {
                    enabled: true,
                    opacity: 1,
                },
                hover: {
                    enabled: true,
                    lineWidth: 4,
                    opacity: 1,
                    marker: {
                        enabled: false
                    }
                }
            },
            marker: {
                enabled: false,
                radius: 0,
                states: {
                    hover: {
                        radius: 0
                    }
                }
            },
        },
    },
};

