<template>
  <div
    id="qr-code"
    ref="qrCode"
  />
</template>

<script>
import QRCodeStyling from 'qr-code-styling';

export default {
  name: 'QrCodeGen',
  props: {
    data: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: {
        width: 120,
        height: 120,
        type: 'svg',
        data: '',
        margin: 0,
        qrOptions: {
          typeNumber: 0,
          mode: 'Byte',
          errorCorrectionLevel: 'L'
        },
        dotsOptions: {
          color: '#000',
          type: 'square'
        },
        backgroundOptions: {
          color: '#fff',
        },
        cornersSquareOptions: {
          color: '#000',
          type: 'square',
        },
        cornersDotOptions: {
          color: '#000',
          type: 'square',
        }
      },
      qrCode: null
    }
  },
  watch: {
    'options.data': function() {
      this.qrCode.update(this.options);
    }
  },
  mounted() {
    this.options.data = this.data
    this.qrCode.append(this.$refs["qrCode"]);
  },
  created() {
    this.qrCode = new QRCodeStyling(this.options);
  },
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
