import service from '../plugins/request';

class AdminService {
    /**
     * @returns {Promise<AxiosResponse<T>>}
     */
    getUsersList() {
        return service.get( '/api/User/admin/all');
    }

    getClientUsersList() {
        return service.get( '/api/ClientAuth/admin/clients');
    }

    /**
     * @param id
     * @param password
     * @returns {Promise<AxiosResponse<T>>}
     */
    resetPasswordAdmin({id, password}) {
        return service.post('/api/User/resetPassword', { id, password });
    }

    /**
     * @returns {Promise<AxiosResponse<T>>}
     */
    getProgramAll() {
        return service.get('/api/PartnerProgram/all');
    }

    getChartTurnover(query) {
        return service.get('/api/Home/GrafTurnover' + query)
    }

    /**
     * @param start
     * @param stop
     * @returns {Promise<AxiosResponse<T>>}
     */
    getReportOrderBlock(query) {
        return service.get('/api/Order/admin/ReportOrderBlock' + query)
    }
}

export default new AdminService();
