<template>
  <tr
    v-for="(user, index) in userActivityList"
    :key="index"
    class="table-row table-content"
  >
    <td class="table-index">
      {{ index+1 }}
    </td>
    <td class="table-createDate">
      {{ dataFormatting(user.createDate) }}
    </td>
    <td
      class="table-userAgent"
      @mouseover="currentAgent = index"
      @mouseleave="currentAgent = null"
    >
      {{ numbersFormatting(user.userAgent) }}
      <transition name="fade">
        <hover-text
          v-if="currentAgent === index"
          :option="user.userAgent"
        />
      </transition>
    </td>
    <td class="table-ipAddress">
      {{ user.ipAddress }}
    </td>
  </tr>
</template>

<script>
import moment from 'moment-timezone';
import HoverText from "@/pages/coins/partials/hoverText";

export default {
  name: "UserActivityItem",
  components: {HoverText},
  props: {
    userActivityList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dateFormat: 'DD.MM.YY HH:mm',
      currentAgent: null
    }
  },
  methods: {
    dataFormatting(value) {
      return moment(value).utcOffset('+0600').format(this.dateFormat);
    },
    numbersFormatting(data) {
      return data.slice(0, 46) + '...';
    },
  }
}
</script>

<style scoped>

</style>
