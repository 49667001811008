<script>
export default {
  name: "PartnersVolumeIcon"
}
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4686_84683)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66667 2.5H18.3333C19.25 2.5 20 3.25 20 4.16667L19.9917 15.8333C19.9917 16.75 19.25 17.5 18.3333 17.5H1.66667C0.75 17.5 0 16.75 0 15.8333V4.16667C0 3.25 0.75 2.5 1.66667 2.5ZM9.16667 7.5C9.16667 6.11667 8.05 5 6.66667 5C5.28333 5 4.16667 6.11667 4.16667 7.5C4.16667 8.88333 5.28333 10 6.66667 10C8.05 10 9.16667 8.88333 9.16667 7.5ZM1.66667 14.1667V15H11.6667V14.1667C11.6667 12.5 8.33333 11.5833 6.66667 11.5833C5 11.5833 1.66667 12.5 1.66667 14.1667ZM15.5417 6.15833L13 4.25V11.75L15.5417 9.84167L18 8L15.5417 6.15833Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4686_84683">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
