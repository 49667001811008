<template>
  <div
    class="list-select"
    @blur="open = false"
  >
    <div
      class="list-select__value"
      :class="{ 'list-select__value-open': open }"
      @click="open = !open"
    >
      {{ valueModel }}
    </div>
    <simplebar
      v-if="list.length && open"
      class="list-select__list"
      data-simplebar-auto-hide="false"
    >
      <div
        v-for="(item, index) of list"
        :key="index"
        class="list-select__list-item"
        :class="{'list-select__list-item-binance': exchange.addressBinanceAddress === item.address}"
        @click="selectHandler(item)"
      >
        <div
          v-if="exchange.addressBinanceAddress === item.address"
          class="icon"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4126_78960)">
              <path
                d="M10.0026 17.5003C10.9231 17.5003 11.6693 16.7541 11.6693 15.8337C11.6693 14.9132 10.9231 14.167 10.0026 14.167C9.08213 14.167 8.33594 14.9132 8.33594 15.8337C8.33594 16.7541 9.08213 17.5003 10.0026 17.5003Z"
                fill="#0A68F7"
              />
              <path
                d="M8.33594 2.5H11.6693V12.5H8.33594V2.5Z"
                fill="#0A68F7"
              />
            </g>
            <defs>
              <clipPath id="clip0_4126_78960">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          v-if="item.coin"
          class="item"
        >
          <div class="item-row">
            <div class="item-title">
              Amount
            </div>
            <div class="item-value item-value_amount">
              {{ item.amount }} {{ item.coin }}
            </div>
          </div>

          <div class="item-row">
            <div class="item-title">
              Date
            </div>
            <div class="item-value">
              {{ dataFormatting(item.createDate) }}
            </div>
          </div>

          <div class="item-row">
            <div class="item-title">
              Hash
            </div>
            <div class="item-value">
              <div>
                {{ item.transactionId }}
              </div>
            </div>
          </div>
          <div class="item-row item-row--address">
            <div class="item-title">
              Address
            </div>
            <div class="item-value">
              <div>
                {{ item.address }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </simplebar>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';

export default {
  name: "SelectList",
  components: {
    simplebar
  },
  props: {
    list: {
      type: Array,
      default: null
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    valueInput: {
      type: String,
      required: false,
      default: '',
    },
    exchange: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      open: false,
      valueModel: 'Select Deposit',
      dateFormat: 'DD.MM.YY - HH:mm',
    };
  },
  mounted() {
    this.open = this.isOpen;
  },
  methods: {
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).utcOffset(2).format(this.dateFormat)
    },
    selectHandler(option) {
      this.valueModel = option.amount + ' ' + option.coin;
      this.emitter.emit('exchange-deposit', option)

      this.open = false;
    },
  },
}
</script>

<style lang="scss">
.list-select {
  position: relative;
  text-align: left;
  outline: none;
  height: auto;
  line-height: 36px;

  &__value {
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1B1A1C;
    padding: 11px 16px;
    cursor: pointer;
    border: 1px solid #DFE0EB;
    user-select: none;

    &-open {
      background: rgba(159, 162, 180, 0.06);
      border-radius: 8px;
    }

    &:after {
      position: absolute;
      content: "";
      top: 18px;
      right: 15px;
      width: 0;
      margin-left: 7px;
      height: 0;
      border: 5px solid transparent;
      border-color: #9FA2B4 transparent transparent transparent;
    }
  }

  &__list {
    color: #1B1A1C;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(15, 0, 58, 0.12);
    border-radius: 12px;
    z-index: 1;
    margin-top: 4px;
    width: 100%;
    line-height: 50px;
    max-height: 500px;
    padding: 8px 0;

    @media (max-width: 1079px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 100%;
      height: 100vh;
      z-index: 999;
      background: rgba(67, 40, 164, 0.54);
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    }

    &-item {
      color: #1B1A1C;
      cursor: pointer;
      user-select: none;
      background: none;
      transition: all .3s ease-in-out;
      padding: 12px 0;

      &:hover {
        background: rgba(27, 26, 28, 0.03);
      }

      .item {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }

        &-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #7C86A0;
          max-width: 72px;
          width: 100%;
        }

        &-value {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1B1A1C;
          text-align: start;
          inline-size: 324px;
          overflow-wrap: break-word;

          &_amount {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #1B1A1C;
          }
        }
      }

      @media (max-width: 1079px) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        box-shadow: 0 6px 24px rgba(27, 26, 28, 0.16);
        border-radius: 16px 16px 0 0;
        width: 100%;
        padding-bottom: 20px;
      }

      &-binance {
        background: rgba(10, 104, 247, 8%);
        position: relative;

        .icon {
          position: absolute;
          right: 12px;
          top: 12px;
          display: flex;
        }

        .item {
          &-row {
            &--address {
              .item-value {
                div {
                  background: rgba(10, 104, 247, 24%);
                }
              }
            }
          }
        }

        &:hover {
          background: rgba(10, 104, 247, 12%);
        }
      }
    }

    .active {
      color: #0A68F7;
      font-weight: 600;
    }

    div {
      text-align: left;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;

      .item {
        padding-left: 20px;
      }
    }
  }
}

</style>
