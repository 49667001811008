export const TABLE_TH = [
    {
        name: 'Name',
        sort: '',
        key: 'name',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Partner value',
        sort: '',
        key: 'partnerValue',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'ID',
        sort: '',
        key: 'id',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Active',
        sort: '',
        key: 'active',
        description: 'Trade round is the latest round of trade negotiations'
    },
];
