export const TABLE_1INCH_TH = [
    {
        name: 'ID',
        sort: '',
        key: 'id',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Name',
        sort: '',
        key: 'name',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Short name',
        sort: '',
        key: 'shotName',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Status',
        sort: '',
        key: 'status',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Networks',
        sort: false,
        key: 'networks',
        description: null,
        isSortable: false
    },

];

export const STATUSES = [
    {
        name: 'Name',
        cssClass: 'left'
    },
    {
        name: 'Is Active',
        cssClass: ''
    },
    {
        name: 'Is Default',
        cssClass: ''
    },
    {
        name: 'Is Deposit',
        cssClass: ''
    },
    {
        name: 'Is Memo',
        cssClass: ''
    },
    {
        name: 'Is Withdraw',
        cssClass: ''
    },
];
