<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_2983_11079)">
      <path
        d="M9.99996 1.66666C5.39996 1.66666 1.66663 5.39999 1.66663 9.99999C1.66663 14.6 5.39996 18.3333 9.99996 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99999C18.3333 5.39999 14.6 1.66666 9.99996 1.66666ZM6.12496 15.4167C7.21663 14.6333 8.54996 14.1667 9.99996 14.1667C11.45 14.1667 12.7833 14.6333 13.875 15.4167C12.7833 16.2 11.45 16.6667 9.99996 16.6667C8.54996 16.6667 7.21663 16.2 6.12496 15.4167ZM15.1166 14.2667C13.7083 13.1667 11.9333 12.5 9.99996 12.5C8.06663 12.5 6.29163 13.1667 4.88329 14.2667C3.91663 13.1083 3.33329 11.625 3.33329 9.99999C3.33329 6.31666 6.31663 3.33332 9.99996 3.33332C13.6833 3.33332 16.6666 6.31666 16.6666 9.99999C16.6666 11.625 16.0833 13.1083 15.1166 14.2667Z"
        fill="#0065FF"
      />
      <path
        d="M9.99992 5C8.39159 5 7.08325 6.30833 7.08325 7.91667C7.08325 9.525 8.39159 10.8333 9.99992 10.8333C11.6083 10.8333 12.9166 9.525 12.9166 7.91667C12.9166 6.30833 11.6083 5 9.99992 5ZM9.99992 9.16667C9.30825 9.16667 8.74992 8.60833 8.74992 7.91667C8.74992 7.225 9.30825 6.66667 9.99992 6.66667C10.6916 6.66667 11.2499 7.225 11.2499 7.91667C11.2499 8.60833 10.6916 9.16667 9.99992 9.16667Z"
        fill="#0065FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_2983_11079">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AdminIcon"
}
</script>

<style scoped>

</style>
