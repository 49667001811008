<template>
  <AccountLayout>
    <template #title>
      <h2>Sub accounts</h2>
    </template>
    <template #content>
      <div class="sub-accounts main-block">
        <loading
          v-if="!subAccountsData.length && isLoading"
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="#0A68F7"
        />
        <div class="table-block">
          <div class="table-block-header">
            <div class="table-block-header-title">
              Sub accounts
            </div>
            <div class="table-block-header-filters">
              <div
                v-if="!isMobile()"
                class="table-block-header-filters-add"
                @click="addSubAccountInfo"
              >
                <div class="table-block-header-filters-add-btn">
                  <add-icon class="add" />
                  Add
                </div>
              </div>
            </div>
          </div>
          <div class="table-block-table">
            <table class="table">
              <thead>
                <tr class="table-headers">
                  <th
                    v-for="(item, index) in tableTh"
                    :key="index"
                    scope="col"
                    :class="{
                      'table-id': item.key === 'id',
                      'table-status': item.key === 'active',
                      'table-lastUsed': item.key === 'lastUpdateDate',
                    }"
                  >
                    <div
                      class="text"
                      @click="sortData(item)"
                      @mouseover="currentIndex = index"
                      @mouseleave="currentIndex = null"
                    >
                      {{ item.name }}
                      <sort-passive v-if="!item.sort" />
                      <sort-a-z v-if="item.sort === 'asc'" />
                      <sort-z-a v-if="item.sort === 'desc'" />
                      <transition name="fade">
                        <hover-text
                          v-if="currentIndex === index && item.description !== null"
                          :option="item.description"
                        />
                      </transition>
                    </div>
                  </th>
                  <th
                    class="table-edit"
                    scope="col"
                  />
                </tr>
              </thead>
              <tbody>
                <SubAccountItem
                  v-if="subAccountsData || subAccountsData.length"
                  :sub-accounts="subAccountsData"
                />
              </tbody>
            </table>
            <no-data-block v-if="!subAccountsData || !subAccountsData.length && !isLoading" />
          </div>
        </div>
      </div>
      <SubAccountEdit
        v-if="SubAccountData"
        :data="SubAccountData"
      />
      <SubAccountAdd
        v-if="AddSubAccount"
        :data="SubAccountData"
      />
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import HoverText from "@/pages/coins/partials/hoverText";
import SortZA from "@/icons/sorting/sort-z-a";
import SortAZ from "@/icons/sorting/sort-a-z";
import SortPassive from "@/icons/sorting/sort-passive";
import filterMixin from "@/mixins/filter.mixin";
import helperMixin from "@/mixins/helper.mixin";
import {TABLE_TH} from "@/pages/sub-accounts/subAccountsData";
import SubAccountItem from "@/pages/sub-accounts/partials/SubAccountItem";
import SubAccountEdit from "@/pages/sub-accounts/partials/SubAccountEdit";
import {mapGetters} from "vuex";
import SubAccountsService from "@/services/subAccounts.service";
import AddIcon from "@/icons/addIcon";
import SubAccountAdd from "@/pages/sub-accounts/partials/SubAccountAdd";
import Loading from 'vue-loading-overlay';
import NoDataBlock from "@/components/admin/NoDataBlock.vue";

export default {
  name: "SubAccounts",
  components: {
    NoDataBlock,
    SubAccountAdd,
    AddIcon,
    SubAccountEdit,
    SubAccountItem,
    HoverText,
    SortZA,
    SortAZ,
    SortPassive,
    AccountLayout,
    Loading
  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      search: null,
      originSubAccounts: null,
      currentSorting: 'id',
      batch: [],
      subAccountsData: [],
      page: 1,
      options: {
        chunk: 4
      },
      currentPerPage: 20,
      currentIndex: null,
      tableTh: TABLE_TH,
      isLoading: false,
      fullPage: true,
    }
  },
  computed: {
    ...mapGetters({
      SubAccountData: 'getSubAccountData',
      AddSubAccount: 'getAddSubAccountData'
    }),
    SubAccounts: {
      get() {
        return this.$store.state.subAccountsList;
      },
      set(value) {
        this.$store.commit('setSubAccounts', value);
      }
    },
  },
  mounted() {
   this.getSubAccountsData();
    if (this.$route.params.id) {
      this.getSubAccountsDataById();
    }
  },
  methods: {
    getSubAccountsData() {
      this.isLoading = true;
      this.$store.dispatch('getSubAccountsList').then(() => {
        this.originSubAccounts = [...this.SubAccounts];
        const batch = this.batchData(this.SubAccounts, this.currentPerPage);
        this.subAccountsData = batch.current;
        this.batch = batch.total;
      });
    },
    getSubAccountsDataById() {
      SubAccountsService.getSubAccountById(this.$route.params.id).then((res) => {
        this.$store.commit('setSubAccountData', res.data.result);
      })
    },
    addSubAccountInfo(account) {
      this.$store.commit('setAddSubAccountData', account);
    },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      switch (type.key) {
        case 'name':
          this.SubAccounts = this.SubAccounts.sort((a, b) => {
            const aCount = a.name.localeCompare(b.name);
            const bCount = b.name.localeCompare(a.name);

            return type.sort === 'asc' ? bCount : aCount;
          });
          break;
        case 'id':
          this.SubAccounts = this.SubAccounts.sort((a, b) => {
            return type.sort === 'asc' ? a.id - b.id : b.id - a.id;
          });
          break;
        case 'isActive':
          this.SubAccounts = this.SubAccounts.sort((a, b) => {
            return type.sort === 'asc' ? b.isActive - a.isActive : a.isActive - b.isActive;
          });
          break;
        case 'isBusy':
          this.SubAccounts = this.SubAccounts.sort((a, b) => {
            return type.sort === 'asc' ? b.isBusy - a.isBusy : a.isBusy - b.isBusy;
          });
          break;

      }
      this.subAccountsData = this.batchData(this.SubAccounts, this.currentPerPage).current;
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/pages/sub-accounts/sub-accounts";
</style>
