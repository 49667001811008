<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_2983_11220)">
      <path
        opacity="0.24"
        d="M12 2L4 5V11.09C4 16.14 7.41 20.85 12 22C16.59 20.85 20 16.14 20 11.09V5L12 2Z"
        fill="#FF603D"
      />
      <path
        d="M15.5 14.09L14.09 15.5L12 13.42L9.91 15.5L8.5 14.09L10.59 12L8.5 9.91L9.91 8.5L12 10.59L14.09 8.5L15.5 9.91L13.42 12L15.5 14.09Z"
        fill="#FF603D"
      />
    </g>
    <defs>
      <clipPath id="clip0_2983_11220">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AmlIconRed"
}
</script>

<style scoped>

</style>
