<template>
  <AccountLayout>
    <template #title>
      <h2>Block Order Reports</h2>
    </template>
    <template #content>
      <div class="main-block block-order-reports">
        <loading
          v-if="!reportsDataList.length && isLoading"
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="#0A68F7"
        />
        <div class="table-block">
          <div class="table-block-header">
            <div class="table-block-header-title">
              Title
            </div>
          </div>
          <div class="filters">
            <div class="filters_block">
              <div class="dates_range">
                <date-picker
                  v-model:value="date"
                  :clearable="false"
                  :editable="false"
                  default-panel="day"
                  separator=" - "
                  value-type="format"
                  :format="dateFormatUrl"
                  placeholder="Reporting period"
                  :month-format="'MMMM'"
                  range
                  input-class="dates-item"
                  :class="{'dates_filled': date}"
                  @change="getReportsDataList"
                >
                  {{ dateText }}
                  <template #icon-calendar>
                    <calendar-icon />
                  </template>
                </date-picker>
              </div>
              <div class="separator" />
              <div
                class="filters--item"
                :class="{'filters--item-active': currentDate === '7d'}"
                @click="setDateHandler('7d')"
              >
                7D
              </div>
              <div
                class="filters--item"
                :class="{'filters--item-active': currentDate === '30d'}"
                @click="setDateHandler('30d')"
              >
                30D
              </div>
              <div class="separator" />
              <div
                class="filters--item"
                :class="{'filters--item-active': currentDate === 'week'}"
                @click="setDateHandler('week')"
              >
                Week
              </div>
              <div
                class="filters--item"
                :class="{'filters--item-active': currentDate === 'month'}"
                @click="setDateHandler('month')"
              >
                Month
              </div>
              <div class="separator" />
              <div
                class="filters--item"
                :class="{'filters--item-active': currentDate === 'q1'}"
                @click="setDateHandler('q1')"
              >
                Q1
              </div>
              <div
                class="filters--item"
                :class="{'filters--item-active': currentDate === 'q2'}"
                @click="setDateHandler('q2')"
              >
                Q2
              </div>
              <div
                class="filters--item"
                :class="{'filters--item-active': currentDate === 'q3'}"
                @click="setDateHandler('q3')"
              >
                Q3
              </div>
              <div
                class="filters--item"
                :class="{'filters--item-active': currentDate === 'q4'}"
                @click="setDateHandler('q4')"
              >
                Q4
              </div>
              <div class="separator" />
              <div
                class="filters--item"
                :class="{'filters--item-active': currentDate === 'year'}"
                @click="setDateHandler('year')"
              >
                Year
              </div>
            </div>
          </div>
          <div class="table-block-table">
            <table class="table">
              <thead>
                <tr class="table-headers">
                  <th
                    v-for="(item, index) in tableTh"
                    :key="index"
                    scope="col"
                    :class="{
                      'table-dataTime': item.key === 'dataTime',
                      'table-with-button': item.key !== 'dataTime'
                    }"
                  >
                    <div
                      class="text"
                      :class="{'orange-button-wrap': item.key !== 'dataTime' && item.key !== 'total'}"
                      @click="sortData(item)"
                      @mouseover="currentIndex = index"
                      @mouseleave="currentIndex = null"
                    >
                      <span :class="`${item.button}-button`">
                        {{ item.name }}
                      </span>
                      <sort-passive v-if="!item.sort" />
                      <sort-a-z v-if="item.sort === 'asc'" />
                      <sort-z-a v-if="item.sort === 'desc'" />
<!--                      <transition name="fade">
                        <hover-text
                          v-if="currentIndex === index && item.description !== null"
                          :option="item.description"
                        />
                      </transition>-->
                    </div>
                  </th>
                  <th
                    class="table-edit"
                    scope="col"
                  />
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(data, date) in reportsDataList"
                  :key="date"
                >
                  <tr class="table-row-day">
                    <td>
                      <strong>{{ dataFormatting(date) }}</strong>
                    </td>
                    <td>
                      <strong>
                        <div>{{ data.totals['11'] ? `${formatNumber(data.totals['11'].tornoverBtc)} BTC` : '—' }}</div>
                        <div>{{ data.totals['11'] ? `${data.totals['11'].cnt} trx` : '—' }}</div>
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <div>{{ data.totals['12'] ? `${formatNumber(data.totals['12'].tornoverBtc)} BTC` : '—' }}</div>
                        <div>{{ data.totals['12'] ? `${data.totals['12'].cnt} trx` : '—' }}</div>
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <div>{{ data.totals['13'] ? `${formatNumber(data.totals['13'].tornoverBtc)} BTC` : '—' }}</div>
                        <div>{{ data.totals['13'] ? `${data.totals['13'].cnt} trx` : '—' }}</div>
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <div>{{ data.totals['1'] ? `${formatNumber(data.totals['1'].tornoverBtc)} BTC` : '—' }}</div>
                        <div>{{ data.totals['1'] ? `${data.totals['1'].cnt} trx` : '—' }}</div>
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <div>{{ data.totals['5'] ? `${formatNumber(data.totals['5'].tornoverBtc)} BTC` : '—' }}</div>
                        <div>{{ data.totals['5'] ? `${data.totals['5'].cnt} trx` : '—' }}</div>
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <div>{{ `${formatNumber(Object.values(data.totals).reduce((sum, item) => sum + item.tornoverBtc, 0))} BTC` }}</div>
                        <div>{{ `${Object.values(data.totals).reduce((sum, item) => sum + item.cnt, 0)} trx` }}</div>
                      </strong>
                    </td>
                  </tr>
                  <template
                    v-for="(user,index) in data.users"
                    :key="index"
                  >
                    <tr
                      class="table-row-login"
                    >
                      <td
                        class="table-dataTime"
                        :class="{last : index === data.users.length - 1}"
                      >
                        {{ user.login || 'direct' }}
                      </td>
                      <td :class="{last : index === data.users.length - 1}">
                        <div class="td-container">
                          <div :class="{isEmpty : !user.statuses[11]}">
                            {{ user.statuses[11] ? `${formatNumber(user.statuses[11].tornoverBtc)} BTC` : '—' }}
                          </div>
                          <div :class="{isEmpty : !user.statuses[11]}">
                            {{ user.statuses[11] ? `${user.statuses[11].cnt} trx` : '—' }}
                          </div>
                        </div>
                      </td>
                      <td :class="{last : index === data.users.length - 1}">
                        <div class="td-container">
                          <div :class="{isEmpty : !user.statuses[12]}">
                            {{ user.statuses[12] ? `${formatNumber(user.statuses[12].tornoverBtc)} BTC` : '—' }}
                          </div>
                          <div :class="{isEmpty : !user.statuses[12]}">
                            {{ user.statuses[12] ? `${user.statuses[12].cnt} trx` : '—' }}
                          </div>
                        </div>
                      </td>
                      <td :class="{last : index === data.users.length - 1}">
                        <div class="td-container">
                          <div :class="{isEmpty : !user.statuses[13]}">
                            {{ user.statuses[13] ? `${formatNumber(user.statuses[13].tornoverBtc)} BTC` : '—' }}
                          </div>
                          <div :class="{isEmpty : !user.statuses[13]}">
                            {{ user.statuses[13] ? `${user.statuses[13].cnt} trx` : '—' }}
                          </div>
                        </div>
                      </td>
                      <td :class="{last : index === data.users.length - 1}">
                        <div class="td-container">
                          <div :class="{isEmpty : !user.statuses[1]}">
                            {{ user.statuses[1] ? `${formatNumber(user.statuses[1].tornoverBtc)} BTC` : '—' }}
                          </div>
                          <div :class="{isEmpty : !user.statuses[1]}">
                            {{ user.statuses[1] ? `${user.statuses[1].cnt} trx` : '—' }}
                          </div>
                        </div>
                      </td>
                      <td :class="{last : index === data.users.length - 1}">
                        <div class="td-container">
                          <div :class="{isEmpty : !user.statuses[5]}">
                            {{ user.statuses[5] ? `${formatNumber(user.statuses[5].tornoverBtc)} BTC` : '—' }}
                          </div>
                          <div :class="{isEmpty : !user.statuses[5]}">
                            {{ user.statuses[5] ? `${user.statuses[5].cnt} trx` : '—' }}
                          </div>
                        </div>
                      </td>
                      <td :class="{last : index === data.users.length - 1}">
                        <div class="td-container">
                          <div>{{ `${formatNumber(Object.values(user.statuses).reduce((sum, item) => sum + item.tornoverBtc, 0))} BTC` }}</div>
                          <div>{{ `${Object.values(user.statuses).reduce((sum, item) => sum + item.cnt, 0)} trx` }}</div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <tr
                  v-if="Object.keys(reportsDataList).length"
                  class="table-row-total"
                >
                  <td>Total</td>
                  <td>
                    <div class="td-container">
                      <div>{{ totalsByStatus[11] ? `${formatNumber(totalsByStatus[11]?.tornoverBtc)} BTC` : '—' }}</div>
                      <div>{{ totalsByStatus[11] ? `${totalsByStatus[11]?.cnt} trx` : '—' }}</div>
                    </div>
                  </td>
                  <td>
                    <div class="td-container">
                      <div>{{ totalsByStatus[12] ? `${formatNumber(totalsByStatus[12]?.tornoverBtc)} BTC` : '—' }}</div>
                      <div>{{ totalsByStatus[12] ? `${totalsByStatus[12]?.cnt} trx` : '—' }}</div>
                    </div>
                  </td>
                  <td>
                    <div class="td-container">
                      <div>{{ totalsByStatus[13] ? `${formatNumber(totalsByStatus[13]?.tornoverBtc)} BTC` : '—' }}</div>
                      <div>{{ totalsByStatus[13] ? `${totalsByStatus[13]?.cnt} trx` : '—' }}</div>
                    </div>
                  </td>
                  <td>
                    <div class="td-container">
                      <div>{{ totalsByStatus[1] ? `${formatNumber(totalsByStatus[1]?.tornoverBtc)} BTC` : '—' }}</div>
                      <div>{{ totalsByStatus[1] ? `${totalsByStatus[1]?.cnt} trx` : '—' }}</div>
                    </div>
                  </td>
                  <td>
                    <div class="td-container">
                      <div>{{ totalsByStatus[5] ? `${formatNumber(totalsByStatus[5]?.tornoverBtc)} BTC` : '—' }}</div>
                      <div>{{ totalsByStatus[5] ? `${totalsByStatus[5]?.cnt} trx` : '—' }}</div>
                    </div>
                  </td>
                  <td>
                    <div class="td-container">
                      <div>{{ overallTotals ? `${formatNumber(overallTotals?.tornoverBtc)} BTC` : '—' }}</div>
                      <div>{{ overallTotals ? `${overallTotals?.cnt} trx` : '—' }}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <no-data-block v-if="!Object.keys(reportsDataList).length && !isLoading" />
          </div>
        </div>
      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from '@/components/admin/AccountLayout.vue';
import {TABLE_TH} from "@/pages/block-order-reports/blockOrderReportData"
import SortZA from '@/icons/sorting/sort-z-a.vue';
import SortPassive from '@/icons/sorting/sort-passive.vue';
import HoverText from '@/pages/coins/partials/hoverText.vue';
import SortAZ from '@/icons/sorting/sort-a-z.vue';
import AdminService from '@/services/admin.service';
import Loading from 'vue-loading-overlay';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DatePicker from 'vue-datepicker-next';
import CalendarIcon from '@/icons/calendarIcon.vue';
import NoDataBlock from '@/components/admin/NoDataBlock.vue';
import filterMixin from '@/mixins/filter.mixin';
import helperMixin from '@/mixins/helper.mixin';

export default {
  name: "BlockOrderReports",
  components: {NoDataBlock, CalendarIcon, DatePicker, Loading, SortAZ, SortPassive, SortZA, AccountLayout},
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      tableTh: TABLE_TH,
      isLoading: false,
      fullPage: true,
      dateFormat: 'DD.MM.YYYY',
      dateFormatUrl: 'YYYY-MM-DD',
      date: '',
      currentDate: '',
      reportsDataList: [],
      currentIndex: null,
    }
  },
  computed: {
    totalsByStatus() {
      const totalsByStatus = {};

      if (!this.reportsDataList || Object.keys(this.reportsDataList).length === 0) {
        return totalsByStatus;
      }

      Object.values(this.reportsDataList).forEach(dateData => {
        if (dateData.users && Array.isArray(dateData.users)) {
          dateData.users.forEach(user => {
            Object.entries(user.statuses).forEach(([status, values]) => {
              if (!totalsByStatus[status]) {
                totalsByStatus[status] = { tornoverBtc: 0, cnt: 0 };
              }

              totalsByStatus[status].tornoverBtc += values.tornoverBtc;
              totalsByStatus[status].cnt += values.cnt;
            });
          });
        }
      });

      return totalsByStatus;
    },
    overallTotals() {
      return Object.values(this.totalsByStatus).reduce((acc, curr) => {
        acc.tornoverBtc += curr.tornoverBtc;
        acc.cnt += curr.cnt;
        return acc;
      }, { tornoverBtc: 0, cnt: 0 });
    },
    dateText() {
      if (this.date) {
        return this.date;
      } else {
        return "Custom date";
      }
    },
  },
  watch: {
    currentDate(val) {
      switch (val) {
        case 'day':
          this.date = [dayjs().format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case '7d':
          this.date = [dayjs().subtract(7, 'day').format(this.dateFormatUrl), dayjs().subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case '30d':
          this.date = [dayjs().subtract(30, 'day').format(this.dateFormatUrl), dayjs().subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'yesterday':
          this.date = [dayjs().subtract(1, 'day').format(this.dateFormatUrl), dayjs().subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'week':
          this.date = [dayjs().startOf('week').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'month':
          this.date = [dayjs().startOf('month').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'year':
          this.date = [dayjs().startOf('year').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'q1':
          this.date = [dayjs().startOf('year').format(this.dateFormatUrl), dayjs().startOf('year').add(3, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q2':
          this.date = [dayjs().startOf('year').add(3, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(6, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q3':
          this.date = [dayjs().startOf('year').add(6, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(9, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q4':
          this.date = [dayjs().startOf('year').add(9, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(11, 'month').endOf('month').format(this.dateFormatUrl)];
          break;
        default:
          break;
      }
      this.getReportsDataList();
    }
  },
  mounted() {
    this.getReportsDataList()
    this.currentDate = 'month'
  },
  methods: {
    async getReportsDataList() {
      this.isLoading = true
      if (this.date) {
        const res = await AdminService.getReportOrderBlock(`?start=${this.date[0]}&stop=${this.date[1]}`)
        if (res.status === 200) {
          this.reportsDataList = this.parseDataByDateReportsData(res?.data?.result)
          this.isLoading = false
        }
      }
    },
    parseDataByDateReportsData(data) {
      return data.reduce((acc, curr) => {
        const { createDate, login, status, tornoverBtc, cnt } = curr;
        const date = createDate.split('T')[0];

        if (!acc[date]) {
          acc[date] = { users: [], totals: {} };
        }

        let user = acc[date].users.find(u => u.login === login);

        if (!user) {
          user = { login, statuses: {} };
          acc[date].users.push(user);
        }

        if (!user.statuses[status]) {
          user.statuses[status] = { tornoverBtc: 0, cnt: 0 };
        }

        user.statuses[status].tornoverBtc += tornoverBtc;
        user.statuses[status].cnt += cnt;

        if (!acc[date].totals[status]) {
          acc[date].totals[status] = { tornoverBtc: 0, cnt: 0 };
        }

        acc[date].totals[status].tornoverBtc += tornoverBtc;
        acc[date].totals[status].cnt += cnt;

        return Object.fromEntries(
            Object.entries(acc).sort((a, b) => {
              return new Date(a[0]) - new Date(b[0])
            })
        );
      }, {});
    },
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format('DD.MM.YY ') //HH:mm:ss
    },
    setDateHandler(date) {
      this.currentDate = date
    },
    formatNumber(num) {
      return num % 1 === 0 ? num.toString() : num.toFixed(4);
  },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      switch (type.key) {
        case 'dataTime':
          this.reportsDataList = Object.fromEntries(
              Object.entries(this.reportsDataList).sort((a, b) => {
                return type.sort === 'asc' ? new Date(a[0]) - new Date(b[0]) : new Date(b[0]) - new Date(a[0])
              })
          );
          break;
        case 'return':
          this.reportsDataList = Object.fromEntries(
              Object.entries(this.reportsDataList).sort((a, b) => {
                const cntA = a[1].totals['11'] ? a[1].totals['11'].cnt : 0;
                const cntB = b[1].totals['11'] ? b[1].totals['11'].cnt : 0;
                return type.sort === 'asc' ? cntB - cntA : cntA - cntB
              })
          );
          break;
        case 'hold':
          this.reportsDataList = Object.fromEntries(
              Object.entries(this.reportsDataList).sort((a, b) => {
                const cntA = a[1].totals['12'] ? a[1].totals['12'].cnt : 0;
                const cntB = b[1].totals['12'] ? b[1].totals['12'].cnt : 0;
                return type.sort === 'asc' ? cntB - cntA : cntA - cntB
              })
          );
          break;
        case 'profit':
          this.reportsDataList = Object.fromEntries(
              Object.entries(this.reportsDataList).sort((a, b) => {
                const cntA = a[1].totals['13'] ? a[1].totals['13'].cnt : 0;
                const cntB = b[1].totals['13'] ? b[1].totals['13'].cnt : 0;
                return type.sort === 'asc' ? cntB - cntA : cntA - cntB
              })
          );
          break;
        case 'pay':
          this.reportsDataList = Object.fromEntries(
              Object.entries(this.reportsDataList).sort((a, b) => {
                const cntA = a[1].totals['1'] ? a[1].totals['1'].cnt : 0;
                const cntB = b[1].totals['1'] ? b[1].totals['1'].cnt : 0;
                return type.sort === 'asc' ? cntB - cntA : cntA - cntB
              })
          );
          break;
        case 'withdrawal':
          this.reportsDataList = Object.fromEntries(
              Object.entries(this.reportsDataList).sort((a, b) => {
                const cntA = a[1].totals['5'] ? a[1].totals['5'].cnt : 0;
                const cntB = b[1].totals['5'] ? b[1].totals['5'].cnt : 0;
                return type.sort === 'asc' ? cntB - cntA : cntA - cntB
              })
          );
          break;
        case 'total':
          this.reportsDataList = Object.fromEntries(
              Object.entries(this.reportsDataList).sort((a, b) => {
                const totalCntA = Object.values(a[1].totals).reduce((sum, status) => sum + status.cnt, 0);
                const totalCntB = Object.values(b[1].totals).reduce((sum, status) => sum + status.cnt, 0);
                return type.sort === 'asc' ? totalCntA - totalCntB : totalCntB - totalCntA
              })
          );
          break;
      }
      //this.settingsData = this.batchData(this.Settings, this.currentPerPage).current;
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/pages/block-order-reports";
</style>
