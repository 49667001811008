<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3644_3076)">
      <path
        d="M6.66927 0.833313H16.6693C17.5859 0.833313 18.3359 1.58331 18.3359 2.49998V14.1666H16.6693V2.49998H6.66927V0.833313ZM4.16927 4.16665H13.3359C14.2526 4.16665 15.0026 4.91665 15.0026 5.83331V17.5C15.0026 18.4166 14.2526 19.1666 13.3359 19.1666H4.16927C3.2526 19.1666 2.5026 18.4166 2.5026 17.5V5.83331C2.5026 4.91665 3.2526 4.16665 4.16927 4.16665ZM4.16927 17.5H13.3359V5.83331H4.16927V17.5Z"
        fill="#9FA2B4"
        fill-opacity="0.7"
      />
    </g>
    <defs>
      <clipPath id="clip0_3644_3076">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="matrix(-1 0 0 1 20 0)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CopyIconGray"
}
</script>

<style scoped>

</style>
