<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33594 4.54669V11.4534C5.33594 11.98 5.91594 12.3 6.3626 12.0134L11.7893 8.56003C12.2026 8.30003 12.2026 7.70003 11.7893 7.43336L6.3626 3.98669C5.91594 3.70003 5.33594 4.02003 5.33594 4.54669Z"
      fill="#0A68F7"
    />
  </svg>
</template>

<script>
export default {
    name: "PlayIcon",
}
</script>

<style scoped lang="scss">

</style>
