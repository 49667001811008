<template>
  <div
    v-if="settingsData.id"
    ref="modalOverlay"
    class="settingModal"
    tabindex="0"
    @click="modalOverlayClose"
  >
    <div class="settingModal-form">
      <div class="settingModal-form-head">
        <div class="settingModal-form-title">
          Edit settings
        </div>
        <div class="settingModal-form-close">
          <close-icon @click="closeModal" />
        </div>
      </div>
      <div class="settingModal-form-inputs">
        <div class="settingModal-form-inputs-item">
          <div class="desc">
            Name
          </div>
          <input
            v-if="data.userKey !== null"
            class="disabled"
            type="text"
            disabled="disabled"
            :value="settingsData.name"
            :placeholder="data.name"
          >
          <input
            v-if="data.name === null"
            type="text"
            placeholder="Empty"
          >
        </div>
        <div
          v-if="data.typeName === 'input' && data.name !== 'MirrorReportBalance'"
          class="settingModal-form-inputs-item"
        >
          <div class="desc">
            User
          </div>
          <input
            v-if="data.userValue !== null"
            v-model="settingsData.userValue"
            type="text"
            :placeholder="data.userValue"
          >
          <input
            v-if="data.userValue === null"
            type="text"
            placeholder="Empty"
          >
        </div>
        <div
          v-if="data.name === 'MirrorReportBalance'"
          class="settingModal-form-inputs-item"
        >
          <div class="desc">
            User
          </div>
          <textarea
            ref="textarea"
            v-model="settingsData.userValue"
            @input="autoResize"
          />
        </div>
        <div
          class="toggles"
          :class="{'toggles_wide': data.typeName !== 'input'}"
        >
          <div
            v-if="data.typeName !== 'input'"
            class="toggles-item"
          >
            <div class="text">
              User value
            </div>
            <Toggle
              v-model="settingsData.userValue"
              true-value="1"
              false-value="0"
            />
          </div>
          <div class="toggles-item">
            <div class="text">
              Is Active
            </div>
            <Toggle v-model="settingsData.isActive" />
          </div>
        </div>
      </div>
      <div class="settingModal-form-buttons">
        <div
          class="settingModal-form-buttons-item cancel"
          @click="closeModal"
        >
          Cancel
        </div>
        <div
          class="settingModal-form-buttons-item submit"
          @click="updateSettings"
        >
          Save
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "../../../icons/closeIcon";
import Toggle from '@vueform/toggle'
import SettingService from '../../../services/settings.service'
import {nextTick} from 'vue';

export default {
  name: "SettingsEdit",
  components: {Toggle, CloseIcon},
  props: {
    data: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      settingsData: {},
      currentTypeName: 0,
    }
  },
  mounted() {
    this.settingsData = this.data;
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.$store.commit('setSettingsData', null);
        this.$router.push({name: 'Settings'})
      }
    });
    this.autoResize()
  },
  beforeUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.$store.commit('setSettingsData', null);
        this.$router.push({name: 'Settings'})
      }
    })
  },
  methods: {
    closeModal() {
      this.$store.commit('setSettingsData', null);
      this.$router.push({name: 'Settings'})
    },
    updateSettings() {
      SettingService.updateSettings(this.settingsData).then(() => {
        this.successPopup();
        this.closeModal();
      }).catch(() => {
        this.errorPopup();
      });
    },
    successPopup() {
      this.$store.commit('setSuccess', {
        name: 'Setting edit success',
        value: this.settingsData,
      });
    },
    errorPopup() {
      this.$store.commit('setError', {
        name: 'Setting edit error',
        value: this.settingsData,
      });
    },
    modalOverlayClose(event) {
      if (event.target === this.$refs.modalOverlay) {
        this.closeModal();
      }
    },
    autoResize() {
      nextTick(() => {
        const textarea = this.$refs.textarea;
        if (textarea) {
          textarea.style.height = 'auto';
          textarea.style.height = textarea.scrollHeight + 'px';
        }
      });
    },
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/pages/settings/settings-edit";
</style>
