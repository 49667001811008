<script>
import {mapGetters} from "vuex";
import {MAIN_DOMAIN} from "@/store/config";

export default {
  name: 'CoinsSvg',
  props: {
    coin: {
      type: String,
      default: ''
    },
    coinsList: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      domain: MAIN_DOMAIN
    }
  },
  methods: {
    getCoin(token) {
      if (this.coinsList) {
        const coin = this.coinsList.find(item => item.shortName === token);
        return coin ? coin.image : '';
      }
    }
  }
}

</script>

<template>
  <div
    v-if="getCoin(coin)"
    class="icon"
  >
    <img
      :src="getCoin(coin)"
      alt=""
    >
  </div>
</template>

<style lang="scss">
.icon {
  display: flex;
  width: 20px;
  height: 20px;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
