<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="20"
      height="20"
      rx="10"
      fill="#1A242B"
    />
    <path
      d="M6.66927 15.3332L3.33594 11.9998L6.66927 8.6665L7.6026 9.6165L5.88594 11.3332H10.6693V12.6665H5.88594L7.6026 14.3832L6.66927 15.3332ZM13.3359 11.3332L12.4026 10.3832L14.1193 8.6665H9.33594V7.33317H14.1193L12.4026 5.6165L13.3359 4.6665L16.6693 7.99984L13.3359 11.3332Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ReplaceIcon"
}
</script>

<style scoped>

</style>
