<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    $route() {
      this.$route.name
    }
  },
  mounted() {
    this.$route.name;
    this.checkAuth();
  },
  methods: {
    checkAuth() {
      const token = localStorage.token;
      const checkRouteNames = ['Login', 'Registration'];
      const routeName = this.$route.name;

      if (!checkRouteNames.includes(routeName) && !token) {
        this.$router.push({ name: 'Login' });
      }

      if (token && checkRouteNames.includes(routeName)) {
        this.$router.push({ name: 'Main dashboard' });
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

*, html, body {
  font-family: 'Inter', sans-serif !important;
}

body {
  background: #F7F8FC;
}


body.modal-open {
  overflow: hidden;
}

a {
  text-decoration: none !important;
}

.mx {
  &-datepicker {

    &-popup, &-main {
      box-shadow: 0 4px 24px rgba(55, 49, 72, 0.12);
      border-radius: 12px;
      overflow: hidden;
      border: none !important;
    }
  }

  &-calendar {
    width: 296px !important;
    padding: 16px !important;
    height: 100% !important;

    &-content {
      height: 100% !important;
    }

    &-header {
      &-label {
        button {
          font-family: 'Inter', sans-serif;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #1B1A1C;
        }
      }
    }
  }

  &-date-row {
    border-radius: 8px !important;
    overflow: hidden !important;
  }

  &-table {
    border-collapse:separate;
    border-spacing: 0 2px !important;

    thead {
      tr {
        th {
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          color: #9FA2B4;
        }
      }
    }

    tbody {
      tr {
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 2px;

        td {
          font-family: 'Inter', sans-serif;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          height: 36px;

          &:first-child {
            border-radius: 8px 0 0 8px;
          }

          &:last-child {
            border-radius:  0 8px 8px 0;
          }
        }

        .cell {
          color: #1B1A1C;
          transition: all .3s ease-in-out;

          &:hover {
            background: rgba(10, 104, 247, 0.06);
            color: #0A68F7;
            border-radius: 8px;
          }
        }

        .active {
          background: #0A68F7 !important;
          border-radius: 8px !important;

          &:hover {
            color: white;
          }
        }

        .in-range {
          background: rgba(10, 104, 247, 0.06) !important;

          &:hover {
            border-radius: 0;
          }
        }

        .not-current-month {

        }
      }
    }
  }

  &-date-row {
    .in-range {
      div {
        color: black;
      }
    }
  }

  &-icon-double-left, &-icon-left{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;

    &:hover {
      background: rgba(10, 104, 247, 0.06);
      border-radius: 8px;
    }

    &::before, &::after {
      border-color: #0A68F7!important;
      transform: rotate(-45deg) scale(0.7) translateX(1px) translateY(2px);
    }


  }

  &-icon-right, &-icon-double-right {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;

    &:hover {
      background: rgba(10, 104, 247, 0.06);
      border-radius: 8px;
    }

    &::before, &::after {
      border-color: #0A68F7!important;
      transform: rotate(135deg) scale(0.7) translateX(1px) translateY(2px);
    }
  }

  &-input-wrapper {
    font-family: 'Inter', sans-serif !important;
  }
}
</style>
