<template>
  <div
    v-if="coinData"
    class=""
  >
    <div
      class="coins-modal"
      tabindex="0"
      @keydown.esc="closeModal"
      @click="modalOverlayClose"
    >
      <div
        ref="modalOverlay"
        class="coins-modal-block"
      >
        <div class="coins-modal-block-form">
          <div class="coins-modal-block-form-title">
            Edit coin
          </div>

          <div
            class="close"
            @click="closeModal"
          >
            <img
              src="../../../../assets/img/close.svg"
              alt=""
            >
          </div>
          <div class="coins-modal-block-form-info_block">
            <div class="coins-modal-block-form-info-image">
              <label for="image">
                <img
                  :src="domain + data.image"
                  alt=""
                >
              </label>
              <input
                id="image"
                ref="uploadFile"
                type="file"
                @change="uploadFile"
              >
              <div
                class="coin-change"
                @click="$refs.uploadFile.click()"
              >
                Upload image
              </div>
            </div>
            <div class="toggle-item">
              <div class="text">
                Status:
              </div>
              <Toggle v-model="coinData.isActive" />
            </div>
          </div>


          <div class="coins-modal-block-form-info-coin-edit">
            <div class="inputs">
              <div class="inputs-item large">
                <div class="inputs-item-title ">
                  Name
                </div>
                <input
                  v-model="coinData.name"
                  type="text"
                >
              </div>
              <div class="inputs-item">
                <div class="inputs-item-title">
                  Shortname / code
                </div>
                <input
                  v-model="coinData.shotName"
                  type="text"
                >
              </div>
              <div class="inputs-item">
                <div class="inputs-item-title">
                  Rating
                </div>
                <input
                  v-model="coinData.rating"
                  type="text"
                >
              </div>
              <div class="inputs-item large">
                <div class="inputs-item-title">
                  Description
                </div>
                <input
                  v-model="coinData.description"
                  type="url"
                >
              </div>
            </div>
            <div
              v-if="coinData.networks"
              class="chains"
            >
              <div class="chains-toggle-block">
                <div
                  class="chains-toggle"
                  @click="isChains = !isChains"
                >
                  `
                  <img
                    :class="{'toggled': isChains === true}"
                    src="../../../../assets/img/icons/chains-toggle.svg"
                    alt=""
                  >
                  <div class="text">
                    Chains ({{ coinData.networks.length }})
                  </div>
                </div>
                <transition>
                  <div
                    v-if="coinData.networks.length !== chainsList.length"
                    class="add-chains"
                  >
                    <div
                      class="add-chains_button"
                      @click="chainsSelect = !chainsSelect"
                    >
                      <img
                        src="../../../../assets/img/icons/plusIcon.svg"
                        alt="plusIcon"
                      >
                      Add new
                    </div>
                    <div
                      v-if="chainsSelect"
                      class="add-chains_select"
                    >
                      <div
                        v-for="(item, index) in chainsList"
                        :key="index"
                        @click="addChain(item)"
                      >
                        <div
                          v-if="item.isActive"
                          class="add-chains_select--item"
                        >
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>

              <div
                v-if="coinData.networks.length && isChains"
                class="chains-block"
              >
                <div
                  v-for="(item, index) in coinData.networks"
                  :key="index"
                  class="chains-block_item"
                >
                  <div class="chains-block_item-title">
                    <div class="chains-block_item-title_text">
                      {{ renderedText(item.shotName) || renderedText(item.network) }}
                    </div>
                    <div class="chains-block_item-title_close">
                      <close-icon @click="removeChain(item)" />
                    </div>
                  </div>
                  <div class="inputs">
                    <div class="inputs-item large">
                      <div class="inputs-item-title">
                        Smart contract
                      </div>
                      <input
                        v-model="item.smartContract"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="coins-modal-block-form-info-coin-submit">
            <button
              class="cancel"
              @click="closeModal"
            >
              Cancel
            </button>
            <button
              class="save"
              @click="addCoinInfo"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MAIN_DOMAIN} from "@/store/config";
import Toggle from '@vueform/toggle'
import FileService from '../../../../services/file.service'
import {STATUSES} from "../../coinData";
import CloseIcon from "@/icons/closeIcon";
import CoinsService from "@/services/coins.service";

export default {
  name: "EditCoinModal",
  components: { CloseIcon, Toggle},
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      domain: MAIN_DOMAIN,
      isChains: false,
      tableTh: STATUSES,
      file: '',
      chainsSelect: false,
      chainsList: [
        {
          name: 'Binance Smart Chain (BEP20)',
          shotName: 'BSC',
          isActive: true
        },
        {
          name: 'Matic Mainnet (Polygon)',
          shotName: 'MATIC',
          isActive: true
        },
        {
          name: 'Ethereum (ERC20)',
          shotName: 'ETH',
          isActive: true
        },
        {
          name: 'Fantom Network',
          shotName: 'FTM',
          isActive: true
        },
        {
          name: 'Avalanche Network',
          shotName: 'AVAX',
          isActive: true
        },
        {
          name: 'Arbitrum One',
          network: 'ARBITRUM',
          isActive: true
        }
      ],
      coinData: {
        id: '',
        name: '',
        shotName: '',
        description: '',
        image: '',
        networks: [],
        isActive: '',
        rating: '',
      },
    }
  },
  mounted() {
    this.coinData = {
      id: this.data.id,
      name: this.data.name,
      shotName: this.data.shotName,
      description: this.data.description,
      image: this.data.image,
      networks: [],
      isActive: this.data.isActive,
      rating: this.data.rating,
    }
    this.networkListMount();
    this.checkChainsList();
  },
  methods: {
    networkListMount() {
      if (this.data.network) {
        let coinNetworks = this.data.network;
        coinNetworks.forEach((item) => {
          this.coinData.networks.push({ network: item.shotName, smartContract: item.smartContract, isActive: item.isActive })
        })
      }
    },
    checkChainsList() {
      const network = this.coinData.networks
      network.forEach((el) => {
        this.chainsList.find(item => item.shotName === el.network).isActive = false
      })

      if (network.length) {
        this.isChains = true
      }
    },
    addChain(item) {
      this.coinData.networks.push({ network: item.shotName, smartContract: '', isActive: true})
      this.chainsList.find(x => x.shotName === item.shotName).isActive = false
      this.chainsSelect = !this.chainsSelect
      this.isChains = true
    },
    removeChain(item) {
      this.coinData.networks.splice(this.coinData.networks.indexOf(item), 1)
      this.chainsList.find(x => x.shotName === item.network).isActive = true
      if (this.coinData.networks.length < 1) {
        this.isChains = false
      }
    },
    closeModal() {
      this.emitter.emit('coin-info-event', null)
      this.$router.push({name: 'Coin1inch'});
    },
    uploadFile(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }
      this.addImage(files[0]);
    },
    addImage(file) {
      const reader = new FileReader();

      reader.onload = () => {
        this.file = file;
        this.uploadImage();
      };
      reader.readAsBinaryString(file);

    },
    uploadImage() {
      FileService.uploadFile(this.file).then((result) => {
        this.coinData.image = result.data.result;
      }).catch((error) => {
        console.error(error);
      })
    },
    successPopup() {
      this.$store.commit('setSuccess', {
        name: 'Edit coin success',
        value: this.coinData,
      });
    },
    errorPopup() {
      this.$store.commit('setError', {
        name: 'Edit coin error',
        value: this.coinData,
      });
    },
    renderedText(item) {
      switch (item) {
        case 'BSC':
          return 'Binance Smart Chain (BEP20)'
        case 'MATIC':
          return 'Matic Mainnet (Polygon)'
        case 'ETH':
          return 'Ethereum (ERC20)'
        case 'FTM':
          return 'Fantom Network'
        case 'AVAX':
          return 'Avalanche Network'
        case 'ARBITRUM':
          return 'Arbitrum One'

      }
    },
    addCoinInfo() {
      CoinsService.saveCoin1inch(this.coinData).then((res) => {
        this.closeModal();
        this.successPopup();
      }).catch(() => {
        this.errorPopup();
      })
    },
    modalOverlayClose(event) {
      if (event.target === this.$refs.modalOverlay) {
        this.closeModal()
      }
    },
  },
}
</script>


<style lang="scss">
@import "~@vueform/toggle/themes/default.css";
@import "../../../../assets/styles/pages/coins/add-modal";


</style>
