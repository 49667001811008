<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3007 0.709971C12.9107 0.319971 12.2807 0.319971 11.8907 0.709971L7.0007 5.58997L2.1107 0.699971C1.7207 0.309971 1.0907 0.309971 0.700703 0.699971C0.310703 1.08997 0.310703 1.71997 0.700703 2.10997L5.5907 6.99997L0.700703 11.89C0.310703 12.28 0.310703 12.91 0.700703 13.3C1.0907 13.69 1.7207 13.69 2.1107 13.3L7.0007 8.40997L11.8907 13.3C12.2807 13.69 12.9107 13.69 13.3007 13.3C13.6907 12.91 13.6907 12.28 13.3007 11.89L8.4107 6.99997L13.3007 2.10997C13.6807 1.72997 13.6807 1.08997 13.3007 0.709971Z"
      fill="#9FA2B4"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseMob"
}
</script>

<style scoped>

</style>