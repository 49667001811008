export const TABLE_TH = [
    {
        name: 'Run bot',
        sort: 'DESC',
        id: 'run-bot',
        key: 'run-bot',
        description: '',
        isSortable: false,
    },
    {
        name: 'Date / Time',
        sort: '',
        key: 'date',
        description: '',
        isSortable: true,
    },
    {
        name: 'ID / Uid',
        sort: '',
        key: 'id',
        description: '',
        isSortable: true,
    },
    {
        name: 'Partner / Mkt',
        sort: '',
        key: 'partner',
        description: '',
        isSortable: true,
    },
    {
        name: 'Pair / mode',
        sort: '',
        key: 'pair',
        description: '',
        isSortable: false,
    },
    {
        name: 'Amount / result',
        sort: '',
        key: 'amount',
        description: '',
        isSortable: false,
    },
    {
        name: 'AML',
        sort: '',
        key: 'aml',
        description: '',
        isSortable: false,
    },
    {
        name: 'Status',
        sort: '',
        key: 'status',
        description: '',
        isSortable: true,
    },
];

export const STATUSES = [
    {
        name: 'Remaining',
        status: 0,
        cssClass: 'status-empty',
    },
    {
        name: 'Pay',
        status: 1,
        cssClass: 'status-pay',
    },
    {
        name: 'Exchange',
        status: 2,
        cssClass: 'status-exchange',
    },
    {
        name: 'Withdrawal',
        status: 5,
        cssClass: 'status-withdrawal',
    },
    {
        name: 'Completed',
        status: 6,
        cssClass: 'status-completed',
    },
    {
        name: 'Overdue',
        status: 10,
        cssClass: 'status-overdue',
    },
    {
        name: 'Returned',
        status: 11,
        cssClass: 'status-returned',
    },
    {
        name: 'Hold',
        status: 12,
        cssClass: 'status-hold',
    },
    {
        name: 'Profit',
        status: 13,
        cssClass: 'status-profit',
    },
];

export const ALLOW_STATUS = [1, 2, 3, 4, 5];
