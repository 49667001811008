<template>
  <div
    class="status-exchange"
  >
    <div
      class="status-exchange_name"
      :class="className"
    >
      <div class="circle" />
      <div>
        {{ valueModel }}
      </div>
    </div>
  </div>
</template>

<script>
import filterMixin from "../../../mixins/filter.mixin";
import helperMixin from "../../../mixins/helper.mixin";
import MarkIcon from "../../../icons/markIcon";

export default {
  name: "StatusExchange",
  mixins: [
    filterMixin,
    helperMixin
  ],
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
    valueInput: {
      type: Number,
      required: false,
      default: 0,
    },
    disableClick: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      valueModel: '',
      className: '',
    };
  },
  watch: {
    valueInput(value) {
      const status = this.getStatus(value);
      this.valueModel = status.name;
      this.className = status.className;
    },
  },
  mounted() {
    const status = this.getStatus(this.valueInput);
    this.valueModel = status.name;
    this.className = status.className;
  },
  methods: {
    getStatus(status) {
      return this.getExchangeStatus(status);
    },
  },
};
</script>

<style lang="scss">
.status-exchange {
  .remaining {
    color: #c00fb2;

    .circle {
      background: #c00fb2;
    }
  }

  .completed {
    color: #02C076;

    .circle {
      background: #02C076;
    }
  }

  .pay {
    color: #44A5FF;

    .circle {
      background: #44A5FF;
    }
  }

  .exchange {
    color: #673DFD;

    .circle {
      background: #673DFD;
    }
  }

  .withdrawal {
    .circle {
      background: #8500ED;
    }
  }

  .overdue {
    color: #FF783E;

    .circle {
      background: #FF783E;
    }
  }

  .returned, .hold, .profit {
    color: red;

    .circle {
      background: red;
    }
  }

  &_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;

    .circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }
}




</style>
