<template>
  <div
    class="user-balances main-block"
  >
    <div class="table-block">
      <div class="table-block-header">
        <div class="table-block-header-title">
          {{ titleText }}
        </div>
      </div>
      <div class="table-block-table">
        <table
          class="table"
          :class="{'balance_main': titleText === 'Main coin balance', 'balance_subAccount' : titleText === 'Sub account balance'}"
        >
          <thead>
            <tr class="table-headers">
              <th
                v-for="(item, index) in tableTh"
                :key="index"
                scope="col"
                :class="{
                  'table-first': item.key === 'email' || item.key === 'coin',
                  'table-balance': item.key === 'balance',
                }"
              >
                <div
                  class="text"
                >
                  {{ item.name }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data"
              :key="index"
              class="table-row"
            >
              <td class="table-cell table-first">
                {{ item.email }}
              </td>
              <td class="table-cell table-balance">
                {{ item.balance }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableBalance",
  props: {
    tableTh: {
      type: Array,
      default: null,
    },
    data: {
      type: Array,
      default: null,
    },
    titleText: {
      type: String,
      default: ''
    }
  },
}
</script>

<style scoped>

</style>
