<script>
export default {
  name: "AdminOrders",
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentStatus: null
    }
  },
  mounted() {
    this.currentStatus = this.getExchangeStatus(this.order);
  },
  methods: {
    routeToExchanges(status) {
      this.$router.push({name: 'Exchanges retro', query: {page: 1, status: status}});
    },
    getExchangeStatus(status) {
      let name = 'Exchange';
      let statusVal = 'E';

      switch (status.status) {
        case 0:
          name = 'Remaining';
          statusVal = 'R';
          break;
        case 1:
          name = 'Pay';
          statusVal = 'P';
          break;
        case 2:
        case 3:
        case 4:
          name = 'Exchange';
          statusVal = 'E';
          break;
        case 5:
          name = 'Withdrawal';
          statusVal = 'W';
          break;
        case 6:
          name = 'Completed';
          statusVal = 'C';
          break;
        case 10:
          name = 'Overdue';
          statusVal = 'O';
          break;
        case 11:
          name = 'Returned';
          statusVal = 'R';
          break;
        case 12:
          name = 'Hold';
          statusVal = 'H';
          break;
        case 13:
          name = 'Profit';
          statusVal = 'P';
          break;
      }

      return {
        name: name,
        statusVal: statusVal,
        className: name.toLowerCase(),
        amount: status.ordersVal
      };
    }
  }
}
</script>

<template>
  <div
    v-if="currentStatus"
    class="admin-orders_block"
    :class="{
      'admin-orders--empty': currentStatus.name === 'Remaining',
      'admin-orders--pay': currentStatus.name === 'Pay',
      'admin-orders--exchange': currentStatus.name === 'Exchange',
      'admin-orders--withdrawal': currentStatus.name === 'Withdrawal',
      'admin-orders--completed': currentStatus.name === 'Completed',
      'admin-orders--overdue': currentStatus.name === 'Overdue',
      'admin-orders--returned': currentStatus.name === 'Returned',
      'admin-orders--hold': currentStatus.name === 'Hold',
      'admin-orders--profit': currentStatus.name === 'Profit',
    }"
    @click="routeToExchanges(order.status)"
  >
    <div
      class="admin-orders_status"
    >
      {{ currentStatus.statusVal }}
    </div>
    <div class="admin-orders_amount">
      <div class="bg" />
      <div class="admin-orders_amount--text">
        {{ currentStatus.amount }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.admin-orders {
  &_block {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    overflow: hidden;
    height: 16px;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &_status {
    width: 16px;
    color: #FFF;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: grid;
    place-items: center;
  }

  &_amount {
    display: grid;
    place-items: center;
    padding: 0 3px;
    box-sizing: border-box;
    position: relative;

    &--text {
      position: relative;
      z-index: 1;
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      min-width: 10px;
    }

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.24;
      z-index: 0;
    }
  }


  &--completed {
    .admin-orders_status, .admin-orders_amount .bg {
      background: #02C076;
    }

    .admin-orders_amount--text {
      color: #02C076;
    }
  }

  &--remaining {
    .admin-orders_status, .admin-orders_amount .bg {
      background: #8B81FF;
    }

    .admin-orders_amount--text {
      color: #8B81FF;
    }
  }

  &--withdrawal {
    .admin-orders_status, .admin-orders_amount .bg {
      background: #8500ED;
    }

    .admin-orders_amount--text {
      color: #8500ED;
    }
  }

  &--canceled {
    .admin-orders_status, .admin-orders_amount .bg {
      background: #FF783E;
    }

    .admin-orders_amount--text {
      color: #FF783E;
    }
  }

  &--empty {
    .admin-orders_status, .admin-orders_amount .bg {
      background: #c00fb2;
    }

    .admin-orders_amount--text {
      color: #c00fb2;
    }
  }

  &--completed {
    .admin-orders_status, .admin-orders_amount .bg {
      background: #02C076;
    }

    .admin-orders_amount--text {
      color: #02C076;
    }
  }

  &--pay {
    .admin-orders_status, .admin-orders_amount .bg {
      background: #44A5FF;
    }

    .admin-orders_amount--text {
      color: #44A5FF;
    }
  }

  &--exchange {
    .admin-orders_status, .admin-orders_amount .bg {
      background: #673DFD;
    }

    .admin-orders_amount--text {
      color: #673DFD;
    }
  }

  &--overdue {
    .admin-orders_status, .admin-orders_amount .bg {
      background: #FF783E;
    }

    .admin-orders_amount--text {
      color: #FF783E;
    }
  }

  &--returned, &--hold, &--profit {
    .admin-orders_status, .admin-orders_amount .bg {
      background: red;
    }

    .admin-orders_amount--text {
      color: red;
    }
  }
}
</style>
