<template>
  <div class="trade-table">
    <div class="trade-table_head">
      <div class="title">
        {{ title }}
      </div>
      <div
        v-if="isButtons && userData && userData.modeAccType !== 'binance'"
        class="switchers"
      >
        <div
          v-for="(button, index) in switchers"
          :key="index"
          class="switchers--item"
          :class="{'switchers--item_active': button.name === selectedSwitch.name}"
          @click="switchButton(button)"
        >
          {{ button.name }}
        </div>
      </div>
      <div
        v-else
        class="switchers"
      >
        <div
          v-for="(button, index) in switchers"
          :key="index"
          class="switchers--item"
          :class="{'switchers--item_active': button.name === selectedSwitch.name}"
        >
          {{ button.name }}
        </div>
      </div>
    </div>
    <div class="trade-table_overflow">
      <table class="trade-table_table">
        <tr>
          <th
            v-for="(tableHeader, index) in tableTh"
            :key="index"
            class="table-header"
            :class="'table-' + tableHeader.key"
          >
            {{ tableHeader.name }}
            <sort-passive
              v-if="tableHeader.sort === ''"
              class="trade-table_sorting_passive"
            />
            <sort-a-z
              v-if="tableHeader.sort === 'ASC'"
              class="trade-table_sorting_a-z"
            />
            <sort-z-a
              v-if="tableHeader.sort === 'DESC'"
              class="trade-table_sorting_z-a"
            />
          </th>
        </tr>
        <slot name="table" />
      </table>
      <slot name="loader" />
      <slot name="noData" />
    </div>
    <slot name="pagination" />
  </div>
</template>

<script>
import SortZA from "@/icons/sorting/sort-z-a.vue";
import SortAZ from "@/icons/sorting/sort-a-z.vue";
import SortPassive from "@/icons/sorting/sort-passive.vue";

export default {
  name: "TradeTableLayout",
  components: {SortPassive, SortAZ, SortZA},
  props: {
    title: {
      type: String,
      default: ''
    },
    isButtons: {
      type: Boolean,
      default: false
    },
    switchers: {
      type: Array,
      default: null
    },
    currentSwitch: {
      type: Object,
      default: null
    },
    tableTh: {
      type: Array,
      default: null
    },
    userData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedSwitch: this.currentSwitch,
      currentPage: null
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        this.selectedSwitch = {
          name: 'Spot',
          key: 'trade'
        }
      }
    }
  },
  methods: {
    switchButton(el) {
      this.selectedSwitch = el
      this.emitter.emit('switch-button', el)
    },
    pageHandler(page) {
      this.currentPage = page
    }
  }
}
</script>

<style lang="scss">
.trade-table {
  display: flex;
  max-width: 1120px;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 177px);
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0 4px 48px 0 rgba(6, 59, 122, 0.08), 0 1px 4px 0 rgba(6, 59, 122, 0.06);

  &_overflow {
    width: 100%;
    overflow: auto;
    height: 100%;
    //height: calc(100vh - 392px);
    scrollbar-gutter: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(124, 163, 210, 0.25) transparent;
  }

  &_head {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 24px 0 18px 24px;

    .title {
      color: #1A242B;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.4px;
    }

    .switchers {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      padding: 2px;
      border-radius: 6px;
      background: rgba(124, 163, 210, 0.12);

      &--item {
        cursor: pointer;
        border-radius: 4px;
        transition: all .2s ease-in-out;
        color: var(--trade-trade-gray, #527AA1);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 114.286% */
        padding: 6px 12px;


        &_active {
          background: #FFF;
          box-shadow: 0 1px 3px 0 #DFE2F3;
          color: var(--trade-trade-black, #1A242B);
        }
      }
    }
  }

  tr {
    transition: all .3s ease-in-out;
  }

  &_sorting {
    &_passive {
      path {
        fill: #7CA3D2;
      }
    }

    &_a-z {
      path {
        fill: #7CA3D2;
        fill-opacity: 0.24;

        &:not(:first-child) {
          fill: #1A242B;
          fill-opacity: 1;
        }
      }
    }

    &_z-a {
      path {
        fill: #1A242B;
        fill-opacity: 1;

        &:not(:first-child) {
          fill: #7CA3D2;
          fill-opacity: 0.24;
        }
      }
    }
  }

  tr:first-child {
    position: sticky;
    top: 0;
    left: 0;
    background: white;
    border-bottom: 2px solid #E0E9F4;
    z-index: 99;
  }

  tr:not(:first-child) {
    border-top: 1px solid #E0E9F4;
  }

  tr:not(:first-child):hover {
    border-top: 1px solid white;
    cursor: pointer;
    box-shadow: 0 4px 24px 0 rgba(6, 59, 122, 0.08), 0 1px 4px 0 rgba(6, 59, 122, 0.06);
    transition: all 0.3s ease-in-out;
  }

  &_table {
    width: 100%;
    border-collapse: collapse;

    tr {
      td, th {
        text-align: start;

        &:first-child {
          padding-left: 24px;
          box-sizing: border-box;
        }

        &:last-child {
          padding-right: 24px;
          box-sizing: border-box;
        }
      }

      td {
        color: var(--trade-trade-black, #1A242B);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      th {
        padding-bottom: 8px;
      }
    }

    .table {

      &-header {
        color: var(--trade-trade-gray, #527AA1);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      &-coin {
        //padding-left: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding-top: 10px;
        padding-bottom: 10px;

        &-name {
          color: var(--trade-trade-black, #1A242B);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
        }

        &-full_name {
          color: var(--trade-trade-gray, #527AA1);
          font-family: Rubik;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 116.667% */
        }
      }

      &-buttons {

        .buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;

          &-sm {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2px;

            &_block {
              position: relative;

              .hovered {
                position: absolute;
                top: calc(100% + 2px);
                box-sizing: border-box;
                left: -18px;
                z-index: 9999;
                border-radius: 4px;
                background: var(--trade-trade-black, #1A242B);
                padding: 4px 8px;
                color: #FFF;
                font-family: 'Rubik', sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 133.333% */

                &-last {
                  right: -18px;
                  left: unset;
                }
              }
            }
          }

          &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: -0.2px;
            cursor: pointer;
            color: var(--trade-trade-gray, #527AA1);
            transition: all .3s ease-in-out;
            padding: 6px;
            border-radius: 6px;

            svg {
              path {
                fill: #527AA1;
                transition: all .3s ease-in-out;
              }
            }

            &:not(&_disabled):hover {
              color: var(--trade-trade-black, #1A242B);
              background: rgba(0, 122, 255, 0.06);

              svg {
                path {
                  fill: #1A242B;
                }
              }
            }

            &_disabled {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }

}
</style>
