<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="32"
      height="32"
      rx="8"
      fill="#FF783E"
      fill-opacity="0.06"
    />
    <path
      d="M15.9993 7.66669C11.3993 7.66669 7.66602 11.4 7.66602 16C7.66602 20.6 11.3993 24.3334 15.9993 24.3334C20.5993 24.3334 24.3327 20.6 24.3327 16C24.3327 11.4 20.5993 7.66669 15.9993 7.66669ZM15.9993 22.6667C12.3243 22.6667 9.33268 19.675 9.33268 16C9.33268 12.325 12.3243 9.33335 15.9993 9.33335C19.6743 9.33335 22.666 12.325 22.666 16C22.666 19.675 19.6743 22.6667 15.9993 22.6667Z"
      fill="#FF783E"
    />
    <path
      d="M14.1774 12.7V19.2455H12.4004V12.7H14.1774Z"
      fill="#FF783E"
    />
    <path
      d="M15.2195 19.2455V12.7H18.0448C18.5306 12.7 18.9557 12.7959 19.32 12.9877C19.6844 13.1794 19.9678 13.4489 20.1702 13.7962C20.3726 14.1435 20.4738 14.5494 20.4738 15.0139C20.4738 15.4827 20.3694 15.8886 20.1606 16.2316C19.9539 16.5747 19.6631 16.8389 19.2881 17.0242C18.9152 17.2096 18.4795 17.3023 17.9809 17.3023H16.2934V15.9216H17.6229C17.8317 15.9216 18.0097 15.8854 18.1567 15.8129C18.3058 15.7384 18.4198 15.6329 18.4987 15.4965C18.5796 15.3602 18.6201 15.1993 18.6201 15.0139C18.6201 14.8264 18.5796 14.6666 18.4987 14.5345C18.4198 14.4003 18.3058 14.298 18.1567 14.2277C18.0097 14.1553 17.8317 14.119 17.6229 14.119H16.9965V19.2455H15.2195Z"
      fill="#FF783E"
    />
  </svg>
</template>

<script>
export default {
  name: "IpFalse"
}
</script>

<style scoped>

</style>
