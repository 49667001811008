<script setup>

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4241_72195)">
      <path
        d="M16.5654 15.392C16.932 14.8087 17.1487 14.1337 17.1487 13.4003C17.1487 11.3253 15.4737 9.65033 13.3987 9.65033C11.3237 9.65033 9.6487 11.3253 9.6487 13.4003C9.6487 15.4753 11.3237 17.1503 13.3904 17.1503C14.1237 17.1503 14.807 16.9337 15.382 16.567L17.982 19.167L19.1654 17.9837L16.5654 15.392ZM13.3987 15.4837C12.2487 15.4837 11.3154 14.5503 11.3154 13.4003C11.3154 12.2503 12.2487 11.317 13.3987 11.317C14.5487 11.317 15.482 12.2503 15.482 13.4003C15.482 14.5503 14.5487 15.4837 13.3987 15.4837ZM13.0987 8.40033C12.482 8.41699 11.8904 8.55032 11.3487 8.77533L10.8904 8.08366L7.7237 13.2337L5.21536 10.3003L2.19036 15.142L0.832031 14.167L4.9987 7.50033L7.4987 10.417L10.832 5.00033L13.0987 8.40033ZM15.257 8.81699C14.7237 8.58366 14.1487 8.44199 13.5487 8.40866L17.8154 1.66699L19.1654 2.65033L15.257 8.81699Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4241_72195">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
