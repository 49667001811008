<script>
import TradeLayout from "@/layouts/trade/TradeLayout.vue";
import TradeTableLayout from "@/components/trade/TradeTableLyout.vue";
import {TRADE_ORDERS_TABLE_TH} from "@/pages/admin-trade/orders/trade-orders.headers";
import dayjs from "dayjs";
import TradeService from "@/services/trade.service";
import TradeNoData from "@/components/trade/components/TradeNoData.vue";
import TradeLoader from "@/components/trade/components/TradeLoader.vue";
import TradePagination from "@/components/trade/components/TradePagination.vue";

export default {
  name: "TradeOrdersPage",
  components: {TradePagination, TradeLoader, TradeNoData, TradeTableLayout, TradeLayout},
  data() {
    return {
      tableTh: TRADE_ORDERS_TABLE_TH,
      tableData: null,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      isLoading: true,
      currentIndex: false,
      maxPage: null,
      currentPage: 0
    }
  },
  computed: {
    hasData() {
      return Array.isArray(this.tableData) && this.tableData.length > 0; // Додали перевірку на масив
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.getDataList();
      }
    }
  },
  mounted() {
    this.getDataList();
  },
  methods: {

    executedCalc(el) {
      const executed = el.amount * el.price
      return this.formatNumber(executed)
    },
    getDataList() {
      this.tableData = null;
      this.isLoading = true;
      TradeService.getListExchange(this.$route.params.id).then((res) => {
        this.tableData = res.data.result.result
        this.maxPage = res.data.result.maxPage
        this.currentPage = res.data.result.currentPage
        this.isLoading = false;
      })
    },
    dateFormatter(value) {
      return dayjs(value).format(this.dateFormat)
    },
    formatNumber(number) {
      const parts = number.toString().split(".");
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      const decimalPart = parts.length > 1 ? parseFloat("0." + parts[1]).toFixed(2).slice(2) : "";

      return decimalPart ? integerPart + "." + decimalPart : integerPart;
    },
    commentHandler(i) {
      if (this.currentIndex !== i) {
        this.currentIndex = i
      } else {
        this.currentIndex = false
      }
    },
    cancelOrder(item) {
      const query = '?idOrder=' + item.id

      TradeLayout.cancelTransaction(query).then((res) => {
        if (res) {
          this.getDataList();
        }
      })
    },
    pageHandler(page) {
      this.currentPage = page
    }
  }
}

</script>

<template>
  <trade-layout>
    <template #tradeSlot>
      <trade-table-layout
        title="Orders"
        :is-buttons="false"
        :table-th="tableTh"
        :is-paginate="true"
      >
        <template
          v-if="tableData"
          #table
        >
          <tr
            v-for="(item, index) in tableData"
            :key="index"
          >
            <td class="table-id table-id_td">
              <span>{{ item.id }}</span>
            </td>
            <td class="table-order-time">
              {{ dateFormatter(item.createDate) }}
            </td>
            <td class="table-pair">
              <div
                v-if="item.coinFrom && item.cointo"
                class="table-pair_td"
              >
                {{ item.coinFrom.shortName }}<span>/</span>{{ item.cointo.shortName }}
              </div>
            </td>
            <td class="table-mode">
              {{ item.mode }}
            </td>
            <td class="table-side">
              <span :class="{'table-side_green': item.side === 'Buy', 'table-side_red': item.side === 'Sell'}">
                {{ item.side }}
              </span>
            </td>
            <td class="table-type">
              {{ item.type }}
            </td>
            <td class="table-price">
              {{ formatNumber(item.price) }}
            </td>
            <td class="table-executed">
              <div class="table-executed_td">
                <div class="table-amount_td-text">
                  {{ executedCalc(item) }}
                </div>
                <div
                  v-if="item.coinFrom"
                  class="table-amount_td-coin"
                >
                  {{ item.cointo.shortName }}
                </div>
              </div>
            </td>
            <td class="table-amount">
              <div class="table-amount_td">
                <div class="table-amount_td-text">
                  {{ formatNumber(item.amount) }}
                </div>
                <div class="table-amount_td-coin">
                  {{ item.coinFrom.shortName }}
                </div>
              </div>
            </td>
            <td class="table-status">
              <div class="table-status_block">
                <svg
                  class="table-status_circle"
                  :class="{'table-status_circle-green': item.status === 'Filled', 'table-status_circle-red': item.status === 'Error'}"
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.4"
                    width="6"
                    height="6"
                    rx="3"
                    fill="#7CA3D2"
                  />
                </svg>
                {{ item.status }}
              </div>
            </td>
            <td class="table-comment table-comment-td">
              <div v-if="item.comment && item.comment.length">
                <div
                  v-if="item.status === 'Processing'"
                  class="icon"
                  @click="cancelOrder(item)"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.0013 13.166L9.0013 10.166L12.0013 13.166L13.168 11.9993L10.168 8.99935L13.168 5.99935L12.0013 4.83268L9.0013 7.83268L6.0013 4.83268L4.83464 5.99935L7.83463 8.99935L4.83464 11.9993L6.0013 13.166ZM9.0013 17.3327C7.84852 17.3327 6.76519 17.1139 5.7513 16.6764C4.73741 16.2389 3.85547 15.6452 3.10547 14.8952C2.35547 14.1452 1.76172 13.2632 1.32422 12.2493C0.886719 11.2355 0.667969 10.1521 0.667969 8.99935C0.667969 7.84657 0.886719 6.76324 1.32422 5.74935C1.76172 4.73546 2.35547 3.85352 3.10547 3.10352C3.85547 2.35352 4.73741 1.75977 5.7513 1.32227C6.76519 0.884766 7.84852 0.666016 9.0013 0.666016C10.1541 0.666016 11.2374 0.884766 12.2513 1.32227C13.2652 1.75977 14.1471 2.35352 14.8971 3.10352C15.6471 3.85352 16.2409 4.73546 16.6784 5.74935C17.1159 6.76324 17.3346 7.84657 17.3346 8.99935C17.3346 10.1521 17.1159 11.2355 16.6784 12.2493C16.2409 13.2632 15.6471 14.1452 14.8971 14.8952C14.1471 15.6452 13.2652 16.2389 12.2513 16.6764C11.2374 17.1139 10.1541 17.3327 9.0013 17.3327ZM9.0013 15.666C10.8624 15.666 12.4388 15.0202 13.7305 13.7285C15.0221 12.4368 15.668 10.8605 15.668 8.99935C15.668 7.13824 15.0221 5.56185 13.7305 4.27018C12.4388 2.97852 10.8624 2.33268 9.0013 2.33268C7.14019 2.33268 5.5638 2.97852 4.27214 4.27018C2.98047 5.56185 2.33464 7.13824 2.33464 8.99935C2.33464 10.8605 2.98047 12.4368 4.27214 13.7285C5.5638 15.0202 7.14019 15.666 9.0013 15.666Z"
                      fill="#527AA1"
                    />
                  </svg>
                </div>
                <div
                  class="icon"
                  @click="commentHandler(index)"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3844_320)">
                      <path
                        d="M16.6641 1.6665H3.33073C2.41406 1.6665 1.6724 2.4165 1.6724 3.33317L1.66406 18.3332L4.9974 14.9998H16.6641C17.5807 14.9998 18.3307 14.2498 18.3307 13.3332V3.33317C18.3307 2.4165 17.5807 1.6665 16.6641 1.6665ZM16.6641 13.3332H4.30573L3.81406 13.8248L3.33073 14.3082V3.33317H16.6641V13.3332ZM9.16406 9.99984H10.8307V11.6665H9.16406V9.99984ZM9.16406 4.99984H10.8307V8.33317H9.16406V4.99984Z"
                        fill="#527AA1"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3844_320">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  v-if="currentIndex === index"
                  class="hover-comment"
                >
                  <div class="text">
                    <div class="user">
                      {{ item.userLogin }}
                    </div>
                    <div class="comment">
                      {{ item.comment }}
                    </div>
                  </div>
                  <div class="avatar">
                    <img
                      :src="'https://swapuz.com' + item.userAvatar"
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template #pagination>
          <TradePagination
            v-if="maxPage > 1"
            :max-page="maxPage"
            :current="currentPage"
            @page-changed="pageHandler($event)"
          />
        </template>
        <template #loader>
          <trade-loader v-if="isLoading" />
        </template>
        <template #noData>
          <TradeNoData v-if="!hasData && !isLoading" />
        </template>
      </trade-table-layout>
    </template>
  </trade-layout>
</template>

<style lang="scss">
.table {
  &-price {
    text-align: right;
  }

  &-id {
    width: 92px;

    &_td {
      span {
        color: var(--trade-trade-black, #1A242B);
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }

  &-order {
    &-time {
      width: 178px;
    }
  }

  &-header + .table-executed, &-header + .table-amount {
    text-align: right !important;
    padding-right: 48px;
    box-sizing: border-box;
  }

  &-pair {
    width: 128px;

    &_td {
      color: var(--trade-trade-black, #1A242B);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      span {
        color: var(--trade-trade-gray, #527AA1);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin: 0 1px;
      }
    }
  }

  &-mode {
    text-transform: capitalize;
    width: 72px;
  }

  &-type {
    width: 56px;
  }

  &-side {
    width: 48px;

    &_green {
      color: var(--trade-trade-green, #00B18B);
    }

    &_red {
      color: var(--trade-trade-red, #FF603D);
    }
  }

  &-price {
    width: 110px;
  }

  &-executed {
    width: 158px;
  }

  &-amount {
    width: 158px;
  }

  &-amount, &-executed {
    text-align: center;

    &_td {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 6px;
      width: 150px;

      &-text {
        width: 100%;
        text-align: right;
      }

      &-coin {
        width: 48px;
        text-align: left;
        color: var(--trade-trade-gray, #527AA1);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
      }
    }
  }

  &-status {
    min-width: 100px;

    &_block {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &_circle {
      margin-right: 6px;

      &-green {
        rect {
          fill: #02C076;
          opacity: 1;
        }
      }

      &-red {
        rect {
          fill: #FF5C00;
          opacity: 1;
        }
      }
    }
  }

  &-comment {
    &-td {
      padding: 7px 0;
      box-sizing: border-box;
    }

    .icon {
      width: 32px;
      height: 32px;
      display: grid;
      place-items: center;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
      box-sizing: border-box;

      &:hover {
        background: rgba(0, 122, 255, 0.06);
      }
    }
  }
}
</style>
