<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3644_2993)">
      <path
        d="M8.0026 1.33331C4.3226 1.33331 1.33594 4.31998 1.33594 7.99998C1.33594 11.68 4.3226 14.6666 8.0026 14.6666C11.6826 14.6666 14.6693 11.68 14.6693 7.99998C14.6693 4.31998 11.6826 1.33331 8.0026 1.33331ZM6.66927 11.3333L3.33594 7.99998L4.27594 7.05998L6.66927 9.44665L11.7293 4.38665L12.6693 5.33331L6.66927 11.3333Z"
        fill="#02C076"
      />
    </g>
    <defs>
      <clipPath id="clip0_3644_2993">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CheckTrueIcon"
}
</script>

<style scoped>

</style>
