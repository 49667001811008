import service from '../plugins/request';
import UrlService from "@/services/url.service";

class OrderService {
    /**
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    getExchangesList(payload) {
        if (!payload.page) {
            payload.page = 1;
        }

        let query = UrlService.buildParams(payload);

        return service.get(`/api/Order/exchanges/?${query}`);
    }

    /**
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    getOrderList(payload) {
        if (!payload.page) {
            payload.page = 1;
        }

        let query = UrlService.buildParams(payload);

        return service.get(`/api/Order/list/?${query}`);
    }

    /**
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    getOrderList1inch(payload) {
        if (!payload.page) {
            payload.page = 1;
        }

        let query = UrlService.buildParams(payload);

        return service.get(`/api/Order1inch/all1inch/?${query}`);
    }

    /**
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    getOrderListMulti(payload) {
        if (!payload.page) {
            payload.page = 1;
        }

        let query = UrlService.buildParams(payload);

        return service.get(`/api/Order1inch/allMulti/?${query}`);
    }

    /**
     * @param id
     * @param status
     * @returns {Promise<AxiosResponse<T>>}
     */
    updateOrderStatusById(id, status) {
        return service.patch(`/api/Order/${id}/status`, status);
    }

    /**
     * @param id
     * @param status
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateOrderMultiStatusById(id, status) {
        return service.patch(`/api/Order1inch/updateMulti?id=${id}&status=${status}`);
    }

    /**
     * @param id
     * @param order
     * @returns {Promise<AxiosResponse<T>>}
     */
    editOrderById(id, order) {
        return service.patch(`/api/Order/${id}/edit`, order);
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    getOrderAdminById(id) {
        return service.get(`/api/Order/admin/${id}`);
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    orderRecalc(id) {
        return service.get(`/api/Order/calc/${id}`);
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    orderStatTotal(query) {
        return service.get('/api/Order/admin/stat/total' + query);
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    orderMainChart(query) {
        return service.get('/api/Order/admin/stat/mainGraf' + query);
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    topDeposit(query) {
        return service.get('/api/Order/admin/topDeposit' + query);
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    topWithdrawal(query) {
        return service.get('/api/Order/admin/topWithdrawal' + query);
    }

    getOrderActivity(id) {
        return service.get('/api/Order/admin/activity?id=' + id )
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    closeOrderById(id) {
        return service.get(`/api/Order1inch/admin/order/${id}/close` )
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getListDeposit() {
        return service.get('/api/Order/listDeposit');
    }

    /**
     * @param idOrder
     * @param idDeposit
     * @returns {Promise<AxiosResponse<any>>}
     */
    setListDeposit(idOrder, idDeposit) {
        return service.patch('/api/Order/setDeposit', {idOrder: idOrder, idDeposit: idDeposit});
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getDashboardStatPartner() {
        return service.get('/api/Order/admin/dashboardStatPartner')
    }


    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCustomDashboardStatPartner(query) {
        return service.get('/api/Order/admin/customDashboardStatPartner' + query)
    }

    /**
     * @param id
     * @param address
     * @returns {Promise<AxiosResponse<any>>}
     */
    getRefundOrder(id) {
        return service.get(`/api/Order/admin/${id}/node/restart`)
    }

    /**
     * @param id
     * @param address
     * @param feeOption
     * @returns {Promise<AxiosResponse<any>>}
     */
     getRestartOrder(id, address, feeOption) {
        return service.get(`/api/Order/admin/${id}/node/refund?address=` + address + '&feeOption=' + feeOption)
    }


    /**
     * @param id
     * @param feeOption
     * @returns {Promise<AxiosResponse<any>>}
     */
    getBumpOrder(id, feeOption) {
        return service.get(`/api/Order/admin/${id}/node/bump?feeOption=` + feeOption)
    }

    /**
     * @param id
     * @param feeOption
     * @returns {Promise<AxiosResponse<any>>}
     */
    getWithdaralBumpOrder(id, feeOption) {
        return service.get(`/api/Order/admin/${id}/withdaral/bump?feeOption=` + feeOption)
    }


    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getStatTotalCustom(query) {
        return service.get('/api/Order/admin/stat/total/custom' + query)
    }


    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getLastOrderPartner() {
        return service.get('api/Order/admin/lastOrderPartner')
    }

    /**
     * @param orderId
     * @returns {Promise<AxiosResponse<any>>}
     */
    getComments(orderId) {
        return service.get(`/api/comment/${orderId}/getComments`)
    }

    getFeeOptions() {
        return service.get('/api/Order/admin/feeOptions')
    }

    patchOrder(id, hashIn, amount) {
        return service.post(`/api/Order/admin/${id}/node/patch`, {
            hash: hashIn, amount: amount
        })
    }

    getBalanceMirror() {
        return service.get('/api/Order/admin/mirror/balance')
    }

    getConfig() {
        return service.get('/api/Order1inch/admin/getConfig')
    }

    sentMirrorBalance({amount, recipientAddress, token, mfaCode, network}) {
        return service.post('/api/Order/admin/mirror/balanceSent', {
            token,
            recipientAddress,
            amount,
            mfaCode,
            network
        })
    }


    createComment(orderId, message, parentCommentId) {
        return service.post(`/api/comment/${orderId}/createComment`, {
            message,
            parentCommentId
        })
    }


    getMainChart(query) {
        return service.get('/api/Order/admin/stat/mainGrafUpdate' + query)
    }

    generateAbuse(query) {
        return service.get('/api/Order/admin/GenerateAbuse' + query)
    }

    rebalanceMirror(query) {
        return service.post('/api/Order/admin/mirror/rebalance', query)
    }

    changeMarket(id,query) {
        return service.post(`/api/Order/reroute/${id}`, query)
    }

    getAvailableExcganges() {
        return service.get('/api/Order/admin/AvailableExcganges')
    }

    checkPairAvailability(query) {
        return service.get('/api/Order/admin/CheckPairAvailability' + query)
    }
    
    getDepositSummary(query) {
        return service.get('/api/Order/admin/node/depositSummary?'+query)
    }

    getDeposits(query) {
        return service.get('/api/Order/admin/node/Deposits?'+query)
    }
}

export default new OrderService();
