<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4035_2512)">
      <path
        d="M9 16.5H3.75C2.9175 16.5 2.25 15.825 2.25 15L2.2575 4.5C2.2575 3.675 2.9175 3 3.75 3H4.5V1.5H6V3H12V1.5H13.5V3H14.25C15.075 3 15.75 3.675 15.75 4.5V9H14.25V7.5H3.75V15H9V16.5ZM16.5975 12.7425L17.13 12.21C17.4225 11.9175 17.4225 11.445 17.13 11.1525L16.5975 10.62C16.305 10.3275 15.8325 10.3275 15.54 10.62L15.0075 11.1525L16.5975 12.7425ZM16.065 13.275L12.09 17.25H10.5V15.66L14.475 11.685L16.065 13.275Z"
        fill="#0A68F7"
        fill-opacity="0.12"
      />
    </g>
    <defs>
      <clipPath id="clip0_4035_2512">
        <rect
          width="18"
          height="18"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>


export default {
    name: 'CalendarPicker',
}
</script>

<style scoped lang="scss">

</style>


