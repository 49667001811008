<script>
import OrderService from "@/services/order.service";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import CopyNotification from "@/pages/notification/CopyNotification.vue";

export default {
  name: 'NodasBalances',
  components: {CopyNotification, NoDataBlock},
  data() {
    return {
      nodasBalances: null,
      isLoading: true,
      copyText: '',
      isSuccessCopy: true,
      tableTh: [
        {
          name: 'Network',
          sort: '',
          key: 'network',
          isSortable: false
        },
        {
          name: 'Node address',
          sort: '',
          key: 'nodeAddress',
          isSortable: false
        },
        {
          name: 'Balance',
          sort: '',
          key: 'balance',
          isSortable: false
        },
      ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';
      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    copyTextToClipboard(text) {
      navigator.clipboard.writeText(text)
          .then(() => {
            this.onCopyAddress();
          })
          .catch(err => {
            console.error('Could not copy text: ', err);
          });
    },
    amountToFixed(val, token) {
      let numberToFixed = 0;
      if (token.toUpperCase() === 'BTC' || token.toUpperCase() === 'ETH' || token.toUpperCase() === 'ARB') {
        numberToFixed = 4;
      }

      if (token.toUpperCase() === 'XMR') {
        numberToFixed = 2;
      }

      if (val !== undefined && val !== null) {
        const fixedVal = val.toFixed(numberToFixed);

        if (val >= 10000) {
          return parseFloat(fixedVal).toLocaleString('en-US', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 5
          });
        } else {
          return fixedVal
        }
      }
    },
    async getData() {
      await OrderService.getConfig().then((res) => {
        if (res?.data?.data) {
          this.nodasBalances = res.data.data
          this.isLoading = true
        }
      })
    }
  }
}
</script>

<template>
  <div class="table_node-balances main-block">
    <div class="table-block">
      <div class="table-block-header">
        <div class="table-block-header-title">
          Node balances
        </div>
      </div>
      <div
        class="table-block-table"
      >
        <table
          v-if="nodasBalances"
          class="table"
        >
          <thead>
            <tr class="table-headers">
              <th
                v-for="(item, index) in tableTh"
                :key="index"
                scope="col"
                :class="{
                  'table-first': item.key === 'network',
                  'table-balance': item.key === 'balance'
                }"
              >
                <div
                  class="text"
                >
                  {{ item.name }}
                </div>
              </th>
              <th class="table-edit" />
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(item, index) in nodasBalances"
              :key="index"
            >
              <tr
                v-if="item.balance !== 0"
                class="table-row"
              >
                <td
                  class="table-cell table-first"
                >
                  {{ item.network }}
                </td>
                <td
                  class="table-cell table-node_address"
                  @click="copyTextToClipboard(item.address)"
                >
                  <div
                    v-if="item.address"
                    class="address"
                  >
                    {{ item.address }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </td>
                <td class="table-cell table-balance">
                  {{ amountToFixed(item.balance, item.network) }}
                </td>
                <td class="table-cell table-cell--edit">
                  <a
                    :href="item.marketURL + item.address"
                    target="_blank"
                  >
                    <div class="edit">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_133_932)">
                          <path
                            d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64167 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z"
                            fill="#0A68F7"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_133_932">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <no-data-block v-if="!nodasBalances" />
      </div>
    </div>
  </div>
  <CopyNotification
    v-if="isSuccessCopy"
    :text="copyText"
  />
</template>

<style scoped lang="scss">

.table_node-balances {
  margin-bottom: 40px;

  @media (max-width: 1079px) {
    margin-bottom: 20px;
  }

  .table {
    margin-bottom: 0;

    &-block {
      &-table {
        padding-bottom: 3px;
        @media (max-width: 1079px) {
          overflow: auto;
        }
      }
    }

    &-first {
      padding-left: 32px;
      width: 168px;

      @media (max-width: 1079px) {
        padding-left: 20px;
      }
    }

    &-node_address {
      color: var(--black, #1B1A1C);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.2px;
      width: 468px;

      .address {
        box-sizing: border-box;
        width: 468px;
        padding: 0;
        overflow: hidden;
        position: relative;
        display: inline-block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all .3s ease-in-out;
      }

      &:hover {
        cursor: pointer;

        .address {
          color: #0A68F7;
        }
      }
    }

    .table-balance {
      text-align: right;
      width: 100%;
    }

    .table-cell--edit {
      width: 77px;
      text-align: center;
      display: grid;
      place-items: center;
      padding-left: 12px;
    }

    .edit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      background: rgba(10, 104, 247, 0.06);

      &:hover {
        cursor: pointer;
        background: rgba(10, 104, 247, 0.08);
      }
    }


    &-row {
      border-bottom: 1px solid #DFE0EB;

      .table-first {
        color: var(--black, #1B1A1C);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

      }


    }

    &-row:last-child {
      border-bottom: transparent;
    }

    & > :not(:first-child) {
      .bottom {
        border-bottom: transparent;
      }

      border-top: 1px solid currentColor;
    }
  }
}

</style>
