<template>
  <div
    ref="search"
    class="search"
  >
    <div class="input-container">
      <input
        v-model="inputText"
        type="text"
        placeholder="ID/Uid/Hash/Address"
        :class="{'empty-input': !inputText}"
        @keyup.enter="sendSearchText"
      >
      <button
        v-if="inputText"
        class="submit-button"
        @click="sendSearchText"
      >
        Ok
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: "Search",
  emits: ['searchHandler'],
  data() {
    return {
      inputText: ''
    }
  },
  methods: {
    sendSearchText() {
      this.$emit('searchHandler', this.inputText);
      this.inputText = '';
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

.search {
  position: relative;
  margin-right: 8px;

  .input-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 3px;
    right: 4px;
    height: 26px;
    width: 26px;
    border-radius: 8px;
    font-size: 12px;
    color: $blue-color;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
    background: $light-gray-color;
    transition: all 0.3s;

    &:hover {
      background: $light-gray-hover-color;
    }
  }

  input.empty-input {
    padding: 6px 11px 6px 30px;
  }

  input {
    display: inline-flex;
    padding: 6px 34px 6px 30px;
    align-items: flex-start;
    width: 162px;
    height: 32px;

    background: url(../../assets/img/icons/search.svg) no-repeat;
    background-size: 20px;
    background-position-x: 6px;
    background-position-y: center;
    border-radius: 8px;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid $another-grey-color;
    box-sizing: border-box;
    color: $black-color1;
            caret-color: $blue-color;
    outline: none;
    transition: border 0.3s ease-in-out;

    &::placeholder {
      font-weight: 400;
      color: $another-grey-color;
    }

    &:hover {
      border: 1px solid rgba(0, 101, 255, 0.40);

    }

    &:focus {
      border: 1px solid $blue-color;
    }
  }
}
</style>
