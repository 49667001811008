<template>
  <div
    class="exchange-info-form-inputs-block exchange-info-info"
  >
    <div class="exchange-info-form-inputs-block_items">
      <div
        v-if="data.currentAdmin"
        class="exchange-info-form-info-block-item"
      >
        <div class="exchange-info-form-info-block-item-main">
          <div class="desc">
            Admin
          </div>
          <div
            class="data data-avatar"
          >
            <avatar :img="data.currentAdmin.avatar" />
            {{ data.currentAdmin.login }}
          </div>
        </div>
      </div>
      <div class="exchange-info-form-info-block-item">
        <div class="exchange-info-form-info-block-item-block">
          <div class="desc">
            Order payment
          </div>
          <div
            class="data"
          >
            {{ dataFormatting(data.paymentDate) }}
          </div>
        </div>
      </div>
      <div class="exchange-info-form-info-block-item">
        <div class="exchange-info-form-info-block-item-block">
          <div class="desc">
            Order complete
          </div>
          <div
            class="data"
          >
            {{ dataFormatting(data.completeDate) }}
          </div>
        </div>
      </div>
      <div class="exchange-info-form-info-block-item">
        <div class="exchange-info-form-info-block-item-block">
          <div class="desc">
            Order duration
          </div>
          <div
            class="data"
          >
            {{ data.minutesElapsed }} min
          </div>
        </div>
      </div>
      <div class="exchange-info-form-info-block-item">
        <div class="exchange-info-form-info-block-item-block">
          <div class="desc">
            User IP
          </div>
          <div
            class="data"
          >
            {{ data.ipAddress }}
          </div>
        </div>
      </div>
      <div class="exchange-info-form-info-block-item">
        <div class="exchange-info-form-info-block-item-block">
          <div class="desc">
            User agent
          </div>
          <div
            class="data"
          >
            {{ data.userAgent }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Avatar from "@/components/admin/AvatarComponent.vue";

export default {
  name: "ExchangeInfoInfo",
  components: {Avatar},
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dateFormat: 'DD.MM.YY - HH:mm',
    }
  },
  mounted() {

  },
  methods: {
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).format(this.dateFormat)
    },
  }
}
</script>

<style lang="scss">
.exchange-info-info {
  .exchange-info-form-info-block-item-block {
    max-width: unset;
  }

  .exchange-info-form-info-block-item-main {
    .data-avatar {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      .avatar {
        border-radius: 50%;
        overflow: hidden;
        width: 24px;
        height: 24px;
        display: flex;
      }
    }
  }


}


</style>
