<script setup>

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4241_71783)">
      <path
        d="M10.0013 1.66699C5.4013 1.66699 1.66797 5.40033 1.66797 10.0003C1.66797 14.6003 5.4013 18.3337 10.0013 18.3337C14.6013 18.3337 18.3346 14.6003 18.3346 10.0003C18.3346 5.40033 14.6013 1.66699 10.0013 1.66699ZM10.7346 14.8003V15.8337H9.2763V14.7587C8.65964 14.6087 7.28464 14.117 6.75964 12.292L8.13464 11.7337C8.18464 11.917 8.61797 13.4753 10.1346 13.4753C10.9096 13.4753 11.7846 13.0753 11.7846 12.1337C11.7846 11.3337 11.2013 10.917 9.88464 10.442C8.96797 10.117 7.09297 9.58366 7.09297 7.68366C7.09297 7.60033 7.1013 5.68366 9.2763 5.21699V4.16699H10.7346V5.20033C12.268 5.46699 12.8263 6.69199 12.9513 7.05866L11.6346 7.61699C11.543 7.32533 11.143 6.50033 10.0513 6.50033C9.46797 6.50033 8.54297 6.80866 8.54297 7.65866C8.54297 8.45033 9.25964 8.75033 10.743 9.24199C12.743 9.93366 13.2513 10.9503 13.2513 12.117C13.2513 14.3087 11.168 14.7253 10.7346 14.8003Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4241_71783">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
