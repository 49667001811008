<script>
import TradeLayout from "@/layouts/trade/TradeLayout.vue";
import TradeTableLayout from "@/components/trade/TradeTableLyout.vue";
import {TRADE_WITHDRAWAL_TABLE_TH} from "@/pages/admin-trade/withdrawal/trade-withdrawal.headers";
import dayjs from "dayjs";
import TradeService from "@/services/trade.service";
import {mapGetters} from "vuex";
import TradeNoData from "@/components/trade/components/TradeNoData.vue";
import TradeLoader from "@/components/trade/components/TradeLoader.vue";
import vClickOutside from 'click-outside-vue3'

export default {
  name: "TradeWithdrawalPage",
  components: {TradeLoader, TradeNoData, TradeTableLayout, TradeLayout},
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      tableTh: TRADE_WITHDRAWAL_TABLE_TH,
      tableData: null,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      currentIndex: null,
      currentAddress: null,
      currentHash: null,
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      UserAcc: 'getUserAcc',
    }),
    hasData() {
      return Array.isArray(this.tableData) && this.tableData.length > 0; // Додали перевірку на масив
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        this.getListData(newId);
      }
    }
  },
  mounted() {
    this.getListData();
  },
  methods: {
    handleClickOutsideAddress() {
      this.currentAddress = null;
    },
    handleClickOutsideHash() {
      this.currentHash = null;
    },
    getListData() {
      this.isLoading = true;
      this.tableData = null;
      const page = '?page=' + 1

      TradeService.getListWithdraw(this.$route.params.id, page).then((res) => {
        this.tableData = res.data.result.result;
        this.isLoading = false;
      })
    },
    dateFormatter(value) {
      return dayjs(value).format(this.dateFormat)
    },
    formatAddress(address, charactersToShow = 8, charactersToDisplay = 8) {
      const addressLength = address.length;

      if (addressLength <= charactersToShow + charactersToDisplay) {
        return address;
      }

      const firstCharacters = address.slice(0, charactersToShow);
      const lastCharacters = address.slice(-charactersToDisplay);

      return `${firstCharacters}...${lastCharacters}`;
    },
    commentHandler(i) {
      if (this.currentIndex !== i) {
        this.currentIndex = i
      } else {
        this.currentIndex = false
      }
    }
  }
}
</script>

<template>
  <trade-layout>
    <template #tradeSlot>
      <trade-table-layout
        title="Withdrawal"
        :is-buttons="false"
        :table-th="tableTh"
      >
        <template
          v-if="tableData"
          #table
        >
          <tr
            v-for="(item, index) in tableData"
            :key="index"
          >
            <td class="table-id table-id-weight">
              {{ item.id }}
            </td>
            <td class="table-time">
              {{ dateFormatter(item.createDate) }}
            </td>
            <td class="table-shortName">
              <span>
                {{ item.coin }}
              </span>
            </td>
            <td class="table-network">
              {{ item.network }}
            </td>
            <td class="table-type">
              {{ item.type }}
            </td>
            <td
              class="table-address"
              @click="currentAddress = index"
            >
              <div
                class="table-address_td"
              >
                {{ formatAddress(item.address) }}
                <transition name="fade">
                  <div
                    v-if="currentAddress === index"
                    v-click-outside="handleClickOutsideAddress"
                    class="hover-copy"
                  >
                    <div
                      v-clipboard:copy="item.address"
                      class="text"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_3860_5404)">
                          <path
                            d="M10.6693 0.666504H2.66927C1.93594 0.666504 1.33594 1.2665 1.33594 1.99984V11.3332H2.66927V1.99984H10.6693V0.666504ZM12.6693 3.33317H5.33594C4.6026 3.33317 4.0026 3.93317 4.0026 4.6665V13.9998C4.0026 14.7332 4.6026 15.3332 5.33594 15.3332H12.6693C13.4026 15.3332 14.0026 14.7332 14.0026 13.9998V4.6665C14.0026 3.93317 13.4026 3.33317 12.6693 3.33317ZM12.6693 13.9998H5.33594V4.6665H12.6693V13.9998Z"
                            fill="#7CA3D2"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3860_5404">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      Copy
                    </div>
                  </div>
                </transition>
              </div>
            </td>
            <td
              class="table-hash"
              @click="currentHash = index"
            >
              <div
                v-if="item.whHash"
                class="table-hash_td"
              >
                {{ formatAddress(item.whHash) }}
                <transition name="fade">
                  <div
                    v-if="currentHash === index"
                    v-click-outside="handleClickOutsideHash"
                    class="hover-copy hover-action"
                  >
                    <div class="text">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_5413_3959)">
                          <path
                            d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z"
                            fill="#527AA1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5413_3959">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      Open
                    </div>
                    <div
                      v-clipboard:copy="item.whHash"
                      class="text"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_4199_42)">
                          <path
                            d="M10.6654 0.666667H2.66536C1.93203 0.666667 1.33203 1.26667 1.33203 2V11.3333H2.66536V2H10.6654V0.666667ZM12.6654 3.33333H5.33203C4.5987 3.33333 3.9987 3.93333 3.9987 4.66667V14C3.9987 14.7333 4.5987 15.3333 5.33203 15.3333H12.6654C13.3987 15.3333 13.9987 14.7333 13.9987 14V4.66667C13.9987 3.93333 13.3987 3.33333 12.6654 3.33333ZM12.6654 14H5.33203V4.66667H12.6654V14Z"
                            fill="#527AA1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4199_42">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      Copy
                    </div>
                  </div>
                </transition>
              </div>
            </td>
            <td class="table-amount">
              <div class="table-amount_td">
                <div class="table-amount_td-text">
                  {{ item.amount }}
                </div>
                <div class="table-amount_td-coin">
                  {{ item.coin }}
                </div>
              </div>
            </td>
            <td class="table-status">
              <svg
                class="table-status_circle"
                :class="{'table-status_circle-green': item.status === 'Filled', 'table-status_circle-red': item.status === 'Error'}"
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.4"
                  width="6"
                  height="6"
                  rx="3"
                  fill="#7CA3D2"
                />
              </svg>
              {{ item.status }}
            </td>
            <td class="table-comment table-comment-td">
              <div
                v-if="item.comment && item.comment.length"
                class="icon"
                @click="commentHandler(index)"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3844_320)">
                    <path
                      d="M16.6641 1.6665H3.33073C2.41406 1.6665 1.6724 2.4165 1.6724 3.33317L1.66406 18.3332L4.9974 14.9998H16.6641C17.5807 14.9998 18.3307 14.2498 18.3307 13.3332V3.33317C18.3307 2.4165 17.5807 1.6665 16.6641 1.6665ZM16.6641 13.3332H4.30573L3.81406 13.8248L3.33073 14.3082V3.33317H16.6641V13.3332ZM9.16406 9.99984H10.8307V11.6665H9.16406V9.99984ZM9.16406 4.99984H10.8307V8.33317H9.16406V4.99984Z"
                      fill="#527AA1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3844_320">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                v-if="currentIndex === index"
                class="hover-comment"
              >
                <div class="text">
                  <div class="user">
                    {{ item.userLogin }}
                  </div>
                  <div class="comment">
                    {{ item.comment }}
                  </div>
                </div>
                <div class="avatar">
                  <img
                    :src="'https://swapuz.com' + item.userAvatar"
                  >
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template #loader>
          <trade-loader v-if="isLoading" />
        </template>
        <template #noData>
          <TradeNoData v-if="!hasData && !isLoading" />
        </template>
      </trade-table-layout>
    </template>
  </trade-layout>
</template>

<style lang="scss">
.trade-table_table {
  tr {
    height: 46px;
  }
}

.table {

  &-id {
    &-weight {
      font-weight: 500 !important;
    }
  }

  &-time {
    width: 176px;
  }

  &-shortName {
    width: 64px;

    span {
      color: var(--trade-trade-black, #1A242B);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }

  &-network {
    width: 154px;
  }

  &-type {
    width: 64px;
  }

  &-amount {
    width: 156px;

    span {
      color: var(--trade-trade-gray, #527AA1);
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
    }
  }

  &-comment {
    width: 80px;
  }

  &-comment-td {
    position: relative;
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .hover-comment {
      position: absolute;
      z-index: 9;
      top: 100%;
      right: 0;
      border-radius: 4px;
      background: var(--trade-trade-black, #1A242B);
      padding: 8px 12px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .text {
        display: flex;
        flex-direction: column;
        gap: 2px;
        min-width: 176px;
        max-width: 180px;
        box-sizing: border-box;
        word-wrap: break-word;
        position: relative;

        .user {
          color: var(--trade-trade-lightgray, #7CA3D2);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }

        .comment {
          color: #FFF;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .avatar {
        width: 32px;
        height: 32px;
        overflow: hidden;
        border-radius: 50%;
      }
    }
  }

  &-address, &-hash {
    width: 160px;

    &_td {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: max-content;
      padding: 8px 4px;
      border-radius: 4px;
      position: relative;

      &:hover {
        background: var(--trade-hover, rgba(0, 122, 255, 0.06));
      }
    }
  }
}

.hover-copy {
  position: absolute;
  z-index: 9999;
  top: calc(100% + 2px);
  right: 0;
  left: 0;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
  background: var(--trade-trade-black, #1A242B);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 16px 8px 8px;
  max-width: max-content;


  .text {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.2px;
    transition: all .3s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;

    svg {
      path {
        transition: all .3s ease-in-out;
      }
    }
  }
}

.hover-action {
  flex-direction: column;
  padding: 0;

  .text {
    padding: 8px 16px 8px 8px;
    min-width: 92px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    gap: 4px;

    &:hover {
      background: #26343F;

      svg {
        path {
          fill: #7CA3D2;
        }
      }
    }
  }
}
</style>
