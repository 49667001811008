import service from '../plugins/request';

class BalanceService {

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getUserBalance() {
        return service.get('/api/coin/userBalance')
    }

    getOrder1inchBalance() {
        return service.get('/api/Order1inch/admin/balance')
    }

    getOrder1inchBalanceToken() {
        return service.get('/api/Order1inch/admin/balance/token')
    }
}


export default new BalanceService();
