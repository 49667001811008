<script>
export default {
  name: "ReportGeneratorIcon"
}
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 14.1667C10.2361 14.1667 10.434 14.0868 10.5938 13.9271C10.7535 13.7674 10.8333 13.5694 10.8333 13.3333C10.8333 13.0972 10.7535 12.8993 10.5938 12.7396C10.434 12.5799 10.2361 12.5 10 12.5C9.76389 12.5 9.56597 12.5799 9.40625 12.7396C9.24653 12.8993 9.16667 13.0972 9.16667 13.3333C9.16667 13.5694 9.24653 13.7674 9.40625 13.9271C9.56597 14.0868 9.76389 14.1667 10 14.1667ZM9.16667 10.8333H10.8333V5.83333H9.16667V10.8333ZM6.875 17.5L2.5 13.125V6.875L6.875 2.5H13.125L17.5 6.875V13.125L13.125 17.5H6.875ZM7.58333 15.8333H12.4167L15.8333 12.4167V7.58333L12.4167 4.16667H7.58333L4.16667 7.58333V12.4167L7.58333 15.8333Z"
      fill="white"
    />
  </svg>
</template>

<style scoped lang="scss">

</style>
