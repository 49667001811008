<script setup>

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4241_71804)">
      <path
        d="M17.4987 4.16699V15.8337H19.1654V4.16699H17.4987ZM14.1654 15.8337H15.832V4.16699H14.1654V15.8337ZM11.6654 4.16699H1.66536C1.20703 4.16699 0.832031 4.54199 0.832031 5.00033V15.0003C0.832031 15.4587 1.20703 15.8337 1.66536 15.8337H11.6654C12.1237 15.8337 12.4987 15.4587 12.4987 15.0003V5.00033C12.4987 4.54199 12.1237 4.16699 11.6654 4.16699ZM6.66536 6.45866C7.6987 6.45866 8.54036 7.30033 8.54036 8.33366C8.54036 9.36699 7.6987 10.2087 6.66536 10.2087C5.63203 10.2087 4.79036 9.36699 4.79036 8.33366C4.79036 7.30033 5.63203 6.45866 6.66536 6.45866ZM10.4154 14.167H2.91536V13.542C2.91536 12.292 5.41536 11.667 6.66536 11.667C7.91536 11.667 10.4154 12.292 10.4154 13.542V14.167Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4241_71804">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
