<template>
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99914 10.2001L2.49914 6.70007C2.10914 6.31007 1.48914 6.31007 1.09914 6.70007C0.709141 7.09007 0.709141 7.71007 1.09914 8.10007L5.28914 12.2901C5.67914 12.6801 6.30914 12.6801 6.69914 12.2901L17.2991 1.70007C17.6891 1.31007 17.6891 0.690068 17.2991 0.300068C16.9091 -0.0899316 16.2891 -0.0899316 15.8991 0.300068L5.99914 10.2001Z"
      fill="#0A68F7"
    />
  </svg>
</template>

<script>
    export default {
        name: "MarkIcon"
    }
</script>

<style scoped>

</style>