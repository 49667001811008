<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>List of exchanges</h2>

        <div class="layout-header_tabs">
          <div class="layout-header_tabs-item layout-header_tabs-item--active">
            Binance
          </div>
          <router-link
            :to="{ name: 'Exchanges 1inch' }"
            class="layout-header_tabs-item"
          >
            1inch
          </router-link>
          <router-link
            :to="{ name: 'Exchanges Multi' }"
            class="layout-header_tabs-item "
          >
            Multi
          </router-link>
          <!--          <div class="layout-header_tabs-item">-->
          <!--            Binance: total-->
          <!--          </div>-->
        </div>
      </div>
    </template>
    <template #content>
      <div
        v-if="exchangesData"
        class="exchangesList main-block exchangesList-binance"
      >
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="false"
          color="#0A68F7"
        />
        <div class="table-block">
          <div class="table-block-header">
            <div class="table-block-header-title">
              Binance exchanges
            </div>
            <div class="table-block-header-filters-container">
              <div class="table-block-header-filters">
                <div
                  v-if="statuses.length"
                  class="table-block-header-filters-block"
                >
                  <div
                    v-for="(status, index) in statuses"
                    :key="index"
                    class="table-block-header-filters-selected"
                    @click="clearStatuses()"
                  >
                    {{ getExchangeStatus(status).name }}
                    <div class="table-block-header-filters-selected-close">
                      <close-icon />
                    </div>
                  </div>
                </div>
                <div v-if="uid && isShowUid">
                  <div
                    class="table-block-header-filters-selected"
                  >
                    {{ uid }}
                    <div class="table-block-header-filters-selected-close">
                      <close-icon @click="uidHandler()" />
                    </div>
                  </div>
                </div>

                <div class="table-block-header-filters-delimeter" />
                <div class="table-block-header-filters-filter">
                  <div
                    class="table-block-header-filters-filter-btn eventBtn"
                    @click="searchStatusToggle"
                  >
                    <filter-icon class="filter" />
                    Status filter
                  </div>
                  <div
                    v-if="isStatusSearch && !isMobile()"
                    class="table-block-header-filters-dropsearch eventBlock"
                  >
                    <div
                      class="searchlist"
                    >
                      <div
                        v-for="(item, index) in ExchangeStatusList"
                        :key="index"
                        class="table-block-header-filters-dropsearch-login"
                      >
                        <div class="table-block-header-filters-dropsearch-login-block">
                          <div
                            class="item"
                            @click="statusHandler(item.status)"
                          >
                            <div
                              class="circle"
                              :class="{
                                'remaining': getExchangeStatus(item.status).className === 'remaining',
                                'pay': getExchangeStatus(item.status).className === 'pay',
                                'exchange': getExchangeStatus(item.status).className === 'exchange',
                                'withdrawal': getExchangeStatus(item.status).className === 'withdrawal',
                                'completed': getExchangeStatus(item.status).className === 'completed',
                                'overdue': getExchangeStatus(item.status).className === 'overdue',
                                'returned': getExchangeStatus(item.status).className === 'returned',
                                'hold': getExchangeStatus(item.status).className === 'hold',
                                'profit': getExchangeStatus(item.status).className === 'profit'
                              }"
                            />
                            <span class="item-text">
                              {{ getExchangeStatus(item.status).name }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="table-block-header-filters-filter-search"
                  >
                    <div class="input">
                      <input
                        v-model="uidSearch"
                        type="text"
                        :placeholder="searchPlaceHolder"
                        :style="{ minWidth: `19ch`}"
                        @keyup.enter="searchLoginHandler"
                        @mouseover="searchPlaceHolder = 'xxxxxxxx'"
                        @mouseleave="searchPlaceHolder = 'Search UID'"
                      >
                      <span
                        v-if="uidSearch.length > 1"
                        class="input-button save"
                        @click="searchLoginHandlerButton(uidSearch)"
                      >
                        OK
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="isStatusSearch && isMobile()"

                  class="table-block-header-filters-mobile"
                >
                  <div
                    v-click-outside="handleClickOutside"
                    class="table-block-header-filters-mobile-select"
                  >
                    <div class="title">
                      Status filter
                    </div>
                    <div class="searchlist">
                      <div
                        v-for="(item, index) in ExchangeStatusList"
                        :key="index"
                        class="table-block-header-filters-dropsearch-login"
                      >
                        <div class="table-block-header-filters-dropsearch-login-block">
                          <div
                            class="item"
                            @click="statusHandler(item.status)"
                          >
                            <label
                              :for="'item-checkbox' + index"
                            >
                              <input
                                :id="'item-checkbox' + index"
                                v-model="statuses"
                                class="checkbox"
                                type="checkbox"
                                :value="item.status"
                              >
                              <span class="item-text">
                                {{ getExchangeStatus(item.status).name }}
                              </span>
                            </label>
                            <div
                              class="circle"
                              :class="{
                                remaining: getExchangeStatus(item.status).className === 'remaining to send funds',
                                pay: getExchangeStatus(item.status).className === 'pay',
                                exchange: getExchangeStatus(item.status).className === 'exchange',
                                withdrawal: getExchangeStatus(item.status).className === 'withdrawal',
                                completed: getExchangeStatus(item.status).className === 'completed',
                                overdue: getExchangeStatus(item.status).className === 'overdue',
                                returned: getExchangeStatus(item.status).className === 'returned',
                                hold: getExchangeStatus(item.status).className === 'hold',
                                profit: getExchangeStatus(item.status).className === 'profit',
                              }"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ref="timer"
                class="table-block-header-filters-timer"
              >
                <div class="table-block-header-filters-timer_block">
                  <div
                    class="icon"
                    @click="timerRefresh()"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4068_2960)">
                        <path
                          d="M9.16667 6.66667V10.8333L12.7083 12.9333L13.35 11.8667L10.4167 10.125V6.66667H9.16667ZM17.5 8.33333V2.5L15.3 4.7C13.95 3.34167 12.075 2.5 10 2.5C5.85833 2.5 2.5 5.85833 2.5 10C2.5 14.1417 5.85833 17.5 10 17.5C14.1417 17.5 17.5 14.1417 17.5 10H15.8333C15.8333 13.2167 13.2167 15.8333 10 15.8333C6.78333 15.8333 4.16667 13.2167 4.16667 10C4.16667 6.78333 6.78333 4.16667 10 4.16667C11.6083 4.16667 13.0667 4.825 14.125 5.875L11.6667 8.33333H17.5Z"
                          fill="#0A68F7"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4068_2960">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div
                    class="minutes"
                    @click="timerHandler()"
                  >
                    {{ selectedTimer.name }}
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.40372 4.19995H8.59628C8.95551 4.19995 9.13512 4.65889 8.88084 4.92785L6.28556 7.67508C6.12815 7.84158 5.87185 7.84158 5.71444 7.67508L3.11916 4.92785C2.86488 4.65889 3.0445 4.19995 3.40372 4.19995Z"
                        fill="#9FA2B4"
                      />
                    </svg>
                  </div>
                </div>
                <div class="timer-progress">
                  <LvProgressBar
                    v-if="selectedTimer.interval"
                    :value="timerValue"
                    :show-value="false"
                    color="#C7C9DA"
                  />
                </div>
                <div
                  v-if="timerHandle"
                  class="dropdown"
                >
                  <div
                    v-for="(item, index) in dropdownItems"
                    :key="index"
                    class="dropdown-item"
                    @click="selectTimerValue(item)"
                  >
                    {{ item.name }}

                    <svg
                      v-if="selectedTimer.name === item.name"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4068_2939)">
                        <path
                          d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                          fill="#0A68F7"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4068_2939">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="table-block-table"
          >
            <Transition name="committee">
              <table
                class="table"
              >
                <thead>
                  <tr class="table-headers">
                    <th
                      class="table-first"
                      scope="col"
                    >
                      Info
                    </th>
                    <th
                      v-for="(item, index) in tableTh"
                      :id="item.id"
                      :key="index"
                      scope="col"
                      :class="'table-' + item.key"
                    >
                      <div
                        class="text"
                        @click="sortData(item)"
                        @mouseover="tokenTo = index"
                        @mouseleave="tokenTo = null"
                      >
                        {{ item.name }}
                        <span v-if="item.isSortable">
                          <sort-passive v-if="!item.sort" />
                          <sort-a-z v-if="item.sort === 'ASC'" />
                          <sort-z-a v-if="item.sort === 'DESC'" />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="exchangesData.length">
                  <ExchangesListItem
                    v-if="exchangesData.length"
                    :exchanges-list="exchangesData"
                  />
                </tbody>
              </table>
            </Transition>
            <Transition name="committee">
              <no-data-block v-if="!exchangesData?.length && !isLoading" />
            </Transition>
          </div>
        </div>
        <paged-pagination
          v-if="exchangesData?.length && !isLoading"
          :total="Exchanges?.maxPage"
          :current="page"
          @change-page="pageHandler"
          @prev-page="pageHandler"
          @next-page="pageHandler"
        />
      </div>
      <exchange-info
        v-if="ExchangeData"
        :data="ExchangeData"
        :logs="StatusActivity"
        route-to-page="Exchanges"
        :route-to="backPath"
      />
      <transition name="committee">
        <CopyNotification
          v-if="isUpdate"
          :text="updateText"
        />
      </transition>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/admin/AccountLayout.vue";
import ExchangesListItem from "./partials/ExchangesListItem";
import filterMixin from "../../mixins/filter.mixin";
import SortZA from "../../icons/sorting/sort-z-a";
import SortAZ from "../../icons/sorting/sort-a-z";
import SortPassive from "../../icons/sorting/sort-passive";
import FilterIcon from "../../icons/filterIcon";
import {mapGetters} from 'vuex';
import ExchangeInfo from "./partials/ExchangeInfo";
import CloseIcon from "../../icons/closeIcon";
import {STATUSES, TABLE_TH} from "./exchangeData";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import OrderService from "@/services/order.service";
import PagedPagination from "../../components/admin/PagedPagiantion.vue";
import helperMixin from "../../mixins/helper.mixin";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import LvProgressBar from "lightvue/progress-bar";
import CopyNotification from "@/pages/notification/CopyNotification";
import vClickOutside from 'click-outside-vue3'

export default {
  name: "ExchangesList",
  components: {
    CopyNotification,
    LvProgressBar,
    NoDataBlock,
    PagedPagination,
    CloseIcon,
    Loading,
    ExchangeInfo,
    FilterIcon,
    SortPassive,
    SortAZ,
    SortZA,
    AccountLayout,
    ExchangesListItem,
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      isStatusSearch: false,
      exchangesData: [],
      tokenTo: false,
      statuses: [],
      uid: null,
      tableTh: TABLE_TH,
      ExchangeStatusList: STATUSES,
      page: 1,
      isLoading: true,
      fullPage: true,
      searchPlaceHolder: 'Search uid',
      isShowUid: false,
      isSearch: false,
      updateText: '',
      // reloadTimer: false,
      uidSearch: '',
      isUpdate: false,
      timerHandle: false,
      maxPages: null,
      dropdownItems: [
        {name: "Off", interval: null},
        {name: "30s", interval: 30000},
        {name: "1m", interval: 60000},
        {name: "2m", interval: 120000},
        {name: "5m", interval: 300000},
        {name: "15m", interval: 900000}
      ],
      selectedTimer: {name: "Off", interval: null},
      timerId: null,
      timerValue: 0,
      backPath: null,
    }
  },
  computed: {
    ...mapGetters({
      ExchangeData: 'getExchangeData',
      ExchangeIpList: 'getIpList',
      IsExchangeModal: 'getIsExchangeModal',
      StatusActivity: 'getOrderStatusActivityData'
    }),
    Exchanges: {
      get() {
        return this.$store.state.exchangesList;
      },
      set(value) {
        this.$store.commit('setExchangesAll', value);
      }
    },
  },
  watch: {
    ExchangeData() {
      this.emitter.on('exchanges-list-refresh', (data) => {
        this.getList(data);
      })
    },
  },
  mounted() {
    this.mountList();
    this.hideDropDownWhenClick();

    if (this.$route.params.id) {
      this.getOrderAdminById();
    }
    this.emitter.on('exchange-multi-info', (data) => {
      this.backPath = data;
    });
    document.addEventListener('click', this.timerClickOutside);
  },
  beforeUnmount() {
    clearInterval(this.timerId);
    clearInterval(this.interval);
    document.removeEventListener('click', this.timerClickOutside);
  },
  methods: {
    getList(query) {
      this.endProgress();

      this.$store.dispatch('getOrderList', query).then(() => {
        this.exchangesData = this.Exchanges.result;
        this.isLoading = false;
        if (this.selectedTimer.interval) {
          this.timerValue = 0;
          this.startProgress()
        }
      });

    },
    mountList() {
      const query = this.$route.query;
      if (query.status) {
        this.statuses.push(query.status);
        this.mountStatus()
      }

      if (query.orderMode) {
        this.toggleSort({
          key: this.$route.query.orderColumn,
          sort: this.$route.query.orderMode === 'ASC' ? 'DESC' : 'ASC',
        });
      }

      if (query.uid) {
        this.uid = query.uid;
        this.isShowUid = true;
      }

      if (query.page) {
        this.page = parseInt(query.page);
      } else {
        const query = this.$route.query;
        query.page = this.page;
      }

      this.getListByQuery(query);
    },
    mountStatus() {
      let status = this.getStatuses();

      if (status) {
        this.statuses = status.map((e) => parseInt(e));
      }
    },
    getOrderAdminById() {
      OrderService.getOrderAdminById(this.$route.params.id).then((res) => {
        this.$store.commit('setExchangeData', res.data.result);
      })
    },
    handleClickOutside() {
      if (this.isStatusSearch) {
        this.isStatusSearch = false
      }
    },
    getStatuses() {
      let status = this.$route.query.status;

      if (!status) {
        return null;
      }

      return Array.isArray(status) ? status : [status];
    },
    onUpdateHandler() {
      this.updateText = 'Pages refresh';

      this.isUpdate = true;

      setTimeout(() => {
        this.isUpdate = false
      }, 2000);
    },
    sortData(type) {
      this.toggleSort(type);

      switch (type.key) {
        case 'id':
        case 'date':
        case 'status':
        case 'partner':
          let query = {
            page: this.page,
            orderMode: type.sort,
            orderColumn: type.key,
          };
          const status = this.getStatuses();

          if (status) {
            query.status = status;
          }

          this.$router.push({query: query}).then(() => {
            this.getList(this.$route.query);
          });
          break;
      }

      this.exchangesData = this.Exchanges.result;
    },
    toggleSort(currentSort = {}) {
      this.tableTh = this.tableTh.map((item) => {
        if (currentSort.key === item.key) {
          item.sort = currentSort.sort === 'ASC' ? 'DESC' : 'ASC';
        }

        return item;
      });
    },
    startProgress() {
      this.timerValue = 0;
      this.interval = setInterval(() => {
        this.timerValue += (100 / (this.selectedTimer.interval / 1000));
        if (this.timerValue >= 100) {
          this.timerValue = 0;
          clearInterval(this.interval); // Зупинка інтервалу
        }
      }, 1000);
    },
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    selectTimerValue(item) {
      this.selectedTimer = item;
      clearInterval(this.timerId);

      if (item.interval) {
        this.startProgress();
        this.timerId = setInterval(this.timerRefresh, item.interval);
      } else {
        this.timerValue = 0
        this.endProgress();
      }
      this.timerHandler();
    },
    timerClickOutside(event) {
      if (this.$refs.timer && !this.$refs.timer.contains(event.target)) {
        this.timerHandle = false
      }
    },
    timerHandler() {
      this.timerHandle = !this.timerHandle
    },
    timerRefresh() {
      if (this.$route.name !== 'Exchanges retro') {
        this.endProgress();
        this.startProgress()
        return;
      }
      this.endProgress();

      this.$store.dispatch('getOrderList', this.$route.query).then(() => {
        this.exchangesData = this.Exchanges.result;
        if (this.selectedTimer.interval) {
          this.timerValue = 0;
          this.startProgress()
        }
        this.onUpdateHandler();
      });
    },
    searchLoginHandler(el) {
      let query = {page: 1};

      if (el.target.value) {
        this.uidSearch = el;
        query.uid = el.target.value;
        this.isSearch = true
      } else {
        this.isSearch = false
      }

      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      query.page = 1;

      this.$router.push({query: query}).then(() => {
        this.uid = el.target.value;
        this.isShowUid = true;

        this.getList(query);

        this.uidSearch = ''
      });
    },
    searchLoginHandlerButton(el) {
      let query = {
        uid: null,
      };

      if (el) {
        this.uidSearch = el;
        query.uid = el;
        this.isSearch = true

        if (this.$route.query.status) {
          query.status = this.statuses[0];
        }

        query.page = 1;

        this.$router.push({query: query}).then(() => {
          this.uid = el;
          this.isShowUid = true;

          this.getList(query);
          el = ''
          this.uidSearch = ''
        });
      } else {
        this.isSearch = false
      }
    },
    searchStatusToggle() {
      this.isStatusSearch = !this.isStatusSearch;
    },
    statusHandler(status) {
      this.isStatusSearch = false;
      this.page = 1;
      let query = {page: this.page};

      this.statuses.push(status);

      query = {page: this.page, status: status};

      if (this.$route.query.uid) {
        query.uid = this.uid;
      }

      this.$router.push({query: query}).then(() => {
        this.getList(query);
        this.mountStatus()
      });
    },
    clearStatuses() {
      this.statuses = [];
      this.page = 1;

      let query = {page: this.page};

      if (this.$route.query.uid) {
        query.uid = this.uid;
      }

      this.getListByQuery(query);
    },
    uidHandler() {
      this.uid = null;
      this.isShowUid = false;

      this.page = 1;
      let query = {page: this.page};

      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      this.getListByQuery(query);
    },
    pageHandler(page) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      this.page = page;

      const query = {page: page};

      if (this.$route.query.status) {
        query.status = this.statuses[0];
      }

      this.getListByQuery(query);
    },
    getListByQuery(query) {
      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
    },
    hideDropDownWhenClick() {
      document.addEventListener('click', (event) => {
        const el = document.querySelector('.eventBlock');
        const elBtn = document.querySelector('.eventBtn');

        if (el && elBtn) {
          const isEl = el.contains(event.target);
          const isELBtn = elBtn.contains(event.target);
          if (!isEl && !isELBtn) {
            this.isStatusSearch = false;
          }
        }
      });
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/pages/exchanges/exchanges";
@import "../../assets/styles/components/filter-dropdown";
</style>
