const StatusCode = {
    Unauthorized: 401,
    Forbidden: 403,
    TooManyRequests: 429,
    InternalServerError: 500,
}

export function handleResponseError(error) {
    if (error.response) {
        const status = error.response.status;

        switch (status) {
            case StatusCode.InternalServerError: {

                break;
            }
            case StatusCode.Forbidden: {
                // Handle Forbidden
                break;
            }
            case StatusCode.Unauthorized: {
                localStorage.token = '';
                location.href = '/login';
                break;
            }
            case StatusCode.TooManyRequests: {
                // Handle TooManyRequests
                break;
            }
        }

        return Promise.reject(error);
    }
}
