<template>
  <tr
    v-for="(exchange, index) in exchangesList"
    :key="index"
    class="table-row"
  >
    <td
      ref="startColumn"
      class="table-cell table-buttons"
    >
      <div class="info">
        <div
          v-if="exchange.status && allowStatuses.includes(exchange.status)"
          class="info-refresh"
          @click="recalculateOrder(exchange)"
        >
          <start-icon />
        </div>
        <div
          class="info-deposit"
          @click="exchangeInfo(exchange.id)"
        >
          <info-icon />
        </div>
      </div>
    </td>
    <td
      class="table-cell table-id"
    >
      {{ exchange.id }}
    </td>
    <td class="table-cell table-uid">
      {{ exchange.uid }}
    </td>
    <td class="table-cell table-from">
      {{ exchange.from.shotName }}
    </td>
    <td class="table-cell table-to">
      {{ exchange.to.shotName }}
    </td>
    <td class="table-cell table-mode">
      {{ exchange.modeCurs }}
      <span v-if="exchange.modeCurs === null">floating</span>
    </td>
    <td class="table-cell table-amount">
      <div
        class="amountData"
        :class="{'amount-save': exchange.id + 'a' === currentExchangeId}"
      >
        <label :for="'amount' + exchange.id">
          <input
            :id="'amount' + exchange.id"
            v-model="exchange.amount"
            type="number"
            @keydown="amountHandler($event, exchange.id + 'a')"
            @click="amountHandler($event, exchange.id + 'a')"
          >
        </label>
        <span
          class="save"
          @click="updateOrder(exchange)"
        >
          OK
        </span>
      </div>
    </td>
    <td class="table-cell table-userAmount">
      <div
        class="amountData"
        :class="{'amount-save': exchange.id + 'r' === currentExchangeId}"
      >
        <label :for="'amountResult' + exchange.id">
          <input
            :id="'amountResult' + exchange.id"
            v-model="exchange.amountResult"
            type="number"
            @keydown="amountHandler($event, exchange.id + 'r')"
            @click="amountHandler($event, exchange.id + 'r')"
          >

        </label>
        <span
          class="save"
          @click="updateOrder(exchange, false)"
        >
          OK
        </span>
      </div>
    </td>
    <td
      class="table-cell table-ip"
    >
      {{ exchange.ipAddress }}
    </td>
    <td class="table-cell table-aml">
      <svg
        v-if="!exchange.amlCheck && exchange.amlCheck !== 0"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3936_3759)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.33594 4.16669L10.0026 1.66669L16.6693 4.16669V9.24169C16.6693 13.45 13.8276 17.375 10.0026 18.3334C6.1776 17.375 3.33594 13.45 3.33594 9.24169V4.16669ZM7.91927 9.58335C7.91927 10.2737 7.35963 10.8334 6.66927 10.8334C5.97891 10.8334 5.41927 10.2737 5.41927 9.58335C5.41927 8.893 5.97891 8.33335 6.66927 8.33335C7.35963 8.33335 7.91927 8.893 7.91927 9.58335ZM11.2526 9.58335C11.2526 10.2737 10.693 10.8334 10.0026 10.8334C9.31225 10.8334 8.7526 10.2737 8.7526 9.58335C8.7526 8.893 9.31225 8.33335 10.0026 8.33335C10.693 8.33335 11.2526 8.893 11.2526 9.58335ZM13.3359 10.8334C14.0263 10.8334 14.5859 10.2737 14.5859 9.58335C14.5859 8.893 14.0263 8.33335 13.3359 8.33335C12.6456 8.33335 12.0859 8.893 12.0859 9.58335C12.0859 10.2737 12.6456 10.8334 13.3359 10.8334Z"
            fill="#9FA2B4"
          />
        </g>
        <defs>
          <clipPath id="clip0_3936_3759">
            <rect
              width="20"
              height="20"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
      <svg
        v-if="exchange.nodeStatus === 'on hold' || exchange.nodeStatus === 'refunded'"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3936_3766)">
          <path
            d="M10.0026 1.66669L3.33594 4.16669V9.24169C3.33594 13.45 6.1776 17.375 10.0026 18.3334C13.8276 17.375 16.6693 13.45 16.6693 9.24169V4.16669L10.0026 1.66669ZM12.9193 11.7417L11.7443 12.9167L10.0026 11.1834L8.26094 12.9167L7.08594 11.7417L8.8276 10L7.08594 8.25835L8.26094 7.08335L10.0026 8.82502L11.7443 7.08335L12.9193 8.25835L11.1859 10L12.9193 11.7417Z"
            fill="#FF783E"
          />
        </g>
        <defs>
          <clipPath id="clip0_3936_3766">
            <rect
              width="20"
              height="20"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
      <svg
        v-if="exchange.amlCheck !== null && exchange.nodeStatus !== 'on hold' && exchange.nodeStatus !== 'refunded'"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3936_3754)">
          <path
            d="M10.0026 1.66669L3.33594 4.16669V9.24169C3.33594 13.45 6.1776 17.375 10.0026 18.3334C13.8276 17.375 16.6693 13.45 16.6693 9.24169V4.16669L10.0026 1.66669ZM9.11927 12.95L6.16927 10L7.34427 8.82502L9.11094 10.5917L12.6443 7.05835L13.8193 8.23335L9.11927 12.95Z"
            fill="#02C076"
          />
        </g>
        <defs>
          <clipPath id="clip0_3936_3754">
            <rect
              width="20"
              height="20"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </td>
    <td class="table-cell table-partnerLogin">
      {{ exchange.partnerName }}
    </td>
    <td
      class="table-cell table-date"
    >
      {{ dataFormatting(exchange.createDate) }}
    </td>
    <td
      class="table-cell table-status"
      :class="[currentIndex === exchange.id ? statusClass: '']"
    >
      <div class="status-btns">
        <!--        <SelectStatusExchange-->
        <!--          v-if="ExchangeStatusList"-->
        <!--          :options="ExchangeStatusList"-->
        <!--          :value-input="exchange.status"-->
        <!--          class="select"-->
        <!--          page="binance"-->
        <!--          :data="exchange"-->
        <!--          @change-select="changeStatus"-->
        <!--        />-->


        <select-status-popup-exchange
          v-if="ExchangeStatusList"
          :options="ExchangeStatusList"
          :value-input="exchange.status"
          class="select select-popup select-popup-item"
          page="binance"
          :data="exchange"
          @change-select="changeStatus"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import OrderService from "../../../services/order.service";
import {mapGetters} from 'vuex';
import {ALLOW_STATUS, STATUSES} from "../exchangeData";
import helperMixin from "../../../mixins/helper.mixin";
import InfoIcon from "../../../icons/infoIcon";
import StartIcon from "../../../icons/startIcon";
// const moment = require('moment-timezone');

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import SelectStatusPopupExchange from "@/pages/exchanges/partials/SelectStatusPopupExchange.vue";

export default {
  name: "ExchangesListItem",
  components: {SelectStatusPopupExchange, StartIcon, InfoIcon},
  mixins: [
    helperMixin
  ],
  props: {
    exchangesList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dateFormat: 'DD.MM.YY HH:mm',
      dateFormatSec: 'DD.MM.YY HH:mm:ss',
      currentProfit: null,
      currentPartnerProfit: null,
      currentIndex: null,
      currentExchangeId: null,
      statusClass: '',
      allowStatuses: ALLOW_STATUS,
      isRecalcLoader: false,
      ExchangeStatusList: STATUSES,
      scrollPosition: false,
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.hideSaveButton();
    this.scrollHandler();
  },
  methods: {
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-id');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 100) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    inputResize(el) {
      el.target.style.width = (el.target.value.length + 9 ) + 'ch';
    },
    exchangeInfo(exchangeId) {
      OrderService.getOrderAdminById(exchangeId).then((res) => {
        this.$store.commit('setExchangeData', res.data.result);
        this.emitter.emit('exchange-multi-info', this.$route.query)
        document.body.classList.add('modal-open');
        this.$router.push({
          name: 'Exchange modal',
          params: {
            id: exchangeId,
          }
        })
      })

      OrderService.getOrderActivity(exchangeId).then((res) => {
        this.$store.commit('setOrderStatusActivityData', res.data.result);
      })
      this.emitter.emit('exchange-binance-timer', false)
    },
    amountHandler(el, exchangeId) {
      this.inputResize(el);
      this.currentExchangeId = exchangeId;
    },
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormatSec)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    changeStatus(data) {
      this.setNotification({name: 'ID ' + data.data.id + ', status has been changed from ' + data.option.name, value: data.option.name});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.updateOrderStatusById(data.data.id, {status: data.option.status}).then(() => {
            this.currentIndex = data.data.id;
            this.statusClass = 'status-success';
            setTimeout(() => {
              this.statusClass = '';
            }, 3000);
            this.$store.commit('setNotification', {});
          }).catch(() => {
            this.statusClass = 'status-error';
            this.$store.commit('setNotification', {name: 'Error', value: 'status: ' + data.option.name});
          });
        }
      });

      this.emitter.emit('exchange-binance-timer', true)
    },
    depositHandler(deposit) {
        return deposit !== null
    },
    updateOrder(exchange, isAmount = true) {
      this.currentExchangeId = exchange.id;

      const partnerFee = parseFloat(exchange.partnerFee);
      const companyFee = parseFloat(exchange.companyFee);

      const order = {
        amount: exchange.amount,
        amountResult: exchange.amountResult,
        status: exchange.status,
        partnerFee: isNaN(partnerFee) ? 0 : partnerFee,
        companyFee: isNaN(companyFee) ? 0 : companyFee,
      };
      const currentValue = isAmount ? exchange.amount : exchange.amountResult;
      this.setNotification({name: 'Update amount', value: currentValue});

      this.emitter.off('update-notification');

      OrderService.editOrderById(exchange.id, order).then(() => {
        this.currentExchangeId = null;
        this.currentIndex = exchange.id;
        this.statusClass = 'status-success';
        setTimeout(() => {
          this.statusClass = '';
          this.closeModal();
        }, 3000);
        this.setNotification({});
      }).catch(() => {
        this.statusClass = 'status-error';
        this.setNotification({name: 'Error'});
      });
    },
    closeModal() {
      this.$store.commit('setExchangeData', null);
      this.emitter.emit('exchange-binance-timer', false)
    },
    recalculateOrder(data) {
      this.setNotification({name: 'Update', value: 'Recalc '});
      this.isRecalcLoader = true;

      setTimeout(() => {
        OrderService.orderRecalc(data.id).then((res) => {
          if (res.data.result === 'OK') {
            this.isRecalcLoader = false;
            this.setNotification({});
          }
        }).catch(() => {
          this.setNotification({name: 'Error', value: 'status: '});
        })

      }, 3000);
    },
    hideSaveButton() {
      document.addEventListener('click', (event) => {
        const el = document.querySelectorAll('.amountData');
        let isEl = false;

        if (el) {
          el.forEach((item) => {
            let isContainsEl = item.contains(event.target);

            if (isContainsEl) {
              isEl = true;
            }
          });
          if (!isEl) {
            this.currentExchangeId = null;
          }
        }
      });
    }
  }
}
</script>

<style lang="scss">
</style>
