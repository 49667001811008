<script>
export default {
  name: "MirrorsIcon"
}
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4686_85535)">
      <path
        d="M12.5 17.5006H14.1667V15.834H12.5V17.5006ZM15.8333 7.50065H17.5V5.83398H15.8333V7.50065ZM2.5 4.16732V15.834C2.5 16.7506 3.25 17.5006 4.16667 17.5006H7.5V15.834H4.16667V4.16732H7.5V2.50065H4.16667C3.25 2.50065 2.5 3.25065 2.5 4.16732ZM15.8333 2.50065V4.16732H17.5C17.5 3.25065 16.75 2.50065 15.8333 2.50065ZM9.16667 19.1673H10.8333V0.833984H9.16667V19.1673ZM15.8333 14.1673H17.5V12.5007H15.8333V14.1673ZM12.5 4.16732H14.1667V2.50065H12.5V4.16732ZM15.8333 10.834H17.5V9.16732H15.8333V10.834ZM15.8333 17.5006C16.75 17.5006 17.5 16.7506 17.5 15.834H15.8333V17.5006Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4686_85535">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
