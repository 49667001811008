<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import {PerfectScrollbar} from 'vue3-perfect-scrollbar'
import OrderService from "@/services/order.service";
import TradeLoader from "@/components/trade/components/TradeLoader.vue";
import CopyNotification from "@/pages/notification/CopyNotification.vue";
import dayjs from "dayjs";

export default {
  name: "ReportGenerator",
  components: {CopyNotification, TradeLoader, AccountLayout, PerfectScrollbar},
  directives: {
    autosize: {
      mounted(el) {
        el.style.height =  '40px';
        el.addEventListener('input', function () {
          el.style.height = '40px';
          el.style.height = el.scrollHeight + 'px';
        });
      }
    }
  },
  data() {
    return {
      inputSend: '',
      orders: [],
      isLoading: false,
      isSuccessCopy: false,
      copyText: '',
      formatterDate: 'DD.MM.YYYY hh:mm'
    }
  },
  computed: {
    formattedReport() {
      return this.orders.map(order => this.formatOrderForDisplay(order)).join('\n\n');
    }
  },
  methods: {
    dateFormatter(value) {
      return dayjs(value).format(this.formatterDate)
    },
    generateData() {
      this.isLoading = true;
      const query = '?ids=' + this.inputSend;
      OrderService.generateAbuse(query)
          .then((res) => {
            console.log(JSON.stringify(res.data.result));
            this.orders = res.data.result;
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    formatOrderForDisplay(order) {
      const date = this.dateFormatter(order.createDate);
      const formattedDate = `<span style="font-weight: 600;word-wrap: anywhere;">${date} UTC 0</span>`;
      const formattedAmount = `<span style="font-weight: 600;word-wrap: anywhere;">${order.amount.toFixed(6)}</span> ${order.fromName}`;
      const formattedAmountResult = `<span style="font-weight: 600;word-wrap: anywhere;">${order.amountResult.toFixed(6)}</span> ${order.toName}`;
      const formattedHashIn = order.depositHash ? `Hash in: <span style="font-weight: 600;word-wrap: anywhere;">${order.depositHash}</span>` : '';
      const formattedHashOut = order.withdrawalHash ? `Hash out: <span style="font-weight: 600;word-wrap: anywhere;">${order.withdrawalHash}</span>` : '';
      const formattedAddressFrom = `Address from: <span style="font-weight: 600;word-wrap: anywhere;">${order.addressFrom}</span>`;
      const formattedAddressTo = `Address to: <span style="font-weight: 600;word-wrap: anywhere;">${order.addressTo}</span>`;
      const formattedIP = `IP: <span style="font-weight: 600;">${order.ipAddressOrder}</span>`;
      const formattedId = `<span style="font-weight: 600;word-wrap: anywhere;">#ID${order.orderID}</span>`;
      const formattedUid = `Multi UID: <span style="font-weight: 600;word-wrap: anywhere;">${order.multiUID}</span>`;
      const formattedUserAgent = `User agent: <span style="font-weight: 600;word-wrap: anywhere;">${order.userAgentOrder}</span>`;

      const formattedString = `
${formattedDate}
${formattedId}
${formattedUid}
Amount: ${formattedAmount}
Amount result: ${formattedAmountResult}
${formattedHashIn ? formattedHashIn: ''}
${formattedHashOut ? formattedHashOut  : ''}
${formattedAddressFrom}
${formattedAddressTo}
${formattedIP}
${formattedUserAgent}
      `.split('\n').filter(line => line.trim()).join('\n');

      return formattedString.trim();
    },
    formatOrderForCopy(order) {
      const date = this.dateFormatter(order.createDate);
      const formattedDate = `**${date} UTC 0**`;
      const formattedAmount = `**${order.amount.toFixed(6)}** ${order.fromName}`;
      const formattedAmountResult = `**${order.amountResult.toFixed(6)}** ${order.toName}`;
      const formattedHashIn = order.depositHash ? `Hash in: **${order.depositHash}**` : '';
      const formattedHashOut = order.withdrawalHash ? `Hash out: **${order.withdrawalHash}**` : '';
      const formattedAddressFrom = `Address from: **${order.addressFrom}**`;
      const formattedAddressTo = `Address to: **${order.addressTo}**`;
      const formattedIP = `IP: **${order.ipAddressOrder}**`;
      const formattedId = `**#ID${order.orderID}**`;
      const formattedUid = `Multi UID: **${order.multiUID}**`;
      const formattedUserAgent = `User agent: **${order.userAgentOrder}**`;

      const formattedString = `
${formattedDate}
${formattedId}
${formattedUid}
Amount: ${formattedAmount}
Amount result: ${formattedAmountResult}
${formattedHashIn ? formattedHashIn : ''}
${formattedHashOut ? formattedHashOut : ''}
${formattedAddressFrom}
${formattedAddressTo}
${formattedIP}
${formattedUserAgent}
      `.split('\n').filter(line => line.trim()).join('\n');

      return formattedString.trim();
    },
    copyReportToClipboard() {
      const formattedReportForCopy = this.orders.map(order => this.formatOrderForCopy(order)).join('\n\n');
      if (formattedReportForCopy) {
        navigator.clipboard.writeText(formattedReportForCopy).then(() => {
          this.onCopy();
        })
      }
    },
    resetData() {
      this.orders = [];
      this.inputSend = '';
    },
    onCopy() {
      this.copyText = 'Report copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
  }
}
</script>

<template>
  <AccountLayout>
    <template #title>
      <h2>Report generator</h2>
    </template>
    <template #content>
      <div
        class="report-container"
        :class="{'report-container_with-content': orders?.length}"
      >
        <div class="report-block">
          <perfect-scrollbar class="transactions border-right">
            <div class="title">
              Transaction IDs
            </div>

            <div class="desc">
              IDs
            </div>
            <div class="input">
              <textarea
                v-model="inputSend"
                v-autosize
              />
            </div>
          </perfect-scrollbar>
          <div class="report">
            <div class="title">
              Report
            </div>
            <div class="report-data">
              <div
                v-if="!orders?.length && !isLoading"
                class="not-loaded"
              >
                The generated report will be here
              </div>
              <div
                v-if="isLoading && !orders?.length"
                class="loader"
              >
                <TradeLoader />
              </div>
              <div
                v-if="!isLoading && orders?.length"
                class="loaded"
              >
                <div class="desc">
                  Generated report
                </div>
                <perfect-scrollbar class="report-data-result">
                  <div class="text">
                    <pre v-html="formattedReport" />
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </div>
        <div class="report-block report-block_actions">
          <div class="actions">
            <div class="buttons">
              <div
                class="main_button"
                @click="generateData"
              >
                Generate
              </div>
              <div
                class="main_button main_button_inverse reset"
                @click="resetData"
              >
                Reset
              </div>
            </div>
          </div>
          <div
            class="main_button copy"
            @click="copyReportToClipboard"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4798_158)">
                <path
                  d="M8 1H20C21.1 1 22 1.9 22 3V17H20V3H8V1ZM5 5H16C17.1 5 18 5.9 18 7V21C18 22.1 17.1 23 16 23H5C3.9 23 3 22.1 3 21V7C3 5.9 3.9 5 5 5ZM5 21H16V7H5V21Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_4798_158">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="matrix(-1 0 0 1 24 0)"
                  />
                </clipPath>
              </defs>
            </svg>

            Copy report
          </div>
        </div>
      </div>
      <CopyNotification
        v-if="isSuccessCopy"
        :text="copyText"
      />
    </template>
  </AccountLayout>
</template>

<style scoped lang="scss">
.report {
  &-container {
    width: 100%;
    max-width: 1122px;
    min-height: 400px;
    max-height: 660px;
    border-radius: 16px;
    border: 1px solid var(--grayscale-divider, #DFE0EB);
    background: var(--grayscale-white, #FFF);
    display: flex;
    flex-direction: column;

    &_with-content {
      height: 100%;
    }
  }

  &-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;

    .border-right {
      border-right: 1px solid var(--grayscale-divider, #DFE0EB);
    }

    &_actions {
      padding: 17px 24px 24px 24px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-top: 1px solid var(--grayscale-divider, #DFE0EB);
      height: unset;

      .actions, .copy {
        width: 100%;
      }
    }

    .transactions, .report {
      width: 100%;
      padding: 32px;
      box-sizing: border-box;

      .title {
        color: #1B1A1C;
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 24px;
      }

      .desc {
        color: #0A68F7;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 8px;
      }

      textarea {
        border-radius: 4px;
        border: 1px solid #DFE0EB;
        background: var(--grayscale-white, #FFF);
        width: 100%;
        box-sizing: border-box;
        resize: none;
        padding: 11px 16px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        margin: 0;
        height: 20px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .main_button {
      border-radius: 8px;
      background: #0A68F7;
      max-width: 160px;
      width: 100%;
      height: 46px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: #FFF;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &_inverse {
        background: none;
        color: #0A68F7;
      }
    }


  }

  &-data {
    .loader {
      height: 232px;
    }

    .not-loaded {
      color: #9FA2B4;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    &-result {
      border-radius: 4px;
      border: 1px solid #DFE0EB;
      background: var(--grayscale-white, #FFF);
      padding: 11px 16px 0;

      .text {
        height: 435px;
        color: var(--black, #1B1A1C);
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        pre {
          white-space: preserve-breaks;
          margin: 0;
          color: var(--black, #1B1A1C);
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
