<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>List of exchanges</h2>
        <div class="layout-header_tabs">
          <router-link
            :to="{ name: 'Exchanges retro' }"
            class="layout-header_tabs-item "
          >
            Binance
          </router-link>
          <div class="layout-header_tabs-item layout-header_tabs-item--active">
            1inch
          </div>
          <router-link
            :to="{ name: 'Exchanges Multi' }"
            class="layout-header_tabs-item "
          >
            Multi
          </router-link>
        </div>
      </div>
    </template>
    <template #content>
      <div
        v-if="exchangesData"
        class="exchangesList main-block exchangesList-linch"
      >
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="false"
          color="#0A68F7"
        />
        <div class="table-block">
          <div class="table-block-header">
            <div class="table-block-header-title">
              1inch - exchanges
            </div>
          </div>
          <div
            class="table-block-table"
          >
            <table
              class="table"
            >
              <thead>
                <tr class="table-headers">
                  <th
                    class="table-first"
                    scope="col"
                  >
                    Info
                  </th>
                  <th
                    v-for="(item, index) in tableTh"
                    :id="item.id"
                    :key="index"
                    scope="col"
                    :class="'table-' + item.key"
                  >
                    <div
                      class="text"
                      @click="sortData(item)"
                      @mouseover="tokenTo = index"
                      @mouseleave="tokenTo = null"
                    >
                      {{ item.name }}
                      <span v-if="item.isSortable">
                        <sort-passive v-if="!item.sort" />
                        <sort-a-z v-if="item.sort === 'ASC'" />
                        <sort-z-a v-if="item.sort === 'DESC'" />
                        <transition name="fade">
                          <hover-text
                            v-if="tokenTo === index"
                            :option="item.description"
                          />
                        </transition>
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ExchangesList1inchItem
                  v-if="exchangesData.length"
                  :exchanges-list="exchangesData"
                />
              </tbody>
            </table>
            <no-data-block v-if="!exchangesData.length && !isLoading" />
          </div>
        </div>
        <paged-pagination
          v-if="exchangesData.length && !isLoading && exchangeMaxPage > 1"
          :total="exchangeMaxPage"
          :current="page"
          @change-page="pageHandler"
          @prev-page="pageHandler"
          @next-page="pageHandler"
        />
      </div>
      <ExchangeInfo1inch
        v-if="exchangeData"
        :data="exchangeData"
      />
    </template>
  </AccountLayout>
</template>

<script>

import Loading from 'vue-loading-overlay';
import filterMixin from "@/mixins/filter.mixin";
import helperMixin from "@/mixins/helper.mixin";
import {TABLE_TH_1INCH, STATUSES} from "@/pages/exchanges/sub-pages/1inch/exchange1inchData";
import OrderService from "@/services/order.service";
import AccountLayout from "@/components/admin/AccountLayout.vue";
import SortPassive from "@/icons/sorting/sort-passive";
import SortAZ from "@/icons/sorting/sort-a-z";
import SortZA from "@/icons/sorting/sort-z-a";
import HoverText from "@/pages/coins/partials/hoverText";
import PagedPagination from "@/components/admin/PagedPagiantion.vue";
import ExchangesList1inchItem from "@/pages/exchanges/sub-pages/1inch/partials/ExchangesList1inchItem";
import ExchangeInfo1inch from "@/pages/exchanges/sub-pages/1inch/partials/ExchangeInfo1inch";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";

export default {
  name: "ExchangesList1inch",
  components: {
    NoDataBlock,
    ExchangeInfo1inch,
    ExchangesList1inchItem,
    PagedPagination,
    HoverText,
    SortZA,
    SortAZ,
    Loading,
    SortPassive,
    AccountLayout

  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      isStatusSearch: false,
      exchangesData: [],
      tokenTo: false,
      statuses: [],
      uid: null,
      tableTh: TABLE_TH_1INCH,
      ExchangeStatusList: STATUSES,
      page: 1,
      isLoading: false,
      fullPage: true,
      searchPlaceHolder: 'search uid',
      isShowUid: false,
      exchangeData: null,
      isExchangeInfo: false,
      exchangeMaxPage: null,
      // reloadTimer: false
    }
  },
  mounted() {
    this.mountList();
    this.mountSort();
    this.mountStatus();

    // this.reloadTimer = setInterval(() => {
    //   this.mountList();
    //   this.mountSort();
    //   this.mountStatus();
    // }, 20000);

    this.hideDropDownWhenClick();

    if (this.$route.params.id) {
      this.getOrderAdminById();
    }

    this.emitter.on('exchange-info-event', (data) => {
      this.exchangeData = data
      this.isExchangeInfo = true
    })

    // this.emitter.on('exchange-1inch-timer', (status) => {
    //   if (status === false) {
    //     clearInterval(this.reloadTimer);
    //   }
    //   else {
    //     this.reloadTimer = setInterval(() => {
    //       this.mountList();
    //       this.mountSort();
    //       this.mountStatus();
    //     }, 20000);
    //   }
    // });

  },
  methods: {
    getList(query) {
      this.isLoading = true;

      OrderService.getOrderList1inch(query).then((res) => {
        this.exchangesData = res.data.result.result;
        this.exchangeMaxPage = res.data.result.maxPage;

        this.isLoading = false;
      });
    },
    pageHandler(page) {
      this.page = page;

      this.$router.push({query: {page: this.page, status: this.statuses}}).then(() => {
        this.getList(this.$route.query);
      });
    },
    mountSort() {
      if (this.$route.query.orderMode) {
        this.toggleSort({
          key: this.$route.query.orderColumn,
          sort: this.$route.query.orderMode === 'ASC' ? 'DESC' : 'ASC',
        });
      }
    },
    mountList() {
      if (this.$route.query.uid) {
        this.uid = this.$route.query.uid;
        this.isShowUid = true;
      }

      if (this.$route.query.page) {
        this.page = parseInt(this.$route.query.page);
        this.getList(this.$route.query);
      } else {
        const query = this.$route.query;
        query.page = this.page;
        this.getList(query);
      }
    },
    mountStatus() {
      let status = this.getStatuses();

      if (status) {
        this.statuses = status.map((e) => parseInt(e));
      }
    },
    getStatuses() {
      let status = this.$route.query.status;

      if (!status) {
        return null;
      }

      return Array.isArray(status) ? status : [status];
    },
    hideDropDownWhenClick() {
      document.addEventListener('click', (event) => {
        const el = document.querySelector('.eventBlock');
        const elBtn = document.querySelector('.eventBtn');

        if (el && elBtn) {
          const isEl = el.contains(event.target);
          const isELBtn = elBtn.contains(event.target);
          if (!isEl && !isELBtn) {
            this.isStatusSearch = false;
          }
        }
      });
    }
  }
}
</script>

<style lang="scss">
.table-buttons {
  max-width: 100%;
  width: 5%;
}
//@import "../../../../assets/styles/pages/exchanges/exchanges";
//@import "../../../../assets/styles/components/filter-dropdown";
</style>
