<template>
  <div
    class="exchange-info-form-inputs-block"
  >
    <div
      v-for="(item, index) in data"
      :key="index"
      class="withdrawal"
    >
      <div
        v-if="item.amountUser"
        class="exchange-info-form-inputs-block_items"
      >
        <div class="exchange-info-form-inputs-block-item">
          <div class="desc">
            Amount
          </div>
          <div
            class="input"
          >
            {{ item.amountUser }}
          </div>
        </div>
        <div
          v-if="item.transactionId"
          class="exchange-info-form-inputs-block-item"
        >
          <div class="desc">
            Transaction id
          </div>
          <div
            v-clipboard:copy="item.transactionId"
            v-clipboard:success="onCopyAddress"
            class="input input-disabled"
          >
            {{ hashFormat(item.transactionId) }}
            <copy-icon-gray class="copyIcon" />
          </div>
        </div>
        <div
          v-if="item.txId"
          class="exchange-info-form-inputs-block-item"
        >
          <div class="desc">
            TxId
          </div>
          <div
            v-clipboard:copy="item.txId"
            v-clipboard:success="onCopyAddress"
            class="input input-disabled"
          >
            {{ hashFormat(item.txId) }}
            <copy-icon-gray class="copyIcon" />
          </div>
        </div>
        <div
          v-if="exchange.status === 5 && exchange.binanceAcc === 'mirror'"
          class="exchange-info-form-inputs-block-item exchange-info-form-inputs-block-item_check"
        >
          <div class="exchange-info-form-inputs-block-item_check-block">
            <div class="warning">
              <warning-icon />
              <div class="warning-desc">
                Ускорение депозита после утверждения в чате. инфо
                <a
                  href="https://t.me/ev_swpz"
                  target="_blank"
                >
                  @rad1111k
                </a>
              </div>
            </div>
            <div
              v-if="exchange.to.shortName === 'BTC' || exchange.to.shortName === 'LTC'"
              id="content"
              class="selected-list"
            >
              <div
                class="exchange-info-form-inputs-block-item"
                @click="dropSpeedOptions"
              >
                <div class="desc">
                  Fee options / Speed
                </div>
                <div class="input">
                  <div class="input-text">
                    <div>
                      {{ selectedOption.option }} /
                      <span v-if="selectedOption.option === 'slow'">
                    1 hour
                  </span>
                      <span v-if="selectedOption.option === 'medium'">
                    30 minutes
                  </span>
                      <span v-if="selectedOption.option === 'fast'">
                    10 minutes
                  </span>
                    </div>

                    <span class="input-text_bold">
                  {{ selectedOption.cost }} BTC
                </span>
                  </div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6635_927)">
                      <path
                        d="M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z"
                        fill="#9FA2B4"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6635_927">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div
                v-if="isDropSpeedList"
                id="dropdown"
                class="dropdown"
              >
                <div
                  v-for="(item, index) in speedOptions"
                  :key="index"
                  class="dropdown--item"

                  @click="selectedOption = item; isDropSpeedList = false"
                >
                  <div class="text">
                    <div class="name">
                      {{ item.option }} / <span v-if="selectedOption.option === 'slow'">
                    1 hour
                  </span>
                      <span v-if="selectedOption.option === 'medium'">
                    30 minutes
                  </span>
                      <span v-if="selectedOption.option === 'fast'">
                    10 minutes
                  </span>
                    </div>
                    <div class="desc">
                      {{ item.description }}
                    </div>
                  </div>

                  <div class="value">
                    <div class="value-cost">
                      {{ item.cost }} {{ exchange.from.shortName }}
                    </div>

                    <div class="value-usdt">
                      {{ item.costUsdt }} USDT
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="save"
              @click="confirmBump"
            >
              Confirm
            </div>
          </div>
        </div>
      </div>
    </div>
    <CopyNotification
      v-if="isSuccessCopy"
      :text="copyText"
    />
  </div>
</template>

<script>
import CopyIconGray from "@/assets/img/icons/copyIconGray.vue";
import CopyNotification from "@/pages/notification/CopyNotification.vue";
import WarningIcon from '@/icons/WarningIcon.vue';
import OrderService from '@/services/order.service';
import {mapGetters} from 'vuex';

export default {
  name: "ExchangeInfoWithdrawal",
  components: {WarningIcon, CopyNotification, CopyIconGray},
  props: {
    data: {
      type: Array,
      default: null,
    },
    exchange: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      copyText: '',
      isSuccessCopy: true,
      isDropSpeedList: false,
      speedOptions: [],
      selectedOption: {},
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.getFeeOptions();
  },
  methods: {
    getFeeOptions() {
      OrderService.getFeeOptions().then((res) => {
        this.speedOptions = res.data.result;

        this.selectedOption = this.speedOptions.find((item) => {
          return item.option === 'medium'
        })
      })
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    errorPopup(val) {
      this.$store.commit('setError', {
        name: 'Error',
        value: val,
      });
    },
    confirmBump() {
      if (confirm('Are you sure?')) {
        this.bumpWithdaralOrderNode()
      }
    },
    bumpWithdaralOrderNode() {
      this.setNotification({name: 'ID ' + this.exchange.id + ', bump started'});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.getWithdaralBumpOrder(this.exchange.id, this.selectedOption.option)
            .then((res) => {
              this.successPopup('bump')
            })
            .catch(error => {
              if (error) {
                this.errorPopup( error.response.data.message)
              }
            });
        }
      })
    },
    hashFormat(val) {
      if (val && val.length > 58) {
        return val.slice(0, 55) + '...'
      } else {
        return val
      }
    },
    dropSpeedOptions() {
      this.isDropSpeedList = !this.isDropSpeedList;
    },
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    successPopup(val) {
      this.$store.commit('setNotificationNode', {name: val + ' completed'});
      this.emitter.off('update-notification-node');
      this.emitter.on('update-notification-node', () => {
        this.exchangeInfo(this.exchange.id);
      })
    },
  }
}
</script>

<style scoped>

</style>
