<template>
  <div class="user_ip-list">
    <div
      class="popup"
      tabindex="0"
      @keydown.esc="closePopup"
    >
      <div class="popup-block ">
        <div class="popup-block-form ip_list">
          <div
            class="popup-block-form-title"
          >
            IP list
          </div>

          <div
            class="close"
            @click="closePopup"
          >
            <img
              src="../../../assets/img/close.svg"
              alt=""
            >
          </div>

          <div class="toggles">
            <div class="toggles-item">
              <div class="text">
                Is Active
              </div>
              <Toggle v-model="isIpList" />
            </div>
          </div>

          <div class="inputs">
            <div class="text">
              IP address
            </div>
            <div
              v-if="ipList.length"
              class="ip_address_selected"
            >
              <div
                v-for="(item, index) in ipList"
                :key="index"
                class="ip_address_selected-item"
              >
                <div class="ip_address_selected-item_text">
                  {{ item }}
                </div>
                <close-icon
                  @click="deleteItem(item)"
                />
              </div>
            </div>

            <div
              class="inputs-item"
              :class="{'inputs-item-error': !isIpValid}"
            >
              <input
                v-model="newIp"
                type="text"
                @keyup="validateIpAddress"
                @keyup.enter="addIp()"
              >
              <div
                class="inputs-item_add"
                @click="addIp"
              >
                Confirm
              </div>
            </div>
            <div
              v-if="!isIpValid"
              class="error_text"
            >
              Invalid IP address
            </div>
          </div>

          <div class="buttons">
            <button
              class="cancel"
              @click="closePopup"
            >
              Cancel
            </button>
            <button
              class="save"
              @click="saveIpList"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Toggle from '@vueform/toggle'
import CloseIcon from "@/icons/closeIcon";
import UserService from "@/services/user.service";
import { isIPAddress } from "ip-address-validator";

export default {
  name: "UserIpList",
  components: {
    CloseIcon,
    Toggle,
  },
  data() {
    return {
      ipList: [],
      newIp: '',
      isIpList: false,
      ipListNew: null,
      isIpValid: true,
    }
  },
  computed: {
    ...mapGetters({
      IpListData: 'getIpList',
    }),
  },
  mounted() {
    this.getIpListData();
  },
  methods: {
    getIpListData() {
      if (this.IpListData.ipList) {
        this.ipList = this.IpListData.ipList.split('|');
      }
      this.isIpList = this.IpListData.isIpList;
    },
    closePopup() {
      this.$store.commit('setIpList', null);
      this.$router.push({name: 'Users'})
    },
    validateIpAddress(e) {
      let regex = /[^0-9\.]/g;

      e.target.value = e.target.value.replace(regex, "");
    },
    addIp() {
      if (isIPAddress(this.newIp)) {
        this.ipList.push(this.newIp);
        this.newIp = '';
      } else {
        this.newIp = '';
        this.isIpValid = false
        setTimeout(() => {
          this.isIpValid = true
        },3000)
      }


    },
    deleteItem(ip) {
      this.ipList = this.ipList.filter((item) => item !== ip);
    },
    saveIpList() {
      if (this.ipList) {
        this.ipListNew = this.ipList.join('|');
      }
      UserService.updateIpList(
          {
            id: this.IpListData.id,
            isIpList: this.isIpList,
            ipList: this.ipListNew
          }
      ).then(() => {
        this.closePopup();
        this.$router.go({ path: '/users' })
      })
    }
  },
}
</script>

<style lang="scss">
@import "../../../assets/styles/pages/users/user-ip-list";
</style>
