<template>
  <div
    v-if="exchange"
    class="exchange-info-form-inputs-block"
  >
    <div class="exchange-info-form-inputs-block_items">
      <div
        v-if="data.subAccount"
        class="exchange-info-form-info-block-item"
      >
        <div class="exchange-info-form-info-block-item-block">
          <div class="desc">
            Sub account
          </div>
          <div
            v-if="data.subAccount !== null"
            class="data address"
          >
            <span>{{ data.subAccount }}</span>
          </div>
          <div
            v-if="data.addressTo === null"
            class="data"
          >
            Empty
          </div>
        </div>
      </div>
      <div
        v-if="exchange.addressTo"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          User Address
        </div>
        <div
          v-clipboard:copy="exchange.addressTo"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ exchange.addressTo }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
      <div
        v-if="exchange.addressFrom && !exchange.addressBinanceAddress && !exchange.addressNodeFrom"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          Deposit address
        </div>
        <div
          v-clipboard:copy="exchange.addressFrom"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ exchange.addressFrom }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
      <div
        v-if="exchange.addressBinanceAddress"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          Binance address
        </div>
        <div
          v-clipboard:copy="exchange.addressBinanceAddress"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ exchange.addressBinanceAddress }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
      <div
        v-if="exchange.addressNodeFrom"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          Node address
        </div>
        <div
          v-clipboard:copy="exchange.addressNodeFrom"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ exchange.addressNodeFrom }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
      <div
        v-if="exchange.memoTo"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          MEMO To
        </div>
        <div
          v-clipboard:copy="exchange.memoTo"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ exchange.memoTo }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
      <div
        v-if="exchange.memoFrom"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          MEMO From
        </div>
        <div
          v-clipboard:copy="exchange.memoFrom"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ exchange.memoFrom }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
      <div
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          TxID
        </div>
        <textarea
          v-model="wTxId"
          rows="2"
          style="resize: none;"
          class="exchange-info-form-info-block-item-input_wide"
        />
      </div>
      <div
        v-if="exchange.addressRefund"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          Address refund
        </div>
        <div
          v-clipboard:copy="exchange.addressRefund"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ exchange.addressRefund }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
      <div
        v-if="exchange.extraIdRefund"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          Extra ID refund
        </div>
        <div
          v-clipboard:copy="exchange.extraIdRefund"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ exchange.extraIdRefund }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
      <div
        v-if="exchange.addressUserFrom"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          Address user from
        </div>
        <div
          v-clipboard:copy="exchange.addressUserFrom"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ exchange.addressUserFrom }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
      <div
        v-if="exchange.tormanentBtc"
        class="exchange-info-form-inputs-block-item_rows"
      >
        <div class="exchange-info-form-inputs-block-item">
          <div class="desc">
            BTC turnover
          </div>
          <div
            v-clipboard:copy="exchange.tormanentBtc"
            class="input"
          >
            <span>{{ exchange.tormanentBtc }}</span>
          </div>
        </div>
        <div class="exchange-info-form-inputs-block-item">
          <div class="desc">
            BTC fact fee
          </div>
          <div
            v-clipboard:copy="exchange.profitBtc"
            class="input"
          >
            <span v-if="exchange.profitBtc">{{ exchange.profitBtc }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="exchange-info-form-inputs-block-item exchange-info-form-inputs-block-item_ratio">
          <div class="desc">
            Ratio
          </div>
          <div
            v-clipboard:copy="ratioCalc(exchange.tormanentBtc, exchange.profitBtc ) + '%'"
            class="input"
          >
            <span v-if="exchange.tormanentBtc && exchange.profitBtc">{{ ratioCalc(exchange.tormanentBtc, exchange.profitBtc ) }}%</span>
            <span v-else>-</span>
          </div>
        </div>
      </div>
      <div
        v-if="exchange.tornmanentUsdt"
        class="exchange-info-form-inputs-block-item_rows"
      >
        <div class="exchange-info-form-inputs-block-item">
          <div class="desc">
            USDT turnover
          </div>
          <div
            v-clipboard:copy="exchange.tornmanentUsdt"
            class="input"
          >
            <span>{{ exchange.tornmanentUsdt }}</span>
          </div>
        </div>
        <div class="exchange-info-form-inputs-block-item">
          <div class="desc">
            USDT fact fee
          </div>
          <div
            v-clipboard:copy="exchange.profitUsdt"
            class="input"
          >
            <span v-if="exchange.profitUsdt">{{ exchange.profitUsdt }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="exchange-info-form-inputs-block-item exchange-info-form-inputs-block-item_ratio">
          <div class="desc">
            Ratio
          </div>
          <div
            v-clipboard:copy="ratioCalc(exchange.tornmanentUsdt, exchange.profitUsdt ) + '%'"
            class="input"
          >
            <span v-if="exchange.tornmanentUsdt && exchange.profitUsdt">{{ ratioCalc(exchange.tornmanentUsdt, exchange.profitUsdt ) }}%</span>
            <span v-else>-</span>
          </div>
        </div>
      </div>
      <div
        v-if="exchange.btcUsdtRate"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          BTC rate
        </div>
        <div
          v-clipboard:copy="exchange.btcUsdtRate"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ exchange.btcUsdtRate }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
    </div>
  </div>
  <CopyNotification
    v-if="isSuccessCopy"
    :text="copyText"
  />
</template>

<script>
import CopyIconGray from "@/assets/img/icons/copyIconGray.vue";
import {mapGetters} from "vuex";
import CopyNotification from "@/pages/notification/CopyNotification.vue";

export default {
  name: "ExchangeInfoGeneral",
  components: {CopyNotification, CopyIconGray},
  props: {
    data: {
      type: Object,
      default: null,
    },
    exchange: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      depositData: null,
      copyText: '',
      isSuccessCopy: true,
      wTxId: ''
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  watch: {
    wTxId() {
      this.$emit('wtx-id-updated', this.wTxId);
    },
  },
  mounted() {
    this.wTxId = this.exchange.wTxId;
  },
  methods: {
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    ratioCalc(turnover, profit) {
      const result = (profit / turnover) * 100
      return result.toFixed(3)
    }
  }
}
</script>

<style scoped>

</style>
