<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import DatePicker from "vue-datepicker-next";
import CalendarIcon from "@/icons/calendarIcon.vue";
import AdminService from "@/services/admin.service";
import dayjs from "dayjs";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import Loading from "vue-loading-overlay";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

export default {
  name: "MirrorTurnover",
  components: {Loading, NoDataBlock, CalendarIcon, DatePicker, AccountLayout, PerfectScrollbar},
  data() {
    return {
      date: '',
      datePicker: '',
      currentDate: '',
      dateFormatFull: 'DD.MM.YYYY',
      dateFormatDay: 'HH:mm',
      dateFormatUrl: 'YYYY-MM-DD',
      dateFormatUrlHour: 'YYYY-MM-DD HH:mm:ss',
      turnoverData: null,
      currentIndex: false,
      isLoading: false,
      openedRows: [],
      modeData: '',
    }
  },
  computed: {
    dateText() {
      if (this.date) {
        return this.date;
      } else {
        return "Custom date";
      }
    },
    sortedCoins() {
      return function (coins) {
        const sortOrder = ['BTC', 'USDT', 'ETH', 'TRX', 'XMR'];

        function compareSymbols(a, b) {
          return sortOrder.indexOf(a.symbol.name) - sortOrder.indexOf(b.symbol.name);
        }

        if (coins) {
          const sortedCoins = coins.slice().filter(coin => sortOrder.includes(coin.symbol.name)).sort((coinA, coinB) => compareSymbols(coinA, coinB));
          const otherCoins = coins.filter(coin => !sortOrder.includes(coin.symbol.name));
          return sortedCoins.concat(otherCoins);
        } else {
          return [];
        }
      };
    },
  },
  watch: {
    currentDate(val) {
      this.isLoading = true;
      this.turnoverData = null;
      this.openedRows = [];

      switch (val) {
        case 'range':
          this.date = [
            dayjs(this.datePicker[0]).startOf('day').format(this.dateFormatUrlHour),
            dayjs(this.datePicker[1]).endOf('day').format(this.dateFormatUrlHour),
          ]
          break;
        case 'last24hours':
          this.date = [
            dayjs().endOf('minute').subtract(1, 'day').format(this.dateFormatUrlHour),
            dayjs().endOf('minute').format(this.dateFormatUrlHour)
          ];
          break;
        case 'last48hours':
          this.date = [
            dayjs().endOf('minute').subtract(2, 'day').format(this.dateFormatUrlHour),
            dayjs().endOf('minute').format(this.dateFormatUrlHour)
          ];
          break;
        case 'day':
          this.date = [
            dayjs().startOf('day').format(this.dateFormatUrlHour),
            dayjs().startOf('minute').format(this.dateFormatUrlHour),
          ];
          break;
        case 'yesterday':
          this.date = [
            dayjs().startOf('day').subtract(1, 'day').format(this.dateFormatUrlHour),
            dayjs().endOf('day').subtract(1, 'day').format(this.dateFormatUrlHour)
          ];
          break;
        case 'week':
          this.date = [
            dayjs().startOf('week').add(1, 'day').format(this.dateFormatUrlHour),
            dayjs().startOf('minute').format(this.dateFormatUrlHour)
          ];
          break;
        case 'month':
          this.date = [
            dayjs().startOf('month').format(this.dateFormatUrlHour),
            dayjs().startOf('minute').format(this.dateFormatUrlHour)
          ];
          break;
        case 'year':
          this.date = [
            dayjs().startOf('year').format(this.dateFormatUrlHour),
            dayjs().startOf('minute').format(this.dateFormatUrlHour)
          ];
          break;
        case 'q1':
          this.date = [
            dayjs().startOf('year').format(this.dateFormatUrlHour),
            dayjs().startOf('year').add(3, 'month').endOf('month').format(this.dateFormatUrlHour)
          ];
          break;
        case 'q2':
          this.date = [
            dayjs().startOf('year').add(3, 'month').format(this.dateFormatUrlHour),
            dayjs().startOf('year').add(6, 'month').endOf('month').format(this.dateFormatUrlHour)
          ];
          break;
        case 'q3':
          this.date = [
            dayjs().startOf('year').add(6, 'month').format(this.dateFormatUrlHour),
            dayjs().startOf('year').add(8, 'month').endOf('month').format(this.dateFormatUrlHour)
          ];
          break;
        case 'q4':
          this.date = [
            dayjs().startOf('year').add(9, 'month').format(this.dateFormatUrlHour),
            dayjs().startOf('year').add(11, 'month').endOf('month').format(this.dateFormatUrlHour)
          ];
          break;
        default:
          break;
      }
      this.getTurnoverData();
    }
  },
  mounted() {
    this.currentDate = 'last48hours';
  },
  methods: {
    async getTurnoverData() {
      let fromDate
      let toDate

      if (this.currentDate === 'last24hours' || this.currentDate === 'last48hours') {
        fromDate = dayjs(this.date[0]).utcOffset(0).format();
        toDate = dayjs(this.date[1]).utcOffset(0).format();
      } else if (this.currentDate === 'yesterday') {
        fromDate = dayjs(this.date[0]).utc(true).format();
        toDate = dayjs(this.date[1]).utc(true).format();
      } else if (this.currentDate !== 'q1' && this.currentDate !== 'q2' && this.currentDate !== 'q3' && this.currentDate !== 'q4' && this.currentDate !== 'range') {
        fromDate = dayjs(this.date[0]).utc(true).format();
        toDate = dayjs(this.date[1]).utcOffset(0).format();
      } else {
        fromDate = dayjs(this.date[0]).utc(true).format();
        toDate = dayjs(this.date[1]).utc(true).format();
      }


      const query = `?from=${fromDate}&to=${toDate}`;

      if (this.date) {
        const res = await AdminService.getChartTurnover(query);

        setTimeout(() => {
          if (res.data.result?.data?.length) {
            this.turnoverData = res.data?.result?.data;
            this.modeData = res.data.result.mode;
          } else {
            this.turnoverData = null;
          }
          this.isLoading = false
        }, 1500)
      }
    },
    rangeDateHandler() {
      this.currentDate = 'range'
    },
    setDateHandler(date) {
      this.currentDate = date
    },
    formatDate(date, format) {
      return dayjs(date).format(format);
    },
    formatDateQuery(date) {
      return dayjs(date).utcOffset(0).format()
    },
    openRowHandler(index) {
      const rowIndex = this.openedRows.indexOf(index);
      if (rowIndex !== -1) {
        this.openedRows.splice(rowIndex, 1);
      } else {
        this.openedRows.push(index);
      }
    },
    isRowOpen(index) {
      return this.openedRows.includes(index);
    },
    calculateTotal(key, symbol) {
      let total = 0;
      for (let i = 0; i < this.turnoverData?.length; i++) {
        total += parseFloat(this.turnoverData[i][key]) || 0;
      }
      if (symbol) {
        return this.formatCurrency(total, symbol);
      } else {
        return total
      }
    },
    formatDateRange(startDate) {
      const mode = this.modeData;

      if (mode === 'Hours') {
        const a = dayjs(startDate).format('HH:mm')
        const b = dayjs(startDate).add(1, 'hour').format('HH:mm')

        return `${dayjs(startDate).format('DD.MM.YYYY') }   ${a} - ${b}`;
      } else {
        return `${dayjs(startDate).format('DD.MM.YYYY')}`;
      }


    },
    formatTime(date) {
      return dayjs.utc(date).local().format(this.dateFormatDay);
    },
    dateFormat(date) {
      return dayjs.utc(date).local().format(this.dateFormatFull);
    },
    formatCurrency(value, coin) {
      if (value === null || value === 0) {
        return '—';
      }

      const parsedValue = parseFloat(value);
      let formattedValue;

      if (coin === 'BTC' || coin === 'ETH') {
        formattedValue = parsedValue.toFixed(4);
      } else {
        formattedValue = parsedValue.toFixed(2);
      }

      if (parsedValue >= 10000) {
        formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      return formattedValue;
    },
    calcResult(coin, symbol) {
      let result = (coin.sumIn - coin.sumOut) + coin.syncBalance;

      return this.formatCurrency(result, symbol)
    },
    formatPercentage(value, total) {
      if (total === 0) {
        return '0%';
      } else {
        const percentage = (value / total) * 100;
        return percentage.toFixed(2) + '%';
      }
    }
  }
}
</script>

<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2> {{ $route.name }}</h2>
      </div>
    </template>
    <template #content>
      <div class="main-block mirror_turnover">
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="false"
          color="#0A68F7"
        />
        <div class="mirror_turnover--head">
          <div class="title">
            Node turnover
          </div>
          <div class="filters-block">
            <div class="filters">
              <div class="dates_range">
                <date-picker
                  v-model:value="datePicker"
                  :clearable="false"
                  :editable="false"
                  default-panel="day"
                  separator=" - "
                  value-type="date"
                  :format="dateFormatFull"
                  placeholder="Custom date"
                  range
                  input-class="dates-item"
                  :class="{'dates_filled': datePicker && currentDate === 'range', 'filters--item-active_range': currentDate === 'range'}"
                  @change="rangeDateHandler"
                >
                  {{ dateText }}
                  <template #icon-calendar>
                    <calendar-icon />
                  </template>
                </date-picker>
              </div>
              <div class="separator" />
              <div class="filters_block">
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'last24hours'}"
                  @click="setDateHandler('last24hours')"
                >
                  24H
                </div>
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'last48hours'}"
                  @click="setDateHandler('last48hours')"
                >
                  48H
                </div>
              </div>
              <div class="separator" />
              <div class="filters_block">
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'day'}"
                  @click="setDateHandler('day')"
                >
                  Today
                </div>
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'yesterday'}"
                  @click="setDateHandler('yesterday')"
                >
                  Yesterday
                </div>
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'week'}"
                  @click="setDateHandler('week')"
                >
                  Week
                </div>
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'month'}"
                  @click="setDateHandler('month')"
                >
                  Month
                </div>
              </div>
              <div class="separator" />
              <div class="filters_block">
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'q1'}"
                  @click="setDateHandler('q1')"
                >
                  Q1
                </div>
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'q2'}"
                  @click="setDateHandler('q2')"
                >
                  Q2
                </div>
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'q3'}"
                  @click="setDateHandler('q3')"
                >
                  Q3
                </div>
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'q4'}"
                  @click="setDateHandler('q4')"
                >
                  Q4
                </div>
              </div>
              <div class="separator" />
              <div class="filters_block">
                <div
                  class="filters--item"
                  :class="{'filters--item-active': currentDate === 'year'}"
                  @click="setDateHandler('year')"
                >
                  Year
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="turnoverData?.length"
          class="mirror_turnover--table"
        >
          <div class="mirror_turnover--table_row">
            <div class="mirror_turnover--table_row-block mirror_turnover--table_row--head">
              <div class="mirror_turnover--table_row--item item_date">
                Data/time
              </div>
              <div class="mirror_turnover--table_row--item item_trx">
                Trx
              </div>
              <div class="mirror_turnover--table_row--item item_missedTrx">
                Missed trx
              </div>
              <div class="mirror_turnover--table_row--item item_turnover">
                Turnover, USDT
              </div>
              <div class="mirror_turnover--table_row--item item_turnover">
                Turnover, BTC
              </div>
              <div class="mirror_turnover--table_row--item item_turnover">
                Profit, BTC
              </div>
            </div>
          </div>

          <PerfectScrollbar
            class="mirror_turnover--table_overflow"
            :swipe-easing="false"
          >
            <div
              v-for="(item, index) in turnoverData"
              :key="index"
              class="mirror_turnover--table_row"
              :class="{'mirror_turnover--table_row-opened': isRowOpen(index)}"
              @click="openRowHandler(index)"
            >
              <div class="mirror_turnover--table_row-block">
                <div class="item item_date">
                  {{ formatDateRange(item.start) }}
                </div>
                <div class="item item_trx">
                  {{ item?.transaction }}
                </div>
                <div class="item item_missedTrx">
                  <span
                    v-if="item?.missedTransaction === 0"
                    class="item_missedTrx--null"
                  >—</span>
                  <span v-else>{{ item?.missedTransaction }}</span>
                </div>
                <div
                  class="item item_turnover"
                  :class="{'item_missedTrx--null': item.turnoverUSDT === 0}"
                >
                  {{ formatCurrency(item?.turnoverUSDT, 'USDT') }} <span v-if="item.turnoverUSDT">USDT</span>
                </div>
                <div
                  class="item item_turnover"
                  :class="{'item_missedTrx--null': item.turnoverBTC === 0}"
                >
                  {{ formatCurrency(item?.turnoverBTC, 'BTC') }} <span v-if="item.turnoverBTC">BTC</span>
                </div>
                <div
                  class="item item_turnover"
                  :class="{'item_missedTrx--null': item.profit === 0}"
                >
                  {{ formatCurrency(item?.profit, 'BTC') }} <span v-if="item.profit">BTC</span>
                </div>
                <div class="item_dropdown">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4628_86132)">
                      <path
                        d="M13.825 7.1582L10 10.9749L6.175 7.1582L5 8.3332L10 13.3332L15 8.3332L13.825 7.1582Z"
                        fill="#9FA2B4"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4628_86132">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div
                v-if="isRowOpen(index)"
                class="mirror_turnover--table_coins"
              >
                <div
                  v-for="(coin, idx) in sortedCoins(item?.coins)"
                  :key="idx"
                  class="mirror_turnover--table_coins-item"
                >
                  <div class="coin">
                    <img
                      :src="coin?.symbol.image"
                      alt=""
                    >
                    <div
                      v-if="coin.symbol.name === 'USDT'"
                      class="coin-network"
                      :class="{
                        'coin-network_trx': coin.network === 'TRX',
                        'coin-network_eth': coin.network === 'ETH',
                      }"
                    >
                      {{ coin.network }}
                    </div>
                  </div>
                  <div class="turnover">
                    <div class="turnover--item">
                      <div class="left">
                        <div
                          class="sum--item sum-in"
                          :style="{ width: formatPercentage(coin.sumIn, coin.sumIn + coin.sumOut) }"
                        />
                      </div>
                      <div class="right">
                        <div v-if="coin.sumIn !== 0">
                          {{ '+' + formatCurrency(coin.sumIn, coin.symbol.name) }} <span>{{ coin?.symbol.name }}</span>
                        </div>
                        <div v-else>
                          -
                        </div>
                      </div>
                    </div>
                    <div class="turnover--item">
                      <div class="left">
                        <div
                          class="sum--item sum-out"
                          :style="{ width: formatPercentage(coin.sumOut, coin.sumIn + coin.sumOut) }"
                        />
                      </div>
                      <div class="right">
                        <div v-if="coin.sumOut !== 0">
                          {{ '-' + formatCurrency(coin.sumOut, coin.symbol.name) }} <span>{{ coin?.symbol.name }}</span>
                        </div>
                        <div v-else>
                          -
                        </div>
                      </div>
                    </div>
                    <div class="turnover--item">
                      <div class="left">
                        Balance
                      </div>
                      <div class="right">
                        <div v-if="coin.syncBalance !== 0">
                          {{ formatCurrency(coin?.syncBalance, coin.symbol.name) }} <span>{{ coin?.symbol.name }}</span>
                        </div>
                        <div v-else>
                          -
                        </div>
                      </div>
                    </div>
                    <div class="turnover--item">
                      <div class="left">
                        Result
                      </div>
                      <div class="right">
                        {{ calcResult(coin, coin?.symbol.name) }} <span>{{ coin?.symbol.name }}</span>
                      </div>
                    </div>
                    <div class="turnover--item">
                      <div class="left">
                        Av.deposit
                      </div>
                      <div class="right">
                        <div v-if="coin.avDeposit !== 0">
                          {{ formatCurrency(coin?.avDeposit, coin?.symbol.name) }} <span>{{ coin?.symbol.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PerfectScrollbar>

          <div class="mirror_turnover--table_row mirror_turnover--table_row--empty" />

          <div class="mirror_turnover--table_row">
            <div class="mirror_turnover--table_row-block mirror_turnover--table_row--total">
              <div class="item item_date">
                Total
              </div>
              <div class="item item_trx">
                {{ calculateTotal('transaction') }}
              </div>
              <div class="item item_missedTrx">
                <span
                  v-if="calculateTotal('missedTransaction') === 0"
                  class="item_missedTrx--null"
                >—</span>
                <span v-else>{{ calculateTotal('missedTransaction') }}</span>
              </div>
              <div class="item item_turnover">
                {{ calculateTotal('turnoverUSDT', 'USDT') }} USDT
              </div>
              <div class="item item_turnover">
                {{ calculateTotal('turnoverBTC', 'BTC') }} BTC
              </div>
              <div class="item item_turnover">
                {{ calculateTotal('profit', 'BTC') }} BTC
              </div>
            </div>
          </div>
        </div>
        <no-data-block v-if="!turnoverData?.length && !isLoading" />
      </div>
    </template>
  </AccountLayout>
</template>

<style lang="scss">

.mirror_turnover {
  max-width: 1122px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  height: calc(100vh - 131px);

  .nodata {
    height: 100%;
  }

  &--head {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 32px 0 0 32px;

    .title {
      color: #1B1A1C;
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
    }

    .filters {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      box-sizing: border-box;

      .dates {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 4px;

        @media (max-width: 1079px) {
          overflow: auto;
          scrollbar-width: none;
        }

        &-item {
          padding: 6px 12px;
          font-size: 14px;
          font-weight: 500;
          background: #FCFDFE;
          border: 1px solid #F0F1F7;
          box-sizing: border-box;
          border-radius: 6px;
          color: #0A68F7;
          cursor: pointer;
          transition: all .3s ease-in-out;

          &:hover {
            border: 1px solid #0A68F7;
          }
        }

        .active {
          color: #FCFDFE;
          background: #0A68F7;
        }

        &_filled {
          .mx-input-wrapper {
            input {
              width: 23ch !important;
              height: 40px;
            }
          }
        }

        &_range {
          background: none !important;

          .mx {
            &-datepicker {
              width: unset;

              &-popup, &-main {
                box-shadow: 0 4px 24px rgba(55, 49, 72, 0.12);
                border-radius: 12px;
                overflow: hidden;
                border: none;
              }
            }

            &-icon-calendar, &-icon-clear {
              left: 9px;
              right: unset;

            }

            &-datepicker svg {
              fill: none;
            }

            &-input-wrapper {
              font-family: 'Inter', sans-serif !important;

              input {
                &::placeholder {
                  color: #0A68F7;
                  opacity: 1;
                }

                width: 126px;
              }

              .dates-item {
                padding-left: 30px !important;
              }

              .mx-icon-calendar {
                svg {
                  path {
                    fill: #0A68F7;
                    fill-opacity: 100%;
                  }
                }
              }
            }
          }


          .dates-item {
            padding: 6px 8px;
          }


        }
      }

      .separator {
        width: 1px;
        height: 20px;
        background: #DFE0EB;
        border-radius: 4px;
      }

      &_block {
        display: flex;
        align-items: center;
        gap: 9px;
      }

      &--item {
        user-select: none;
        padding: 10px 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0A68F7;
        background: #FCFDFE;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
          border: 1px solid #0A68F7;
        }

        &-active {
          background: #0A68F7;
          border: 1px solid #0A68F7;
          color: white;
          cursor: default;

          &_range {
            .dates-item {
              color: #FCFDFE;
              background: #0A68F7;
            }

            .mx {
              &-icon-calendar, &-icon-clear {
                fill: white !important;
                fill-opacity: 100%;
              }

              &-datepicker svg path {
                fill: white !important;
              }

              &-input-wrapper {
                input {
                  &::placeholder {
                    color: white;
                    opacity: 1;
                  }

                  width: 14ch;
                }
              }
            }


            .mx-icon-calendar {
              svg path {
                fill: white !important;
                fill-opacity: 100%;
              }
            }
          }
        }
      }
    }
  }

  &--table {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    &_overflow {
      min-height: 400px;
      height: 100%;
      max-height: calc(100vh - 400px);
      box-sizing: border-box;
      overflow: scroll;
    }

    &_row {
      width: 100%;
      position: relative;
      cursor: pointer;
      padding-left: 32px;
      box-sizing: border-box;
      transition: background-color .3s ease-in-out;
      background-color: white;

      &:not(:last-child) {
        border-bottom: 1px solid #DFE0EB;
      }

      &-block {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 14px 32px 14px 0;
        box-sizing: border-box;

        .item {
          color: var(--black, #1B1A1C);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          width: 100%;

          &_date {
            max-width: 230px;
          }

          &_trx {
            max-width: 84px;
          }

          &_missedTrx {
            max-width: 76px;

            &--null {
              color: #9FA2B4;
            }
          }

          &_turnover {
            max-width: 200px;
            text-align: right;
          }

          &_dropdown {
            position: absolute;
            right: 12px;
            top: 14px;

            svg {
              path {
                transition: all .3s ease-in-out;
              }
            }
          }
        }
      }

      &--head {
        padding: 0 32px 12px 0;
        cursor: default;

        .mirror_turnover--table_row--item {
          color: var(--grayscale-gray, #9FA2B4);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          width: 100%;
        }
      }

      &--total {
        padding-top: 29px;
        padding-bottom: 31px;

        &:hover {
          background: none;
          cursor: default;
        }

        .item {
          color: var(--black, #1B1A1C);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
        }
      }

      &:not(:first-child):hover {
        background-color: #F8FAFB;

        .item_dropdown {
          svg {
            path {
              fill: #0A68F7;
            }
          }
        }
      }

      &-opened {
        background: #F8FAFB;

        .mirror_turnover--table_coins {
          height: auto;
        }
      }

      &--empty {
        height: 16px;
      }
    }

    &_coins {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px 2%;
      padding: 12px 24px 28px 0;
      box-sizing: border-box;
      height: 0;
      transition: all .3s ease-in-out;

      &-item {
        width: 100%;
        max-width: 248px;
        display: flex;
        flex-direction: row;
        gap: 8px;

        .coin {
          width: 32px;
          height: 32px;
          display: flex;
          margin-top: 4px;
          position: relative;

          &-network {
            position: absolute;
            bottom: -4px;
            left: 0;
            right: 0;
            margin: 0 auto;
            color: #FFF;
            text-align: center;
            font-family: 'Inter', sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            border-radius: 3px;
            display: grid;
            place-items: center;
            width: 26px;
            height: 14px;

            &_trx {
              background: #0A68F7;
            }

            &_eth {
              background: #FB0000;
            }
          }
        }

        .turnover {
          display: flex;
          flex-direction: column;
          width: 208px;

          &--item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .left {
              color: var(--grayscale-gray, #9FA2B4);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              width: 100%;
              max-width: 72px;
              display: flex;
              flex-direction: row;
              align-items: center;

              .sum {
                &--item {
                  border-radius: 4px;
                  height: 12px;
                }

                &-in {
                  background: rgba(0, 177, 139, 0.40);
                }

                &-out {
                  background: rgba(255, 96, 61, 0.40);
                }
              }
            }

            .right {
              width: 100%;
              color: var(--black, #1B1A1C);
              text-align: right;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;

              span {
                color: #9FA2B4;
              }
            }
          }
        }
      }
    }
  }
}

</style>
