<script>
import Loading from "vue-loading-overlay";
import SortAZ from "@/icons/sorting/sort-a-z.vue";
import SortPassive from "@/icons/sorting/sort-passive.vue";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import SortZA from "@/icons/sorting/sort-z-a.vue";
import {LAST_ORDER_PARTNER_TABLE_TH} from "@/pages/dashboard/partials/lastOrderPartner";
import dayjs from "dayjs";
import AlertProgressPartner from "@/components/admin/AlertProgressPartner.vue";
import PartnerActivityAlert from "@/assets/img/icons/ParttnerActivityAlert.vue";
import OrderService from "@/services/order.service";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default {
  name: "PartnersActivity",
  components: {
    PartnerActivityAlert,
    AlertProgressPartner,
    SortZA,
    NoDataBlock,
    SortPassive,
    SortAZ,
    Loading,
  },
  data() {
    return {
      lastOrderPartner: null,
      isLoading: false,
      tableTh: LAST_ORDER_PARTNER_TABLE_TH,
      currentIndex: false,
      alertStatus: null,
      alertHandle: false,
      dateFormatUrl: 'DD.MM.YY - HH:mm',
      dropdownItems: [
        {value: 1},
        {value: 3},
        {value: 6},
        {value: 12},
        {value: 24},

      ],
      selectedAlert: {value: 12},
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      OrderService.getLastOrderPartner().then((res) => {
        this.lastOrderPartner = res.data.result
      })
    },
    alertHandler() {
      this.alertHandle = !this.alertHandle
    },
    selectTimerValue(item) {
      this.selectedAlert = item;
      this.alertHandle = false
    },
    dateFormatter(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormatUrl)
    },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      switch (type.key) {
        case 'partner':
          this.lastOrderPartner = this.lastOrderPartner.sort((a, b) => {
            return type.sort === 'asc' ? this.calculatePeriod(a.createDate) - this.calculatePeriod(b.createDate) : this.calculatePeriod(b.createDate) - this.calculatePeriod(a.createDate);
          });
          break;
        case 'countOrders':
          this.lastOrderPartner = this.lastOrderPartner.sort((a, b) => {
            return type.sort === 'asc' ? b.cntAll - a.cntAll : a.cntAll - b.cntAll;
          });
          break;
        case 'completedOrder':
          this.lastOrderPartner = this.lastOrderPartner.sort((a, b) => {
            return type.sort === 'asc' ? b.cntComplete - a.cntComplete : a.cntComplete - b.cntComplete;
          });
          break;
        case 'lastOrder':
          this.lastOrderPartner = this.lastOrderPartner.sort((a, b) => {
            return type.sort === 'asc' ? b.profit - a.profit : a.profit - b.profit;
          });
          break;
        case 'lastOrderTime':
          this.lastOrderPartner = this.lastOrderPartner.sort((a, b) => {
            const dateA = dayjs(a.createDate);
            const dateB = dayjs(b.createDate);

            return type.sort === 'asc' ? dateA - dateB : dateB - dateA;
          });
          break;
      }
    },
    calculatePeriod(date) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      const now = dayjs();
      const createTime = dayjs.utc(date).local();
      const timeDifference = now.diff(createTime, 'minute');
      const selectedPeriod = this.selectedAlert.value * 60;

      if (timeDifference < selectedPeriod / 3) {
        return 1;
      } else if (timeDifference < (selectedPeriod * 2) / 3) {
        return 2;
      } else if (timeDifference < selectedPeriod) {
        return 3;
      } else {
        return 4;
      }
    }
  }
}

</script>

<template>
  <div class="partnerActivity main-block">
    <loading
      v-if="!lastOrderPartner && isLoading"
      v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="true"
      color="#0A68F7"
    />
    <div class="table-block">
      <div class="table-block-header">
        <div class="table-block-header-title">
          Partners activity
        </div>
      </div>
      <div class="table-block-table">
        <table class="table">
          <thead>
            <tr class="table-headers">
              <th
                v-for="(item, index) in tableTh"
                :key="index"
                scope="col"
                :class="{'table-first': item.key === 'partner'}"
              >
                <div
                  class="text"
                  @click="item.isSortable && sortData(item)"
                  @mouseover="currentIndex = index"
                  @mouseleave="currentIndex = null"
                >
                  {{ item.name }}
                  <sort-passive v-if="!item.sort && item.isSortable" />
                  <sort-a-z v-if="item.sort === 'asc' && item.isSortable" />
                  <sort-z-a v-if="item.sort === 'desc' && item.isSortable" />
                </div>
              </th>
              <th
                ref="alert"
                class="table-alert"
              >
                <div

                  @click="alertHandler"
                >
                  <div class="text">
                    Alert - {{ selectedAlert.value }}h
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4206_3129)">
                        <path
                          d="M2.25 12.75V14.25H6.75V12.75H2.25ZM2.25 3.75V5.25H9.75V3.75H2.25ZM9.75 15.75V14.25H15.75V12.75H9.75V11.25H8.25V15.75H9.75ZM5.25 6.75V8.25H2.25V9.75H5.25V11.25H6.75V6.75H5.25ZM15.75 9.75V8.25H8.25V9.75H15.75ZM11.25 6.75H12.75V5.25H15.75V3.75H12.75V2.25H11.25V6.75Z"
                          fill="#0A68F7"
                          fill-opacity="0.12"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4206_3129">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div
                  v-if="alertHandle"
                  class="dropdown"
                >
                  <div
                    v-for="(item, index) in dropdownItems"
                    :key="index"
                    class="dropdown-item"
                    @click="selectTimerValue(item)"
                  >
                    {{ item.value }} hours
                    <svg
                      v-if="selectedAlert.value === item.value"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4068_2939)">
                        <path
                          d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                          fill="#0A68F7"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4068_2939">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </th>
              <th class="table-edit" />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(stat, index) in lastOrderPartner"
              :key="index"
              class="table-row"
            >
              <td
                class="table-cell table-partner"
                :class="{'table-partner_alert': calculatePeriod(stat.createDate) === 4}"
              >
                <span v-if="stat.login">{{ stat.login }}</span>
                <span v-else>Direct</span>
                <partner-activity-alert v-if="calculatePeriod(stat.createDate) === 4" />
              </td>
              <td class="table-cell table-countOrders">
                {{ stat.cntAll }}
              </td>
              <td class="table-cell table-completedOrder">
                {{ stat.cntComplete }}
              </td>
              <td class="table-cell table-lastOrder">
                {{ stat?.partnerFloat }}/{{ stat?.partnerFixed }}
              </td>
              <td class="table-cell table-lastOrder">
                {{ stat.orderId }}
              </td>
              <td class="table-cell table-lastOrder">
                {{ dateFormatter(stat.createDate) }}
              </td>
              <td class="table-cell table-payments">
                <alert-progress-partner :value="calculatePeriod(stat.createDate)" />
              </td>
            </tr>
          </tbody>
        </table>
        <no-data-block v-if="!lastOrderPartner && !isLoading" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.partnerActivity {
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  z-index: 99;

  .table {
    margin-bottom: 0;
    border-collapse: collapse;

    &-first {
      padding-left: 32px;
    }

    &-partner {
      padding-left: 32px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      @media (max-width: 1079px) {
        position: sticky;
        top: 0;
        bottom: 0;
        z-index: 99;
        left: 0;

        &.sticky {
          background: white;
          box-shadow: 1px 0 2px rgba(27, 26, 28, 0.16);
        }
      }

      &_alert {
        color: #FF5C00 !important;
      }
    }

    &-row {
      &:last-child {
        border-bottom: transparent;
      }
    }

    &-alert {
      position: relative;
      padding-left: 0;

      .text {
        svg {
          path {
            transition: all .3s ease-in-out;
          }
        }
      }


      &:hover {
        .text {
          svg {
            path {
              fill-opacity: 1;
            }
          }
        }
      }

      .dropdown {
        position: absolute;
        background: white;
        top: 96%;
        left: -20px;
        width: 147px;
        z-index: 9999999;
        border-radius: 12px;
        padding: 8px 0;
        box-shadow: 0px 4px 24px 0px rgba(15, 0, 58, 0.12);

        &-item {
          padding: 8px 16px;
          color: #1B1A1C;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          cursor: pointer;
          transition: all .3s ease-in-out;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          &:hover {
            background: rgba(27, 26, 28, 0.03);
          }

          .marked {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &-edit img {
      background: rgba(10, 104, 247, 0.06);
      border-radius: 8px;
      padding: 8px;
      cursor: pointer;
    }

    &-block {
      &-table {
        @media (max-width: 1079px) {
          overflow: auto;
        }
      }
    }
  }
}
</style>
