<template>
  <div
    ref="adminFilter"
    class="admin-filter"
    @click="adminSearchToggle"
  >
    <admin-icon
      class="filter"
      height="20px"
    />
    Admin
    <perfect-scrollbar
      v-if="adminsList.length && isAdminSearch"
      class="dropdown"
    >
      <div
        v-for="(adminItem) in adminsList"
        :key="adminItem.userId"
        class="dropdown-item"
        @click="adminSearchHandler(adminItem)"
      >
        <div>
          {{ adminItem.login }}
        </div>
        <svg
          v-if="selectedAdmin && selectedAdmin.userId === adminItem.userId"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4068_2939)">
            <path
              d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
              fill="#0A68F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_4068_2939">
              <rect
                width="20"
                height="20"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>

import AdminIcon from '@/assets/img/icons/AdminIcon.vue';
import UserService from '@/services/user.service';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import {onBeforeUnmount, onMounted, ref} from 'vue';

export default {
  name: "AdminFilter",
  components: {
    AdminIcon,
    PerfectScrollbar
  },
  props: {
    isAdminSearch: {
      type: Boolean,
      required: true
    },
    adminSearchToggle: {
      type: Function,
      required: true
    },
    adminSearchHandler: {
      type: Function,
      required: true
    },
    selectedAdmin: {
      type: Object,
      required: true
    }
  },
  emits: ['handleAdminSearchClickOutside'],
  setup(props, { emit }) {
    const adminFilter = ref(null);

    const handleClickOutside = (event) => {
      if (adminFilter.value && !adminFilter.value.contains(event.target)) {
        emit('handleAdminSearchClickOutside');
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      adminFilter,
    };
  },
  data() {
    return {
      adminsList: [],
    }
  },
  mounted() {
    this.getAdmins()
  },
  methods: {
    getAdmins() {
      UserService.getAdmins().then((res) => {
        if (res.status === 200) {
          this.adminsList = res.data.result;
        }
      }).catch((error) => {
        console.log(error.response.data.message)
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

.admin-filter {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px 12px 6px 6px;
  height: 32px;
  min-width: fit-content;
  font-size: 12px;
  color: $blue-color;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  background: $light-gray-color;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: $light-gray-hover-color;
  }

  .dropdown {
    position: absolute;
    background: white;
    top: 37px;
    left: -10px;
    width: 180px;
    height: 208px;
    z-index: 999;
    border-radius: 8px;
    padding: 8px 0;
    box-shadow: 0 1px 4px 0 rgba(6, 59, 122, 0.12), 0 4px 24px 0 rgba(6, 59, 122, 0.08);
    box-sizing: border-box;

    .dropdown-item {
      padding: 6px 12px;
      color: $black-color1;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: $hover-color;
      }
    }
  }
}
</style>
