<template>
  <AccountLayout>
    <template #title>
      <h2>Settings</h2>
    </template>
    <template #content>
      <div class="settings main-block">
        <loading
          v-if="!settingsData.length && isLoading"
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="#0A68F7"
        />
        <div class="table-block">
          <div class="table-block-header">
            <div class="table-block-header-title">
              Settings
            </div>
          </div>
          <div class="table-block-table">
            <table class="table">
              <thead>
                <tr class="table-headers">
                  <th
                    v-for="(item, index) in tableTh"
                    :key="index"
                    scope="col"
                    :class="{
                      'table-userKey': item.key === 'userKey',
                      'table-value': item.key === 'value',
                      'table-status': item.key === 'active',
                    }"
                  >
                    <div
                      class="text"
                      @click="sortData(item)"
                      @mouseover="currentIndex = index"
                      @mouseleave="currentIndex = null"
                    >
                      {{ item.name }}
                      <sort-passive v-if="!item.sort" />
                      <sort-a-z v-if="item.sort === 'asc'" />
                      <sort-z-a v-if="item.sort === 'desc'" />
                      <transition name="fade">
                        <hover-text
                          v-if="currentIndex === index && item.description !== null"
                          :option="item.description"
                        />
                      </transition>
                    </div>
                  </th>
                  <th
                    class="table-edit"
                    scope="col"
                  />
                </tr>
              </thead>
              <tbody>
                <SettingsItem
                  v-if="settingsData.length"
                  :settings-list="settingsData"
                />
              </tbody>
            </table>
            <no-data-block v-if="!settingsData || !settingsData.length && !isLoading" />
          </div>
        </div>
      </div>
      <SettingsEdit
        v-if="SettingsData"
        :data="SettingsData"
      />
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/admin/AccountLayout.vue";
import {mapGetters} from 'vuex';
import filterMixin from "../../mixins/filter.mixin";
import helperMixin from "../../mixins/helper.mixin";
import SortPassive from "../../icons/sorting/sort-passive";
import SortAZ from "../../icons/sorting/sort-a-z";
import SortZA from "../../icons/sorting/sort-z-a";
import HoverText from "../coins/partials/hoverText";
import SettingsItem from "./partials/SettingsItem";
import SettingsEdit from "./partials/SettingsEdit";
import {TABLE_TH} from "@/pages/settings/settingsData";
import Loading from 'vue-loading-overlay';
import NoDataBlock from "@/components/admin/NoDataBlock.vue";

export default {
  name: "Settings",
  components: {
    NoDataBlock,
    SettingsEdit,
    SettingsItem,
    HoverText,
    SortZA,
    SortAZ,
    SortPassive,
    AccountLayout,
    Loading
  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      originSettings: null,
      currentSorting: 'userKey',
      batch: [],
      settingsData: [],
      options: {
        chunk: 4
      },
      currentPerPage: 20,
      currentIndex: null,
      tableTh: TABLE_TH,
      isLoading: false,
      fullPage: true,
    }
  },
  computed: {
    ...mapGetters({
      SettingsData: 'getSettingsData',
    }),
    Settings: {
      get() {
        return this.$store.state.settingsList;
      },
      set(value) {
        this.$store.commit('setSettings', value);
      }
    },
  },
  mounted() {
    this.getSettingsDataList();
  },
  methods: {
    getSettingsDataList() {
      this.isLoading = true;

      this.$store.dispatch('getSettings').then(() => {
        this.originSettings = [...this.Settings];
        this.settingsData = this.Settings;
        /*const batch = this.batchData(this.Settings, this.currentPerPage);
        this.settingsData = batch.current;
        console.log(this.settingsData,'!!!');
        this.batch = batch.total;*/
        this.isLoading = false;
      });
    },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      switch (type.key) {
        case 'userKey':
          this.Settings = this.Settings.sort((a, b) => {
            const aCount = a.userKey.localeCompare(b.userKey);
            const bCount = b.userKey.localeCompare(a.userKey);

            return type.sort === 'asc' ? bCount : aCount;
          });
          break;
        case 'value':
          this.Settings = this.Settings.sort((a, b) => {
            return type.sort === 'asc' ? a.userValue - b.userValue : b.userValue - a.userValue;
          });
          break;
        case 'active':
          this.Settings = this.Settings.sort((a, b) => {
            return type.sort === 'asc' ? b.isActive - a.isActive : a.isActive - b.isActive;
          });
          break;

      }
      this.settingsData = this.batchData(this.Settings, this.currentPerPage).current;
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/pages/settings/settings";
</style>
