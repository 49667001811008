import service from '../plugins/request';
import UrlService from '@/services/url.service';

class BlogService {
    /**
     * @param name
     * @param description
     * @param status
     * @param slag
     * @return {Promise<AxiosResponse<any>>}
     */
    createTag({name,description,status,slag}) {
        return service.post('/api/blog/admin/createTag', {name,description,status,slag});
    }

    /**
     * @return {Promise<AxiosResponse<any>>}
     */

    editPost({postId,title,content,announce,slag,timeToRead,status,tagIDs,publishDate}) {
        return service.post('/api/blog/admin/editPost/' + postId, {title,content,announce,slag,timeToRead,status,tagIDs,publishDate});
    }

    /**
     * @return {Promise<AxiosResponse<any>>}
     */

    createPost({title,content,announce,publishDate,slag,timeToRead,status,tagIDs}) {
        return service.post('/api/blog/admin/createPost', {title,content,announce,publishDate,slag,timeToRead,status,tagIDs});
    }

    /**
     * @return {Promise<AxiosResponse<any>>}
     */

    UpdateImage({postId,image}) {
        const formData = new FormData();
        formData.append("UploadFile", image);
        return service.post('/api/blog/admin/UpdateImage/'+ postId, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    }

    UploadArticleImage(image) {
        const formData = new FormData();
        formData.append("UploadFile", image);
        return service.post('/api/Other/upload', formData, {headers: {'Content-Type': 'multipart/form-data'}});
    }

    /**
     * @param query
     * @return {Promise<AxiosResponse<any>>}
     */
    getPost(query) {
        return service.get('/api/blog/admin/GetPost?postId=' + query)
    }

    /**
     * @param query
     * @return {Promise<AxiosResponse<any>>}
     */
    search(query) {
        let queryList = UrlService.buildParams(query);
        return service.get('/api/blog/admin/Search/?' + queryList)
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getTags() {
        return service.get('/api/blog/GetTags')
    }
}

export default new BlogService();
