<template>
  <AccountLayout class="dashboard_page">
    <template #title>
      <div class="layout-header">
        <h2>Dashboard mirror</h2>
        <div class="layout-header_tabs">
          <div class="layout-header_tabs">
            <router-link
              :to="{ name: 'Dashboard market' }"
              class="layout-header_tabs-item "
            >
              WhiteBit
            </router-link>
            <div class="layout-header_tabs-item layout-header_tabs-item--active">
              Mirror
            </div>
          </div>
        </div>
      </div>
    </template>
    <template
      #content
      class="content_dashboard"
    >
      <div class="dashboard">
        <TransactionsValue market="mirror" />
        <div class="dashboard-rating">
          <DepositTop market="mirror" />
          <WithdrawalTop market="mirror" />
        </div>
      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/admin/AccountLayout.vue";
import { API_URL } from "@/store/config";
import DepositTop from "@/pages/dashboard/partials/DepositTop.vue";
import WithdrawalTop from "@/pages/dashboard/partials/WithdrawalTop.vue";
import TransactionsValue from "@/pages/dashboard/partials/TransactionsValue.vue";

export default {
  name: "DashboardMarket",
  components: {TransactionsValue, WithdrawalTop, DepositTop, AccountLayout},
  data() {
    return {
      API_URL: API_URL
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/layout/dashboard';
</style>
