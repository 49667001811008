<template>
  <label class="custom-checkbox">
    <input type="checkbox" :checked="checked" @change="toggle" >
    <span class="checkmark" />
    <span class="label-text">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: this.modelValue
    };
  },
  watch: {
    modelValue(newValue) {
      this.checked = newValue;
    }
  },
  methods: {
    toggle(event) {
      this.checked = event.target.checked;
      this.$emit('update:modelValue', this.checked);
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  padding: 6px 0;
  height: 20px;
  width: fit-content;
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-checkbox .checkmark {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  background: white;
  border: 2px solid $another-grey-color;
  border-radius: 4px;
  box-sizing: border-box;
}

.custom-checkbox input:checked + .checkmark {
  background: $blue-color;
  border-color: $blue-color;
}

.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  left: 3px;
  top: 1px;
  width: 4px;
  height: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s;
}

.custom-checkbox input:checked + .checkmark::after {
  opacity: 1;
}

.label-text {
  padding-left: 6px;
  color: $black-color1;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
</style>
