export const TRADE_WITHDRAWAL_TABLE_TH = [
    {
        name: 'ID',
        sort: false,
        key: 'id',
    },
    {
        name: 'Time',
        sort: false,
        key: 'time',
    },
    {
        name: 'Coin',
        sort: false,
        key: 'shortName',
    },
    {
        name: 'Network',
        sort: false,
        key: 'network',
    },
    {
        name: 'Type',
        sort: false,
        key: 'type',
    },
    {
        name: 'Address',
        sort: false,
        key: 'address',
    },
    {
        name: 'Hash',
        sort: false,
        key: 'hash',
    },
    {
        name: 'Amount',
        sort: false,
        key: 'amount',
    },
    {
        name: 'Status',
        sort: false,
        key: 'status',
    },
    {
        name: '',
        sort: false,
        key: 'comment',
    },
]
