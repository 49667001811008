<script>

export default {
  name: 'LogsChangeableIcon',
  props: {
    status: {
      type: Number,
      default: null
    }
  }
}

</script>

<template>
  <div class="changeable-icon">
    <svg
      v-if="status === 0"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="6"
        fill="#02C076"
        fill-opacity="0.2"
      />
      <g clip-path="url(#clip0_4300_74379)">
        <path
          d="M7.99896 12.7991L5.19896 9.99909L4.26562 10.9324L7.99896 14.6658L15.999 6.66576L15.0656 5.73242L7.99896 12.7991Z"
          fill="#02C076"
        />
      </g>
      <defs>
        <clipPath id="clip0_4300_74379">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>

    <svg
      v-if="status === 1"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="6"
        fill="#FFA800"
        fill-opacity="0.2"
      />
      <g clip-path="url(#clip0_4300_74498)">
        <path
          d="M11.3733 8.01333L11.9867 8.62667L5.94667 14.6667H5.33333V14.0533L11.3733 8.01333ZM13.7733 4C13.6067 4 13.4333 4.06667 13.3067 4.19333L12.0867 5.41333L14.5867 7.91333L15.8067 6.69333C16.0667 6.43333 16.0667 6.01333 15.8067 5.75333L14.2467 4.19333C14.1133 4.06 13.9467 4 13.7733 4ZM11.3733 6.12667L4 13.5V16H6.5L13.8733 8.62667L11.3733 6.12667Z"
          fill="#FFA800"
        />
      </g>
      <defs>
        <clipPath id="clip0_4300_74498">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>


    <svg
      v-if="status === 2"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="6"
        fill="#9FA2B4"
        fill-opacity="0.2"
      />
      <path
        d="M10 14L12.6667 11.3333L11.7333 10.4L10.6667 11.4667V8.66667H9.33333V11.4667L8.26667 10.4L7.33333 11.3333L10 14ZM5.33333 7.33333V14.6667H14.6667V7.33333H5.33333ZM5.33333 16C4.96667 16 4.65278 15.8694 4.39167 15.6083C4.13056 15.3472 4 15.0333 4 14.6667V6.35C4 6.19444 4.025 6.04444 4.075 5.9C4.125 5.75556 4.2 5.62222 4.3 5.5L5.13333 4.48333C5.25556 4.32778 5.40833 4.20833 5.59167 4.125C5.775 4.04167 5.96667 4 6.16667 4H13.8333C14.0333 4 14.225 4.04167 14.4083 4.125C14.5917 4.20833 14.7444 4.32778 14.8667 4.48333L15.7 5.5C15.8 5.62222 15.875 5.75556 15.925 5.9C15.975 6.04444 16 6.19444 16 6.35V14.6667C16 15.0333 15.8694 15.3472 15.6083 15.6083C15.3472 15.8694 15.0333 16 14.6667 16H5.33333Z"
        fill="#9FA2B4"
      />
    </svg>

    <svg
      v-if="status === 3"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="6"
        fill="#02C076"
        fill-opacity="0.2"
      />
      <g clip-path="url(#clip0_4300_74450)">
        <path
          d="M13.332 13.333L6.66536 13.333C4.82536 13.333 3.33203 11.8397 3.33203 9.99967C3.33203 8.15967 4.82536 6.66634 6.66536 6.66634L13.332 6.66634C15.172 6.66634 16.6654 8.15967 16.6654 9.99967C16.6654 11.8397 15.172 13.333 13.332 13.333ZM13.332 7.99967C12.2254 7.99967 11.332 8.89301 11.332 9.99967C11.332 11.1063 12.2254 11.9997 13.332 11.9997C14.4387 11.9997 15.332 11.1063 15.332 9.99967C15.332 8.89301 14.4387 7.99967 13.332 7.99967Z"
          fill="#02C076"
        />
      </g>
      <defs>
        <clipPath id="clip0_4300_74450">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(1 0 0 -1 2 18)"
          />
        </clipPath>
      </defs>
    </svg>


    <svg
      v-if="status === 4"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="6"
        fill="#9FA2B4"
        fill-opacity="0.2"
      />
      <g clip-path="url(#clip0_4300_74426)">
        <path
          d="M6.66797 6.66699H13.3346C15.1746 6.66699 16.668 8.16033 16.668 10.0003C16.668 11.8403 15.1746 13.3337 13.3346 13.3337H6.66797C4.82797 13.3337 3.33463 11.8403 3.33463 10.0003C3.33463 8.16033 4.82797 6.66699 6.66797 6.66699ZM6.66797 12.0003C7.77464 12.0003 8.66797 11.107 8.66797 10.0003C8.66797 8.89366 7.77464 8.00033 6.66797 8.00033C5.5613 8.00033 4.66797 8.89366 4.66797 10.0003C4.66797 11.107 5.5613 12.0003 6.66797 12.0003Z"
          fill="#9FA2B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_4300_74426">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(-1 0 0 1 18 2)"
          />
        </clipPath>
      </defs>
    </svg>

    <svg
      v-if="status === 5"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="6"
        fill="#FF783E"
        fill-opacity="0.2"
      />
      <path
        d="M6.66797 16C6.3013 16 5.98741 15.8694 5.7263 15.6083C5.46519 15.3472 5.33464 15.0333 5.33464 14.6667V6H4.66797V4.66667H8.0013V4H12.0013V4.66667H15.3346V6H14.668V14.6667C14.668 15.0333 14.5374 15.3472 14.2763 15.6083C14.0152 15.8694 13.7013 16 13.3346 16H6.66797ZM13.3346 6H6.66797V14.6667H13.3346V6ZM8.0013 13.3333H9.33464V7.33333H8.0013V13.3333ZM10.668 13.3333H12.0013V7.33333H10.668V13.3333Z"
        fill="#FF783E"
      />
    </svg>

    <svg
      v-if="status === 6"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="6"
        fill="#02C076"
        fill-opacity="0.2"
      />
      <g clip-path="url(#clip0_4300_74522)">
        <path
          d="M13.332 13.333L6.66536 13.333C4.82536 13.333 3.33203 11.8397 3.33203 9.99967C3.33203 8.15967 4.82536 6.66634 6.66536 6.66634L13.332 6.66634C15.172 6.66634 16.6654 8.15967 16.6654 9.99967C16.6654 11.8397 15.172 13.333 13.332 13.333ZM13.332 7.99967C12.2254 7.99967 11.332 8.89301 11.332 9.99967C11.332 11.1063 12.2254 11.9997 13.332 11.9997C14.4387 11.9997 15.332 11.1063 15.332 9.99967C15.332 8.89301 14.4387 7.99967 13.332 7.99967Z"
          fill="#02C076"
        />
      </g>
      <defs>
        <clipPath id="clip0_4300_74522">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(1 0 0 -1 2 18)"
          />
        </clipPath>
      </defs>
    </svg>

    <svg
      v-if="status === 7"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="6"
        fill="#9FA2B4"
        fill-opacity="0.2"
      />
      <g clip-path="url(#clip0_4300_74546)">
        <path
          d="M6.66797 6.66699H13.3346C15.1746 6.66699 16.668 8.16033 16.668 10.0003C16.668 11.8403 15.1746 13.3337 13.3346 13.3337H6.66797C4.82797 13.3337 3.33463 11.8403 3.33463 10.0003C3.33463 8.16033 4.82797 6.66699 6.66797 6.66699ZM6.66797 12.0003C7.77464 12.0003 8.66797 11.107 8.66797 10.0003C8.66797 8.89366 7.77464 8.00033 6.66797 8.00033C5.5613 8.00033 4.66797 8.89366 4.66797 10.0003C4.66797 11.107 5.5613 12.0003 6.66797 12.0003Z"
          fill="#9FA2B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_4300_74546">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(-1 0 0 1 18 2)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style lang="scss">
.changeable-icon {
  display: grid;
}
</style>
