<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>List of coins</h2>

        <div class="layout-header_tabs">
          <router-link
            :to="{ name: 'Coins list' }"
            class="layout-header_tabs-item "
          >
            All coins
          </router-link>
          <div class="layout-header_tabs-item layout-header_tabs-item--active">
            1inch coins
          </div>
          <!--          <div class="layout-header_tabs-item">-->
          <!--            Binance: total-->
          <!--          </div>-->
        </div>
      </div>
    </template>
    <template #content>
      <div class="coinsList coins1inch main-block">
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="false"
          color="#0A68F7"
        />
        <div class="table-block">
          <div class="table-block-header">
            <div class="table-block-header-title">
              Coins1inch
            </div>
            <div class="table-block-header-filters">
              <div class="table-block-header-filters-search">
                <input
                  type="text"
                  placeholder="Coin search"
                  @keyup="searchCoin"
                >
              </div>
              <div
                v-if="!isMobile()"
                class="table-block-header-filters-delimeter"
              />
              <div
                v-if="!isMobile()"
                class="table-block-header-filters-add"
                @click="addCoinInfo"
              >
                <div class="table-block-header-filters-add-btn">
                  <add-icon class="add" />
                  Add
                </div>
              </div>
            </div>
          </div>
          <div class="table-block-table">
            <table class="table">
              <thead>
                <tr class="table-headers">
                  <th scope="col">
                    <div class="text">
                      Img
                    </div>
                  </th>
                  <th
                    v-for="(item, index) in tableTh"
                    :key="index"
                    scope="col"
                    :class="{'table-id': item.key === 'id','table-headers-isTrue': (item.key === 'status')}"
                  >
                    <div
                      v-if="item.isSortable === false"
                      class="text"
                    >
                      {{ item.name }}
                    </div>
                    <div
                      v-else
                      class="text"
                      @click="sortData(item)"
                    >
                      {{ item.name }}
                      <sort-passive v-if="!item.sort" />
                      <sort-a-z v-if="item.sort === 'asc'" />
                      <sort-z-a v-if="item.sort === 'desc'" />
                    </div>
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                <Coin1inchListItem
                  v-if="coinsData"
                  :coins-list="coinsData"
                />
              </tbody>
            </table>
            <no-data-block v-if="(!coinsData || !coinsData.length) && !isLoading" />
          </div>
        </div>
        <paged-pagination
          v-if="coinsData && coinsData.length"
          :total="batch.length - 1"
          :current="page"
          @change-page="pageHandler"
          @prev-page="pageHandler"
          @next-page="pageHandler"
        />
      </div>
      <div
        v-if="isMobile()"
        class="addButton"
      >
        <add-plus @click="addCoinInfo" />
      </div>
      <EditCoinModal
        v-if="coinData"
        :data="coinData"
      />
      <AddCoinModal v-if="isAddCoin" />
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import filterMixin from "@/mixins/filter.mixin";
import helperMixin from "@/mixins/helper.mixin";
import AddCoinModal from "@/pages/coins/sub-pages/partials/AddCoinModal";
import AddIcon from "@/icons/addIcon";
import {TABLE_1INCH_TH} from "@/pages/coins/sub-pages/coin1inchData";
import SortZA from "@/icons/sorting/sort-z-a";
import SortPassive from "@/icons/sorting/sort-passive";
import SortAZ from "@/icons/sorting/sort-a-z";
import Coin1inchListItem from "@/pages/coins/sub-pages/partials/Coin1inchListItem";
import CoinsService from "@/services/coins.service";
import PagedPagination from "@/components/admin/PagedPagiantion.vue";
import EditCoinModal from "@/pages/coins/sub-pages/partials/EditCoinModal";
import AddPlus from "@/icons/addPlus";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import Loading from 'vue-loading-overlay';

export default {
  name: "Coins1inchList",
  components: {
    NoDataBlock,
    AddPlus,
    EditCoinModal,
    PagedPagination,
    Coin1inchListItem, SortAZ, SortPassive, SortZA, AddIcon, AddCoinModal, AccountLayout, Loading},
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      isAddCoin: false,
      isEditCoin: false,
      isLoading: false,
      originCoins: null,
      currentSorting: 'id',
      batch: [],
      page: 1,
      coinsData: [],
      currentPerPage: 20,
      options: {
        chunk: 4
      },
      currentIndex: false,
      tableTh: TABLE_1INCH_TH,
      coinData: null,
      coinsList: null
    }
  },
  mounted() {
    this.emitter.on('coin-add-event', (status) => {
      this.isAddCoin = status
    })
    if (this.$route.params.id) {
      this.getCoinDataById();
    }
    this.emitter.on('coin-info-event', (data) => {
      this.coinData = data
    })

    this.getCoinsData();
  },
  methods: {
    getCoinsData() {
      this.isLoading = true
      CoinsService.getCoins1inchList().then((res) => {
        this.coinsList = res.data.result
        this.originCoins = [...this.coinsList];
        const batch = this.batchData(this.coinsList, this.currentPerPage);
        this.coinsData = batch.current;
        this.batch = batch.total;
        this.isLoading = false
      })
    },
    addCoinInfo() {
      this.isAddCoin = true
    },
    getCoinDataById() {
      CoinsService.getCoin1inchById(this.$route.params.id).then((res) => {
        this.emitter.emit('coin-info-event', res.data.result)
      })
    },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      switch (type.key) {
        case 'id':
          this.coinsList = this.coinsList.sort((a, b) => {
            return type.sort === 'asc' ?  b.id - a.id : a.id - b.id;
          });
          break;

        case 'name':
          this.coinsList = this.coinsList.sort((a, b) => {
            const aCount = a.name.localeCompare(b.name);
            const bCount = b.name.localeCompare(a.name);

            return type.sort === 'asc' ? aCount : bCount;
          });
          break;

        case 'shotName':
          this.coinsList = this.coinsList.sort((a, b) => {
            const aCount = a.shotName.localeCompare(b.shotName);
            const bCount = b.shotName.localeCompare(a.shotName);

            return type.sort === 'asc' ? aCount : bCount;
          });
          break;

        case 'status':
          this.coinsList = this.coinsList.sort((a, b) => {
            return type.sort === 'asc' ? a.isActive - b.isActive : b.isActive - a.isActive;
          });
          break;
      }
      this.coinsData = this.batchData(this.coinsList, this.currentPerPage).current;
    },
    searchCoin(el) {
      const value = el.target.value.toLowerCase();

      this.coinsList = this.originCoins;

      if (value.length) {
        this.coinsList = this.coinsList.filter((coin) => {
          const isName = coin.name.toLowerCase().indexOf(value) > -1;
          const isShotName = coin.shotName.toLowerCase().indexOf(value) > -1;

          return isName || isShotName;
        });
      }

      this.coinsData = this.batchData(this.coinsList, this.currentPerPage).current;
    },
    pageHandler(page) {
      this.coinsData = this.batch[page];
    },
  },
}
</script>

<style lang="scss">
@import "../../../assets/styles/pages/coins/coins";
.coins1inch {
  .table-id {
    @media (max-width: 1079px) {
      width: 4%;
      position: sticky;
      top: 0;
      bottom: 0;
      z-index: 99;
      left: 0;

      &.sticky {
        background: white;
        box-shadow: 1px 0 2px rgba(27, 26, 28, 0.16);
      }
    }
  }
  .table-block-header-filters-add-btn {
    @media (max-width: 1079px) {
      margin: 20px 0;
    }
  }
}

</style>
