<script>

import UserService from "@/services/user.service";
import SearchIcon from "@/icons/searchIcon.vue";
import CoinsService from "@/services/coins.service";
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import FakeService from "@/services/fake.service";

export default {
  name: 'RateCorrectionCreate',
  components: {SearchIcon, simplebar},
  data() {
    return {
      isShowPartners: false,
      isShowCoins: false,
      isShowCoinsFrom: false,
      isShowCoinsTo: false,
      partnerSearchValue: '',
      coinSearchValue: '',
      coinFromSearchValue: '',
      coinToSearchValue: '',
      partnersList: null,
      partnersListOrigin: null,
      coinsList: null,
      coinsListOrigin: null,
      selectedPartner: {
        login: 'All partners'
      },
      selectedCoinFrom: {},
      selectedCoinTo: null,
      correctionType: 'coin',
      isExistFake: false,
      routeFake: null,
      rules: [
        {
          minAmount: 0,
          maxAmount: 0,
          percent: 0,
          isActive: true
        }
      ]
    }
  },
  watch: {
    selectedPartner() {
      this.getValidate();
    },
    selectedCoinFrom() {
      this.getValidate();
    },
    selectedCoinTo() {
      this.getValidate();
    },
    correctionType(newVal) {
      if (newVal === 'coin') {
        this.selectedCoinTo = null;
      } else {
        this.setDefaultCoin()
      }
      this.getValidate();
    }
  },
  mounted() {
    console.log(this.$route, 'create');
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closePopup()
      }
    });
    this.getPartnerList();
    this.getCoinsListData();
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closePopup();
      }
    });
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    getFakeById() {
      const idItem = this.routeFake;
      this.$router.push({name: 'Rate correction edit', params: {id: idItem}})
      this.emitter.emit('create-new-correction', false);
    },
    getValidate() {
      console.log('validate');

      let query

      if (this.correctionType === 'coin') {
        query = {
          partnerLogin: this.selectedPartner.login,
          coinFromName: this.selectedCoinFrom.name,
        };
      } else {
        query = {
          partnerLogin: this.selectedPartner.login,
          coinFromName: this.selectedCoinFrom.name,
          coinToName: this.selectedCoinTo.name
        };
      }

      FakeService.validateFake(query).then((res) => {
        if (res.data.result) {
          this.isExistFake = true;
          this.routeFake = res.data.result;
        } else {
          this.isExistFake = false;
          this.routeFake = null;
        }
      })
    },
    handleClickOutside(event) {
      if (this.$refs.partners && !this.$refs.partners.contains(event.target)) {
        this.isShowPartners = false;
      }

      if (this.$refs.coins && !this.$refs.coins.contains(event.target)) {
        this.isShowCoins = false;
      }
    },
    closePopup() {
      this.emitter.emit('create-new-correction', false);
      this.$router.push({name: 'Rate correction'})
    },
    addNewRule() {
      this.rules.push(
          {
            minAmount: 0,
            maxAmount: 0,
            percent: 0,
            isActive: true
          }
      )
    },
    removeRule(index) {
      if (index > 0 && index < this.rules.length) {
        this.rules.splice(index, 1);
      }
    },
    incrementPercent(index) {
      const currentPercent = parseFloat(this.rules[index].percent);
      if (!isNaN(currentPercent)) {
        this.rules[index].percent = (Math.round((currentPercent + 0.1) * 100) / 100).toFixed(2);
      }
    },
    decrementPercent(index) {
      const currentPercent = parseFloat(this.rules[index].percent);
      if (!isNaN(currentPercent) && currentPercent >= 0.1) {
        this.rules[index].percent = (Math.round((currentPercent - 0.1) * 100) / 100).toFixed(2);
      }
    },
    setDefaultCoin() {
      this.selectedCoinFrom = this.coinsList.find((item) => {
        return item.shortName === 'USDT'
      })

      if (this.correctionType === 'pair') {
        this.selectedCoinTo = this.coinsList.find((item) => {
          return item.shortName === 'BTC'
        })
      }
    },
    getCoinsListData() {
      CoinsService.getCoinsList().then((res) => {
        this.coinsList = res.data.result;
        this.coinsListOrigin = res.data.result;
        if (!this.selectedCoinFrom.shortName) {
          this.setDefaultCoin();
        }
      })
    },
    dropPartners() {
      this.isShowPartners = !this.isShowPartners
    },
    searchPartnerHandler(el) {
      const value = el.target.value.toLowerCase();

      if (el.inputType === 'deleteContentBackward' && value.length === 0) {
        this.partnersList = this.partnersListOrigin;
        return;
      }

      if (value.length) {
        this.partnersList = this.partnersListOrigin.filter((partner) => {
          return partner.login.toLowerCase().indexOf(value) > -1;
        });
      } else {
        this.partnersList = this.partnersListOrigin;
      }
    },
    searchCoin(el) {
      const value = el.target.value.toLowerCase();

      this.coinsList = this.coinsListOrigin;

      if (value && value.length) {
        this.coinsList = this.coinsList.filter((coin) => {
          const isName = coin.name.toLowerCase().indexOf(value) > -1;
          const isShotName = coin.shotName.toLowerCase().indexOf(value) > -1;

          return isName || isShotName;
        });
      } else {
        this.isSearch = false
      }
    },
    getPartnerList() {
      UserService.getUserFavorite().then((res) => {
        this.partnersList = res.data.result;
        this.partnersListOrigin = res.data.result;

        console.log(this.partnersList);
      })
    },
    createNew() {
      let login = this.selectedPartner.login

      if (login === 'All partners') {
        login = null
      }

      let query

      if (this.correctionType === 'coin') {
        query = {
          partnerLogin: login,
          coinFromName: this.selectedCoinFrom.name,
          coinToName: null,
          isActive: true,
          rows: this.rules,
          coinType: this.correctionType
        }
      } else {
        query = {
          partnerLogin: login,
          coinFromName: this.selectedCoinFrom.name,
          coinToName: this.selectedCoinTo.name,
          isActive: true,
          rows: this.rules,
          coinType: this.correctionType
        }
      }

      FakeService.createFake(query).then((res) => {
        this.emitter.emit('update-rate-correction');
        this.closePopup()
      }).catch((error) => {
        console.log(error);
      })
    },
    modalOverlayClose(event) {
      console.log(event.target , this.$refs.modalOverlay)
      if (event.target === this.$refs.modalOverlay) {
        this.closePopup()
      }
    },
  }
}

</script>

<template>
  <div
    ref="modalOverlay"
    class="modal"
    @click="modalOverlayClose"
  >
    <div class="popup">
      <div class="head">
        <div class="title">
          New correction
        </div>
        <div
          class="close"
          @click="closePopup"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4300_72996)">
              <path
                d="M18.2987 5.7107C17.9087 5.3207 17.2787 5.3207 16.8887 5.7107L11.9988 10.5907L7.10875 5.7007C6.71875 5.3107 6.08875 5.3107 5.69875 5.7007C5.30875 6.0907 5.30875 6.7207 5.69875 7.1107L10.5888 12.0007L5.69875 16.8907C5.30875 17.2807 5.30875 17.9107 5.69875 18.3007C6.08875 18.6907 6.71875 18.6907 7.10875 18.3007L11.9988 13.4107L16.8887 18.3007C17.2787 18.6907 17.9087 18.6907 18.2987 18.3007C18.6887 17.9107 18.6887 17.2807 18.2987 16.8907L13.4087 12.0007L18.2987 7.1107C18.6787 6.7307 18.6787 6.0907 18.2987 5.7107V5.7107Z"
                fill="#9FA2B4"
              />
            </g>
            <defs>
              <clipPath id="clip0_4300_72996">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <simplebar class="inputs">
        <div
          class="selectors"
          :class="{'selectors-validate': isExistFake}"
        >
          <div class="type">
            <div class="title">
              Type:
            </div>
            <div class="checkbox_block">
              <div
                class="item"
                @click="correctionType = 'coin'"
              >
                <div class="checkbox">
                  <svg
                    v-if="correctionType !== 'coin'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="3"
                      y="3"
                      width="18"
                      height="18"
                      rx="9"
                      fill="white"
                    />
                    <rect
                      x="3.5"
                      y="3.5"
                      width="17"
                      height="17"
                      rx="8.5"
                      stroke="#1E2231"
                      stroke-opacity="0.12"
                    />
                  </svg>
                  <svg
                    v-else
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4300_72935)">
                      <rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="9"
                        fill="#0A68F7"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.5303 8.46967C16.8232 8.76256 16.8232 9.23744 16.5303 9.53033L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.46967 12.5303C7.17678 12.2374 7.17678 11.7626 7.46967 11.4697C7.76256 11.1768 8.23744 11.1768 8.53033 11.4697L10.5 13.4393L15.4697 8.46967C15.7626 8.17678 16.2374 8.17678 16.5303 8.46967Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4300_72935">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                Coin
              </div>
              <div
                class="item"
                @click="correctionType = 'pair'"
              >
                <div class="checkbox">
                  <svg
                    v-if="correctionType !== 'pair'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="3"
                      y="3"
                      width="18"
                      height="18"
                      rx="9"
                      fill="white"
                    />
                    <rect
                      x="3.5"
                      y="3.5"
                      width="17"
                      height="17"
                      rx="8.5"
                      stroke="#1E2231"
                      stroke-opacity="0.12"
                    />
                  </svg>
                  <svg
                    v-else
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4300_72935)">
                      <rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="9"
                        fill="#0A68F7"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.5303 8.46967C16.8232 8.76256 16.8232 9.23744 16.5303 9.53033L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.46967 12.5303C7.17678 12.2374 7.17678 11.7626 7.46967 11.4697C7.76256 11.1768 8.23744 11.1768 8.53033 11.4697L10.5 13.4393L15.4697 8.46967C15.7626 8.17678 16.2374 8.17678 16.5303 8.46967Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4300_72935">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                Pair
              </div>
            </div>
          </div>
          <div class="select-items">
            <div
              v-if="selectedCoinFrom && correctionType === 'coin'"
              ref="coins"
              class="item"
            >
              <div class="name">
                Coin
              </div>
              <div
                class="input"
                @click="isShowCoins = !isShowCoins"
              >
                {{ selectedCoinFrom.shortName }} - {{ selectedCoinFrom.shotName }}
                <div class="drop">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4301_75354)">
                      <path
                        d="M13.825 7.1582L10 10.9749L6.175 7.1582L5 8.3332L10 13.3332L15 8.3332L13.825 7.1582Z"
                        fill="#9FA2B4"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4301_75354">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div
                v-if="isShowCoins"
                class="dropdown"
              >
                <div class="search">
                  <search-icon />
                  <input
                    v-model="coinSearchValue"
                    type="text"
                    placeholder="Coin search"
                    @input="searchCoin"
                    @keyup.enter="searchCoin"
                  >
                </div>
                <div class="dropdown_list">
                  <div
                    v-for="(item, index) in coinsList"
                    :key="index"
                    class="dropdown_list--item"
                    @click="selectedCoinFrom = item; isShowCoins = false"
                  >
                    {{ item.shortName }} - {{ item.shotName }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="correctionType === 'pair'"
              class="pair"
            >
              <div
                v-if="selectedCoinFrom"
                ref="coinFrom"
                class="item"
              >
                <div class="name">
                  1st coin
                </div>
                <div
                  class="input"
                  @click="isShowCoinsFrom = !isShowCoinsFrom; isShowCoinsTo = false"
                >
                  {{ selectedCoinFrom.shortName }}
                  <div class="drop">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4301_75354)">
                        <path
                          d="M13.825 7.1582L10 10.9749L6.175 7.1582L5 8.3332L10 13.3332L15 8.3332L13.825 7.1582Z"
                          fill="#9FA2B4"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4301_75354">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div
                  v-if="isShowCoinsFrom"

                  class="dropdown"
                >
                  <div class="search">
                    <search-icon />
                    <input
                      v-model="coinFromSearchValue"
                      type="text"
                      placeholder="Coin search"
                      @input="searchCoin"
                      @keyup.enter="searchCoin"
                    >
                  </div>
                  <div class="dropdown_list">
                    <div
                      v-for="(item, index) in coinsList"
                      :key="index"
                      class="dropdown_list--item"
                      @click="selectedCoinFrom = item; isShowCoinsFrom = false"
                    >
                      {{ item.shortName }} - {{ item.shotName }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="selectedCoinTo"
                ref="coinTo"
                class="item"
              >
                <div class="name">
                  2nd coin
                </div>
                <div
                  class="input"
                  @click="isShowCoinsTo = !isShowCoinsTo; isShowCoinsFrom = false"
                >
                  {{ selectedCoinTo.shortName }}
                  <div class="drop">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4301_75354)">
                        <path
                          d="M13.825 7.1582L10 10.9749L6.175 7.1582L5 8.3332L10 13.3332L15 8.3332L13.825 7.1582Z"
                          fill="#9FA2B4"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4301_75354">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div
                  v-if="isShowCoinsTo"
                  class="dropdown"
                >
                  <div class="search">
                    <search-icon />
                    <input
                      v-model="coinToSearchValue"
                      type="text"
                      placeholder="Coin search"
                      @input="searchCoin"
                      @keyup.enter="searchCoin"
                    >
                  </div>
                  <div class="dropdown_list">
                    <div
                      v-for="(item, index) in coinsList"
                      :key="index"
                      class="dropdown_list--item"
                      @click="selectedCoinTo = item; isShowCoinsTo = false"
                    >
                      {{ item.shortName }} - {{ item.shotName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              ref="partners"
              class="item"
            >
              <div class="name">
                Partners
              </div>
              <div
                class="input"
                @click="dropPartners"
              >
                {{ selectedPartner.login }}
                <div class="drop">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4301_75354)">
                      <path
                        d="M13.825 7.1582L10 10.9749L6.175 7.1582L5 8.3332L10 13.3332L15 8.3332L13.825 7.1582Z"
                        fill="#9FA2B4"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4301_75354">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div
                v-if="isShowPartners"
                class="dropdown"
              >
                <div class="search">
                  <search-icon />
                  <input
                    v-model="partnerSearchValue"
                    type="text"
                    placeholder="Login search"
                    @input="searchPartnerHandler"
                    @keyup.enter="searchPartnerHandler"
                  >
                </div>
                <div class="dropdown_list">
                  <div
                    class="dropdown_list--item"
                    @click="selectedPartner = {login: 'All partners'}; isShowPartners = false"
                  >
                    All partners
                  </div>
                  <div
                    class="dropdown_list--item"
                    @click="selectedPartner = {login: 'Direct'}; isShowPartners = false"
                  >
                    Direct
                  </div>
                  <div
                    v-for="(item, index) in partnersList"
                    :key="index"
                    class="dropdown_list--item"
                    @click="selectedPartner = item; isShowPartners = false"
                  >
                    {{ item.login }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isExistFake"
          class="validate"
        >
          <div class="error">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4374_198)">
                <path
                  d="M11.2 8H12.8V9.6H11.2V8ZM11.2 11.2H12.8V16H11.2V11.2ZM12 4C7.584 4 4 7.584 4 12C4 16.416 7.584 20 12 20C16.416 20 20 16.416 20 12C20 7.584 16.416 4 12 4ZM12 18.4C8.472 18.4 5.6 15.528 5.6 12C5.6 8.472 8.472 5.6 12 5.6C15.528 5.6 18.4 8.472 18.4 12C18.4 15.528 15.528 18.4 12 18.4Z"
                  fill="#FF783E"
                />
              </g>
              <defs>
                <clipPath id="clip0_4374_198">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>

            This rule already exists
          </div>
          <div
            class="validate_button"
            @click="getFakeById()"
          >
            Go to edit it
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4374_205)">
                <path
                  d="M12.5013 4.16602L11.3263 5.34102L15.143 9.16602H1.66797V10.8327H15.143L11.318 14.6577L12.5013 15.8327L18.3346 9.99935L12.5013 4.16602Z"
                  fill="#0A68F7"
                />
              </g>
              <defs>
                <clipPath id="clip0_4374_205">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div
          class="rules"
        >
          <div
            v-for="(rule, index) in rules"
            :key="index"
            class="rules--item"
          >
            <div class="rules--item_head">
              <div class="rules--item_head-title">
                Rule {{ index + 1 }}
              </div>
              <div
                class="delete"
                @click="removeRule(index)"
              >
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 27C12.45 27 11.9792 26.8042 11.5875 26.4125C11.1958 26.0208 11 25.55 11 25V12H10V10H15V9H21V10H26V12H25V25C25 25.55 24.8042 26.0208 24.4125 26.4125C24.0208 26.8042 23.55 27 23 27H13ZM23 12H13V25H23V12ZM15 23H17V14H15V23ZM19 23H21V14H19V23Z"
                    fill="#DFE0EB"
                  />
                </svg>
              </div>
            </div>
            <div class="rules--item_inputs">
              <div class="rules--item_inputs--item">
                <div class="name">
                  Min amount
                </div>
                <div class="input">
                  <input
                    v-model="rule.minAmount"
                    type="number"
                    placeholder="0.00"
                  >
                </div>
              </div>
              <div class="rules--item_inputs--item">
                <div class="name">
                  Max amount
                </div>
                <div class="input">
                  <input
                    v-model="rule.maxAmount"
                    type="number"
                    placeholder="0.00"
                  >
                </div>
              </div>
              <div class="rules--item_inputs--item rules--item_inputs--item-correction">
                <div class="name">
                  Correction %
                </div>
                <div class="input">
                  <div
                    class="input-correction input-correction_minus"
                    @click="decrementPercent(index)"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="28"
                        height="28"
                        rx="2"
                        transform="matrix(1 0 0 -1 0 28)"
                        fill="#9FA2B4"
                        fill-opacity="0.1"
                      />
                      <g clip-path="url(#clip0_4300_72964)">
                        <path
                          d="M19.8346 14.8327H8.16797V13.166H19.8346V14.8327Z"
                          fill="#9FA2B4"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4300_72964">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(4 4)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <input
                    v-model="rule.percent"
                    type="number"
                    placeholder="0"
                  >
                  <div
                    class="input-correction input-correction_plus"
                    @click="incrementPercent(index)"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="28"
                        height="28"
                        rx="2"
                        fill="#9FA2B4"
                        fill-opacity="0.1"
                      />
                      <g clip-path="url(#clip0_4300_72958)">
                        <path
                          d="M19.8346 14.8327H14.8346V19.8327H13.168V14.8327H8.16797V13.166H13.168V8.16602H14.8346V13.166H19.8346V14.8327Z"
                          fill="#9FA2B4"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4300_72958">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(4 4)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="add-new"
            @click="addNewRule"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4300_73003)">
                <path
                  d="M15.0013 10.8327H10.8346V14.9994C10.8346 15.4577 10.4596 15.8327 10.0013 15.8327C9.54297 15.8327 9.16797 15.4577 9.16797 14.9994V10.8327H5.0013C4.54297 10.8327 4.16797 10.4577 4.16797 9.99935C4.16797 9.54102 4.54297 9.16602 5.0013 9.16602H9.16797V4.99935C9.16797 4.54102 9.54297 4.16602 10.0013 4.16602C10.4596 4.16602 10.8346 4.54102 10.8346 4.99935V9.16602H15.0013C15.4596 9.16602 15.8346 9.54102 15.8346 9.99935C15.8346 10.4577 15.4596 10.8327 15.0013 10.8327Z"
                  fill="#0A68F7"
                />
              </g>
              <defs>
                <clipPath id="clip0_4300_73003">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>

            Add new
          </div>
        </div>
      </simplebar>
      <div class="footer">
        <div class="buttons">
          <div
            class="buttons--item buttons--item_cancel"
            @click="closePopup"
          >
            Cancel
          </div>
          <div
            class="buttons--item buttons--item_create"
            @click="createNew"
          >
            Create rules
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.modal {
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  position: fixed;
  background: rgba(67, 40, 164, 0.24);
  backdrop-filter: blur(10px);
  display: grid;
  place-items: center;
  box-sizing: border-box;

  .simplebar-scrollbar::before {
    opacity: 1;
    background-color: rgba(223, 224, 235, 0.70);
  }

  .popup {
    min-width: 480px;
    border-radius: 16px;
    border-bottom: 1px solid #DFE0EB;
    background: #FFF;
    height: 90vh;
    max-height: 800px;
    display: flex;
    flex-direction: column;

    &_block {
      display: flex;
      flex-direction: row;
    }

    .head {
      position: relative;
      padding: 24px 24px 37px 24px;
      border-bottom: 1px solid #DFE0EB;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;

      .login {
        position: absolute;
        top: 60px;
        left: 24px;
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        &-logs {
          border-radius: 8px;
          background: rgba(10, 104, 247, 0.06);
          color: #0A68F7;
          font-size: 14px;
          padding: 6px 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          cursor: pointer;
          transition: all .3s ease-in-out;

          &:hover {
            background: rgba(10, 104, 247, 0.12);
          }
        }

        &-archive {
          display: flex;
          cursor: pointer;

          svg {
            path {
              transition: all .3s ease-in-out;
            }
          }

          &:hover {
            svg {
              path {
                fill: #0A68F7;
              }
            }
          }
        }

        &-toggle {
          display: grid;
          place-items: center;
          width: 36px;
          height: 36px;
          cursor: pointer;
        }
      }

      .title {
        color: #1B1A1C;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }

      .close {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;

        svg {
          path {
            transition: all .3s ease-in-out;
          }
        }

        &:hover {
          svg {
            path {
              fill: #0A68F7;
            }
          }
        }
      }
    }

    .inputs {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 620px;
      height: 100%;

      .selectors {
        padding: 12px 24px 39px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-bottom: 1px solid #DFE0EB;

        &-validate {
          padding: 12px 24px;
          border-bottom: none;
        }

        .type {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 19px;

          .title {
            color: var(--black, #1B1A1C);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
          }

          .checkbox_block {
            display: flex;
            flex-direction: row;
            align-items: center;

            .item {
              border-radius: 4px;
              color: var(--black, #1B1A1C);
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;
              width: 96px;
              padding: 12px 9px;
              box-sizing: border-box;
              cursor: pointer;

              .checkbox {
                display: flex;
              }
            }
          }
        }

        .select-items {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .pair {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 20px;

            .item {
              width: 100%;
            }
          }

          .item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: relative;

            .name {
              color: #0A68F7;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 114.286% */
            }

            .input {
              padding: 9px 5px 9px 15px;
              border-radius: 4px;
              border: 1px solid #DFE0EB;
              background: var(--grayscale-white, #FFF);
              color: var(--black, #1B1A1C);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 128.571% */
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              .drop {
                display: flex;
              }
            }

            .dropdown {
              max-width: 100%;
              top: calc(100% + 4px);
              position: absolute;
              z-index: 9;
              width: 100%;
              border-radius: 12px;
              background: var(--grayscale-white, #FFF);
              box-shadow: 0px 4px 24px 0px rgba(15, 0, 58, 0.12);

              .search {
                width: 100%;
                position: relative;
                padding: 11px;
                border-bottom: 1px solid #DFE0EB;
                box-sizing: border-box;

                svg {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto 0;
                  left: 20px;
                }

                input {
                  width: 100%;
                  box-sizing: border-box;
                  padding: 10px 10px 10px 45px;
                  border-radius: 8px;
                  border: 1px solid #F0F1F7;
                  background: #FCFDFE;
                }
              }

              &_list {
                overflow: auto;
                max-height: 300px;

                &--item {
                  padding: 8px 20px;
                  color: #1B1A1C;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; /* 171.429% */
                  cursor: pointer;
                  transition: all .3s ease-in-out;

                  &:hover {
                    background: rgba(27, 26, 28, 0.03);
                  }
                }

              }
            }
          }
        }
      }

      .validate {
        padding: 0 24px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        box-sizing: border-box;

        .error {
          display: flex;
          flex-direction: row;
          gap: 2px;
          align-items: center;
          color: var(--red, #FF783E);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }

        &_button {
          padding: 6px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: center;
          border-radius: 8px;
          background: rgba(10, 104, 247, 0.06);
          color: #0A68F7;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          transition: all .3s ease-in-out;
          cursor: pointer;

          &:hover {
            background: rgba(10, 104, 247, 0.12);
          }
        }
      }


      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }

      .rules {
        width: 480px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-height: 100%;
        box-sizing: border-box;

        &--item {
          padding: 18px 24px 32px 24px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          gap: 18px;

          &:not(:first-child) {
            border-top: 1px solid #DFE0EB;
          }

          &_head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &-rule {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
            }

            .num {
              min-width: 36px;
              padding: 0 4px;
              height: 36px;
              border-radius: 8px;
              opacity: 0.8;
              color: #DFE0EB;
              background: #FAFAFC;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 18px; /* 128.571% */
              display: grid;
              place-items: center;

              &-up {
                background: #D6F5E9;
                color: #02C076;
              }

              &-down {
                background: rgba(255, 120, 62, 0.20);
                color: #FF783E;
              }
            }

            &-title {
              color: var(--black, #1B1A1C);
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 18px;

              span {
                color: var(--black, #1B1A1C);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
              }
            }

            &-buttons {
              display: flex;
              flex-direction: row;
              gap: 8px;
              align-items: center;


              &--toggle {
                width: 36px;
                height: 36px;
                display: grid;
                place-items: center;
              }
            }


            .delete {
              display: flex;
              cursor: pointer;

              svg {
                path {
                  transition: all .3s ease-in-out;
                }
              }

              &:hover {
                svg {
                  path {
                    fill: #0A68F7;
                  }
                }
              }
            }
          }

          &_inputs {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            &--item {
              max-width: 140px;
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 8px;

              .name {
                color: #0A68F7;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 114.286% */
              }

              .input {
                input {
                  max-width: 100%;
                  box-sizing: border-box;
                  border-radius: 4px;
                  border: 1px solid #DFE0EB;
                  background: var(--grayscale-white, #FFF);
                  padding: 11px 15px;
                  letter-spacing: 0.4px;

                  &:focus {
                    outline: none;
                  }

                  &::placeholder {
                    color: var(--black, #1B1A1C);
                  }
                }
              }

              &-correction {
                max-width: 112px;

                .name {
                  text-align: center;
                }

                .input {
                  position: relative;

                  &-correction {
                    position: absolute;
                    top: 6px;
                    left: 6px;
                    cursor: pointer;

                    &_plus {
                      left: unset;
                      right: 6px;
                    }
                  }


                  input {
                    text-align: center;

                    &::placeholder {
                      text-align: center;
                    }
                  }
                }
              }
            }
          }

          &_not-valid {
            .rules--item_head-rule {
              .rules--item_head-title {
                color: #FF783E !important;
              }
            }

            .rules--item_inputs--item {
              .name {
                color: #FF783E !important;
              }
            }
          }
        }

        .add-new {
          width: 106px;
          margin-left: 24px;
          padding: 6px;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: center;
          border-radius: 8px;
          background: rgba(10, 104, 247, 0.06);
          color: #0A68F7;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          transition: all .3s ease-in-out;
          cursor: pointer;
          margin-bottom: 32px;

          &:hover {
            background: rgba(10, 104, 247, 0.12);
          }
        }
      }

      .logs {
        display: flex;
        border-left: 1px solid #DFE0EB;
        height: 100%;
        width: 480px;
        padding: 20px 24px;
        box-sizing: border-box;

        .simplebar-content {
          display: flex;
          flex-direction: column;
          gap: 24px;

          &::before {
            display: none;
          }
        }

        &--item {
          display: flex;
          flex-direction: column;

          &_info {
            color: #7C86A0;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;

            span {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;
            }
          }

          &_title {
            color: var(--text, #131521);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            margin-bottom: 6px;
          }

          &_description {
            color: var(--text, #131521);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }

    .footer {
      padding: 17px 24px 24px 24px;
      border-top: 1px solid #DFE0EB;
      box-sizing: border-box;
      position: sticky;
      bottom: 0;
      left: 0;
      background: white;
      border-radius: 0 0 16px 16px;


      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;

        &--item {
          width: 160px;
          height: 48px;
          display: grid;
          place-items: center;
          border-radius: 8px;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          transition: all .3s ease-in-out;
          cursor: pointer;

          &_cancel {
            color: #0A68F7;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */

            &:hover {
              background: rgba(10, 104, 247, 0.06);
            }
          }

          &_create {
            color: #FFF;
            background: #0A68F7;

            &:hover {
              background: rgba(10, 104, 247, 0.92);
            }
          }
        }

      }
    }

    &_not-active {
      color: #7C86A0 !important;

      .inputs .rules--item_head-title {
        color: #7C86A0 !important;
      }

      .head .title {
        color: #7C86A0 !important;
      }
    }
  }
}
</style>
