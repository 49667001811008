<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import RateRequestTableDate from "@/pages/rate-request/sub-pages/by-date/RateRequestTableDate.vue";
import RateRequestChart from "@/pages/rate-request/partials/RateRequestChart.vue";

export default {
  name: 'RateRequestDate',
  components: {RateRequestChart, RateRequestTableDate, AccountLayout},
}

</script>

<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>Rate request</h2>

        <div class="layout-header_tabs">
          <div class="layout-header_tabs-item layout-header_tabs-item--active">
            By date
          </div>
          <router-link
            :to="{ name: 'Rate request pairs' }"
            class="layout-header_tabs-item"
          >
            By pairs
          </router-link>
          <router-link
            :to="{ name: 'Rate request coins' }"
            class="layout-header_tabs-item"
          >
            Not found
          </router-link>

          <!--          <div class="layout-header_tabs-item">-->
          <!--            Binance: total-->
          <!--          </div>-->
        </div>
      </div>
    </template>
    <template #content>
      <RateRequestChart />
      <rate-request-table-date />
    </template>
  </AccountLayout>
</template>

<style lang="scss">

</style>
