export const LAST_ORDER_PARTNER_TABLE_TH = [
    {
        name: 'Partner',
        sort: '',
        key: 'partner',
        isSortable: true
    },
    {
        name: 'Count orders',
        sort: '',
        key: 'countOrders',
        isSortable: true
    },
    {
        name: 'Completed orders',
        sort: '',
        key: 'completedOrder',
        isSortable: true
    },
    {
        name: 'Profit, %',
        sort: null,
        key: 'profit',
        isSortable: false
    },
    {
        name: 'Last order',
        sort: null,
        key: 'lastOrder',
        isSortable: false
    },
    {
        name: 'Last order date',
        sort: '',
        key: 'lastOrderTime',
        isSortable: true
    },
];
