export const TRADE_ORDERS_TABLE_TH = [
    {
        name: 'Order ID',
        sort: false,
        key: 'order-id'
    },
    {
        name: 'Order time',
        sort: false,
        key: 'order-time',
    },
    {
        name: 'Pair',
        sort: false,
        key: 'pair',
    },
    {
        name: 'Mode',
        sort: false,
        key: 'mode',
    },
    {
        name: 'Side',
        sort: false,
        key: 'side',
    },
    {
        name: 'Type',
        sort: false,
        key: 'type',
    },
    {
        name: 'Price',
        sort: false,
        key: 'price',
    },
    {
        name: 'Executed',
        sort: false,
        key: 'executed',
    },
    {
        name: 'Amount',
        sort: false,
        key: 'amount',
    },
    {
        name: 'Status',
        sort: false,
        key: 'status',
    },
    {
        name: '',
        sort: false,
        key: 'comment',
    }
];
