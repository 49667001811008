<script>
import TradeNav from "@/components/trade/TradeNav.vue";
import UserProfile from "@/components/UserProfile.vue";

export default {
  name: "TradePopupLayout",
  components: {UserProfile, TradeNav},
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    isHeader: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<template>
  <div class="trade-layout">
    <trade-nav />
    <div class="page">
      <div
        v-if="isHeader"
        class="trade-layout_header"
      >
        <div class="title">
          <div
            class="back"
            @click="goBack"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3860_5780)">
                <path
                  d="M13.9963 18L15.4062 16.59L10.8263 12L15.4063 7.41L13.9963 6L7.99625 12L13.9963 18Z"
                  fill="#1A242B"
                />
              </g>
              <defs>
                <clipPath id="clip0_3860_5780">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="matrix(-1 0 0 -1 24 24)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          {{ title }}
        </div>
        <UserProfile />
      </div>
      <slot name="tradePopupSlot" />
    </div>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.trade-layout {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  font-family: 'Rubik', sans-serif;

  .page {
    background: #F4F8FE;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 34px 32px 0;
  }

  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      color: var(--trade-trade-black, #1A242B);
      font-family: 'Rubik', sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px; /* 125% */
      letter-spacing: -0.4px;

      .back {
        width: 40px;
        height: 40px;
        display: grid;
        place-items: center;
        border-radius: 8px;
        background: rgba(130, 170, 200, 0.12);
        cursor: pointer;
        transition: all .3s ease-in-out;

        svg {
          path {
            transition: all .3s ease-in-out;
          }
        }

        &:hover {
          background: #1A242B;

          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }
}
</style>
