<template>
  <div class="avatar">
    <img
      v-if="img"
      :src="img"
    >
    <empty-logo v-else />
  </div>
</template>

<script>
    import EmptyLogo from "../../icons/emptyAvatarCoin1inch.vue";

    export default {
        name: "AvatarCoin1inch",
        components: {EmptyLogo},
        props: {
            data: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                img: '',
            }
        },
        mounted() {
            if (this.data) {
                this.img = this.data.avatar;
            }
        },
    }
</script>

<style scoped>

</style>
