<template>
  <tr
    v-for="(stat, index) in statsList"
    :key="index"
    class="table-row"
  >
    <td class="table-cell table-id">
      {{ stat.id }}
    </td>
    <td class="table-cell table-login">
      {{ stat.login }}
    </td>
    <td class="table-cell table-cnt">
      {{ stat.cnt }}
    </td>
    <td class="table-cell table-profit">
      {{ formatNumber(stat.profit) }}
    </td>
    <td class="table-cell table-payments">
      {{ formatNumber(stat.payments) }}
    </td>
    <td
      class="table-cell table-credit"
    >
      {{ creditCalculate(stat) }}
    </td>
    <td class="table-cell table-payments">
      <alert-progress
        :value="debtHandler(creditCalculate(stat))"
      />
    </td>
    <td class="table-cell table-edit">
      <img
        src="../../../assets/img/icons/pay.svg"
        alt=""
        @click="payInfo(stat)"
      >
    </td>
  </tr>
</template>

<script>
import AlertProgress from "@/components/admin/AlertProgress.vue";

export default {
  name: "PartnerStatItem",
  components: {
      AlertProgress,
  },
  props: {
    statsList: {
      type: Array,
      default: null,
    },
    alertData: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.$store.dispatch('getPartnersStats');
    this.scrollHandler();
  },
  methods: {
      formatNumber(num) {
          const number = parseFloat(num);
          if (number !== 0) {
              const [integerPart, decimalPart] = number.toFixed(5).split(".");
              const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              return `${formattedIntegerPart}.${decimalPart}`;
          } else {
              return number.toString();
          }
      },
      creditCalculate(stat) {
          if (stat) {
              const profit = parseFloat(stat.profit);
              const payments = parseFloat(stat.payments);
              return this.formatNumber(profit - payments);
          }
      },
      debtHandler(stat) {
          const number = parseFloat(stat);
          let percentage = Math.abs((number / this.alertData) * 100);
          percentage = Math.min(percentage, 100);
          return parseFloat(percentage.toFixed(2));
      },
      scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-id');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 40) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    payInfo(stat) {
      this.$store.commit('setPartnerStatPayData', stat);
    },
  }
}
</script>

<style lang="scss">

.table-row {
  .table-credit {
    &-up {
      color: #02C076 !important;
    }

    &-down {
      color: #ff5959 !important;
    }
  }

  .table-payments {
    .lv-progressbar {
      width: 48px;
      height: 4px;
      background: #F0F1F7;
    }
  }
}

</style>
