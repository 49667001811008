export const TABLE_TH = [
    {
        name: 'Coin',
        sort: '',
        id: 'coin',
        key: 'coin',
        description: '',
        isSortable: true,
    },
    {
        name: 'New',
        sort: '',
        id: 'new',
        key: 'new',
        description: '',
        isSortable: false,
    },
    {
        name: 'Unconfirmed',
        sort: '',
        id: 'unconfirmed',
        key: 'unconfirmed',
        description: '',
        isSortable: false,
    },
    {
        name: 'Sent',
        sort: '',
        id: 'sent',
        key: 'sent',
        description: '',
        isSortable: false,
    },
    {
        name: 'Closed',
        sort: '',
        id: 'closed',
        key: 'closed',
        description: '',
        isSortable: false,
    },
    {
        name: 'Overdue',
        sort: '',
        id: 'overdue',
        key: 'overdue',
        description: '',
        isSortable: false,
    },
    {
        name: 'On pause',
        sort: '',
        id: 'onPause',
        key: 'onPause',
        description: '',
        isSortable: false,
    },
    {
        name: 'On hold',
        sort: '',
        id: 'onHold',
        key: 'onHold',
        description: '',
        isSortable: false,
    },
    {
        name: 'Service on hold',
        sort: '',
        id: 'serviceOnHold',
        key: 'serviceOnHold',
        description: '',
        isSortable: false,
    },
    {
        name: 'Wrong network',
        sort: '',
        id: 'wrongNetwork',
        key: 'wrongNetwork',
        description: '',
        isSortable: false,
    },
    {
        name: 'Disabled',
        sort: '',
        id: 'disabled',
        key: 'disabled',
        description: '',
        isSortable: false,
    },
    {
        name: 'Routing',
        sort: '',
        id: 'routing',
        key: 'routing',
        description: '',
        isSortable: false,
    },
    {
        name: 'Stopped',
        sort: '',
        id: 'stopped',
        key: 'stopped',
        description: '',
        isSortable: false,
    },
    {
        name: 'Refunded',
        sort: '',
        id: 'refunded',
        key: 'refunded',
        description: '',
        isSortable: false,
    },
    {
        name: 'Refill',
        sort: '',
        id: 'refill',
        key: 'refill',
        description: '',
        isSortable: false,
    },
    {
        name: 'Checked',
        sort: '',
        id: 'checked',
        key: 'checked',
        description: '',
        isSortable: false,
    },
    {
        name: 'Receiving',
        sort: '',
        id: 'receiving',
        key: 'receiving',
        description: '',
        isSortable: false,
    },
    {
        name: 'Blacklisted',
        sort: '',
        id: 'blacklisted',
        key: 'blacklisted',
        description: '',
        isSortable: false,
    },
    {
        name: 'Segmented',
        sort: '',
        id: 'segmented',
        key: 'segmented',
        description: '',
        isSortable: false,
    },
    {
        name: 'Sending',
        sort: '',
        id: 'sending',
        key: 'sending',
        description: '',
        isSortable: false,
    },
    {
        name: 'Received',
        sort: '',
        id: 'received',
        key: 'received',
        description: '',
        isSortable: false,
    },
    {
        name: 'Refunding',
        sort: '',
        id: 'refunding',
        key: 'refunding',
        description: '',
        isSortable: false,
    },
    {
        name: 'Total',
        sort: '',
        id: 'total',
        key: 'total',
        description: '',
        isSortable: false,
    },
];

