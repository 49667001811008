<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.24"
      d="M6.2625 18.0125C7.77083 20.0875 9.68333 21.4167 12 22C14.3167 21.4167 16.2292 20.0875 17.7375 18.0125C19.2458 15.9375 20 13.6333 20 11.1V5L12 2L4 5V11.1C4 13.6333 4.75417 15.9375 6.2625 18.0125Z"
      fill="#02C076"
    />
    <path
      d="M10.95 15.55L16.6 9.89998L15.175 8.47498L10.95 12.7L8.85005 10.6L7.42505 12.025L10.95 15.55Z"
      fill="#02C076"
    />
  </svg>
</template>

<script>
export default {
  name: "AmlIconGreen"
}
</script>

<style scoped>

</style>
