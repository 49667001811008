export const TABLE_TH_MAIN = [
    {
        name: 'Coin',
        sort: '',
        key: 'coin',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Balance',
        sort: '',
        key: 'balance',
        description: 'Trade round is the latest round of trade negotiations'
    },
];

export const TABLE_TH_SUB = [
    {
        name: 'Email',
        sort: '',
        key: 'email',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Balance',
        sort: '',
        key: 'balance',
        description: 'Trade round is the latest round of trade negotiations'
    },
];
