import service from '../plugins/request';

class UserService {
    /**
     * @param login
     * @param password
     * @return {Promise<AxiosResponse<any>>}
     */
    login({login, password}) {
        return service.post('/api/User/login', {login, password});
    }

    /**
     * @param login
     * @param password
     * @param email
     * @returns {Promise<AxiosResponse<T>>}
     */
    registerPartner({login, password, email}) {
        return service.post('/api/User/partner', {login, password, email});
    }

    /**
     * @param login
     * @param password
     * @returns {Promise<AxiosResponse<T>>}
     */
    registerAdmin({login, password}) {
        return service.post('/api/User/createAdmin', {login, password});
    }

    /**
     * @param login
     * @return {Promise<AxiosResponse<any>>}
     */
    checkLogin(login) {
        return service.get('/api/User/checkLogin?login=' + login)
    }

    /**
     * @return {Promise<AxiosResponse<any>>}
     */
    getProfile() {
        return service.get('/api/User/profile')
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getUserById(id) {
        return service.get(`/api/User/admin/${id}`)
    }

    /**
     * @param id
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    editUserById(id, query) {
        return service.patch(`/api/User/admin/${id}/editPartner`, query)
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getProfileActivity(id) {
        return service.get('/api/User/activity?id=' + id)
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getUserIpList(id) {
        return service.get('/api/User/IpList?id=' + id)
    }

    /**
     * @param id
     * @param status
     * @returns {Promise<AxiosResponse<T>>}
     */
    updateStatus({id, status}) {
        return service.post( '/api/User/updateStatus', {id, status} )
    }

    /**
     * @param id
     * @param isIpList
     * @param ipList
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateIpList({id, isIpList, ipList}) {
        return service.post('api/User/IpList', {id, isIpList, ipList})
    }

    /**
     * @param oldPassword
     * @param newPassword
     * @return {Promise<AxiosResponse<any>>}
     */
    changePassword({oldPassword, newPassword}) {
        return service.patch('/api/User/password', {oldPassword, newPassword})
    }

    /**
     * @param id
     * @param password
     * @returns {Promise<AxiosResponse<T>>}
     */
    resetPassword({id, password}) {
        return service.post('/api/User/resetPassword', {id, password})
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getUserFavorite() {
        return service.get('/api/User/admin/favorite')
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    startShiftUser(query) {
        return service.get('/api/User/startShift' + query)
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    finishShiftUser(query) {
        return service.get('/api/User/finishShift' + query)
    }


    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getQrCode() {
        return service.get('/api/User/generateCode')
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCurrentAdmin() {
        return service.get('/api/User/currentAdmin')
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    generateQr() {
        return service.get('/api/User/generateCode')
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAdmins() {
        return service.get('/api/User/admins')
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getMarkets() {
        return service.get('/api/User/getMarkets')
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPartners() {
        return service.get('/api/User/GetPartners')
    }
}

export default new UserService();
