<template>
  <tr
    v-for="(item, index) in subAccounts"
    :key="index"
    class="table-row"
  >
    <td class="table-cell table-id">
      {{ item.id }}
    </td>
    <td class="table-cell table-name">
      {{ item.name }}
    </td>
    <td class="table-cell table-status">
      <true-status v-if="item.isActive === true" />
      <false-status v-if="item.isActive === false" />
    </td>
    <td class="table-cell table-status">
      <Toggle
        v-model="item.isBusy"
        @click="updateStatus(item)"
      />
    </td>
    <td class="table-cell table-lastUsed">
      {{ dataFormatting(item.lastUpdateDate) }}
    </td>
    <td class="table-cell table-edit">
      <img
        src="../../../assets/img/icons/edit-icon.svg"
        alt=""
        @click="getSubAccountsDataById(item.id)"
      >
    </td>
  </tr>
</template>

<script>
import TrueStatus from "@/icons/trueStatus";
import FalseStatus from "@/icons/falseStatus";
import SubAccountsService from "@/services/subAccounts.service";
const moment = require('moment-timezone');
import Toggle from '@vueform/toggle'
import {mapGetters} from "vuex";


export default {
  name: "SubAccountItem",
  components: {FalseStatus, TrueStatus, Toggle},
  props: {
    subAccounts: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dateFormat: 'DD.MM.YYYY HH:mm',
      statusClass: ''
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    })
  },
  mounted() {
    if (this.$route.params.id) {
      this.getSubAccountsDataById();
    }
    this.scrollHandler();
  },
  methods: {
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-id');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 40) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    dataFormatting(value) {
      return moment(value).format(this.dateFormat);
    },
    getSubAccountsDataById(accountId) {
      SubAccountsService.getSubAccountById(accountId).then((res) => {
        this.$store.commit('setSubAccountData', res.data.result);
        this.$router.push({
          path: `/sub-accounts/${accountId}`,
        })
      })
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    updateStatus(item) {
      this.setNotification({name: 'Update isBusy', value: 'Is busy: ' + item.isBusy});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification.name) {
          SubAccountsService.editSubAccountBusy(item.id,  item.isBusy).then(() => {
            this.setNotification({});
          }).catch(() => {
            this.setNotification({name: 'Error', value: 'Is busy: ' + item.isBusy});
          });
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
