<script setup>

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4241_71791)">
      <path
        d="M2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667ZM12.5 7.5C12.5 8.88333 11.3833 10 10 10C8.61667 10 7.5 8.88333 7.5 7.5C7.5 6.11667 8.61667 5 10 5C11.3833 5 12.5 6.11667 12.5 7.5ZM5 14.1667C5 12.5 8.33333 11.5833 10 11.5833C11.6667 11.5833 15 12.5 15 14.1667V15H5V14.1667Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4241_71791">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
