<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>Balance</h2>

        <div class="layout-header_tabs">
          <div class="layout-header_tabs-item layout-header_tabs-item--active">
            1inch: system
          </div>
          <router-link
            :to="{ name: 'Balance total' }"
            class="layout-header_tabs-item"
          >
            1inch: total
          </router-link>
          <!--          <div class="layout-header_tabs-item">-->
          <!--            Binance: total-->
          <!--          </div>-->
        </div>
      </div>
    </template>
    <template #content>
      <div class="user-balances main-block">
        <div class="table-block">
          <div class="table-block-header">
            <div class="table-block-header-title">
              System balance
            </div>
          </div>
          <div
            class="table-block-table"
          >
            <table
              v-if="resData"
              class="table"
            >
              <thead>
                <tr class="table-headers">
                  <th
                    scope="col"
                    class="table-first table-first-header"
                  >
                    <div class="text">
                      Address
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="table-cell-after_line"
                  />
                  <th
                    v-for="(item, index) in resData[0].balances"
                    :key="index"
                    scope="col"
                  >
                    <div
                      class="text table-orderId-cell"
                    >
                      {{ item.name }}
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="table-cell-after_line"
                  />
                </tr>
              </thead>
              <tbody>
                <tr class="table-cell-line">
                  <td class="table-cell-line-first table-first under-header-line">
                    <div class="line" />
                  </td>
                </tr>
                <template
                  v-for="(item, index) in resData"
                  :key="index"
                >
                  <tr>
                    <td class="table-cell table-first">
                      <div
                        v-clipboard:copy="item.address"
                        v-clipboard:success="onCopyAddress"
                        class="table-first_block"
                      >
                        <div class="img">
                          <copy-address-icon />
                        </div>
                        <div class="text">
                          {{ addressFormat(item.address) }}
                        </div>
                      </div>
                    </td>
                    <td class="table-cell-after_line" />
                    <td
                      v-for="(el, id) in item.balances"
                      :key="id"
                      class="table-cell table-orderId"
                    >
                      <div
                        v-if="el.balance"
                        class="text table-orderId-cell"
                        :class="{
                          'table-orderId-cell_down': tokenCheckHandler(el) === 'down',
                          'table-orderId-cell_up': tokenCheckHandler(el) === 'up',
                          'table-orderId-cell_standard': tokenCheckHandler(el) === 'standard',
                        }"
                      >
                        {{ el.balance }}
                      </div>
                      <div
                        v-else
                        class="text table-orderId-cell"
                      >
                        -
                      </div>
                    </td>
                    <td class="table-cell-after_line" />
                  </tr>
                </template>
                <tr class="table-cell-line_last">
                  <td class="table-cell-line-first table-first" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CopyNotification
        v-if="isSuccessCopy"
        :text="copyText"
      />
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import filterMixin from "@/mixins/filter.mixin";
import helperMixin from "@/mixins/helper.mixin";
import BalanceService from "@/services/balance.service";
import CopyAddressIcon from "@/icons/CopyAddressIcon";
import CopyNotification from "@/pages/notification/CopyNotification";

export default {
  name: "BalanceList",
  components: {CopyNotification, CopyAddressIcon, AccountLayout},
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      balanceList: [],
      nativeCoinList: null,
      currentIndex: false,
      isSuccessCopy: false,
      resData: null,
      copyText: '',
      tokensList: [],
      tokenCheck: [
        {
          name: 'ETH',
          first: 0.02,
          second: 0.04
        },
        {
          name: 'BNB',
          first: 0.035,
          second: 0.07
        } ,
        {
          name: 'MATIC',
          first: 4,
          second: 8
        },
        {
          name: 'FTM',
          first: 10,
          second: 20
        },
        {
          name: 'AVAX',
          first: 0.7,
          second: 1.4
        }
      ]
    }
  },
  mounted() {
    this.getList();
    this.scrollHandler();
  },
  methods: {
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    tokenCheckHandler(token) {
      let currentTokenStatus = '';
      const currentCheck = this.tokenCheck.find((item) => {
        return item.name === token.name
      })

      if (token.balance <= currentCheck.first) {
        currentTokenStatus = 'down'
      }

      else if (token.balance <= currentCheck.second) {
        currentTokenStatus = 'up'
      }

      else if (token.balance > currentCheck.second) {
        currentTokenStatus = 'standard'
      }
      return currentTokenStatus
    },
    getList() {
      BalanceService.getOrder1inchBalance().then((res) => {
        this.balanceList = res.data.result.balances

        const groupedArray = this.balanceList.reduce((acc, curr) => {
          curr.addresses.forEach(address => {
            const key = address.address;
            if (!acc[key]) {
              acc[key] = {};
            }
            if (!acc[key][curr.native_coin]) {
              acc[key][curr.native_coin] = [];
            }
            acc[key][curr.native_coin].push(address);
          });
          return acc;
        }, {});

        console.log(groupedArray);

        // this.generateData(this.balanceList);
        //
        // this.resData = this.tokensList.map((item) => {
        //   item.balances = item.balances.map((coin) => {
        //     return {
        //       name: coin,
        //       balance: this.filter(this.balanceList, coin, item.address).toFixed(4)
        //     }
        //   });
        //   return item;
        // })

      })
    },
    generateData(array) {
      const uniqAddress = [];
      array.forEach((item) => {
        item.addresses.forEach((addr) => {
          if (uniqAddress.indexOf(addr.address) < 0) {
            this.tokensList.push({
              address: addr.address,
              balances: array.map((el) => el.native_coin),
            })
          }
          uniqAddress.push(addr.address);
        })
      })
    },
    filter(array, coin, address) {
      return array.find((item) => item.native_coin === coin).addresses
          .find((addr) => addr.address === address)?.balance;
    },
    addressFormat(address) {
      if (!this.isMobile()) {
        return address.slice(0, 7) + '...' + address.slice((address.length - 7), address.length)
      } else {
        return address.slice(0, 5) + '...' + address.slice((address.length - 5), address.length)
      }
    },
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-first');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 5) {
            el.classList.remove('sticky');
          }
        });
      });
    },
  }
}
</script>

<style lang="scss">
.address-locked {
  .table-block {
    .table-cell {
      height: 36px;

      &-after_line {
        width: 1%;
      }

      &-line {
        height: 20px;

        &_last {
          height: 32px;
        }
      }
    }
  }
  .table-headers {
    border-bottom: 1px solid #DFE0EB;
  }
  tr {
    border: 0 transparent;
    margin: 2px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #9FA2B4;

  }
  .table-first {
    border-right: 1px solid #DFE0EB;
    padding-right: 22px;
    width: 12%;

    &_block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .img {
        margin-right: 6px;

        svg {
          path {
            fill: white;
            transition: all .3s ease-in-out;
            @media (max-width: 1079px) {
              fill: #9FA2B4;
            }
          }
        }
      }
      .text {
        transition: all .3s ease-in-out;
      }
    }

    &:hover {
      cursor: pointer;
      .text {
        color: #0A68F7;
      }
      .img {
        svg {
          path {
            fill: #0A68F7;
          }
        }
      }
    }

    @media (max-width: 1079px) {
      position: sticky;
      top: 0;
      bottom: 0;
      z-index: 99;
      left: 0;

      &.sticky {
        background: white;
        box-shadow: 1px 0 0 rgba(27, 26, 28, 0.16);

        .line{
          position: absolute;
          background: rgba(27, 26, 28, 0.16);
          top: -0.5px;
          left: 0;
          width: 100%;
          height: 0.5px;
        }
      }
    }

    &-header {
      border-right: 1px solid red;
      padding-right: 22px;
      width: 12%;
      background: white;

      @media (max-width: 1079px) {
        position: sticky;
        top: 0;
        bottom: 0;
        z-index: 99;
        left: 0;
        padding-left: 16px;

        &.sticky {
          background: white;
          border-bottom: 1px solid #DFE0EB;
          box-shadow: 1px 0 1px rgba(27, 26, 28, 0.16);

        }
      }
    }
  }
  .table-orderId {
    width: 15%;

    &-cell {
      width: 100%;
      padding: 4px 20px;
      border-radius: 4px;
      text-align: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #1B1A1C;
    }
  }

  thead {
    th, .text {
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: #9FA2B4;

      &:hover {
        cursor: default !important;
        color: #9FA2B4 !important;
      }
    }
  }
  tbody {
    .table-first {
      padding-left: 12px;
    }

    .table-orderId-cell {
      background: #F8FAFB;

      &_down {
        background: #FF783E;
        color: white;
      }
      &_up {
        background: #FCE155;
        color: #1B1A1C;
      }
    }
  }
}
</style>
