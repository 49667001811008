<script>
export default {
  name: "BalanceMirrorItemPercentage",
  props: {
    usdt: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    totalFirst: {
      type: Number,
      default: 0,
      required: false
    }
  },
  computed: {
    percentage() {
      if (this.total === 0) {
        return 0;
      }
      return ((this.usdt / this.total) * 100).toFixed(2);
    },
    percentageFirst() {
      if (this.total === 0) {
        return 0;
      }
      return ((this.usdt / this.totalFirst) * 100).toFixed(2);
    },
  }
}
</script>

<template>
  <div class="percent">
    <div
      class="percent-bar"
      :class="{'percent_null': percentage === 0}"
      :style="{ width: percentageFirst + '%' }"
    />
    <div
      class="percent-number"
      :class="{'percent_null': percentage === 0}"
    >
      {{ percentage }} %
    </div>
  </div>
</template>

<style scoped lang="scss">
.percent {
  width: 120px;
  padding: 4px 6px;
  box-sizing: border-box;
  position: relative;

  &-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 4px;
    background: rgba(159, 162, 180, 0.12);
    min-width: 8px;
    max-width: 120px;
  }

  &-number {
    color: #9FA2B4;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  &_null {
    &-bar {
      width: 0!important;
      min-width: unset;
    }

    &-number {
      color: #FF603D;
    }
  }
}
</style>
