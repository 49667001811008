<template>
  <div
    class="transactions"
  >
    <div class="exchange-info-form-inputs-block exchange-info-form-inputs-block_transactions">
      <div
        v-for="(item, index) in data"
        :key="index"
      >
        <div class="exchange-info-form-inputs-block-row">
          <div
            v-if="item.from"
            class="exchange-info-form-inputs-block-item"
          >
            <div class="desc">
              From
            </div>
            <div
              class="input"
            >
              {{ item.from.name }}
            </div>
          </div>
          <div
            v-if="item.to"
            class="exchange-info-form-inputs-block-item"
          >
            <div class="desc">
              To
            </div>
            <div
              class="input"
            >
              {{ item.to.name }}
            </div>
          </div>
        </div>

        <div class="exchange-info-form-inputs-block_items">
          <div
            v-if="item.amount"
            class="exchange-info-form-inputs-block-item"
          >
            <div class="desc">
              Amount in BTC
            </div>
            <div
              class="input"
            >
              {{ item.cummulativeQuoteQty }}
            </div>
          </div>
          <div
            v-if="item.price"
            class="exchange-info-form-inputs-block-item"
          >
            <div class="desc">
              Curs
            </div>
            <div
              class="input"
            >
              {{ item.price }}
            </div>
          </div>
          <div
            v-if="item.id"
            class="exchange-info-form-inputs-block-item"
          >
            <div class="desc">
              Transaction id
            </div>
            <div
              v-clipboard:copy="item.trans"
              v-clipboard:success="onCopyAddress"
              class="input"
            >
              {{ item.trans }}
              <copy-icon-gray class="copyIcon" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <CopyNotification
      v-if="isSuccessCopy"
      :text="copyText"
    />
  </div>
</template>

<script>
import CopyNotification from "@/pages/notification/CopyNotification.vue";
import CopyIconGray from "@/assets/img/icons/copyIconGray.vue";

export default {
  name: "ExchangeInfoTransactions",
  components: {CopyIconGray, CopyNotification},
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      copyText: '',
      isSuccessCopy: true,
    }
  },
  methods: {
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
  }
}
</script>

<style scoped>

</style>
