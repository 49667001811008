<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5013 13.3334H15.8346V15H12.5013V13.3334ZM12.5013 6.66671H18.3346V8.33337H12.5013V6.66671ZM12.5013 10H17.5013V11.6667H12.5013V10ZM2.5013 15C2.5013 15.9167 3.2513 16.6667 4.16797 16.6667H9.16797C10.0846 16.6667 10.8346 15.9167 10.8346 15V6.66671H2.5013V15ZM4.16797 8.33337H9.16797V15H4.16797V8.33337ZM8.33464 3.33337H5.0013L4.16797 4.16671H1.66797V5.83337H11.668V4.16671H9.16797L8.33464 3.33337Z"
      fill="#FF783E"
    />
  </svg>
</template>

<script>
export default {
  name: "ClearAllIcon"
}
</script>

<style scoped>

</style>
