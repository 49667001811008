<template>
  <div class="nodata">
    <Vue3Lottie
      :animation-data="Spiderweb"
      :loop="true"
      :height="100"
      :width="100"
    />
    <img
      src="../../assets/spiderweb.json"
      alt=""
    >
    <div class="title">
      No Data
    </div>
    <div class="desc">
      Apparently this data is not available at all. Or you don't have a connection
    </div>
  </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import Spiderweb from '../../assets/spiderweb.json'
export default {
  name: "NoDataBlock",
  components: {Vue3Lottie},
  data() {
    return {
      Spiderweb
    }
  }
}
</script>

<style lang="scss">
  .nodata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 39px;
    padding-bottom: 120px;
    box-sizing: border-box;

    .title {
      margin-top: 20px;
      max-width: 350px;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #1B1A1C;
      margin-bottom: 12px;
    }

    .desc {
      max-width: 350px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #1B1A1C;
    }
  }
</style>
