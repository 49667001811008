import service from '../plugins/request';

class SubAccountsService {

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getSubAccounts() {
        return service.get('/api/SubAccount/all');
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getSubAccountById(id) {
        return service.get(`/api/SubAccount/${id}`);
    }

    /**
     * @param id
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    editSubAccountById(id, query) {
        return service.patch(`/api/SubAccount/${id}`, query)
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    createSubAccount(query) {
        return service.post('/api/SubAccount/create', query)
    }

    /**
     * @param id
     * @param status
     * @returns {Promise<AxiosResponse<any>>}
     */
    editSubAccountBusy(id, status) {
        return service.patch(`/api/SubAccount/${id}/busyStatus?status=${status}` );
    }
}


export default new SubAccountsService();
