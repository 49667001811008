<script>
export default {
  name: "RateIcon"
}
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4686_84721)">
      <path
        d="M1.66797 16.6585L7.91797 10.4001L11.2513 13.7335L17.1596 7.0918L18.3346 8.2668L11.2513 16.2335L7.91797 12.9001L2.91797 17.9085L1.66797 16.6585ZM2.91797 12.9085L7.91797 7.90013L11.2513 11.2335L18.3346 3.2668L17.1596 2.0918L11.2513 8.73346L7.91797 5.40013L1.66797 11.6585L2.91797 12.9085Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4686_84721">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
