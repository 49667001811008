export const DEPOSIT_TOP_TABLE_TH = [
    {
        name: 'Coin',
        sort: '',
        key: 'coin',
    },
    {
        name: 'Count',
        sort: '',
        key: 'count',
    },
    {
        name: 'Total',
        sort: '',
        key: 'amount',
    },
    {
        name: 'Total BTC',
        sort: '',
        key: 'amountBtc',
    },
];
