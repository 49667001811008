<template>
  <AccountLayout>
    <template #title>
      <h2>Balances</h2>
    </template>
    <template #content>
      <TableBalance
        :table-th="mainBalance.tableTh"
        :data="mainBalance.data"
        :title-text="mainBalance.title"
      />
      <TableBalance
        :table-th="subAccounts.tableTh"
        :data="subAccounts.data"
        :title-text="subAccounts.title"
      />
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import filterMixin from "@/mixins/filter.mixin";
import helperMixin from "@/mixins/helper.mixin";
import {TABLE_TH_MAIN, TABLE_TH_SUB} from "@/pages/user-balance/userBalanceData";
import BalanceService from "@/services/balance.service";
import TableBalance from "@/pages/user-balance/partials/TableBalance";

export default {
  name: "UserBalances",
  components: {
    TableBalance,
    AccountLayout
  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      mainBalance: {
        data: null,
        title: 'Main coin balance',
        tableTh: TABLE_TH_MAIN
      },
      subAccounts: {
        data: null,
        title: 'Sub account balance',
        tableTh: TABLE_TH_SUB
      }
    }
  },
  mounted() {
    BalanceService.getUserBalance().then((res) => {
      this.mainBalance.data = res.data.result.mainBalance;
      this.subAccounts.data = res.data.result.subAccounts;
    })
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/pages/user-balances/user-balances";
</style>
