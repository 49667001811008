<script>
import TradePopupLayout from "@/layouts/trade/TradePopupLayout.vue";
import TradeService from "@/services/trade.service";
import {MAIN_DOMAIN} from "@/store/config";
import {mapGetters} from "vuex";
import UserService from "@/services/user.service";
import Avatar from "@/components/admin/AvatarComponent.vue";

export default {
  name: "CreateWithdrawal",
  components: {
    Avatar,
    TradePopupLayout
  },
  data() {
    return {
      defaultCoin: 'BNB',
      apiUrl: MAIN_DOMAIN,
      isSelectType: false,
      isAddComment: false,
      selectedType: 'Profit',
      switchersType: ['Order', 'Profit', 'Other'],
      preSavedComment: '',
      comment: '',
      selectedCoin: null,
      selectedNetwork: null,
      amount: null,
      amountCoin: '',
      address: '',
      passCode: '',
      isDropCoins: false,
      isDropNetworks: false,
      isMemo: '',
      memo: '',
      coinsList: null,
      addressTouched: false,
      memoTouched: false,
      userProfile: null,
      successPopup: false
    }
  },
  computed: {
    ...mapGetters({
      UserAcc: 'getUserAcc',
    }),
    isValidMemo() {
      return !this.selectedNetwork.isMemo || (this.memo && this.selectedNetwork.memoRegex && !!this.memo.match(this.selectedNetwork.memoRegex));
    },
    isValidAddress() {
      return this.selectedNetwork.regexAddress === null || (this.address && !!this.address.match(this.selectedNetwork.regexAddress));
    },
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newValue, oldValue) {
        this.getCoinsList(newValue);
      }
    }
  },
  created() {
    this.getProfile();
    this.getCoinsList(this.$route.params.id);
  },
  mounted() {
    document.addEventListener('click', this.handleClickCoins);
    document.addEventListener('click', this.handleClickNetworks);
    document.addEventListener('click', this.handleClickType);
  },
  methods: {
    handleClickCoins(event) {
      if (this.$refs.coinsList && !this.$refs.coinsList.contains(event.target)) {
        this.isDropCoins = false
      }
    },
    handleClickNetworks(event) {
      if (this.$refs.networksList && !this.$refs.networksList.contains(event.target)) {
        this.isDropNetworks = false
      }
    },
    handleClickType(event) {
      if (this.$refs.type && !this.$refs.type.contains(event.target)) {
        this.isSelectType = false
      }
    },
    async getCoinsList(id) {
      const query = '?mode=funding' + '&isNetworks=true'
      TradeService.getBalanceAcc(id, query).then(async (res) => {
        this.coinsList = res.data.result

        await this.$route.params.coin

        if (this.coinsList) {

          if (this.$route.params.coin) {
            this.selectedCoin = this.coinsList.find((item) => {
              return item.coin.symbol === this.$route.params.coin
            });
          } else {
            this.selectedCoin = this.coinsList[0]
          }

          this.defaultNetwork()
        } else {
          this.selectedCoin = null
        }
      })
    },
    focusOn(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].focus();
      }
    },
    defaultNetwork() {
      if (this.selectedCoin) {
        this.selectedNetwork = this.selectedCoin.coin.networks[0]
      }
    },
    networkSelect(network) {
      this.isDropNetworks = false
      this.selectedNetwork = network
    },
    selectCoinHandler(coin) {
      this.selectedCoin = coin;
      this.defaultNetwork()
    },
    getProfile() {
      UserService.getProfile().then((res) => {
        this.userProfile = res.data.result;
      })
    },
    switchType(el) {
      this.selectedType = el
      this.isSelectType = false
    },
    addCommentHandler() {
      this.isAddComment = !this.isAddComment;
    },
    handleButtonClick() {
      if (!this.isAddComment) {
        this.addCommentHandler();
      }
    },
    saveComment() {
      this.comment = this.preSavedComment;

    },
    adjustTextareaHeight() {
      const textarea = this.$refs.commentTextarea;

      textarea.style.height = 'auto';

      if (this.preSavedComment.length > 40) {
        textarea.style.height = `70px`;
      }

      if (this.preSavedComment.length > 120) {
        textarea.style.height = `100px`;
      }
    },
    cancelComment() {
      this.isAddComment = false;
      this.comment = '';
    },
    goBack() {
      this.successPopup = false;
      this.$router.push({
        name: 'Trade balance',
        params: {
          id: this.$route.params.id
        }
      });
    },
    createWithdrawal() {
      if (!this.isValidAddress && !this.amount) {
        console.log("Invalid data entered. Withdrawal not created.");
      } else {
        const query = {
          accountId: this.UserAcc.id,
          userId: this.userProfile.id,
          ipAddress: this.userProfile.ipAddress,
          userAgent: navigator.userAgent || "Unknown User Agent",
          coin: this.selectedCoin.coin.symbol,
          network: this.selectedNetwork.name,
          amount: this.amount,
          address: this.address,
          type: this.selectedType,
          comment: this.comment
        }

        if (this.selectedNetwork.isMemo) {
          query.memo = this.memo;
        }
        TradeService.createWithdraw(this.$route.params.id, query).then((res) => {
          if (res) {
            this.successPopup = true
          }
        })
      }
    }
  }
}
</script>

<template>
  <trade-popup-layout title="New withdrawal">
    <template #tradePopupSlot>
      <div class="create-order create-order_withdrawal">
        <div class="create-order_tab create-order_inputs">
          <div class="create-order_withdrawal-title">
            Details
          </div>
          <div class="head">
            <div
              ref="type"
              class="type"
            >
              <div
                class="select-input"
                @click="isSelectType = !isSelectType"
              >
                {{ selectedType }}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3860_6222)">
                    <path
                      d="M7 10L12 15L17 10H7Z"
                      fill="#1A242B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3860_6222">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="matrix(0 1 -1 0 24 0)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                v-if="isSelectType"
                class="select-input_dropmenu"
              >
                <div
                  v-for="(type, index) in switchersType"
                  :key="index"
                  class="item"
                  @click="switchType(type)"
                >
                  {{ type }}

                  <svg
                    v-if="type === selectedType"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3860_8139)">
                      <path
                        d="M7.50208 13.4749L4.02708 9.99987L2.84375 11.1749L7.50208 15.8332L17.5021 5.8332L16.3271 4.6582L7.50208 13.4749Z"
                        fill="#007AFF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3860_8139">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="add-comment"
              :class="{'add-comment_disabled': isAddComment}"
              @click="addCommentHandler()"
              @click.stop="handleButtonClick"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_3860_8125)">
                  <path
                    d="M16.6641 1.6665H3.33073C2.41406 1.6665 1.6724 2.4165 1.6724 3.33317L1.66406 18.3332L4.9974 14.9998H16.6641C17.5807 14.9998 18.3307 14.2498 18.3307 13.3332V3.33317C18.3307 2.4165 17.5807 1.6665 16.6641 1.6665ZM16.6641 13.3332H4.30573L3.81406 13.8248L3.33073 14.3082V3.33317H16.6641V13.3332ZM9.16406 9.99984H10.8307V11.6665H9.16406V9.99984ZM9.16406 4.99984H10.8307V8.33317H9.16406V4.99984Z"
                    fill="#527AA1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3860_8125">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>
                Add comment
              </span>
            </div>
          </div>
          <div
            v-if="selectedCoin"
            class="order"
          >
            <div class="inputs">
              <div
                ref="coinsList"
                class="inputs--item inputs--item_select"
                :class="{'focus': !!isDropCoins}"
                @click="isDropCoins = !isDropCoins"
              >
                <div class="selected">
                  <div class="selected_coin_info">
                    <div
                      class="selected_coin"
                    >
                      <img
                        :src="apiUrl + selectedCoin.coin.image"
                        alt=""
                      >
                      <div class="selected_coin-text">
                        <div class="selected_coin-text--coin">
                          {{ selectedCoin.coin.symbol }}
                        </div>
                        <div class="selected_coin-text--fullName">
                          {{ selectedCoin.coin.fullName }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="selected_coin_balance">
                    {{ selectedCoin.balance }} <br>
                    <span>
                      USDT {{ selectedCoin.coin.balanceUsdt }}
                    </span>
                  </div>
                </div>
                <div class="arrow">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3860_5848)">
                      <path
                        d="M7 10L12 15L17 10H7Z"
                        fill="#1A242B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3860_5848">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(0 1 -1 0 24 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  v-if="isDropCoins"
                  class="inputs--item_select--dropdown"
                >
                  <div
                    v-for="(coin, index) in coinsList"
                    :key="index"
                    class="list--item"
                    :class="{'list--item-selected': selectedCoin && selectedCoin.coin.symbol === coin.coin.symbol}"
                    @click="selectCoinHandler(coin)"
                  >
                    <div class="info">
                      <img
                        :src="apiUrl + coin.coin.image"
                        alt=""
                      >
                      <div class="info_text">
                        <div class="info_text--coin">
                          {{ coin.coin.symbol }}
                        </div>
                        <div class="info_text--fullName">
                          {{ coin.coin.fullName }}
                        </div>
                      </div>
                    </div>

                    <div class="list--item_balance">
                      {{ coin.balance }} <br>
                      <span>
                        USDT {{ coin.coin.balanceUsdt }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="inputs--item inputs--item_amount"
                :class="{'focus': !!amount}"
                @click="focusOn('amount')"
              >
                <div class="input">
                  <div class="desc">
                    Amount
                  </div>
                  <input
                    ref="amount"
                    v-model="amount"
                    type="text"
                  >
                </div>
                <div class="max-coin">
                  <div class="coin">
                    {{ selectedCoin.coin.symbol }}
                  </div>
                  <div class="separator" />
                  <div
                    class="max"
                    @click="amount = selectedCoin.balance"
                  >
                    Max
                  </div>
                </div>
              </div>
              <div
                ref="networksList"
                class="inputs--item inputs--item_select"
                :class="{'focus': isDropNetworks}"
              >
                <div
                  class="select-input"
                  @click="isDropNetworks = !isDropNetworks"
                >
                  <div class="select-input__selected">
                    <div class="select-input_desc">
                      Network
                    </div>
                    <div class="select-input_network">
                      {{ selectedNetwork.name }}
                    </div>
                  </div>
                  <div class="select-input_arrow">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3860_5848)">
                        <path
                          d="M7 10L12 15L17 10H7Z"
                          fill="#1A242B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3860_5848">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="matrix(0 1 -1 0 24 0)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>

                <div
                  v-if="isDropNetworks"
                  class="select-input--dropdown"
                >
                  <div
                    v-for="(network, index) in selectedCoin.coin.networks"
                    :key="index"
                    class="item"
                    :class="{'item-selected': selectedNetwork && selectedNetwork.name === network.name}"
                    @click="networkSelect(network)"
                  >
                    <div class="item-name">
                      {{ network.name }}
                    </div>
                    <div v-if="selectedNetwork.name === network.name">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3919_4922)">
                          <path
                            d="M7.50208 13.4749L4.02708 9.99987L2.84375 11.1749L7.50208 15.8332L17.5021 5.8332L16.3271 4.6582L7.50208 13.4749Z"
                            fill="#007AFF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3919_4922">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-error">
                <div
                  class="inputs--item inputs--item_amount"
                  :class="{'focus': !!address, 'error': addressTouched && !isValidAddress}"
                  @click="focusOn('address')"
                >
                  <div class="input">
                    <div class="desc">
                      Address
                    </div>
                    <input
                      ref="address"
                      v-model="address"
                      type="text"
                      @blur="addressTouched = true"
                    >
                  </div>
                </div>
                <div
                  v-if="addressTouched && !isValidAddress && address"
                  class="error-text"
                >
                  Invalid address
                </div>
              </div>
              <div class="item-error">
                <div
                  v-if="selectedNetwork.isMemo"
                  class="inputs--item inputs--item_amount"
                  :class="{'focus': !!memo, 'error': memoTouched && !isValidMemo}"
                  @click="focusOn('memo')"
                >
                  <div class="input">
                    <div class="desc">
                      Memo
                    </div>
                    <input
                      ref="memo"
                      v-model="memo"
                      type="text"
                      @blur="memoTouched = true"
                    >
                  </div>
                </div>
                <div
                  v-if="memoTouched && !isValidMemo && memo"
                  class="error-text"
                >
                  Invalid memo
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isAddComment"
            class="comment"
            :class="{'comment_disabled': comment.length}"
          >
            <div class="author-avatar">
              <avatar :img="userProfile.avatar" />
            </div>
            <div class="author">
              <div class="author-name">
                {{ userProfile.login }}
              </div>
              <textarea
                ref="commentTextarea"
                v-model="preSavedComment"
                :disabled="comment.length"
                @input="adjustTextareaHeight"
              />
            </div>
            <div
              v-if="!comment.length"
              class="buttons"
            >
              <div
                class="item item_send"
                @click="saveComment"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3860_8944)">
                    <path
                      d="M1.6724 17.5L19.1641 10L1.6724 2.5L1.66406 8.33333L14.1641 10L1.66406 11.6667L1.6724 17.5Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3860_8944">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Send
              </div>
              <div
                class="item"
                @click="cancelComment"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
        <div class="create-order_tab create-order_inputs">
          <div class="create-order_withdrawal-title">
            MFA
          </div>
          <div class="create-order_withdrawal-desc">
            Activate your code from the app. Remember that the code is valid for only 20 seconds, so you
            should enter it last
          </div>
          <div class="order">
            <div class="inputs">
              <div
                class="inputs--item inputs--item_amount"
                :class="{'focus': !!passCode}"
                @click="focusOn('passCode')"
              >
                <div class="input">
                  <div class="desc">
                    Pass Code
                  </div>
                  <input
                    ref="passCode"
                    v-model="passCode"
                    type="text"
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="button"
            @click="createWithdrawal"
          >
            Withdrawal USDT
          </div>
        </div>
      </div>
    </template>
  </trade-popup-layout>
  <div
    v-if="successPopup"
    class="success-popup"
  >
    <div class="success-popup_modal">
      <div
        class="close"
        @click="goBack()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4825_285)">
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#527AA1"
            />
          </g>
          <defs>
            <clipPath id="clip0_4825_285">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="text">
        <div class="text_title">
          Withdrawal transaction created successfully
        </div>
        <div class="text_desc">
          After a successful withdrawal, you can see the hash and other details of the transaction in the
          "Withdrawals" section
        </div>
      </div>

      <div class="buttons">
        <div
          class="buttons--item"
          @click="goBack()"
        >
          Ok
        </div>
        <router-link
          :to="{name: 'Trade withdrawals', params: {id: UserAcc.id}}"
          class="buttons--item buttons--item_black"
        >
          Go to Withdrawals
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.create-order_withdrawal {
  max-width: 740px;

  .head {
    margin-bottom: 32px;

    .type {
      .select-input {
        max-width: 204px;
      }
    }
  }

  .create-order_tab {
    padding: 24px 32px 32px;
    box-sizing: border-box;

    &:first-child {
      border-right: 1px solid #E0E9F4;
      max-width: 424px;
    }

    &:not(:first-child) {
      max-width: 316px;
    }
  }

  &-title {
    color: #1A242B;
    font-family: 'Rubik', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.4px;
    margin-bottom: 16px;
  }

  &-desc {
    color: var(--trade-trade-gray, #527AA1);
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 40px;
  }

  .order {
    margin-top: 0;

    .inputs {
      .selected {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 8px;
        box-sizing: border-box;

        &_coin {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          height: 100%;

          img {
            max-width: 32px;
          }

          &-text {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            &--coin {
              color: var(--trade-trade-black, #1A242B);
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px; /* 114.286% */
            }

            &--fullName {
              color: var(--trade-trade-gray, #527AA1);
              font-family: 'Rubik', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
            }
          }

          &_balance {
            color: var(--black, #1A242B);
            text-align: right;
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;

            span {
              color: var(--trade-trade-gray, #527AA1);
              text-align: right;
              font-family: 'Rubik', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
            }
          }
        }
      }

      &--item {
        width: 100%;
        position: relative;

        .input {
          width: 100%;

          input {
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
          }
        }

        .max-coin {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          .separator {
            width: 1px;
            height: 12px;
            background: rgba(124, 163, 210, 0.24);
          }

          .max {
            color: var(--trade-trade-gray, #527AA1);
            text-align: right;
            font-family: 'Rubik', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            cursor: pointer;
            &:hover{
              color: #1A242B;
            }
          }
        }

        &--error {
          border: 1px solid #FF603D;
        }

        &_select {
          align-items: center;
          position: relative;

          &--dropdown {
            position: absolute;
            top: calc(100% + 2px);
            z-index: 9;
            left: 0;
            width: 100%;
            border-radius: 6px;
            background: #FFF;
            box-shadow: 0px 1px 6px 0px rgba(6, 59, 122, 0.16);
            outline: 1px solid white;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 460px;
            max-width: 359px;
            scrollbar-gutter: stable;
            scrollbar-width: thin;
            scrollbar-color: #EAF0F8 transparent;
            padding: 6px 0;

            .list--item {
              width: 100%;
              max-width: 100%;
              padding: 12px;
              height: unset;
              transition: all .3s ease-in-out;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              box-sizing: border-box;

              &:hover {
                background: var(--trade-hover, rgba(0, 122, 255, 0.06));
              }

              .info {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                img {
                  max-width: 32px;
                }

                &_text {
                  display: flex;
                  flex-direction: column;
                  gap: 2px;

                  &--coin {
                    color: var(--trade-trade-black, #1A242B);
                    font-family: 'Rubik', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                  }

                  &--fullName {
                    color: var(--trade-trade-gray, #527AA1);
                    font-family: 'Rubik', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                  }
                }
              }

              &_balance {
                color: var(--black, #1A242B);
                text-align: right;
                font-family: 'Rubik', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;

                span {
                  color: var(--trade-trade-gray, #527AA1);
                  text-align: right;
                  font-family: 'Rubik', sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 14px; /* 116.667% */
                }
              }
            }
          }

          .select-input {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &_network {
              color: var(--trade-trade-black, #1A242B);
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
            }

            &_desc {
              transform: none;
              color: var(--trade-trade-gray, #527AA1);
              font-family: 'Rubik', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
            }


            &--dropdown {
              position: absolute;
              top: calc(100% + 2px);
              z-index: 9;
              left: 0;
              width: 100%;
              border-radius: 6px;
              background: #FFF;
              box-shadow: 0px 1px 6px 0px rgba(6, 59, 122, 0.16);
              outline: 1px solid white;
              padding: 6px 0;

              .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 10px 10px 10px 12px;
                transition: all .3s ease-in-out;

                &:hover {
                  background: var(--trade-hover, rgba(0, 122, 255, 0.06));
                }

                &-name {
                  color: var(--trade-trade-black, #1A242B);
                  font-family: 'Rubik', sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px; /* 114.286% */
                }
              }
            }
          }
        }
      }

      .item-error {
        width: 100%;

        .error-text {
          color: var(--trade-trade-red, #FF603D);
          font-family: 'Rubik', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          padding-left: 12px;
          margin-top: 6px;
        }

        .error {
          outline: 2px solid var(--trade-trade-red, #FF603D);

          .input {
            .desc {
              color: var(--trade-trade-red, #FF603D);
              font-family: 'Rubik', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
            }
          }
        }
      }
    }
  }

  .button {
    margin-top: 78px;
    border-radius: 6px;
    border: 1px solid rgba(130, 170, 200, 0.24);
    background: var(--trade-trade-blue, #007AFF);
    padding: 14px 0;
    color: #FFF;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.2px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), #007AFF;
    }

  }
}

.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(26, 36, 43, 0.8);
  display: grid;
  place-items: center;

  &_modal {
    max-width: 360px;
    padding: 28px 32px 32px 32px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 48px 0px rgba(6, 59, 122, 0.08), 0px 1px 4px 0px rgba(6, 59, 122, 0.06);
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 6px;
      padding: 6px;
      transition: all .3s ease-in-out;
      cursor: pointer;

      svg {
        path {
          transition: all .3s ease-in-out;
        }
      }

      &:hover {
        background: var(--trade-hover, rgba(0, 122, 255, 0.06));

        svg {
          path {
            fill: rgba(26, 36, 43, 1);
          }
        }
      }
    }


    .text {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_title {
        color: #1A242B;
        font-family: 'Rubik', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 116.667% */
        letter-spacing: -0.4px;
      }

      &_desc {
        color: var(--trade-trade-gray, #527AA1);
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &--item {
        border-radius: 6px;
        border: 1px solid rgba(130, 170, 200, 0.24);
        background: var(--trade-trade-blue, #007AFF);
        padding: 14px 0;
        color: #FFF;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.2px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), #007AFF;
        }

        &_black {
          background: var(--trade-trade-black, #1A242B);

          &:hover {
            background: linear-gradient(0deg, #0E1418 0%, #0E1418 100%), #1A242B;
          }
        }
      }
    }
  }
}
</style>
