<template>
  <div class="AuthLayout">
    <div class="form">
      <div class="form-info">
        <div class="logo">
          <img
            alt="Vue logo"
            src="../../assets/img/logo.svg"
          >
        </div>
        <div class="copyrights">
          <h5>The short descriptor here</h5>
          <h5>Copyright © 2020 - 2021 Swapuz!</h5>
        </div>
      </div>
      <slot name="main" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout"
}
</script>

<style lang="scss">
.AuthLayout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--grayscale-divider, #DFE0EB);

    &-info {
      background-image: url('~@/assets/img/bg_layout.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 32px 46px 28px 28px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;

      h5 {
        padding: 0;
        margin: 0;
        color: rgba(255, 255, 255, 0.38);
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &-input {
      padding: 40px 48px 48px 48px;
      box-sizing: border-box;
      background: white;

      .title {
        color: var(--black, #1B1A1C);
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 120% */
        margin-top: 0;
        margin-bottom: 24px;
      }

      &-group {
        width: 400px;
        display: flex;
        flex-direction: column;

        .input-title {
          color: var(--blue, #673DFD);
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
          margin-bottom: 8px;
        }

        .desc {
          margin-top: 6px;
          color: #9FA2B4;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 116.667% */
        }

        input {
          border-radius: 8px;
          border: 1px solid #DFE0EB;
          padding: 11px;

          &:focus {
            border: 1px solid var(--blue, #673DFD);
            outline: none;
          }
        }
      }

      .buttons {
        margin-top: 56px;

        .btn-submit {
          width: 192px;
          border-radius: 8px;
          background: #0A68F7;
          outline: none;
          border: none;
          color: #FFF;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          padding: 14px 0;
          cursor: pointer;

          &:hover {
            background: linear-gradient(0deg, #095EDE 0%, #095EDE 100%), #0A68F7;
          }
        }
      }
    }
  }
}
</style>
