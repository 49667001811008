<template>
  <TradeLayout :is-header="false">
    <template #tradeSlot>
      <div class="trade-header">
        <div class="trade-header_top">
          <div class="title-button">
            <div class="title">
              Account control
            </div>
            <router-link
              :to="{name: 'Create account', params: {id: $route.params.id}}"
              class="button"
            >
              <span>+</span>Add account
            </router-link>
          </div>
          <UserProfile />
        </div>
      </div>
      <trade-table-layout
        title="List of accounts"
        :is-buttons="true"
        :table-th="tableTh"
        class="trade-accounts"
      >
        <template
          v-if="tableData"
          #table
        >
          <template
            v-for="(tableTd, index) in tableData"
            :key="index"
          >
            <tr
              v-if="tableTd.modeAccType"
              class="tr-accounts"
              :class="{'tr-accounts_notActive': tableTd.isActive === false}"
            >
              <td class="table-logo">
                <span v-html="logos[tableTd.modeAccType]" />
              </td>
              <td class="table-market table-market_td">
                {{ tableTd.modeAccType }}
              </td>
              <td class="table-account">
                {{ tableTd.name }}
              </td>
              <td class="table-accId">
                <div
                  class="table-accId_text"
                  @click="currentIdHandler(index)"
                >
                  {{ tableTd.id }}
                </div>
                <transition name="fade">
                  <div
                    v-if="currentId === index"
                    class="hover-copy"
                  >
                    <div
                      v-clipboard:copy="tableTd.id"
                      class="text"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_3860_5404)">
                          <path
                            d="M10.6693 0.666504H2.66927C1.93594 0.666504 1.33594 1.2665 1.33594 1.99984V11.3332H2.66927V1.99984H10.6693V0.666504ZM12.6693 3.33317H5.33594C4.6026 3.33317 4.0026 3.93317 4.0026 4.6665V13.9998C4.0026 14.7332 4.6026 15.3332 5.33594 15.3332H12.6693C13.4026 15.3332 14.0026 14.7332 14.0026 13.9998V4.6665C14.0026 3.93317 13.4026 3.33317 12.6693 3.33317ZM12.6693 13.9998H5.33594V4.6665H12.6693V13.9998Z"
                            fill="#7CA3D2"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3860_5404">
                            <rect
                              width="16"
                              height="16"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      Copy
                    </div>
                  </div>
                </transition>
              </td>
              <td class="table-type">
                {{ tableTd.typeName }}
              </td>
              <td class="table-partner">
                <span v-if="tableTd.partnerLogin">
                  {{ tableTd.partnerLogin }}
                </span>
                <span v-else>-</span>
              </td>
              <td class="table-activity">
                {{ dateFormatter(tableTd.lastActive) }}
              </td>
              <td class="table-status">
                <Toggle
                  v-model="tableTd.isActive"
                  @change="changeStatus(tableTd.id, tableTd.isActive)"
                />
              </td>
            </tr>
          </template>
        </template>
        <template #loader>
          <trade-loader v-if="isLoading" />
        </template>
        <template #noData>
          <TradeNoData v-if="!hasData && !isLoading" />
        </template>
      </trade-table-layout>
    </template>
  </TradeLayout>
</template>

<script>
import TradeLayout from "@/layouts/trade/TradeLayout.vue";
import TradeTableLayout from "@/components/trade/TradeTableLyout.vue";
import {TRADE_ACCOUNTS_TABLE_TH} from "@/pages/admin-trade/accounts/trade-accounts.headers";
import TradeLoader from "@/components/trade/components/TradeLoader.vue";
import TradeNoData from "@/components/trade/components/TradeNoData.vue";
import {mapGetters} from "vuex";
import {MAIN_DOMAIN} from "@/store/config";
import TradeService from "@/services/trade.service";
import serviceLogos from "@/assets/img/servicesLogos/serviceLogos";
import dayjs from "dayjs";
import Toggle from '@vueform/toggle'
import UserProfile from "@/components/UserProfile.vue";

export default {
  components: {UserProfile, TradeNoData, TradeLoader, TradeTableLayout, TradeLayout, Toggle},
  data() {
    return {
      tableTh: TRADE_ACCOUNTS_TABLE_TH,
      tableData: [],
      apiUrl: MAIN_DOMAIN,
      isLoading: true,
      logos: serviceLogos,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      currentId: false
    }
  },
  computed: {
    ...mapGetters({
      UserAcc: 'getUserAcc',
    }),
    hasData() {
      return Array.isArray(this.tableData) && this.tableData.length > 0; // Додали перевірку на масив
    }
  },

  mounted() {
    this.getListAccounts()
  },
  methods: {
    currentIdHandler(id) {
      if (this.currentId === id) {
        this.currentId = false
      } else {
        this.currentId = id
      }
    },
    getListAccounts() {
      this.isLoading = true;
      this.tableData = null;
      TradeService.getAccountsList().then((res) => {
        this.tableData = res.data.result;
        this.isLoading = false;
      })
    },
    dateFormatter(value) {
      return dayjs(value).format(this.dateFormat)
    },
    changeStatus(id, status) {
      const query = '?idAcc=' + id + '&isActive=' + status
      TradeService.updateAccountStatus(query).then((res) => {
        if (res.status === 200) {
          this.getListAccounts()
        }
      })
    }
  }

}
</script>

<style lang="scss">

.trade-accounts {
  .tr-accounts {
    height: 44px;

    &_notActive {
      .table {
        &-logo {
          filter: grayscale(100);
          opacity: 0.4;
        }

        &-market_td, &-account, &-accId, &-type, &-partner, &-activity {
          color: var(--trade-trade-lightgray, #7CA3D2);
          opacity: 0.4;
        }
      }
    }
  }

  .table {

    &-logo {
      width: 66px;
    }

    &-market {
      min-width: 88px;
      padding-right: 15px;

      &_td {
        color: var(--trade-trade-black, #1A242B);
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }

    &-account {
      width: 166px;
    }

    &-accId {
      width: 200px;
      position: relative;

      &_text {
        width: max-content;
        border-radius: 4px;
        padding: 8px 4px;
        box-sizing: border-box;
        transform: translateX(-4px);
        min-width: 74px;
        transition: all .3s ease-in-out;

        &:hover {
          background: var(--trade-hover, rgba(0, 122, 255, 0.06));
        }


      }
      .hover-copy {
        width: 100%;
        max-width: 92px;
        left: -13px;
        margin: unset;
      }

    }

    &-type {
      width: 136px;
      text-transform: capitalize;
    }

    &-partner {
      width: 214px;
      text-transform: capitalize;
    }

    &-activity {
      width: 166px;
    }

    &-status {
      padding-left: 0;
      text-align: right;

      .toggle {
        width: var(--toggle-width, 22px);
        height: var(--toggle-height, 6px);
        outline: none;

        &-container {
          &:focus {
            box-shadow: none;
          }
        }
      }

      .toggle-on {
        background: #ADD4FF;
        border-color: transparent;

        &:hover {
          background: #85BFFF;
        }
      }

      .toggle-off {
        background: #E0E9F4;
        border-color: transparent;

        &:hover {
          background: #CBDAED;
        }
      }

      .toggle-handle-on {
        transform: translateX(-70%);
        background: var(--trade-trade-blue, #007AFF);
        box-shadow: 0px 1px 2px 0px #7CA3D2;
      }

      .toggle-handle-off {
        left: -2px;
      }

      .toggle-handle {
        width: 16px;
        height: 16px;
        top: -5px;
        box-shadow: 0px 1px 2px 0px #7CA3D2;
      }
    }
  }


}

</style>
