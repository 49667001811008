<template>
  <div class="transactions_value-chart transactions_value-chart_block">
    <AdminShift
      v-if="$route.name === 'Dashboard'"
      class="admin-shift_hide"
    />
    <div class="transactions_value-chart-title-block">
      <div class="transactions_value-chart-title-block-title">
        Transactions and value
      </div>
      <div class="dates">
        <div
          v-for="(item, index) in dates"
          :key="index"
          :class="{'dates-item': item.key !== 'range', 'dates_range': item.key === 'range', active: item.status === true && item.key !== 'range', 'dates_range_active': item.status === true && item.key === 'range' }"
          @click="filterDate(item)"
        >
          <date-picker
            v-if="item.key === 'range'"
            v-model:value="date"
            :clearable="false"
            :editable="false"
            default-panel="day"
            separator=" - "
            value-type="format"
            placeholder="Custom date"
            :format="customDate"
            :month-format="'MMMM'"
            range
            input-class="dates-item"
            :class="{'dates_filled': date && item.key === 'range'}"
            @change="getStats"
          >
            {{ dateText }}
            <template #icon-calendar>
              <calendar-icon />
            </template>
          </date-picker>
          <span v-if="item.key !== 'range'">
            {{ item.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="transactions_value-chart-chart">
      <div class="chart">
        <div
          v-if="chartOptions.series[0].data.length === 0"
          class="chart_empty"
        >
          No transactions
        </div>
        <vue-highcharts
          v-if="chartOptions.series[0].data.length"
          :options="chartOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueHighcharts from 'vue3-highcharts/src/vue3-highcharts';
import moment from 'moment'
import OrderService from "@/services/order.service";
import {TRANSACTION_DATES} from "@/pages/dashboard/dashboardData";
import {CHART_DATA, FILTER_DATA} from "@/pages/dashboard/chartData";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import CalendarIcon from "@/icons/calendarIcon.vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import AdminShift from "@/pages/dashboard/partials/AdminShift.vue";

export default {
  name: "Chart",
  components: {
    AdminShift,
    CalendarIcon,
    VueHighcharts,
    DatePicker
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    market: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      moment: moment,
      dates: TRANSACTION_DATES,
      dateFormat: 'YYYY-MM-DD',
      customDate: 'DD.MM.YYYY',
      chartDateFormat: 'DD MMM YYYY',
      headerFormat: 'YYYY-MM-DD',
      chartOptions: CHART_DATA,
      filterData: FILTER_DATA,
      date: '',
      formattedDate: ''
    }
  },
  computed: {
    dateText() {
      if (this.date) {
        return this.date;
      } else {
        return "Custom date";
      }
    },
  },
  mounted() {
    this.dates = this.dates.map((item) => {
      item.status = item.key === 'month';

      return item;
    });
    this.generateChartData();
  },
  methods: {
    generateChartData(startDate = this.filterData.month) {
      const date = new Date();
      const start = this.moment(date).startOf(startDate).format(this.dateFormat)
      const end = this.moment(date).format(this.dateFormat);
      const company = [];
      const partners = [];
      const total = [];
      const dates = [];

      if (!this.market) {
        OrderService.orderMainChart('/?start=' + start + '&end=' + end).then((res) => {
          const data = res.data.result;

          data.forEach((item) => {
            dates.push(this.moment(item.day).format(this.chartDateFormat));
            company.push(item.company);
            partners.push(item.partner);
            total.push(item.total);
          });

          this.chartOptions.xAxis.categories = dates;
          this.chartOptions.series[0].data = company;
          this.chartOptions.series[1].data = partners;
          this.chartOptions.series[2].data = total;
        });
      } else {
        OrderService.orderMainChart('/?filterExchange=' + this.market + '&start=' + start + '&end=' + end).then((res) => {
          const data = res.data.result;

          data.forEach((item) => {
            dates.push(this.moment(item.day).format(this.chartDateFormat));
            company.push(item.company);
            partners.push(item.partner);
            total.push(item.total);
          });

          this.chartOptions.xAxis.categories = dates;
          this.chartOptions.series[0].data = company;
          this.chartOptions.series[1].data = partners;
          this.chartOptions.series[2].data = total;
        });
      }


    },
    getStats() {
      const dates = this.date;
      const startDate = this.moment(dates[0], 'DD.MM.YYYY').format('YYYY-MM-DD');
      const endDate = this.moment(dates[1], 'DD.MM.YYYY').format('YYYY-MM-DD');
      const company = [];
      const partners = [];
      const total = [];

      if (!this.market) {
        OrderService.orderMainChart('/?start=' + startDate + '&end=' + endDate).then((res) => {
          const data = res.data.result;

          data.forEach((item) => {
            dates.push(this.moment(item.day).format(this.chartDateFormat));
            company.push(item.company);
            partners.push(item.partner);
            total.push(item.total);
          });

          this.chartOptions.xAxis.categories = dates;
          this.chartOptions.series[0].data = company;
          this.chartOptions.series[1].data = partners;
          this.chartOptions.series[2].data = total;
        });
      } else {
        OrderService.orderMainChart('/?filterExchange=' + this.market + '&start=' + startDate + '&end=' + endDate).then((res) => {
          const data = res.data.result;

          data.forEach((item) => {
            dates.push(this.moment(item.day).format(this.chartDateFormat));
            company.push(item.company);
            partners.push(item.partner);
            total.push(item.total);
          });

          this.chartOptions.xAxis.categories = dates;
          this.chartOptions.series[0].data = company;
          this.chartOptions.series[1].data = partners;
          this.chartOptions.series[2].data = total;
        });
      }
    },
    dataFormatting(value) {

      return dayjs.utc(value).utcOffset(2).format(this.dateFormat)
    },
    filterDate(item) {
      this.dates = this.dates.map((el) => {
        el.status = item.key === el.key;
        return el;
      });

      this.generateChartData(this.getFilterDate(item));
    },
    getFilterDate(item) {
      let result = 360;

      switch (item.key) {
        case 'today':
          result = this.filterData.day;
          break;
        case 'week':
          result = this.filterData.week
          break;
        case 'month':
          result = this.filterData.month
          break;
        case 'total':
          result = this.filterData.year
          break;
      }

      return result;
    }
  }
}
</script>

<style lang="scss">
.chart {
  display: flex;
  width: 100%;

  .vue-highcharts {
    width: 100%;
  }

  &_empty {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: RGB(159, 162, 180);
    font-weight: 300;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1079px) {
    padding: 0;
  }

  img {
    width: 100%;
  }
}
</style>
