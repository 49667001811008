<script>
export default {
  name: "MainDashboardIcon"
}
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4704_84620)">
      <path
        d="M2.5 2.5H9.16667V9.16667H2.5V2.5ZM10.8333 2.5H17.5V9.16667H10.8333V2.5ZM2.5 10.8333H9.16667V17.5H2.5V10.8333Z"
        fill="white"
        fill-opacity="0.54"
      />
      <path
        d="M17.5 10.8335H10.8333V17.5002H17.5V10.8335Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4704_84620">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
