<template>
  <div
    v-if="jsonVisitor"
    class="exchange-info-form-inputs-block user-block"
  >
    <div
      class="exchange-info-form-inputs-block_items"
    >
      <div class="exchange-info-form-info-block-item">
        <div class="exchange-info-form-user-row">
          <div class="desc">
            Browser
          </div>
          <div
            class="data"
          >
            {{ `${jsonVisitor.identification.data.browserDetails.browserName} ${jsonVisitor.identification.data.browserDetails.browserMajorVersion}` }}
          </div>
        </div>
        <div class="exchange-info-form-user-row">
          <div class="desc">
            Location
          </div>
          <div
            class="data"
          >
            {{ `${jsonVisitor.identification.data.ipLocation.country.name}/${jsonVisitor.identification.data.ipLocation.city.name}` }}
          </div>
        </div>
      </div>
      <div class="exchange-info-form-info-block-item">
        <div class="exchange-info-form-user-row">
          <div class="desc">
            Device
          </div>
          <div
            class="data"
          >
            {{ `${jsonVisitor.identification.data.browserDetails.device}` }}
          </div>
        </div>
        <div class="exchange-info-form-user-row">
          <div class="desc">
            IP
          </div>
          <div
            class="data"
          >
            {{ `${jsonVisitor.identification.data.ip}` }}
          </div>
        </div>
      </div>
      <div class="exchange-info-form-info-block-item">
        <div class="exchange-info-form-user-row">
          <div class="desc">
            Data and time
          </div>
          <div
            class="data"
          >
            {{ `${jsonVisitor.identification.data.time}` }}
          </div>
        </div>
        <div class="exchange-info-form-user-row">
          <div class="desc">
            Visitor ID
          </div>
          <div
            class="data"
          >
            {{ `${jsonVisitor.identification.data.visitorId}` }}
          </div>
        </div>
      </div>
      <div class="exchange-info-form-info-block-item">
        <div class="exchange-info-form-user-row">
          <div class="desc">
            VPN
          </div>
          <div
            class="data"
          >
            {{ `${jsonVisitor.vpn.data.result ? 'Yes' : 'No'}` }}
          </div>
        </div>
        <div class="exchange-info-form-user-row">
          <div class="desc">
            Proxy
          </div>
          <div class="data" />
          {{ `${jsonVisitor.incognito.data.result ? 'Yes' : 'No'}` }}
        </div>
      </div>
      <div class="exchange-info-form-info-block-item">
        <div class="exchange-info-form-user-row">
          <div class="desc">
            Risk Score
          </div>
          <div
            class="data"
          />
          {{ `${jsonVisitor.suspectScore.data.result}` }}
        </div>
      </div>
      <div class="exchange-info-form-info-block-item">
        <div
          class="exchange-info-form-user-row"
          style="width: 100%"
        >
          <div class="desc">
            URL
          </div>
          <div
            class="data"
          >
            {{ `${jsonVisitor.identification.data.url}` }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FpjsService from "@/services/fpjs.service";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default {
  name: "ExchangeInfoUser",
  props: {
    data: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      jsonVisitor: null,
      uidData: null,
      dateFormatSec: 'DD.MM.YY HH:mm:ss',
    }
  },
  mounted() {
    this.parseJson();
  },
  methods: {
    parseJson() {
      const result = JSON.parse(this.data.jsonVisitor)
      const {identification, vpn, incognito, suspectScore} = result.products
      this.jsonVisitor =  {identification, vpn, incognito, suspectScore}
    },
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormatSec)
    },
  }
}
</script>

<style scoped>
.user-block {
  overflow: auto;
}

.exchange-info-form-user-row {
  width: 50%;

  .desc {
    font-size: 14px;
    line-height: 18px;
    color: #7C86A0;
    margin-bottom: 4px;
    font-weight: 400;
  }

  .data {
    font-size: 14px;
    line-height: 20px;
    color: #131521;
  }
}
</style>
