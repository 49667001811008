export const TABLE_TH = [
    {
        name: 'Name',
        sort: '',
        key: 'userKey',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'User value',
        sort: '',
        key: 'value',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Active',
        sort: '',
        key: 'active',
        description: 'Trade round is the latest round of trade negotiations'
    },
];
