import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index';
import {store} from './store/index'
import mitt from 'mitt';
import Maska from 'maska';
import VueClipboard from 'vue3-clipboard';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/style.css';

const emitter = mitt();
const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app
    .use(router)
    .use(PerfectScrollbarPlugin, {
        componentName: 'Scrollbar'
    })
    .use(store)
    .use(Maska)
    .component(VueQrcode.name, VueQrcode)
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    })
    .mount('#app');
