<template>
  <div class="trade-layout">
    <trade-nav />
    <div class="page">
      <TradeHeaderLayout v-if="isHeader" />
      <slot name="tradeSlot" />
    </div>
  </div>
</template>

<script>
import TradeNav from "@/components/trade/TradeNav.vue";
import TradeHeaderLayout from "@/components/trade/TradeHeaderLayout.vue";

export default {
  name: "TradeLayout",
  components: {TradeHeaderLayout, TradeNav},
  props: {
    isHeader: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.trade-layout {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  font-family: 'Rubik', sans-serif;
  overflow: hidden;

  .page {
    background: #F4F8FE;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 34px 32px 0;
    z-index: 1;
  }
}
</style>
