<template>
  <trade-popup-layout title="Add new account">
    <template #tradePopupSlot>
      <div class="create-account">
        <div class="title">
          Details
        </div>

        <div class="inputs">
          <div
            v-if="selectedService"
            ref="selectService"
            class="type"
          >
            <div
              class="select-input"
              @click="isSelectService = !isSelectService"
            >
              <div class="select-input_selected">
                <span v-html="logos[selectedService.modeAccType]" />
                <div class="selected">
                  <div class="desc">
                    Market
                  </div>
                  <div class="value">
                    {{ selectedService.modeAccType }}
                  </div>
                </div>
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3860_6222)">
                  <path
                    d="M7 10L12 15L17 10H7Z"
                    fill="#1A242B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3860_6222">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="matrix(0 1 -1 0 24 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              v-if="isSelectService"
              class="select-input_dropmenu"
            >
              <div
                v-for="(service, index) in switchersService"
                :key="index"
                class="item"
                @click="selectedService = service; isSelectService = !isSelectService"
              >
                <div class="item--market">
                  <span v-html="logos[service.modeAccType]" />
                  {{ service.modeAccType }}
                </div>

                <svg
                  v-if="service.modeAccType === selectedService.modeAccType"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3860_8139)">
                    <path
                      d="M7.50208 13.4749L4.02708 9.99987L2.84375 11.1749L7.50208 15.8332L17.5021 5.8332L16.3271 4.6582L7.50208 13.4749Z"
                      fill="#007AFF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3860_8139">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div
            ref="selectType"
            class="type"
          >
            <div
              class="select-input"
              @click="isSelectType = !isSelectType"
            >
              <div class="selected">
                <div class="desc">
                  Type
                </div>
                <div class="value">
                  {{ selectedType }}
                </div>
              </div>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3860_6222)">
                  <path
                    d="M7 10L12 15L17 10H7Z"
                    fill="#1A242B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3860_6222">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="matrix(0 1 -1 0 24 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              v-if="isSelectType"
              class="select-input_dropmenu"
            >
              <div
                v-for="(type, index) in switchersType"
                :key="index"
                class="item"
                @click="selectedType = type; isSelectType = !isSelectType"
              >
                {{ type }}

                <svg
                  v-if="type === selectedType"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3860_8139)">
                    <path
                      d="M7.50208 13.4749L4.02708 9.99987L2.84375 11.1749L7.50208 15.8332L17.5021 5.8332L16.3271 4.6582L7.50208 13.4749Z"
                      fill="#007AFF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3860_8139">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div
            ref="selectPartner"
            class="type"
          >
            <div
              class="select-input"
              @click="isSelectPartner = !isSelectPartner"
            >
              <div class="selected">
                <div class="desc">
                  Partner
                </div>
                <div class="value">
                  {{ selectedPartner.login }}
                </div>
              </div>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3860_6222)">
                  <path
                    d="M7 10L12 15L17 10H7Z"
                    fill="#1A242B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3860_6222">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="matrix(0 1 -1 0 24 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              v-if="isSelectPartner"
              class="select-input_dropmenu"
            >
              <div
                v-if="selectedPartner.login !== 'All'"
                class="item"
                @click="selectedPartner.login = 'All'; isSelectPartner = !isSelectPartner"
              >
                All
              </div>
              <div
                v-for="(partner, index) in switchersPartner"
                :key="index"
                class="item"
                @click="selectedPartner = partner; isSelectPartner = !isSelectPartner"
              >
                {{ partner.login }}

                <svg
                  v-if="partner.login === selectedPartner.login"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3860_8139)">
                    <path
                      d="M7.50208 13.4749L4.02708 9.99987L2.84375 11.1749L7.50208 15.8332L17.5021 5.8332L16.3271 4.6582L7.50208 13.4749Z"
                      fill="#007AFF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3860_8139">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div
            class="inputs--item inputs--item_amount"
            :class="{'focus': !!nameAcc, 'error': nameError}"
            @click="focusOn('nameAcc')"
          >
            <div class="input">
              <div class="desc">
                Name
              </div>
              <input
                ref="nameAcc"
                v-model="nameAcc"
                type="text"
                @keyup="checkValidName(nameAcc)"
              >
            </div>
          </div>
        </div>

        <div
          v-if="selectedService"
          class="button"
          :class="{'button-disabled': nameError || nameAcc.length < 6}"
          @click="createAccount()"
        >
          Create {{ selectedService.modeAccType }} account
        </div>
      </div>
    </template>
  </trade-popup-layout>
</template>

<script>
import TradePopupLayout from "@/layouts/trade/TradePopupLayout.vue";
import UserService from "@/services/user.service";
import serviceLogos from "@/assets/img/servicesLogos/serviceLogos";
import MultiExchangeService from "@/services/multiExchange.service";
import TradeService from "@/services/trade.service";

export default {
  components: {TradePopupLayout},
  data() {
    return {
      logos: serviceLogos,
      nameAcc: '',
      selectedService: null,
      isSelectService: false,
      switchersService: null,
      selectedType: 'direct',
      isSelectType: false,
      switchersType: ['direct', 'node'],
      selectedPartner: {
        login: 'All'
      },
      isSelectPartner: false,
      switchersPartner: null,
      nameError: false
    }
  },
  mounted() {
    this.getUserAccList();
  },
  methods: {
    focusOn(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].focus();
      }
    },
    createAccount() {
      const account = {
        modeAccType: this.selectedService.modeAccType,
        name: this.nameAcc,
        type: this.selectedType,
        partner: this.selectedPartner.login
      }

      if (account.name.length >= 6) {

        TradeService.createTradeAccount(account).then((res) => {
          if (res) {
            this.$router.push({
              name: 'Trade accounts',
              params: {
                id: this.$route.params.id
              }
            });
          }
        })
      } else {
        this.nameError = true
      }
    },
    checkValidName(val) {
      this.nameError = val.length <= 6;
    },
    getUserAccList() {
      MultiExchangeService.getAccountsList().then((res) => {
        const users = res.data.result;
        const foundUserAcc = users[0];

        if (foundUserAcc) {
          this.selectedService = foundUserAcc;
          this.switchersService = users.reduce((acc, curr) => {
            const existingItem = acc.find((item) => item.modeAccType === curr.modeAccType);
            if (!existingItem) {
              acc.push(curr);
            }
            return acc;
          }, []);
        }
        this.getPartnerList();
      });

    },
    getPartnerList() {
      UserService.getUserFavorite().then((res) => {
        this.switchersPartner = res.data.result;
      })
    },
  }
}
</script>

<style lang="scss">
.create-account {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0 4px 48px 0 rgba(6, 59, 122, 0.08), 0px 1px 4px 0px rgba(6, 59, 122, 0.06);
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  padding: 24px 32px 32px;
  box-sizing: border-box;

  .title {
    color: #1A242B;
    font-family: 'Rubik',sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.4px;
  }

  .inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px 0;
    margin-top: 16px;
    margin-bottom: 24px;

    &--item {
      width: 100%;
      border-radius: 6px;
      outline: 1px solid rgba(124, 163, 210, 0.24);
      padding: 6px 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      cursor: pointer;
      transition: all .2s ease-in-out;
      border: none;
      height: 50px;

      &:hover {
        background: var(--trade-hover, rgba(0, 122, 255, 0.06));

        .desc {
          color: #1A242B;
        }
      }

      .input {
        position: relative;
        height: 100%;
      }


      .desc {
        color: var(--trade-trade-gray, #527AA1);
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        transform: translateY(8px);
        transition: all .2s ease-in-out;
      }

      input {
        background: none;
        border: none;
        outline: none;
        color: var(--trade-trade-black, #1A242B);
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        max-width: 188px;
        cursor: pointer;
      }

      .coin {
        color: var(--trade-trade-gray, #527AA1);
        text-align: right;
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 183.333% */
      }

      &_switchers {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-top: 8px;

        .switchers {
          &--item {
            width: 100%;
            padding: 0;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
            letter-spacing: -0.2px;
            transition: all .3s ease-in-out;

            &_active {
              font-weight: 500;
            }
          }
        }
      }

      &:focus-within {
        border: none;
        outline: 2px solid var(--trade-trade-black, #1A242B);

        .desc {
          font-size: 12px;
          line-height: 14px;
          color: #1A242B;
          transform: translateY(0);
        }

        &:hover {
          background: none;
        }
      }
    }

    .focus {
      .desc {
        transform: translateY(0);
        font-size: 12px;
        line-height: 14px;
        color: #527AA1;
      }
    }

    .error {
      outline: 2px solid var(--trade-trade-red, #FF603D);

      .desc {
        color: #FF603D;
      }
    }

    .type {
      width: 100%;
      padding: 6px 8px 6px 12px;
      border-radius: 6px;
      border: 1px solid rgba(124, 163, 210, 0.24);
      position: relative;
      cursor: pointer;
      box-sizing: border-box;

      .select-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &_selected {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }

        .selected {
          display: flex;
          flex-direction: column;

          .desc {
            color: var(--trade-trade-gray, #527AA1);
            font-family: 'Rubik', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 116.667% */
          }

          .value {
            color: var(--trade-trade-black, #1A242B);
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }
        }

        &_dropmenu {
          z-index: 9;
          position: absolute;
          padding: 6px 0;
          top: calc(100% + 4px);
          left: 0;
          width: 100%;
          border-radius: 6px;
          background: #FFF;
          box-shadow: 0px 1px 6px 0px rgba(6, 59, 122, 0.16);
          max-height: 300px;
          overflow: auto;

          .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 8px 20px 8px 12px;
            cursor: pointer;
            color: var(--trade-trade-black, #1A242B);
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 114.286% */

            &:hover {
              background: var(--trade-hover, rgba(0, 122, 255, 0.06));
            }

            &--market {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
              font-weight: 500;
            }
          }
        }
      }

      &:hover {
        background: var(--trade-hover, rgba(0, 122, 255, 0.06));
      }
    }
  }

  .button {
    padding: 14px 0;
    width: 100%;
    border-radius: 6px;
    border: 1px solid rgba(130, 170, 200, 0.24);
    background: var(--trade-trade-blue, #007AFF);
    color: #FFF;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.2px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), #007AFF;
    }

    &-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
</style>
