<script setup>

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4241_71824)">
      <path
        d="M15.9504 10.7833C15.9837 10.5333 16.0004 10.275 16.0004 10C16.0004 9.73333 15.9837 9.46667 15.9421 9.21667L17.6337 7.9C17.7837 7.78333 17.8254 7.55833 17.7338 7.39167L16.1337 4.625C16.0338 4.44167 15.8254 4.38333 15.6421 4.44167L13.6504 5.24167C13.2337 4.925 12.7921 4.65833 12.3004 4.45833L12.0004 2.34167C11.9671 2.14167 11.8004 2 11.6004 2H8.40042C8.20042 2 8.04208 2.14167 8.00875 2.34167L7.70875 4.45833C7.21708 4.65833 6.76708 4.93333 6.35875 5.24167L4.36708 4.44167C4.18375 4.375 3.97542 4.44167 3.87542 4.625L2.28375 7.39167C2.18375 7.56667 2.21708 7.78333 2.38375 7.9L4.07542 9.21667C4.03375 9.46667 4.00042 9.74167 4.00042 10C4.00042 10.2583 4.01708 10.5333 4.05875 10.7833L2.36708 12.1C2.21708 12.2167 2.17542 12.4417 2.26708 12.6083L3.86708 15.375C3.96708 15.5583 4.17542 15.6167 4.35875 15.5583L6.35042 14.7583C6.76708 15.075 7.20875 15.3417 7.70042 15.5417L8.00042 17.6583C8.04208 17.8583 8.20042 18 8.40042 18H11.6004C11.8004 18 11.9671 17.8583 11.9921 17.6583L12.2921 15.5417C12.7837 15.3417 13.2338 15.075 13.6421 14.7583L15.6337 15.5583C15.8171 15.625 16.0254 15.5583 16.1254 15.375L17.7254 12.6083C17.8254 12.425 17.7837 12.2167 17.6254 12.1L15.9504 10.7833ZM10.0004 13C8.35042 13 7.00042 11.65 7.00042 10C7.00042 8.35 8.35042 7 10.0004 7C11.6504 7 13.0004 8.35 13.0004 10C13.0004 11.65 11.6504 13 10.0004 13Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4241_71824">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
