<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2496 0.758309C10.9246 0.433309 10.3996 0.433309 10.0746 0.758309L5.99961 4.82498L1.92461 0.749976C1.59961 0.424976 1.07461 0.424976 0.749609 0.749976C0.424609 1.07498 0.424609 1.59998 0.749609 1.92498L4.82461 5.99998L0.749609 10.075C0.424609 10.4 0.424609 10.925 0.749609 11.25C1.07461 11.575 1.59961 11.575 1.92461 11.25L5.99961 7.17498L10.0746 11.25C10.3996 11.575 10.9246 11.575 11.2496 11.25C11.5746 10.925 11.5746 10.4 11.2496 10.075L7.17461 5.99998L11.2496 1.92498C11.5663 1.60831 11.5663 1.07498 11.2496 0.758309Z"
      fill="#9FA2B4"
    />
  </svg>
</template>

<script>
    export default {
        name: "CloseIcon"
    }
</script>

<style scoped>
    svg {
        cursor: pointer;
    }
</style>