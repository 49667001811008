<template>
  <AccountLayout class="dashboard_page">
    <template #title>
      <div class="layout-header">
        <h2>Dashboard</h2>
      </div>
    </template>
    <template
      #content
      class="content_dashboard"
    >
      <div class="dashboard">
        <TransactionsValue />
        <NodasBalances />
        <div class="dashboard-rating">
          <DepositTop />
          <WithdrawalTop />
        </div>
      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/admin/AccountLayout.vue";
import DepositTop from "./partials/DepositTop.vue";
import WithdrawalTop from "./partials/WithdrawalTop.vue";
import TransactionsValue from "./partials/TransactionsValue.vue";
import NodasBalances from "@/pages/dashboard/partials/NodasBalances.vue";
import { API_URL } from "@/store/config";

export default {
  name: "Dashboard",
  components: {NodasBalances, TransactionsValue, WithdrawalTop, DepositTop, AccountLayout},
  data() {
    return {
      API_URL: API_URL
    }
  }
}
</script>

<style lang="scss">
  @import '../../assets/styles/layout/dashboard';
</style>
