<template>
  <div class="">
    <div
      class="editSubAccount"
      tabindex="0"
    >
      <div class="editSubAccount-block">
        <div class="editSubAccount-block-form">
          <div
            class="close"
            @click="closePopup"
          >
            <img
              src="../../../assets/img/close.svg"
              alt=""
            >
          </div>
          <div class="editSubAccount-block-form-title">
            Edit sub account
          </div>
          <div class="editSubAccount-block-form-inputs">
            <div class="editSubAccount-block-form-inputs">
              <div class="input">
                <div class="input-title">
                  Name
                </div>
                <input
                  v-model="subAccountData.name"
                  type="text"
                  :placeholder="subAccountData.name"
                >
              </div>
              <div class="input">
                <div class="input-title">
                  Api Key
                </div>
                <input
                  v-model="subAccountData.apiKey"
                  type="text"
                  :placeholder="subAccountData.apiKey"
                >
              </div>
              <div class="input">
                <div class="input-title">
                  Api Secret
                </div>
                <input
                  v-model="subAccountData.apiSecret"
                  type="text"
                  :placeholder="subAccountData.apiSecret"
                >
              </div>
              <div class="toggles">
                <div class="toggles-item">
                  <div class="text">
                    Is Active
                  </div>
                  <Toggle v-model="subAccountData.isActive" />
                </div>
                <div class="toggles-item">
                  <div class="text">
                    Is Busy
                  </div>
                  <Toggle v-model="subAccountData.isBusy" />
                </div>
              </div>
            </div>
          </div>
          <div class="editSubAccount-block-form-submit">
            <button
              class="cancel"
              @click="closePopup"
            >
              Cancel
            </button>
            <button
              class="save"
              @click="changeSubAccountData"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubAccountsService from "@/services/subAccounts.service";
import Toggle from '@vueform/toggle'

export default {
  name: "SubAccountEdit",
  components: {
    Toggle,
  },
  props: {
    data: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      subAccountData: {
        name: '',
        isActive: false,
        isBusy: false,
        apiKey: '',
        apiSecret: '',
      },
    }
  },
  mounted() {
    this.subAccountData = this.data;
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closePopup()
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closePopup()
      }
    })
  },
  methods: {
    successPopup() {
      this.$store.commit('setSuccess', {
        name: 'Edit sub account success',
        value: this.subAccountData,
      });
    },
    errorPopup() {
      this.$store.commit('setError', {
        name: 'Edit sub account error',
        value: this.subAccountData,
      });
    },
    closePopup() {
      this.$store.commit('setSubAccountData', null);
      this.$router.push({name: 'Sub accounts'})

    },
    changeSubAccountData() {
      const id = this.subAccountData.id;
      delete this.subAccountData.id;

      SubAccountsService.editSubAccountById(id, this.subAccountData).then(() => {
        this.closePopup();
        this.successPopup();
      }).catch(() => {
        this.errorPopup();
      })
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/pages/sub-accounts/edit-sub-account";
</style>
