export const DEPOSIT_TABLE_TH = [
    {
        name: 'Range',
        key: 'range',
        status: false
    },
    {
        name: 'Today',
        key: 'today',
        status: false
    },
    {
        name: 'Week',
        key: 'week',
        status: false
    },
    {
        name: 'Month',
        key: 'month',
        status: true
    },
    {
        name: 'Total',
        key: 'total',
        status: false
    }
];

export const WITHDRAWAL_TABLE_TH = [
    {
        name: 'Range',
        key: 'range',
        status: false
    },
    {
        name: 'Today',
        key: 'today',
        status: false
    },
    {
        name: 'Week',
        key: 'week',
        status: false
    },
    {
        name: 'Month',
        key: 'month',
        status: true
    },
    {
        name: 'Total',
        key: 'total',
        status: false
    }
];


export const TRANSACTION_DATES = [
    {
        name: 'Range',
        key: 'range',
        status: false
    },
    {
        name: 'Today',
        key: 'today',
        status: false
    },
    {
        name: 'Week',
        key: 'week',
        status: false
    },
    {
        name: 'Month',
        key: 'month',
        status: true,
    },
    {
        name: 'Total',
        key: 'total',
        status: false
    }
];

export const TRANSACTION_TABLE_TH = [
    {
        name: 'Today',
        cssClass: '',
    },
    {
        name: 'Yesterday',
        cssClass: '',
    },
    {
        name: 'Current week',
        cssClass: '',
    },
    {
        name: 'Current month',
        cssClass: '',
    },
    {
        name: 'Total',
        cssClass: 'text-total',
    }
];
