<script setup>

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4241_72190)">
      <path
        d="M12.4987 3.33301C8.81536 3.33301 5.83203 6.31634 5.83203 9.99967C5.83203 13.683 8.81536 16.6663 12.4987 16.6663C16.182 16.6663 19.1654 13.683 19.1654 9.99967C19.1654 6.31634 16.182 3.33301 12.4987 3.33301ZM2.4987 9.99967C2.4987 7.82467 3.89036 5.97467 5.83203 5.29134V3.54967C2.95703 4.29134 0.832031 6.89134 0.832031 9.99967C0.832031 13.108 2.95703 15.708 5.83203 16.4497V14.708C3.89036 14.0247 2.4987 12.1747 2.4987 9.99967Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4241_72190">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
