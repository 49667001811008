<template>
  <svg width="173" height="32" viewBox="0 0 173 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45.181 14.0483H49.9947C49.9766 10.4109 47.1716 8.00403 42.6837 8.00403C38.2772 8.00403 35.1646 10.3747 35.1917 13.9035C35.1827 16.7989 37.1914 18.4095 40.4759 19.1153L42.3217 19.5134C44.3847 19.9658 45.0905 20.4816 45.1086 21.3231C45.0905 22.237 44.2581 22.8794 42.6475 22.8794C40.7202 22.8794 39.5801 21.9746 39.4987 20.2735H35.252C35.2701 24.9243 37.8881 27.0054 42.7199 27.0054C47.4159 27.0054 50.2299 24.9786 50.248 21.3231C50.2299 18.5 48.547 16.5185 44.4571 15.6408L42.937 15.315C41.1817 14.9441 40.3945 14.4283 40.4397 13.5416C40.4488 12.7272 41.1274 12.13 42.6837 12.13C44.3124 12.13 45.0815 12.8449 45.181 14.0483Z" fill="white"/>
    <path d="M53.9521 26.7882H59.3449L61.2993 19.3325H61.4441L63.3985 26.7882H68.7913L72.2658 12.8901H67.2711L65.751 21.2869H65.6425L63.7966 12.8901H58.9467L57.1733 21.3593H57.0647L55.4722 12.8901H50.4776L53.9521 26.7882Z" fill="white"/>
    <path d="M76.3846 27.0054C78.1942 27.0054 79.5605 26.3901 80.402 24.8338H80.5106V26.7882H85.1795V17.2695C85.1795 14.6997 82.7455 12.7091 78.7733 12.7091C74.6202 12.7091 72.5752 14.8807 72.4033 17.4142H76.9998C77.1175 16.5546 77.7509 16.1475 78.7009 16.1475C79.5696 16.1475 80.1848 16.5456 80.1848 17.2695V17.3057C80.1848 18.1109 79.2981 18.3643 76.9637 18.5362C74.0954 18.7443 71.8604 19.9025 71.8604 22.9518C71.8604 25.7205 73.7244 27.0054 76.3846 27.0054ZM78.0133 23.8566C77.1627 23.8566 76.5655 23.4404 76.5655 22.6622C76.5655 21.9474 77.0722 21.3955 78.2304 21.2145C79.0267 21.0878 79.6962 20.925 80.221 20.7078V21.866C80.221 23.1327 79.1443 23.8566 78.0133 23.8566Z" fill="white"/>
    <path d="M86.292 32H91.2866V24.5081H91.359C91.9381 25.9196 93.2772 26.9692 95.2317 26.9692C98.1995 26.9692 100.697 24.6528 100.697 19.8392C100.697 14.8083 98.0185 12.7091 95.2679 12.7091C93.2049 12.7091 91.9019 13.9035 91.359 15.315H91.2504V12.8901H86.292V32ZM91.178 19.8392C91.178 17.7762 92.0105 16.5456 93.3858 16.5456C94.7612 16.5456 95.5574 17.7762 95.5574 19.8392C95.5574 21.9022 94.7612 23.1327 93.3858 23.1327C92.0105 23.1327 91.178 21.866 91.178 19.8392Z" fill="white"/>
    <path d="M110.527 20.7078C110.536 22.0831 109.74 22.9156 108.501 22.9156C107.243 22.9156 106.519 22.0831 106.51 20.7078V12.8901H101.515V21.7574C101.524 24.7976 103.443 26.9692 106.365 26.9692C108.437 26.9692 110.03 25.9196 110.636 24.1461H110.781V26.7882H115.522V12.8901H110.527V20.7078Z" fill="white"/>
    <path d="M116.933 26.7882H129.13V22.988H123.375V22.9156L128.913 15.9303V12.8901H117.15V16.6904H123.013V16.7628L116.933 24.0376V26.7882Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3561 0.19853C21.2497 -0.343048 22.3945 0.285795 22.4167 1.33045L22.5515 7.66217L29.1114 8.79302C30.1411 8.97052 30.5925 10.1961 29.9239 10.9991L25.5587 16.242L28.8737 18.9055C29.6882 19.5599 29.4888 20.8507 28.5148 21.2289L22.5244 23.5547L23.2587 27.2568C23.462 28.2817 22.4796 29.1423 21.4903 28.8059L17.2172 27.3528C12.3436 29.7196 6.50462 28.4925 2.98389 24.4768C8.19863 25.9823 12.3782 24.4087 14.3621 22.782C14.8452 22.3859 14.9157 21.6732 14.5197 21.1901C14.1236 20.7071 13.4109 20.6365 12.9278 21.0326C11.281 22.3829 6.78308 24.1578 0.960642 21.2628C-1.35055 15.8598 0.606624 9.4407 5.77716 6.30695C8.0523 4.92805 10.6035 4.39502 13.0642 4.61799L20.3561 0.19853ZM9.80191 16.1945C8.73343 16.842 6.4965 15.9394 5.84892 14.8709C5.20133 13.8025 5.54254 12.4113 6.61102 11.7637C7.6795 11.1162 11.4827 9.42921 12.1303 10.4977C12.7779 11.5662 10.8704 15.5469 9.80191 16.1945Z" fill="#0065FF"/>
    <rect x="133" y="11" width="40" height="17" rx="3" fill="#0065FF"/>
    <path d="M138.911 23H137.211L139.419 16.4545H141.526L143.734 23H142.034L140.496 18.1037H140.445L138.911 23ZM138.684 20.424H142.238V21.6257H138.684V20.424ZM146.858 23H144.439V16.4545H146.855C147.522 16.4545 148.096 16.5856 148.578 16.8477C149.061 17.1076 149.434 17.4826 149.696 17.9727C149.958 18.4606 150.089 19.0444 150.089 19.7241C150.089 20.4059 149.958 20.9918 149.696 21.4819C149.436 21.9719 149.064 22.348 148.581 22.6101C148.097 22.87 147.523 23 146.858 23ZM146.021 21.6513H146.797C147.164 21.6513 147.474 21.5895 147.727 21.4659C147.983 21.3402 148.176 21.1367 148.306 20.8555C148.438 20.5721 148.504 20.195 148.504 19.7241C148.504 19.2532 148.438 18.8782 148.306 18.5991C148.174 18.3178 147.979 18.1154 147.721 17.9918C147.465 17.8661 147.15 17.8033 146.775 17.8033H146.021V21.6513ZM151.013 16.4545H152.972L154.634 20.5071H154.711L156.373 16.4545H158.332V23H156.791V18.9794H156.737L155.165 22.9585H154.18L152.608 18.957H152.553V23H151.013V16.4545ZM160.901 16.4545V23H159.319V16.4545H160.901ZM167.464 16.4545V23H166.122L163.517 19.2223H163.476V23H161.894V16.4545H163.255L165.831 20.2259H165.886V16.4545H167.464Z" fill="#1A242B"/>
  </svg>
</template>

<script>
    export default {
        name: "Logo"
    }
</script>

<style scoped>

</style>
