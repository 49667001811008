<script>
import TradePopupLayout from "@/layouts/trade/TradePopupLayout.vue";
import TradeService from "@/services/trade.service";
import {MAIN_DOMAIN} from "@/store/config";
import {mapGetters} from "vuex";
import VueQrcode from '@chenfengyuan/vue-qrcode';
import UserService from "@/services/user.service";
import TradeLoader from "@/components/trade/components/TradeLoader.vue";
import CopyNotification from "@/pages/notification/CopyNotification.vue";

export default {
  name: "CreateDeposit",
  components: {
    CopyNotification,
    TradeLoader,
    TradePopupLayout,
    VueQrcode
  },
  data() {
    return {
      defaultCoin: 'BNB',
      apiUrl: MAIN_DOMAIN,
      isSelectType: false,
      isAddComment: false,
      selectedType: 'Profit',
      switchersType: ['Order', 'Profit', 'Other'],
      preSavedComment: '',
      comment: '',
      selectedCoin: null,
      selectedNetwork: null,
      amount: null,
      amountCoin: '',
      address: '',
      passCode: '',
      isDropCoins: false,
      isDropNetworks: false,
      isMemo: '',
      memo: '',
      coinsList: null,
      addressTouched: false,
      memoTouched: false,
      userProfile: null,
      successPopup: false,
      searchText: '',
      selectedAddress: null,
      isLoader: false,
      isSuccessCopy: false,
      copyText: ''
    }
  },
  computed: {
    ...mapGetters({
      UserAcc: 'getUserAcc',
    }),
    filteredCoins() {
      const searchText = this.searchText.toLowerCase();
      return this.coinsList.filter(coin =>
          coin.shortName.toLowerCase().includes(searchText) ||
          coin.name.toLowerCase().includes(searchText)
      );
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newValue, oldValue) {
        this.getCoinsList(newValue);
      }
    }
  },
  created() {
    this.getProfile();
    this.getCoinsList();
  },
  mounted() {
    document.addEventListener('click', this.handleClickCoins);
    document.addEventListener('click', this.handleClickNetworks);
    document.addEventListener('click', this.handleClickType);
  },
  methods: {
    handleClickCoins(event) {
      if (this.$refs.coinsList && !this.$refs.coinsList.contains(event.target)) {
        this.isDropCoins = false
      }
    },
    handleClickNetworks(event) {
      if (this.$refs.networksList && !this.$refs.networksList.contains(event.target)) {
        this.isDropNetworks = false
      }
    },
    handleClickType(event) {
      if (this.$refs.type && !this.$refs.type.contains(event.target)) {
        this.isSelectType = false
      }
    },
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    addressHandler(address) {
      if (address.length < 28) {
        return address;
      }

      const firstPart = address.slice(0, 14);
      const lastPart = address.slice(-14);

      return firstPart + '...' + lastPart;
    },
    getCoinsList() {
      TradeService.getDepositCoins().then(async (res) => {
        this.coinsList = res.data.result

        if (this.$route.params.coin) {
          this.selectedCoin = this.coinsList.find((item) => {
            return item.shortName === this.$route.params.coin
          });
        }
      })
    },
    getDepositAddress() {
      this.isLoader = true;
      const query = '?acc=' + this.UserAcc.id + '&idNetwork=' + this.selectedNetwork.id
      TradeService.getDepositAddress(query).then((res) => {
        if (res.data.result) {
          this.selectedAddress = res.data.result;
          this.isLoader = false;
        } else {
          this.isLoader = true;
        }
      })
    },
    focusOn(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].focus();
      }
    },
    networkSelect(network) {
      this.selectedAddress = null;
      this.isDropNetworks = false
      this.selectedNetwork = network

      this.getDepositAddress();
    },
    selectCoinHandler(coin) {
      this.isDropCoins = !this.isDropCoins
      this.selectedCoin = coin;
      this.selectedNetwork = null;
      this.selectedAddress = null;
    },
    getProfile() {
      UserService.getProfile().then((res) => {
        this.userProfile = res.data.result;
      })
    },
    switchType(el) {
      this.selectedType = el
      this.isSelectType = false
    },
    addCommentHandler() {
      this.isAddComment = !this.isAddComment;
    },
    handleButtonClick() {
      if (!this.isAddComment) {
        this.addCommentHandler();
      }
    },
    saveComment() {
      this.comment = this.preSavedComment;
    },
    adjustTextareaHeight() {
      const textarea = this.$refs.commentTextarea;

      textarea.style.height = 'auto';

      if (this.preSavedComment.length > 40) {
        textarea.style.height = `70px`;
      }

      if (this.preSavedComment.length > 120) {
        textarea.style.height = `100px`;
      }
    },
    cancelComment() {
      this.isAddComment = false;
      this.comment = '';
    },
    goBack() {
      this.successPopup = false;
      this.$router.push({
        name: 'Trade balance',
        params: {
          id: this.$route.params.id
        }
      });
    },
  }
}
</script>

<template>
  <trade-popup-layout title="New deposit">
    <template #tradePopupSlot>
      <div class="create-order create-order_withdrawal">
        <div class="create-order_tab create-order_tab-deposit create-order_inputs">
          <div class="create-order_withdrawal-title">
            Details
          </div>
          <div class="create-order_withdrawal-desc">
            Select the coin and network you are interested in. After this, we will generate an address for
            replenishment, which you will see on the right
          </div>
          <div
            class="order"
          >
            <div class="inputs">
              <div
                ref="coinsList"
                class="inputs--item inputs--item_select"
                :class="{'focus': !!isDropCoins}"
              >
                <div
                  class="selected"
                  @click="isDropCoins = !isDropCoins"
                >
                  <div class="selected_coin_info">
                    <div
                      v-if="selectedCoin"
                      class="selected_coin"
                    >
                      <img
                        v-if="selectedCoin.image"
                        :src="apiUrl + selectedCoin.image"
                        alt=""
                      >
                      <div class="selected_coin-text">
                        <div class="selected_coin-text--coin">
                          {{ selectedCoin.shortName }}
                        </div>
                        <div class="selected_coin-text--fullName">
                          {{ selectedCoin.name }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="selected_coin"
                    >
                      <div class="selected_coin-text">
                        <div class="selected_coin-text--coin" />
                        <div class="selected_coin-text--fullName">
                          Select coin
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="arrow"
                  @click="isDropCoins = !isDropCoins"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3860_5848)">
                      <path
                        d="M7 10L12 15L17 10H7Z"
                        fill="#1A242B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3860_5848">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(0 1 -1 0 24 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  v-if="isDropCoins"
                  class="inputs--item_select--dropdown"
                >
                  <div class="list-searchBlock">
                    <div class="list--search">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4217_51319)">
                          <path
                            d="M16.3243 15.2488L12.7386 11.6483C13.6605 10.5902 14.1657 9.25887 14.1657 7.8729C14.1657 4.63474 11.4369 2.00018 8.08284 2.00018C4.72882 2.00018 2 4.63474 2 7.8729C2 11.1111 4.72882 13.7456 8.08284 13.7456C9.34199 13.7456 10.5419 13.379 11.5678 12.6829L15.1807 16.3107C15.3317 16.4621 15.5349 16.5456 15.7525 16.5456C15.9585 16.5456 16.154 16.4698 16.3023 16.3319C16.6176 16.0391 16.6277 15.5534 16.3243 15.2488ZM8.08284 3.5322C10.562 3.5322 12.5789 5.47939 12.5789 7.8729C12.5789 10.2664 10.562 12.2136 8.08284 12.2136C5.60369 12.2136 3.58683 10.2664 3.58683 7.8729C3.58683 5.47939 5.60369 3.5322 8.08284 3.5322Z"
                            fill="#527AA1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4217_51319">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <input
                        v-model="searchText"
                        type="text"
                        placeholder="Search"
                      >
                    </div>
                  </div>
                  <div
                    v-for="(coin, index) in filteredCoins"
                    :key="index"
                    class="list--item"
                    :class="{'list--item-selected': selectedCoin && selectedCoin.shortName === coin.shortName}"
                    @click="selectCoinHandler(coin)"
                  >
                    <div class="info">
                      <img
                        :src="apiUrl + coin.image"
                        alt=""
                      >
                      <div class="info_text">
                        <div class="info_text--coin">
                          {{ coin.shortName }}
                        </div>
                        <div class="info_text--fullName">
                          {{ coin.name }}
                        </div>
                      </div>
                    </div>
                    <div v-if="selectedCoin && selectedCoin.shortName === coin.shortName">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3919_4922)">
                          <path
                            d="M7.50208 13.4749L4.02708 9.99987L2.84375 11.1749L7.50208 15.8332L17.5021 5.8332L16.3271 4.6582L7.50208 13.4749Z"
                            fill="#007AFF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3919_4922">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ref="networksList"
                class="inputs--item inputs--item_select"
                :class="{'focus': isDropNetworks}"
                @click="selectedCoin ? isDropNetworks = !isDropNetworks : null"
              >
                <div
                  class="select-input"
                >
                  <div class="select-input__selected">
                    <div
                      v-if="selectedNetwork"
                      class="select-input_desc"
                    >
                      Network
                    </div>
                    <div
                      v-else
                      class="select-input_desc"
                    >
                      Select network
                    </div>
                    <div
                      v-if="selectedNetwork"
                      class="select-input_network"
                    >
                      {{ selectedNetwork.name }}
                    </div>
                  </div>
                  <div class="select-input_arrow">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3860_5848)">
                        <path
                          d="M7 10L12 15L17 10H7Z"
                          fill="#1A242B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3860_5848">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="matrix(0 1 -1 0 24 0)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>

                <div
                  v-if="isDropNetworks"
                  class="select-input--dropdown"
                >
                  <div
                    v-for="(network, index) in selectedCoin.network"
                    :key="index"
                    class="item"
                    :class="{'item-selected': selectedNetwork && selectedNetwork.shortName === network.shortName}"
                    @click="networkSelect(network)"
                  >
                    <div class="item-name">
                      {{ network.name }}
                    </div>
                    <div v-if="selectedNetwork && selectedNetwork.shortName === network.shortName">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_3919_4922)">
                          <path
                            d="M7.50208 13.4749L4.02708 9.99987L2.84375 11.1749L7.50208 15.8332L17.5021 5.8332L16.3271 4.6582L7.50208 13.4749Z"
                            fill="#007AFF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3919_4922">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="create-order_tab create-order_tab-deposit create-order_inputs">
          <div class="create-order_withdrawal-title">
            Deposit address
          </div>
          <div
            v-if="!selectedAddress && !isLoader"
            class="create-order_withdrawal-desc"
          >
            The deposit address will appear after you specify the coin and network
          </div>
          <div
            v-else
            class="create-order_withdrawal-desc"
          >
            Scan the code or copy the information below to transfer funds from your account. Please, attend! For some
            coins you need to specify MEMO
          </div>
          <div
            v-if="selectedAddress"
            class="order"
          >
            <div class="inputs">
              <div
                class="inputs--item inputs--item_select"
              >
                <div
                  v-clipboard:copy="selectedAddress.address"
                  v-clipboard:success="onCopyAddress"
                  class="select-input"
                >
                  <div class="select-input__selected">
                    <div class="select-input_desc">
                      Address
                    </div>
                    <div class="select-input_network">
                      {{ addressHandler(selectedAddress.address) }}
                    </div>
                  </div>
                  <div class="copy">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 21V19.2H5.8V21H4ZM4 17.85V16.05H5.8V17.85H4ZM4 14.7V12.9H5.8V14.7H4ZM4 11.55V9.75H5.8V11.55H4ZM4 8.4V6.6H5.8V8.4H4ZM7.15 21V19.2H8.95V21H7.15ZM7.6 17.4V3H19.3V17.4H7.6ZM9.4 15.6H17.5V4.8H9.4V15.6ZM10.3 21V19.2H12.1V21H10.3ZM13.45 21V19.2H15.25V21H13.45Z"
                        fill="#527AA1"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <trade-loader v-if="isLoader" />
          <div class="qr-block">
            <vue-qrcode
              v-if="selectedNetwork && !isLoader"
              :value="selectedAddress.address"
              tag="svg"
              :options="{ width: 120, margin: 2.5 }"
            />
          </div>
          <div
            v-if="selectedNetwork && !isLoader"
            v-clipboard:copy="selectedAddress.address"
            v-clipboard:success="onCopyAddress"
            class="button"
          >
            Copy deposit address
          </div>
        </div>
      </div>
      <CopyNotification
        v-if="isSuccessCopy"
        :text="copyText"
      />
    </template>
  </trade-popup-layout>
  <div
    v-if="successPopup"
    class="success-popup"
  >
    <div class="success-popup_modal">
      <div
        class="close"
        @click="goBack()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4825_285)">
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#527AA1"
            />
          </g>
          <defs>
            <clipPath id="clip0_4825_285">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="text">
        <div class="text_title">
          Withdrawal transaction created successfully
        </div>
        <div class="text_desc">
          After a successful withdrawal, you can see the hash and other details of the transaction in the
          "Withdrawals" section
        </div>
      </div>

      <div class="buttons">
        <div
          class="buttons--item"
          @click="goBack()"
        >
          Ok
        </div>
        <router-link
          :to="{name: 'Trade withdrawals', params: {id: UserAcc.id}}"
          class="buttons--item buttons--item_black"
        >
          Go to Withdrawals
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.create-order_withdrawal {
  max-width: 740px;
  min-height: 452px;

  .head {
    margin-bottom: 32px;

    .type {
      .select-input {
        max-width: 204px;
      }
    }
  }

  .create-order_tab {
    padding: 24px 32px 32px;
    box-sizing: border-box;

    &-deposit {
      &:first-child {
        border-right: 1px solid #E0E9F4;
        max-width: 50%;
      }

      &:not(:first-child) {
        max-width: 50%;
      }
    }

    .qr-block {
      padding-top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  &-title {
    color: #1A242B;
    font-family: 'Rubik', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.4px;
    margin-bottom: 16px;
  }

  &-desc {
    color: var(--trade-trade-gray, #527AA1);
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 40px;
  }

  .order {
    margin-top: 0;

    .inputs {
      .selected {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 8px;
        box-sizing: border-box;
        height: 100%;

        &_coin {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          height: 100%;

          img {
            max-width: 32px;
          }

          &-text {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            &--coin {
              color: var(--trade-trade-black, #1A242B);
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px; /* 114.286% */
            }

            &--fullName {
              color: var(--trade-trade-gray, #527AA1);
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }

          &_balance {
            color: var(--black, #1A242B);
            text-align: right;
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;

            span {
              color: var(--trade-trade-gray, #527AA1);
              text-align: right;
              font-family: 'Rubik', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
            }
          }
        }
      }

      &--item {
        width: 100%;
        position: relative;

        .input {
          width: 100%;

          input {
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
          }
        }

        .max-coin {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          .separator {
            width: 1px;
            height: 12px;
            background: rgba(124, 163, 210, 0.24);
          }

          .max {
            color: var(--trade-trade-gray, #527AA1);
            text-align: right;
            font-family: 'Rubik', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            cursor: pointer;

            &:hover {
              color: #1A242B;
            }
          }
        }

        &--error {
          border: 1px solid #FF603D;
        }

        &_select {
          align-items: center;
          position: relative;

          &--dropdown {
            position: absolute;
            top: calc(100% + 4px);
            z-index: 9;
            left: 0;
            width: 100%;
            border-radius: 6px;
            background: #FFF;
            box-shadow: 0px 1px 6px 0px rgba(6, 59, 122, 0.16);
            outline: 1px solid white;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 460px;
            max-width: 359px;
            scrollbar-gutter: stable;
            scrollbar-width: thin;
            scrollbar-color: #EAF0F8 transparent;
            padding: 0;

            .list {
              &--item {
                width: 100%;
                max-width: 100%;
                padding: 12px;
                height: unset;
                transition: all .3s ease-in-out;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;

                &:hover {
                  background: var(--trade-hover, rgba(0, 122, 255, 0.06));
                }

                .info {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 8px;

                  img {
                    max-width: 32px;
                  }

                  &_text {
                    display: flex;
                    flex-direction: column;
                    gap: 2px;

                    &--coin {
                      color: var(--trade-trade-black, #1A242B);
                      font-family: 'Rubik', sans-serif;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 16px;
                    }

                    &--fullName {
                      color: var(--trade-trade-gray, #527AA1);
                      font-family: 'Rubik', sans-serif;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 14px;
                    }
                  }
                }

                &_balance {
                  color: var(--black, #1A242B);
                  text-align: right;
                  font-family: 'Rubik', sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px;

                  span {
                    color: var(--trade-trade-gray, #527AA1);
                    text-align: right;
                    font-family: 'Rubik', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px; /* 116.667% */
                  }
                }

                &-selected {

                  .info_text--coin {
                    color: #007AFF;
                  }

                }
              }

              &-searchBlock {
                padding: 12px;
                box-sizing: border-box;
                position: sticky;
                top: 0;
                left: 0;
                background: white;
              }

              &--search {
                width: 100%;
                max-width: 100%;
                border-radius: 6px;
                border: 1px solid rgba(124, 163, 210, 0.24);
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
                padding: 9px 12px 9px 8px;
                box-sizing: border-box;

                input {
                  width: 100%;
                  padding: 0;
                  margin: 0;
                  color: var(--trade-trade-gray, #527AA1);
                  font-family: 'Rubik', sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */

                  &::placeholder {
                    opacity: 1;
                  }
                }
              }
            }
          }

          .select-input {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            position: relative;

            .copy {
              display: flex;
            }

            &_network {
              color: var(--trade-trade-black, #1A242B);
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
            }

            &_arrow {
              display: flex;
            }

            &_desc {
              transform: none;
              color: var(--trade-trade-gray, #527AA1);
              font-family: 'Rubik', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }


            &--dropdown {
              position: absolute;
              top: calc(100% + 2px);
              z-index: 9;
              left: 0;
              width: 100%;
              border-radius: 6px;
              background: #FFF;
              box-shadow: 0px 1px 6px 0px rgba(6, 59, 122, 0.16);
              outline: 1px solid white;
              padding: 6px 0;

              .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 10px 10px 10px 12px;
                transition: all .3s ease-in-out;

                &-selected {
                  .item-name {
                    color: #007AFF;
                  }
                }

                &:hover {
                  background: var(--trade-hover, rgba(0, 122, 255, 0.06));
                }

                &-name {
                  color: var(--trade-trade-black, #1A242B);
                  font-family: 'Rubik', sans-serif;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px; /* 114.286% */
                }
              }
            }
          }
        }

        &_address {

        }
      }

      .item-error {
        width: 100%;

        .error-text {
          color: var(--trade-trade-red, #FF603D);
          font-family: 'Rubik', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          padding-left: 12px;
          margin-top: 6px;
        }

        .error {
          outline: 2px solid var(--trade-trade-red, #FF603D);

          .input {
            .desc {
              color: var(--trade-trade-red, #FF603D);
              font-family: 'Rubik', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
            }
          }
        }
      }
    }
  }

  .button {
    margin-top: 40px;
    border-radius: 6px;
    border: 1px solid rgba(130, 170, 200, 0.24);
    background: var(--trade-trade-blue, #007AFF);
    padding: 14px 0;
    color: #FFF;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.2px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), #007AFF;
    }

  }
}

.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(26, 36, 43, 0.8);
  display: grid;
  place-items: center;

  &_modal {
    max-width: 360px;
    padding: 28px 32px 32px 32px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 48px 0px rgba(6, 59, 122, 0.08), 0px 1px 4px 0px rgba(6, 59, 122, 0.06);
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 6px;
      padding: 6px;
      transition: all .3s ease-in-out;
      cursor: pointer;

      svg {
        path {
          transition: all .3s ease-in-out;
        }
      }

      &:hover {
        background: var(--trade-hover, rgba(0, 122, 255, 0.06));

        svg {
          path {
            fill: rgba(26, 36, 43, 1);
          }
        }
      }
    }


    .text {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_title {
        color: #1A242B;
        font-family: 'Rubik', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 116.667% */
        letter-spacing: -0.4px;
      }

      &_desc {
        color: var(--trade-trade-gray, #527AA1);
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &--item {
        border-radius: 6px;
        border: 1px solid rgba(130, 170, 200, 0.24);
        background: var(--trade-trade-blue, #007AFF);
        padding: 14px 0;
        color: #FFF;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.2px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), #007AFF;
        }

        &_black {
          background: var(--trade-trade-black, #1A242B);

          &:hover {
            background: linear-gradient(0deg, #0E1418 0%, #0E1418 100%), #1A242B;
          }
        }
      }
    }
  }
}
</style>
