<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import RateRequestTable from "@/pages/rate-request/partials/RateRequestTable.vue";
export default {
  name: 'RateRequest',
  components: {RateRequestTable, AccountLayout},
}

</script>

<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>Rate request</h2>

        <div class="layout-header_tabs">
          <router-link
            :to="{ name: 'Rate request' }"
            class="layout-header_tabs-item"
          >
            By date
          </router-link>
          <div class="layout-header_tabs-item layout-header_tabs-item--active">
            By pairs
          </div>

          <router-link
            :to="{ name: 'Rate request coins' }"
            class="layout-header_tabs-item"
          >
            Not found
          </router-link>
          <!--          <div class="layout-header_tabs-item">-->
          <!--            Binance: total-->
          <!--          </div>-->
        </div>
      </div>
    </template>
    <template #content>
      <RateRequestTable />
    </template>
  </AccountLayout>
</template>

<style lang="scss">

</style>
