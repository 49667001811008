<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3644_3160)">
      <path
        d="M8.0026 1.33331C4.31594 1.33331 1.33594 4.31331 1.33594 7.99998C1.33594 11.6866 4.31594 14.6666 8.0026 14.6666C11.6893 14.6666 14.6693 11.6866 14.6693 7.99998C14.6693 4.31331 11.6893 1.33331 8.0026 1.33331ZM11.3359 10.3933L10.3959 11.3333L8.0026 8.93998L5.60927 11.3333L4.66927 10.3933L7.0626 7.99998L4.66927 5.60665L5.60927 4.66665L8.0026 7.05998L10.3959 4.66665L11.3359 5.60665L8.9426 7.99998L11.3359 10.3933Z"
        fill="#FF783E"
      />
    </g>
    <defs>
      <clipPath id="clip0_3644_3160">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CheckFalseIcon"
}
</script>

<style scoped>

</style>
