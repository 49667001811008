<template>
  <tr
    v-for="(exchange, index) in exchangesList"
    :key="index"
    class="table-row"
  >
    <td
      ref="startColumn"
      class="table-cell table-buttons"
    >
      <div class="info">
        <div
          class="info-deposit"
          @click="exchangeInfo(exchange)"
        >
          <info-icon />
        </div>
      </div>
    </td>
    <td class="table-cell table-id">
      {{ exchange.id }}
    </td>
    <td class="table-cell table-uid">
      {{ exchange.uid }}
    </td>
    <td class="table-cell table-uid">
      {{ exchange.uuid }}
    </td>
    <td class="table-cell table-from">
      {{ exchange.from.shotName }}
    </td>
    <td class="table-cell table-to">
      {{ exchange.to.shotName }}
    </td>
    <td class="table-cell table-amount">
      {{ numbersFormatting(exchange.amount) }}
    </td>
    <td class="table-cell table-userAmount">
      {{ numbersFormatting(exchange.amountResult) }}
    </td>
    <td
      class="table-cell table-date"
    >
      {{ dataFormatting(exchange.createDate) }}
    </td>
    <td
      class="table-cell table-status"
      :class="[currentIndex === exchange.id ? statusClass: '']"
    >
      <div class="status-btns">
        <SelectStatusExchange
          v-if="ExchangeStatusList"
          :options="ExchangeStatusList"
          :value-input="exchange.status"
          class="select"
          page="1inch"
          :data="exchange"
          :disable-click="true"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import InfoIcon from "@/icons/infoIcon";
import SelectStatusExchange from "@/pages/exchanges/partials/SelectStatusExchange";
import {mapGetters} from "vuex";
import {ALLOW_STATUS, STATUSES} from "@/pages/exchanges/exchangeData";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default {
  name: "ExchangesList1inchItem",
  components: {SelectStatusExchange, InfoIcon},
  props: {
    exchangesList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dateFormat: 'DD.MM.YY HH:mm',
      dateFormatSec: 'DD.MM.YY HH:mm:ss',
      currentProfit: null,
      currentPartnerProfit: null,
      currentIndex: null,
      currentExchangeId: null,
      statusClass: '',
      allowStatuses: ALLOW_STATUS,
      isRecalcLoader: false,
      ExchangeStatusList: STATUSES,
      scrollPosition: false,
      maxNumberLength: 7,
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.scrollHandler();
  },
  methods: {
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-id');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 100) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    inputResize(el) {
      el.target.style.width = (el.target.value.length + 9 ) + 'ch';
    },
    exchangeInfo(exchange) {
      this.emitter.emit('exchange-info-event', exchange)
      this.emitter.emit('exchange-1inch-timer', false)
    },
    dataFormatting(value) {

      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).local().format(this.dateFormatSec)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    numbersFormatting(data) {
      return data.toFixed(this.maxNumberLength);
    },
  }
}
</script>

<style scoped>

</style>
