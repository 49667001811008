<template>
  <div class="avatar">
    <img
      v-if="img"
      :src="'https://swapuz.com' + img"
    >

    <empty-logo v-else />
  </div>
</template>

<script>
import EmptyLogo from "@/icons/emptyLogo.vue";

export default {
  name: "Avatar",
  components: {EmptyLogo},
  props: {
    img: {
      type: String,
      default: '',
    },
  },

}
</script>

<style lang="scss">
  .avatar {
    display: grid;
    place-items: center;

    img {
      max-width: 100%;
      width: 100%;
    }
  }
</style>
