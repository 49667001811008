<template>
  <input
    :value="displayValue"
    :readonly="readonly"
    :class="{ 'input-error': hasError }"
    type="text"
    @input="handleInput"
    @paste="handlePaste"
  >
</template>

<script>
export default {
  name: "ValidateInput",
  props: {
    value: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('update:value', newValue);
        this.$emit('update:error', this.error(newValue));
      }
    },
    hasError() {
      return this.error(this.value);
    }
  },
  methods: {
    error(value) {
      const validNumberRegex = /^(-?(0|[1-9]\d*)(\.\d+)?|\.\d+)$/;
      return !validNumberRegex.test(value);
    },
    handleInput(event) {
      this.displayValue = event.target.value;
    },
    handlePaste(event) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const sanitizedText = pastedText.replace(/[^0-9.-]/g, '');
      this.displayValue = sanitizedText;
    }
  }
};
</script>

<style>
.input-error {
  border: 1px solid red !important;
}

input {
  &:focus {
    outline: none;
  }
}
</style>
