import service from "../plugins/request";

class CalendarService {


    getCalendarList() {
        return service.get('/api/User/calendar')
    }
}


export default new CalendarService();
