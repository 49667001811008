export const TRADE_BALANCE_TABLE_TH = [
    {
        name: 'Coin',
        sort: false,
        key: 'coin',
    },
    {
        name: 'Balance',
        sort: 'ASC',
        key: 'balance',
    },
    {
        name: '',
        sort: false,
        key: 'buttons',
    },
];
