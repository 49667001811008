export const TABLE_TH = [
  {
    name: 'Data / time',
    sort: '',
    key: 'dataTime',
    description: 'Some description'
  },
  {
    name: 'Return',
    sort: '',
    key: 'return',
    button: 'orange',
    description: 'Some description'
  },
  {
    name: 'Hold',
    sort: '',
    key: 'hold',
    button: 'orange',
    description: 'Some description'
  },
  {
    name: 'Profit',
    sort: '',
    key: 'profit',
    button: 'orange',
    description: 'Some description'
  },
  {
    name: 'Pay',
    sort: '',
    key: 'pay',
    button: 'light-blue',
    description: 'Some description'
  },
  {
    name: 'Withdrawal',
    sort: '',
    key: 'withdrawal',
    button: 'blue',
    description: 'Some description'
  },
  {
    name: 'Total',
    sort: '',
    key: 'total',
    description: 'Some description'
  },
];
