<template>
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.029 5.1001L0.971003 5.1001C0.551911 5.1001 0.342366 4.56467 0.639026 4.25088L3.66685 1.04578C3.85049 0.851536 4.14951 0.851536 4.33315 1.04578L7.36097 4.25088C7.65763 4.56467 7.44809 5.1001 7.029 5.1001Z"
      fill="#02C076"
    />
  </svg>
</template>

<script>
    export default {
        name: "UpIcon"
    }
</script>

<style scoped>

</style>