<script setup>

</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4241_71809)">
      <path
        d="M8.33333 9.99967C10.1743 9.99967 11.6667 8.50729 11.6667 6.66634C11.6667 4.82539 10.1743 3.33301 8.33333 3.33301C6.49238 3.33301 5 4.82539 5 6.66634C5 8.50729 6.49238 9.99967 8.33333 9.99967Z"
        fill="white"
        fill-opacity="0.54"
      />
      <path
        d="M8.89297 10.8497C8.70963 10.8413 8.5263 10.833 8.33463 10.833C6.31797 10.833 4.43464 11.3913 2.8263 12.3497C2.09297 12.783 1.66797 13.5997 1.66797 14.458V16.6663H9.38463C8.7263 15.7247 8.33463 14.5747 8.33463 13.333C8.33463 12.4413 8.54297 11.608 8.89297 10.8497Z"
        fill="white"
        fill-opacity="0.54"
      />
      <path
        d="M17.2945 13.3327C17.2945 13.1493 17.2695 12.9827 17.2445 12.8077L18.1945 11.966L17.3612 10.5243L16.1529 10.9327C15.8862 10.7077 15.5862 10.5327 15.2529 10.4077L15.0029 9.16602H13.3362L13.0862 10.4077C12.7529 10.5327 12.4529 10.7077 12.1862 10.9327L10.9779 10.5243L10.1445 11.966L11.0945 12.8077C11.0695 12.9827 11.0445 13.1493 11.0445 13.3327C11.0445 13.516 11.0695 13.6827 11.0945 13.8577L10.1445 14.6993L10.9779 16.141L12.1862 15.7327C12.4529 15.9577 12.7529 16.1327 13.0862 16.2577L13.3362 17.4993H15.0029L15.2529 16.2577C15.5862 16.1327 15.8862 15.9577 16.1529 15.7327L17.3612 16.141L18.1945 14.6993L17.2445 13.8577C17.2695 13.6827 17.2945 13.516 17.2945 13.3327ZM14.1695 14.9993C13.2529 14.9993 12.5029 14.2493 12.5029 13.3327C12.5029 12.416 13.2529 11.666 14.1695 11.666C15.0862 11.666 15.8362 12.416 15.8362 13.3327C15.8362 14.2493 15.0862 14.9993 14.1695 14.9993Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4241_71809">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
