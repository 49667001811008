<script>
export default {
  name: "MirrorTurnoverIcon"
}
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4686_84655)">
      <path
        d="M12.5 17.5H14.1667V15.8333H12.5V17.5ZM15.8333 7.5H17.5V5.83333H15.8333V7.5ZM2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H9.16667V15.8333H4.16667V4.16667H9.16406V2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667ZM15.8333 2.5V4.16667H17.5C17.5 3.25 16.75 2.5 15.8333 2.5ZM9.16667 17.5H10.8333V15.8333H9.16667V17.5ZM15.8333 14.1667H17.5V12.5H15.8333V14.1667ZM12.5 4.16667H14.1667V2.5H12.5V4.16667ZM15.8333 10.8333H17.5V9.16667H15.8333V10.8333ZM15.8333 17.5C16.75 17.5 17.5 16.75 17.5 15.8333H15.8333V17.5Z"
        fill="white"
        fill-opacity="0.54"
      />
      <path
        d="M10.8307 4.16667H9.16406V2.5H10.8307V4.16667Z"
        fill="white"
        fill-opacity="0.54"
      />
      <path
        d="M8 6.25L10.5417 8.15833L13 10L10.5417 11.8417L8 13.75V6.25Z"
        fill="white"
        fill-opacity="0.54"
      />
    </g>
    <defs>
      <clipPath id="clip0_4686_84655">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
