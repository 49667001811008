<template>
  <svg
    width="23"
    height="12"
    viewBox="0 0 23 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893L22.7071 5.29289C23.0976 5.68342 23.0976 6.31658 22.7071 6.70711L17.7071 11.7071C17.3166 12.0976 16.6834 12.0976 16.2929 11.7071C15.9024 11.3166 15.9024 10.6834 16.2929 10.2929L19.5858 7H1C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5H19.5858L16.2929 1.70711C15.9024 1.31658 15.9024 0.683417 16.2929 0.292893Z"
      fill="#0A68F7"
    />
  </svg>
</template>

<script>
    export default {
        name: "ArrowExchange"
    }
</script>

<style scoped>

</style>