<script>
import OrderService from "@/services/order.service";
import CalendarIcon from "@/icons/calendarIcon.vue";
import DatePicker from "vue-datepicker-next";
import VueHighcharts from "vue3-highcharts/src/vue3-highcharts";
import AdminShift from "@/pages/dashboard/partials/AdminShift.vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

export default {
  name: "MainChartUpdate",
  components: {AdminShift, VueHighcharts, DatePicker, CalendarIcon, PerfectScrollbar},
  data() {
    return {
      date: '',
      datePicker: '',
      dates: null,
      chartData: null,
      dateFormat: 'DD MMM',
      dateFormatUrl: 'YYYY-MM-DD',
      chartDateFormat: 'DD.MM.YYYY',
      dateFormatUrlHour: 'YYYY-MM-DD HH:mm:ss',
      currentDate: '',
      chartOptions: {
        chart: {
          zooming: {
            type: 'xy'
          }
        },
        title: '',
        credits: {
          enabled: false
        },
        xAxis: [{
          categories: [],
          crosshair: true
        }],
        yAxis: [
          {
            labels: {
              format: '{value} BTC',
              style: {
                color: '#9FA2B4'
              }
            },
            gridLineWidth: 0,
            title: {
              text: '',
              style: {
                color: '#9FA2B4'
              }
            }
          },
          {
            title: {
              text: '',
              style: {
                color: '#9FA2B4'
              }
            },
            tickInterval: 1,
            gridLineWidth: 0,
            labels: {
              format: '{value}trx',
              style: {
                color: '#9FA2B4'
              }
            },
            opposite: true
          }
        ],
        tooltip: {
          positioner: function (labelWidth, labelHeight, point) {
            let tooltipX, tooltipY;
            const legendHeight = this.chart.legend && this.chart.legend.display ? this.chart.legend.legendHeight : 0;
            const spaceBetween = 10;

            tooltipX = Math.max(
                Math.min(
                    point.plotX + this.chart.plotLeft,
                    this.chart.chartWidth - labelWidth
                ),
                this.chart.plotLeft
            );
            tooltipY = Math.min(
                point.plotY + this.chart.plotTop,
                this.chart.chartHeight - labelHeight - legendHeight - spaceBetween
            );

            return {
              x: tooltipX,
              y: tooltipY
            };
          },
          backgroundColor: '#1B1A1C',
          borderRadius: 6,
          borderColor: '#1B1A1C',
          style: {
            color: 'white',
            fontWeight: 400,
            fontFamily: 'Inter, sans-serif',
            fontSize: '12px',
            zIndex: 9999999
          },
          shared: true,
          formatter: function () {
            let points = this.points;
            let total = 0;

            points.forEach(point => {
              if (point.series.options.type === 'column') {
                total += point.y;
              }
            });

            let tooltip = '<div style="font-size: 14px; font-weight: 700; line-height: 16px;">' + this.x + '</div><br/><br/>';

            points.forEach(point => {
              let icon = point.series.options.type === 'column' ? '\u25A0' : '\u25CF';
              tooltip += '<div style="color:' + point.color + '">' + icon + '</div> ' + point.series.name + ': ' + point.y + ' ' + '<span style="color: rgba(255, 255, 255, 0.60); ">' + point.series.userOptions.tooltip.valueSuffix + '</span><br/>';
            });

            tooltip += '<br>Total: ' + total.toFixed(4) + '<span style="color: rgba(255, 255, 255, 0.60); "> BTC</span>';

            return tooltip;
          }
        },
        legend: {
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'bottom',
          y: 10,
          className: 'legend-custom',
          useHTML: true,
          itemStyle: {
            color: '#1B1A1C',
            fontWeight: 'normal',
            fontFamily: 'Inter, sans-serif',
            fontSize: '12px'
          },
          itemDistance: 3,
          symbolPadding: 0,
          symbolWidth: 0,
          symbolHeight: 0,
          squareSymbol: false,
          labelFormatter: function () {
            return '<div class="highcharts-legend-item_custom" style="display: flex; align-items: center; left: 32px">' +
                '<div class="highcharts-legend-item_color" style="width: 16px; height: 16px; border-radius: 4px; background-color: ' + this.color + ';"></div>' +
                '<div class="highcharts-legend-item_custom--text">' + this.name + '</div>' +
                '</div>';
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            borderRadius: 4,
          },
          series: {
            states: {
              inactive: {
                enabled: false,
                opacity: 0.2
              },
              hover: {
                enabled: true,
                opacity: 1,
                marker: {
                  enabled: false
                }
              }
            },
            marker: {
              enabled: false,
              radius: 0,
              states: {
                hover: {
                  enabled: false
                }
              }
            },
          },
        },
        series: [
          {
            name: 'Partner market',
            data: [],
            type: 'column',
            stack: 'Company',
            color: '#0A68F7',
            tooltip: {
              valueSuffix: 'BTC'
            }
          },
          {
            name: 'Partner mirror',
            data: [],
            type: 'column',
            stack: 'Company',
            color: '#84B3FB',
            tooltip: {
              valueSuffix: 'BTC'
            }
          },
          {
            name: 'Company market',
            data: [],
            type: 'column',
            stack: 'Company',
            color: '#00B18B',
            tooltip: {
              valueSuffix: 'BTC'
            }
          },
          {
            name: 'Company mirror',
            data: [],
            type: 'column',
            stack: 'Company',
            color: '#80D8C5',
            tooltip: {
              valueSuffix: 'BTC'
            }
          },
          {
            name: 'Market',
            data: [],
            yAxis: 1,
            type: 'spline',
            color: '#5C53C1',
            tooltip: {
              valueSuffix: 'trx'
            }
          },
          {
            name: 'Mirror',
            data: [],
            yAxis: 1,
            type: 'spline',
            color: '#3F8173',
            tooltip: {
              valueSuffix: 'trx'
            }
          },
        ]
      },
    }
  },
  computed: {
    dateText() {
      if (this.date) {
        return this.date;
      } else {
        return "Custom date";
      }
    },
  },
  watch: {
    currentDate(val) {
      switch (val) {
        case 'range':
          this.date = [
            dayjs(this.datePicker[0]).startOf('day').format(this.dateFormatUrlHour),
            dayjs(this.datePicker[1]).endOf('day').format(this.dateFormatUrlHour),
          ]
          break;
        case 'day':
          this.date = [
            dayjs().startOf('day').format(this.dateFormatUrlHour),
            dayjs().startOf('minute').format(this.dateFormatUrlHour),
          ];
          break;
        case 'yesterday':
          this.date = [
            dayjs().startOf('day').subtract(1, 'day').format(this.dateFormatUrlHour),
            dayjs().endOf('day').subtract(1, 'day').format(this.dateFormatUrlHour)
          ];
          break;
        case 'week':
          this.date = [
            dayjs().startOf('week').add(1, 'day').format(this.dateFormatUrlHour),
            dayjs().startOf('minute').format(this.dateFormatUrlHour)
          ];
          break;
        case 'month':
          this.date = [
            dayjs().startOf('month').format(this.dateFormatUrlHour),
            dayjs().startOf('minute').format(this.dateFormatUrlHour)
          ];
          break;
        case 'year':
          this.date = [
            dayjs().startOf('year').format(this.dateFormatUrlHour),
            dayjs().startOf('minute').format(this.dateFormatUrlHour)
          ];
          break;
        default:
          break;
      }
      this.generateChartData();
    }
  },
  mounted() {
    this.currentDate = 'week'
  },
  methods: {
    setDateHandler(date) {
      this.currentDate = date
    },
    rangeDateHandler() {

      if (this.currentDate === 'range') {
        this.date = [
          dayjs(this.datePicker[0]).startOf('day').format(this.dateFormatUrlHour),
          dayjs(this.datePicker[1]).endOf('day').format(this.dateFormatUrlHour),
        ]
        this.generateChartData();
      } else {
        this.currentDate = 'range';
      }



    },
    dataFormatting(value) {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      return dayjs.utc(value).format(this.dateFormat)
    },
    dateFormatter(value) {

      return dayjs(value).utc(true).format(this.dateFormatUrl)
    },
    hourFormatter(val) {
      return val.toString().padStart(2, '0') + ":00";
    },
    async generateChartData() {
      const companyMarket = [];
      const companyMirror = [];
      const partnerMarket = [];
      const partnerMirror = [];
      const countMarket = [];
      const countMirror = [];

      const dates = [];
      const profits = [];

      let fromDate
      let toDate

      if (this.currentDate !== 'range' && this.currentDate !== 'yesterday') {
        fromDate = dayjs(this.date[0]).utc(true).format();
        toDate = dayjs(this.date[1]).utcOffset(0).format();
      } else {
        fromDate = dayjs(this.date[0]).utc(true).format();
        toDate = dayjs(this.date[1]).utc(true).format();
      }
      const query = `?start=${fromDate}&end=${toDate}`;

      try {
        await OrderService.getMainChart(query).then((res) => {
          const data = res.data.result;


          if ((this.currentDate !== 'day' && this.currentDate !== 'yesterday') && !dayjs(this.date[0]).isSame(this.date[1], 'day')) {
            data.forEach((item) => {
              dates.push(this.dataFormatting(item.day));
              companyMarket.push(item.companyMarket);
              companyMirror.push(item.companyMirror);
              partnerMarket.push(item.partnerMarket);
              partnerMirror.push(item.partnerMirror);
              countMarket.push(item.countMarket);
              countMirror.push(item.countMirror);


              if (item.companyMarket !== 0) {
                profits.push(item.companyMarket);
              }

              if (item.companyMirror !== 0) {
                profits.push(item.companyMirror);
              }

              if (item.partnerMarket !== 0) {
                profits.push(item.partnerMarket);
              }

              if (item.partnerMirror !== 0) {
                profits.push(item.partnerMirror);
              }
            });
          } else {
            data.forEach((item) => {
              dates.push(this.hourFormatter(item.hour));
              companyMarket.push(item.companyMarket);
              companyMirror.push(item.companyMirror);
              partnerMarket.push(item.partnerMarket);
              partnerMirror.push(item.partnerMirror);
              countMarket.push(item.countMarket);
              countMirror.push(item.countMirror);

              if (item.companyMarket !== 0) {
                profits.push(item.companyMarket);
              }

              if (item.companyMirror !== 0) {
                profits.push(item.companyMirror);
              }

              if (item.partnerMarket !== 0) {
                profits.push(item.partnerMarket);
              }

              if (item.partnerMirror !== 0) {
                profits.push(item.partnerMirror);
              }
            });
          }

          this.chartOptions.xAxis[0].categories = dates;


          this.chartOptions.series[0].data = partnerMarket;
          this.chartOptions.series[1].data = partnerMirror;
          this.chartOptions.series[2].data = companyMarket;
          this.chartOptions.series[3].data = companyMirror;
          this.chartOptions.series[4].data = countMarket;
          this.chartOptions.series[5].data = countMirror;


          console.log(this.chartOptions);


          if (data.length > 46 || data.length > 46) {
            this.chartOptions.plotOptions.series.pointWidth = 7
          } else if (data.length > 32 ) {
            this.chartOptions.plotOptions.series.pointWidth = 12
          } else {
            this.chartOptions.plotOptions.series.pointWidth = 24
          }
        })
      } catch (error) {
        console.log(error);
      }
    },
  }
}

</script>

<template>
  <div class="main-chart_dashboard transactions_value-chart transactions_value-chart_block">
    <AdminShift />
    <div class="transactions_value-chart-title-block">
      <div class="transactions_value-chart-title-block-title">
        Partner/Company turnover ratio
      </div>
      <perfect-scrollbar class="filters-block">
        <div class="filters">
          <div class="dates_range">
            <date-picker
              v-model:value="datePicker"
              :clearable="false"
              :editable="false"
              default-panel="day"
              separator=" - "
              value-type="date"
              :format="dateFormatUrl"
              placeholder="Custom date"
              range
              input-class="dates-item"
              :class="{'dates_filled': date && currentDate === 'range', 'filters--item-active_range': currentDate === 'range'}"
              @change="rangeDateHandler"
            >
              {{ dateText }}
              <template #icon-calendar>
                <calendar-icon />
              </template>
            </date-picker>
          </div>
          <div class="filters_block">
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'day'}"
              @click="setDateHandler('day')"
            >
              Today
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'yesterday'}"
              @click="setDateHandler('yesterday')"
            >
              Yesterday
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'week'}"
              @click="setDateHandler('week')"
            >
              Week
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'month'}"
              @click="setDateHandler('month')"
            >
              Month
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'year'}"
              @click="setDateHandler('year')"
            >
              Total
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <perfect-scrollbar class="transactions_value-chart-chart">
      <div class="chart">
        <vue-highcharts
          :options="chartOptions"
        />
      </div>
    </perfect-scrollbar>
  </div>
</template>

<style lang="scss">
.main-chart_dashboard {
  padding-bottom: 15px !important;


  .transactions_value-chart-title-block {
    position: relative;

    @media (max-width: 1079px) {
      padding-bottom: 20px;

      &-title {
        position: sticky;
        left: 16px;
        top: 10px;
        width: 250px;
      }
    }
  }

  .highcharts-legend-item {
    position: relative;

    span {
      position: relative !important;
      left: unset;
      top: unset;
      width: max-content;
    }
  }

  .legend-custom {
    z-index: -1 !important;


    div {
      position: relative !important;
      left: unset !important;
      top: unset !important;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4px;
      align-items: flex-start;
    }
  }

  .highcharts-legend-item_custom {
    background: #FCFDFE;
    border: 1px solid #F0F1F7;
    border-radius: 6px;
    padding: 4px 6px 4px 12px;
    z-index: 0;
    position: relative;
    left: unset;
    bottom: unset;
    top: unset;
    right: unset;


    &--text {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #1B1A1C;
    }
  }

  .highcharts-root {
    z-index: -1 !important;
    position: relative;
  }

  .highcharts-tooltip {
    z-index: 999999999 !important;
  }

  .highcharts-legend-item-hidden {

    .highcharts-legend-item_custom {

      background: none;
      border: 1px solid transparent;
    }

    .highcharts-legend-item_color {
      opacity: 0.64;
    }

    .highcharts-legend-item_custom--text {
      color: #9FA2B4 !important;
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    box-sizing: border-box;

    .dates {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 4px;

      @media (max-width: 1079px) {
        overflow: auto;
        scrollbar-width: none;
      }

      &-item {
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 500;
        background: #FCFDFE;
        border: 1px solid #F0F1F7;
        box-sizing: border-box;
        border-radius: 6px;
        color: #0A68F7;
        cursor: pointer;
        transition: all .3s ease-in-out;

        &:hover {
          border: 1px solid #0A68F7;
        }
      }

      .active {
        color: #FCFDFE;
        background: #0A68F7;
      }

      &_filled {
        .mx-input-wrapper {
          input {
            width: 25ch !important;
            height: 40px;
          }
        }
      }

      &_range {
        background: none !important;

        .mx {
          &-datepicker {
            width: unset;

            &-popup, &-main {
              box-shadow: 0 4px 24px rgba(55, 49, 72, 0.12);
              border-radius: 12px;
              overflow: hidden;
              border: none;
            }
          }

          &-icon-calendar, &-icon-clear {
            left: 9px;
            right: unset;

          }

          &-datepicker svg {
            fill: none;
          }

          &-input-wrapper {
            input {
              &::placeholder {
                color: #0A68F7;
                opacity: 1;
              }

              width: 126px;
            }

            .dates-item {
              padding-left: 30px !important;
            }

            .mx-icon-calendar {
              svg {
                path {
                  fill: #0A68F7;
                  fill-opacity: 100%;
                }
              }
            }
          }
        }

        .dates-item {
          line-height: 18px;
          padding: 6px 8px;
        }
      }
    }

    .separator {
      width: 1px;
      height: 20px;
      background: #DFE0EB;
      border-radius: 4px;
    }

    &_block {
      display: flex;
      align-items: center;
      gap: 9px;
    }

    &--item {
      user-select: none;
      padding: 6px 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #0A68F7;
      background: #FCFDFE;
      border: 1px solid #F0F1F7;
      border-radius: 8px;
      transition: all .3s ease-in-out;
      cursor: pointer;
      box-sizing: border-box;

      &:hover {
        border: 1px solid #0A68F7;
      }

      &-active {
        background: #0A68F7;
        border: 1px solid #0A68F7;
        color: white;
        cursor: default;

        &_range {
          .dates-item {
            color: #FCFDFE;
            background: #0A68F7;
          }

          .mx {
            &-icon-calendar, &-icon-clear {
              fill: white !important;
              fill-opacity: 100%;
            }

            &-datepicker svg path {
              fill: white !important;
            }

            &-input-wrapper {
              input {
                &::placeholder {
                  color: white;
                  opacity: 1;
                }

                width: 14ch;
              }
            }
          }


          .mx-icon-calendar {
            svg path {
              fill: white !important;
              fill-opacity: 100%;
            }
          }
        }
      }
    }
  }
}


</style>
