<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.07897 4.34966L6.38869 1.65938C6.17618 1.44687 5.83254 1.44687 5.6223 1.65938L2.92976 4.34966C2.58838 4.69103 2.83028 5.27656 3.31408 5.27656H8.69464C9.17844 5.27656 9.42034 4.69103 9.07897 4.34966Z"
      fill="#0A68F7"
      fill-opacity="0.12"
    />
    <path
      d="M3.31408 6.72339H8.69464C9.17844 6.72339 9.42034 7.30892 9.07897 7.65029L6.38869 10.3406C6.17618 10.5531 5.83254 10.5531 5.6223 10.3406L2.92976 7.65029C2.58838 7.30892 2.83028 6.72339 3.31408 6.72339Z"
      fill="#0A68F7"
    />
  </svg>
</template>

<script>
    export default {
        name: "SortAZ"
    }
</script>

<style scoped>

</style>