<template>
  <div class="paginate_block">
    <div class="pagination">
      <button
        :disabled="currentPage === 1 || loading"
        @click="prevPage"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_3844_2400)">
            <path
              d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
              fill="#527AA1"
            />
          </g>
          <defs>
            <clipPath id="clip0_3844_2400">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
      <button
        v-if="currentPage > 2 || loading"
        @click="gotoPage(1)"
      >
        1
      </button>
      <span v-if="currentPage > 3">...</span>
      <button
        v-for="page in pagesToShow"
        :key="page"
        :class="{ active: page === currentPage }"
        @click="gotoPage(page)"
      >
        {{ page }}
      </button>
      <span v-if="currentPage < maxPage - 2">...</span>
      <button
        v-if="currentPage < (maxPage - 1) || loading"
        @click="gotoPage(maxPage)"
      >
        {{ maxPage }}
      </button>
      <button
        :disabled="currentPage === maxPage || loading"
        @click="nextPage"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3844_4528)">
            <path
              d="M8.59 16.59L10 18L16 12L10 6L8.59 7.41L13.17 12L8.59 16.59Z"
              fill="#527AA1"
            />
          </g>
          <defs>
            <clipPath id="clip0_3844_4528">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="matrix(-1 0 0 -1 24 24)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    maxPage: {
      type: Number,
      required: true,
    },
    current: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  emits: ['page-changed'],
  data() {
    return {
      currentPage: 1,
      pagesToShow: [],
      loading: false,
    };
  },
  computed: {
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.maxPage;
    },
  },
  watch: {
    maxPage() {
      this.updatePagesToShow();
    },
  },
  created() {
    this.updatePagesToShow();
  },
  methods: {
    gotoPage(page) {
      if (page === this.currentPage || this.loading) return;
      this.currentPage = page;
      this.$emit('page-changed', page);
      this.updatePagesToShow();
    },
    prevPage() {
      if (!this.isFirstPage && !this.loading) {
        this.gotoPage(this.currentPage - 1);
      }
    },
    nextPage() {
      if (!this.isLastPage && !this.loading) {
        this.gotoPage(this.currentPage + 1);
      }
    },
    updatePagesToShow() {
      this.pagesToShow = this.calculatePagesToShow();
    },
    calculatePagesToShow() {
      const pages = [];
      const visiblePages = 3;
      let startPage = Math.max(this.currentPage - Math.floor(visiblePages / 2), 1);
      const endPage = Math.min(startPage + visiblePages - 1, this.maxPage);

      while (pages.length < visiblePages && startPage <= endPage) {
        pages.push(startPage);
        startPage++;
      }

      return pages;
    },
  },
};
</script>

<style lang="scss">

.paginate_block {
  width: 100%;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid rgba(124, 163, 210, 0.24);

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }

  button {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    border-radius: 6px;
    color: #527AA1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.2px;
    transition: all .3s ease-in-out;

    svg {
      path {
        transition: all .3s ease-in-out;
      }
    }

    &:not(.active):hover {
      background: rgba(0, 122, 255, 0.06);
      color: rgba(26, 36, 43, 1);

      svg {
        path {
          fill: rgba(26, 36, 43, 1);
        }
      }
    }
  }

  span {
    color: #527AA1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.2px;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
  }

  button[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  button.active {
    background: #1A242B;
    color: white;
  }
}

</style>
