export const TABLE_TH = [

    {
        name: 'Data/Time',
        sort: '',
        key: 'data-time',
        description: '',
        isSortable: false,
    },
    {
        name: 'Image',
        sort: '',
        key: 'image',
        description: '',
        isSortable: false,
    },
    {
        name: 'Title',
        sort: '',
        key: 'title',
        description: '',
        isSortable: false,
    },
    {
        name: 'Tags/Category',
        sort: '',
        key: 'tags-category',
        description: '',
        isSortable: false,
    },
    {
        name: 'Author',
        sort: '',
        key: 'author',
        description: '',
        isSortable: false,
    },
    {
        name: 'Status',
        sort: '',
        key: 'status',
        description: '',
        isSortable: false,
    },
];

export const STATUSES = [
    {
        name: 'Unpublished',
        status: 0,
    },
    {
        name: 'Published',
        status: 1,
    },
];
