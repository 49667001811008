<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_1_1897)">
      <path d="M5 16.4583C3.55556 16.0833 2.36111 15.3055 1.41667 14.125C0.472222 12.9444 0 11.5694 0 9.99998C0 8.43053 0.472222 7.05554 1.41667 5.87498C2.36111 4.69442 3.55556 3.91665 5 3.54165V5.29165C4.01389 5.62498 3.21181 6.2222 2.59375 7.08331C1.97569 7.94442 1.66667 8.91665 1.66667 9.99998C1.66667 11.0833 1.97569 12.0555 2.59375 12.9166C3.21181 13.7778 4.01389 14.375 5 14.7083V16.4583ZM11.6667 16.6666C9.81944 16.6666 8.24653 16.0173 6.94792 14.7187C5.64931 13.4201 5 11.8472 5 9.99998C5 8.15276 5.64931 6.57984 6.94792 5.28123C8.24653 3.98262 9.81944 3.33331 11.6667 3.33331C12.5833 3.33331 13.4444 3.50692 14.25 3.85415C15.0556 4.20137 15.7639 4.68054 16.375 5.29165L15.2083 6.45831C14.75 5.99998 14.2188 5.64234 13.6146 5.3854C13.0104 5.12845 12.3611 4.99998 11.6667 4.99998C10.2778 4.99998 9.09722 5.48609 8.125 6.45831C7.15278 7.43053 6.66667 8.61109 6.66667 9.99998C6.66667 11.3889 7.15278 12.5694 8.125 13.5416C9.09722 14.5139 10.2778 15 11.6667 15C12.3611 15 13.0104 14.8715 13.6146 14.6146C14.2188 14.3576 14.75 14 15.2083 13.5416L16.375 14.7083C15.7639 15.3194 15.0556 15.7986 14.25 16.1458C13.4444 16.493 12.5833 16.6666 11.6667 16.6666ZM16.6667 13.3333L15.5 12.1666L16.8333 10.8333H10.8333V9.16665H16.8333L15.5 7.83331L16.6667 6.66665L20 9.99998L16.6667 13.3333Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1_1897">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
