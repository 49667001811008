<template>
  <svg
    width="161"
    height="32"
    viewBox="0 0 161 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.1809 14.0482H49.9946C49.9765 10.4107 47.1715 8.00391 42.6836 8.00391C38.277 8.00391 35.1644 10.3746 35.1916 13.9034C35.1825 16.7988 37.1913 18.4094 40.4758 19.1152L42.3216 19.5133C44.3846 19.9657 45.0904 20.4815 45.1085 21.323C45.0904 22.2368 44.258 22.8793 42.6474 22.8793C40.7201 22.8793 39.58 21.9744 39.4986 20.2734H35.2519C35.27 24.9242 37.888 27.0053 42.7197 27.0053C47.4158 27.0053 50.2298 24.9785 50.2479 21.323C50.2298 18.4999 48.5468 16.5183 44.457 15.6406L42.9369 15.3149C41.1815 14.9439 40.3943 14.4282 40.4396 13.5414C40.4486 12.7271 41.1272 12.1299 42.6836 12.1299C44.3122 12.1299 45.0813 12.8447 45.1809 14.0482Z"
      fill="white"
    />
    <path
      d="M53.952 26.7881H59.3447L61.2992 19.3323H61.4439L63.3984 26.7881H68.7911L72.2657 12.89H67.271L65.7509 21.2868H65.6423L63.7965 12.89H58.9466L57.1732 21.3592H57.0646L55.4721 12.89H50.4774L53.952 26.7881Z"
      fill="white"
    />
    <path
      d="M76.3844 27.0053C78.1941 27.0053 79.5604 26.39 80.4019 24.8337H80.5105V26.7881H85.1794V17.2693C85.1794 14.6996 82.7454 12.709 78.7732 12.709C74.62 12.709 72.5751 14.8806 72.4032 17.4141H76.9997C77.1174 16.5545 77.7507 16.1474 78.7008 16.1474C79.5694 16.1474 80.1847 16.5455 80.1847 17.2693V17.3055C80.1847 18.1108 79.298 18.3642 76.9635 18.5361C74.0952 18.7442 71.8603 19.9024 71.8603 22.9517C71.8603 25.7204 73.7243 27.0053 76.3844 27.0053ZM78.0131 23.8565C77.1626 23.8565 76.5654 23.4403 76.5654 22.6621C76.5654 21.9473 77.0721 21.3953 78.2303 21.2144C79.0265 21.0877 79.6961 20.9248 80.2209 20.7077V21.8659C80.2209 23.1326 79.1442 23.8565 78.0131 23.8565Z"
      fill="white"
    />
    <path
      d="M86.2919 31.9999H91.2865V24.508H91.3589C91.938 25.9195 93.2771 26.9691 95.2315 26.9691C98.1994 26.9691 100.697 24.6527 100.697 19.839C100.697 14.8082 98.0184 12.709 95.2677 12.709C93.2047 12.709 91.9018 13.9034 91.3589 15.3149H91.2503V12.89H86.2919V31.9999ZM91.1779 19.839C91.1779 17.776 92.0104 16.5455 93.3857 16.5455C94.761 16.5455 95.5573 17.776 95.5573 19.839C95.5573 21.9021 94.761 23.1326 93.3857 23.1326C92.0104 23.1326 91.1779 21.8659 91.1779 19.839Z"
      fill="white"
    />
    <path
      d="M110.527 20.7077C110.536 22.083 109.74 22.9155 108.5 22.9155C107.243 22.9155 106.519 22.083 106.51 20.7077V12.89H101.515V21.7573C101.524 24.7975 103.442 26.9691 106.365 26.9691C108.437 26.9691 110.03 25.9195 110.636 24.146H110.781V26.7881H115.522V12.89H110.527V20.7077Z"
      fill="white"
    />
    <path
      d="M116.933 26.7881H129.13V22.9878H123.375V22.9155L128.913 15.9302V12.89H117.15V16.6902H123.013V16.7626L116.933 24.0374V26.7881Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.3561 0.19853C21.2497 -0.343048 22.3945 0.285795 22.4167 1.33045L22.5515 7.66217L29.1114 8.79302C30.1411 8.97052 30.5925 10.1961 29.9239 10.9991L25.5587 16.242L28.8737 18.9055C29.6882 19.5599 29.4888 20.8507 28.5148 21.2289L22.5244 23.5547L23.2587 27.2568C23.462 28.2817 22.4796 29.1423 21.4903 28.8059L17.2172 27.3528C12.3436 29.7196 6.50462 28.4925 2.98389 24.4768C8.19863 25.9823 12.3782 24.4087 14.3621 22.782C14.8452 22.3859 14.9157 21.6732 14.5197 21.1901C14.1236 20.7071 13.4109 20.6365 12.9278 21.0326C11.281 22.3829 6.78308 24.1578 0.960642 21.2628C-1.35055 15.8598 0.606624 9.4407 5.77716 6.30695C8.0523 4.92805 10.6035 4.39502 13.0642 4.61799L20.3561 0.19853ZM9.80191 16.1945C8.73343 16.842 6.4965 15.9394 5.84892 14.8709C5.20133 13.8025 5.54254 12.4113 6.61102 11.7637C7.6795 11.1162 11.4827 9.42921 12.1303 10.4977C12.7779 11.5662 10.8704 15.5469 9.80191 16.1945Z"
      fill="#F7CF00"
    />
    <rect
      x="133"
      y="11"
      width="28"
      height="17"
      rx="3"
      fill="#F7CF00"
    />
    <path
      d="M139.915 23H137.495V16.4545H139.912C140.578 16.4545 141.153 16.5856 141.634 16.8477C142.118 17.1076 142.491 17.4826 142.753 17.9727C143.015 18.4606 143.146 19.0444 143.146 19.7241C143.146 20.4059 143.015 20.9918 142.753 21.4819C142.493 21.9719 142.121 22.348 141.637 22.6101C141.154 22.87 140.58 23 139.915 23ZM139.077 21.6513H139.854C140.221 21.6513 140.531 21.5895 140.784 21.4659C141.04 21.3402 141.233 21.1367 141.363 20.8555C141.495 20.5721 141.561 20.195 141.561 19.7241C141.561 19.2532 141.495 18.8782 141.363 18.5991C141.23 18.3178 141.036 18.1154 140.778 17.9918C140.522 17.8661 140.207 17.8033 139.832 17.8033H139.077V21.6513ZM144.07 23V16.4545H148.634V17.7393H145.652V19.0817H148.4V20.3697H145.652V21.7152H148.634V23H144.07ZM151.11 16.4545L152.574 21.2486H152.629L154.092 16.4545H155.863L153.654 23H151.548L149.34 16.4545H151.11Z"
      fill="#1B1A1C"
    />
  </svg>
</template>

<script>
    export default {
        name: "LogoDev"
    }
</script>

<style scoped>

</style>
