<template>
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0714 6.85714H7.42857V11.1429C7.42857 11.6143 7.01071 12 6.5 12C5.98929 12 5.57143 11.6143 5.57143 11.1429V6.85714H0.928571C0.417857 6.85714 0 6.47143 0 6C0 5.52857 0.417857 5.14286 0.928571 5.14286H5.57143V0.857143C5.57143 0.385714 5.98929 0 6.5 0C7.01071 0 7.42857 0.385714 7.42857 0.857143V5.14286H12.0714C12.5821 5.14286 13 5.52857 13 6C13 6.47143 12.5821 6.85714 12.0714 6.85714Z"
      fill="white"
    />
  </svg>
</template>

<script>
    export default {
        name: "AddIcon"
    }
</script>

<style scoped>

</style>