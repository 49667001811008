<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13L1.07071 7.07071C1.03166 7.03166 1.03166 6.96834 1.07071 6.92929L7 1"
      stroke="#0A68F7"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M11 11L7.07071 7.07071C7.03166 7.03166 7.03166 6.96834 7.07071 6.92929L11 3"
      stroke="#0A68F7"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowFirst"
}
</script>

<style scoped>

</style>
