<template>
  <AccountLayout>
    <template #title>
      <h2>List of client users</h2>
    </template>
    <template #content>
      <div
        class="usersList main-block"
        :class="{'popup-active': Profile || UserItem || IpList}"
      >
        <loading
          v-if="!usersData && isLoading"
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div
          class="table-block"
        >
          <div class="table-block-header">
            <div class="table-block-header-title">
              All client users
            </div>
            <div class="table-block-header-filters">
              <div class="table-block-header-filters-search">
                <input
                  type="text"
                  placeholder="email search"
                  @keyup="searchLogin"
                >
              </div>
            </div>
          </div>
          <div class="table-block-table">
            <table class="table">
              <thead>
                <tr class="table-headers">
                  <th
                    v-for="(item, index) in tableTh"
                    :key="index"
                    scope="col"
                    :class="{
                      'table-id': item.key === 'id',
                      'table-name': item.key === 'name',
                      'table-email': item.key === 'email',
                      'table-email-verified': item.key === 'isEmailVerified',
                      'table-createDate': item.key === 'createDate',
                      'table-status': item.key === 'status',
                    }"
                  >
                    <div
                      class="text"
                      @click="sortData(item)"
                      @mouseover="currentIndex = index"
                      @mouseleave="currentIndex = null"
                    >
                      {{ item.name }}
                      <sort-passive v-if="!item.sort && !item.noSort" />
                      <sort-a-z v-if="!item.noSort && item.sort === 'asc'" />
                      <sort-z-a v-if="!item.noSort && item.sort === 'desc'" />
                      <transition name="fade">
                        <hover-text
                          v-if="currentIndex === index && item.description !== null"
                          :option="item.description"
                        />
                      </transition>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ClientUserListItem
                  v-if="usersData && usersData.length"
                  :users-list="usersData"
                />
              </tbody>
            </table>
            <no-data-block v-if="!usersData || !usersData.length && !isLoading" />
          </div>
        </div>
        <paged-pagination
          v-if="usersData && usersData.length && !isSearch"
          :total="batch.length"
          :current="page"
          @change-page="pageHandler"
          @prev-page="pageHandler"
          @next-page="pageHandler"
        />
        <user-ip-list v-if="IpList" />
      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/admin/AccountLayout.vue";
import {mapGetters} from 'vuex';
import HoverText from "../coins/partials/hoverText";
import filterMixin from "../../mixins/filter.mixin";
import helperMixin from "../../mixins/helper.mixin";
import SortZA from "../../icons/sorting/sort-z-a";
import SortAZ from "../../icons/sorting/sort-a-z";
import SortPassive from "../../icons/sorting/sort-passive";
import {TABLE_TH} from "@/pages/clientUsers/clientUserData";
import PagedPagination from "@/components/admin/PagedPagiantion.vue";
import UserIpList from "@/pages/users/partials/UserIpList";
import Loading from 'vue-loading-overlay';
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import AdminService from '@/services/admin.service';
import ClientUserListItem from '@/pages/clientUsers/partials/ClientUserListItem.vue';

export default {
  name: "ClientUsersList",
  components: {
    ClientUserListItem,
    NoDataBlock,
    UserIpList,
    PagedPagination,
    SortPassive,
    SortAZ,
    SortZA,
    HoverText,
    AccountLayout,
    Loading
  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      search: null,
      originUsers: null,
      currentSorting: 'id',
      isAdd: false,
      batch: [],
      page: 1,
      usersData: [],
      options: {
        chunk: 4
      },
      currentPerPage: 20,
      currentIndex: null,
      tableTh: TABLE_TH,
      isLoading: false,
      fullPage: true,
      userData: null,
      isSearch: false
    }
  },
  computed: {
    ...mapGetters({
      UserItem: 'getUserItem',
      Profile: 'getProfile',
      IpList: 'getIpList',
    }),
   /* Users: {
      get() {
        return this.$store.state.users;
      },
      set(value) {
        this.$store.commit('setUsers', value);
      }
    },*/
  },
  mounted() {
    this.getClientUsersDataList();
    this.emitter.on('edit-user-popup', (data) => {
      this.userData = data
    })
  },
  methods: {
   /* handleChangeFav() {
      this.getUsersDataList();
    },*/
    getClientUsersDataList() {
      this.isLoading = true;

      return AdminService.getClientUsersList().then((result) => {
        if (result.status === 200) {
          this.usersData = result.data.result

          this.originUsers = this.usersData;
          this.batch = this.batchDataNew(this.usersData, this.currentPerPage);

          this.page = 1;
          this.usersData = this.batch[0];
          this.isLoading = false;
          this.isLoading = false;
        }
      }).catch((error) => {
        this.isLoading = false;
        /*throw new Error('Get users list failed ' + error)*/
      })
    },
    batchDataNew(data, perPage) {
      return data.map((e, i) => {
        return i % perPage === 0 ? data.slice(i, i + perPage) : null;
      }).filter(e => e);
    },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      switch (type.key) {
        case 'id':
          this.usersData = this.usersData.sort((a, b) => {
            return type.sort === 'asc' ? a.id - b.id : b.id - a.id;
          });
          break;
        case 'name':
          this.usersData = this.usersData.sort((a, b) => {
            const aCount = a.name.localeCompare(b.name);
            const bCount = b.name.localeCompare(a.name);

            return type.sort === 'asc' ? aCount : bCount;
          });
          break;
        case 'email':
          this.usersData = this.usersData.sort((a, b) => {
            const aCount = a.email.localeCompare(b.email);
            const bCount = b.email.localeCompare(a.email);

            return type.sort === 'asc' ? aCount : bCount;
          });
          break;
        case 'isEmailVerified':
          this.usersData = this.usersData.sort((a, b) => {
            return type.sort === 'asc' ? a.isEmailVerified - b.isEmailVerified : b.isEmailVerified - a.isEmailVerified;
          });
          break;
        case 'createDate':
          this.usersData = this.usersData.sort((a, b) => {
            const aDate = Date.parse(a.createDate);
            const bDate = Date.parse(b.createDate);

            return type.sort === 'asc' ? aDate - bDate : bDate - aDate;
          });
          break;
      }
      this.usersData = this.batchData(this.usersData, this.currentPerPage).current;
    },
    searchLogin(event) {
      const value = event.target.value.toLowerCase();
      this.usersData = this.originUsers;

      if (value.length) {
        this.usersData = this.usersData.filter((item) => item.email.toLowerCase().includes(value));
        this.isSearch = true;
      } else {
        this.isSearch = false;
      }

      this.updatePages();
    },
    updatePages() {
      const batch = this.batchData(this.Users, this.currentPerPage);
      this.usersData = batch.current;
      this.batch = batch.total;
      this.page = 0;
    },

    pageHandler(page) {
      this.usersData = this.batch[page - 1];
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/styles/components/search";
@import "../../assets/styles/pages/clientUsers/clientUsers";
</style>
