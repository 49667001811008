export const TABLE_TH = [
    {
        name: 'Id',
        sort: '',
        key: 'id',
        description: 'Id'
    },
    {
        name: 'Name',
        sort: '',
        key: 'name',
        description: 'Name'
    },
    {
        name: 'Full name',
        sort: '',
        key: 'fullName',
        description: 'fullName'
    },
    {
        name: 'Market url',
        sort: '',
        key: 'marketUrl',
        description: 'Market url'
    },
];
