<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>List of coins</h2>
      </div>
    </template>
    <template #content>
      <div class="user-balances address-locked main-block">
        <div class="table-block">
          <div class="table-block-header">
            <div class="table-block-header-title">
              Address locked list
            </div>
          </div>
          <div
            class="table-block-table"
          >
            <table class="table">
              <thead>
                <tr class="table-headers">
                  <th
                    scope="col"
                    class="table-first"
                  >
                    <div class="text">
                      Address
                    </div>
                  </th>
                  <th
                    v-for="(item, index) in tokenData"
                    :key="index"
                    scope="col"
                  >
                    <div
                      class="text table-orderId-cell"
                    >
                      {{ item }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(item, index) in resData"
                  :key="index"
                >
                  <tr>
                    <td class="table-cell table-first">
                      <div
                        v-clipboard:copy="item.address"
                        class="table-first_block"
                      >
                        <div class="img">
                          <copy-address-icon />
                        </div>
                        <div class="text">
                          {{ addressFormat(item.address) }}
                        </div>
                      </div>
                    </td>
                    <td
                      v-for="(el, id) in item.data"
                      :key="id"
                      class="table-cell table-orderId"
                    >
                      <div
                        v-if="el.item"
                        class="text table-orderId-cell"
                        @click="closeOrderById(el.item.orderd)"
                      >
                        {{ el.item.orderd }}
                      </div>
                      <div
                        v-else
                        class="text table-orderId-cell"
                      >
                        -
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <no-data-block v-if="!resData || !resData.length" />
          </div>
        </div>
      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "@/components/admin/AccountLayout.vue";
import filterMixin from "@/mixins/filter.mixin";
import helperMixin from "@/mixins/helper.mixin";
import CoinsService from "@/services/coins.service";
import CopyAddressIcon from "@/icons/CopyAddressIcon";
import OrderService from "@/services/order.service";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";

export default {
  name: "AddressLockedList",
  components: {NoDataBlock, CopyAddressIcon, AccountLayout},
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      addressList: null,
      objectList: null,
      currentIndex: false,
      currentSorting: 'shortName',
      batch: [],
      page: 1,
      currentPerPage: 20,
      options: {
        chunk: 4
      },
      resData: {},
      copyData: {},
      tokenData: {},
    }
  },
  mounted() {
    this.getList()
    this.scrollHandler();
  },
  methods: {
    getList() {
      CoinsService.getAddressLockedList().then((res) => {
        this.addressList = res.data.result
        this.getUniqueList()
      })
    },
    getUniqueList() {
      this.copyData = this.addressList
      this.tokenData = this.getTokens(this.copyData).filter((item) => item !== '');
      this.resData = this.parseData(this.copyData).map((item) => {
        return {
          address: item.address,
          data: this.getFilterData(item.data),
        }
      })
    },
    parseData(data) {
      return this.filter(data);
    },
    filter(array) {
      return Object.values(array.reduce((acc, {address, ...props}) => {
        if (!acc[address])
          acc[address] = Object.assign({}, {address, data: [props]});
        else
          acc[address].data.push(props);
        return acc;
      }, {}));
    },
    getTokens(array) {
      return Object.values(array.reduce((acc, {token}) => {
        if (!acc[token])
          acc[token] = token;
        else
          acc[token] = token;
        return acc;
      }, {}));
    },
    getFilterData(data) {
      return this.tokenData.map((item) => {
        return {
          name: item,
          item: data.find((el) => el.token === item)
        }
      });
    },
    addressFormat(address) {
      if (!this.isMobile()) {
        return address.slice(0, 7) + '...' + address.slice((address.length - 7), address.length)
      } else {
        return address.slice(0, 5) + '...' + address.slice((address.length - 5), address.length)
      }
    },
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-first');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 40) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    closeOrderById(id) {
      OrderService.closeOrderById(id).then((res) => {
        if (res) {
          this.getList()
        }
      })

    }

  }
}
</script>

<style lang="scss">
.address-locked {
  .table-block {
    .table-cell {
      height: 36px;
    }
  }
  .table-headers {
    border-bottom: 1px solid #DFE0EB;
  }
  tr {
    border: 0 transparent;
    margin: 2px 0;
  }
  .table-first {
    border-right: 1px solid #DFE0EB;
    padding-right: 22px;
    width: 8%;


    &_block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .img {
        margin-right: 6px;

        svg {
          path {
            fill: white;
            transition: all .3s ease-in-out;
          }
        }
      }
      .text {
        transition: all .3s ease-in-out;
      }
    }

    //&:hover {
    //  cursor: pointer;
    //  .text {
    //    color: #0A68F7;
    //  }
    //  .img {
    //    svg {
    //      path {
    //        fill: #0A68F7;
    //      }
    //    }
    //  }
    //}

    @media (max-width: 1079px) {
      position: sticky;
      top: 0;
      bottom: 0;
      z-index: 99;
      left: 0;
      &.sticky {
        background: white;
        box-shadow: 1px 0 2px rgba(27, 26, 28, 0.16);
      }
    }
  }
  .table-orderId {
    &-cell {
      width: 8%;
      padding: 4px 20px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        color: #0A68F7;

      }
    }
  }

  tbody {
    .table-first {
      padding-left: 12px;
    }

    .table-orderId-cell {
      background: #F8FAFB;
    }
  }
}
</style>
