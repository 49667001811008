<template>
  <svg
    width="138"
    height="24"
    viewBox="0 0 138 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.98947 6.04425H14.8032C14.7851 2.40684 11.9801 0 7.49215 0C3.08564 0 -0.0269686 2.37065 0.00017616 5.89947C-0.0088721 8.79492 1.99984 10.4055 5.28437 11.1113L7.13022 11.5094C9.19322 11.9618 9.89899 12.4776 9.91708 13.3191C9.89899 14.2329 9.06655 14.8754 7.45595 14.8754C5.52867 14.8754 4.38859 13.9705 4.30715 12.2695H0.060498C0.0785945 16.9203 2.69656 19.0014 7.52834 19.0014C12.2244 19.0014 15.0384 16.9746 15.0565 13.3191C15.0384 10.496 13.3554 8.51442 9.26561 7.63674L7.7455 7.311C5.99013 6.94002 5.20293 6.42427 5.24817 5.53754C5.25722 4.7232 5.93584 4.12601 7.49215 4.12601C9.12084 4.12601 9.88994 4.84083 9.98947 6.04425Z"
      fill="white"
    />
    <path
      d="M18.7606 18.7842H24.1533L26.1078 11.3284H26.2525L28.207 18.7842H33.5997L37.0743 4.88607H32.0796L30.5595 13.2829H30.4509L28.6051 4.88607H23.7552L21.9817 13.3552H21.8732L20.2807 4.88607H15.286L18.7606 18.7842Z"
      fill="white"
    />
    <path
      d="M41.193 19.0014C43.0027 19.0014 44.369 18.3861 45.2105 16.8298H45.319V18.7842H49.988V9.26543C49.988 6.69572 47.554 4.7051 43.5818 4.7051C39.4286 4.7051 37.3837 6.87669 37.2118 9.4102H41.8083C41.9259 8.55062 42.5593 8.14345 43.5094 8.14345C44.378 8.14345 44.9933 8.54157 44.9933 9.26543V9.30162C44.9933 10.1069 44.1066 10.3603 41.7721 10.5322C38.9038 10.7403 36.6689 11.8985 36.6689 14.9477C36.6689 17.7165 38.5328 19.0014 41.193 19.0014ZM42.8217 15.8526C41.9712 15.8526 41.374 15.4364 41.374 14.6582C41.374 13.9434 41.8807 13.3914 43.0389 13.2105C43.8351 13.0838 44.5047 12.9209 45.0295 12.7038V13.862C45.0295 15.1287 43.9528 15.8526 42.8217 15.8526Z"
      fill="white"
    />
    <path
      d="M51.1004 23.996H56.0951V16.504H56.1675C56.7466 17.9156 58.0857 18.9652 60.0401 18.9652C63.008 18.9652 65.5053 16.6488 65.5053 11.8351C65.5053 6.8043 62.827 4.7051 60.0763 4.7051C58.0133 4.7051 56.7104 5.89947 56.1675 7.311H56.0589V4.88607H51.1004V23.996ZM55.9865 11.8351C55.9865 9.77213 56.819 8.54157 58.1943 8.54157C59.5696 8.54157 60.3659 9.77213 60.3659 11.8351C60.3659 13.8981 59.5696 15.1287 58.1943 15.1287C56.819 15.1287 55.9865 13.862 55.9865 11.8351Z"
      fill="white"
    />
    <path
      d="M75.3358 12.7038C75.3448 14.0791 74.5486 14.9116 73.309 14.9116C72.0513 14.9116 71.3274 14.0791 71.3184 12.7038V4.88607H66.3237V13.7534C66.3328 16.7936 68.251 18.9652 71.1736 18.9652C73.2456 18.9652 74.8381 17.9156 75.4444 16.1421H75.5891V18.7842H80.3304V4.88607H75.3358V12.7038Z"
      fill="white"
    />
    <path
      d="M81.7415 18.7842H93.9386V14.9839H88.1839V14.9116L93.7214 7.92629V4.88607H81.9587V8.68634H87.822V8.75873L81.7415 16.0335V18.7842Z"
      fill="white"
    />
    <rect
      x="97.8086"
      y="2.99597"
      width="40"
      height="17"
      rx="3"
      fill="#FF783E"
    />
    <path
      d="M103.72 14.996H102.02L104.228 8.45052H106.334L108.543 14.996H106.842L105.305 10.0997H105.254L103.72 14.996ZM103.493 12.42H107.047V13.6217H103.493V12.42ZM111.667 14.996H109.247V8.45052H111.664C112.33 8.45052 112.905 8.58155 113.386 8.84363C113.87 9.10357 114.243 9.47857 114.505 9.96863C114.767 10.4566 114.898 11.0404 114.898 11.72C114.898 12.4019 114.767 12.9878 114.505 13.4779C114.245 13.9679 113.873 14.344 113.389 14.6061C112.906 14.866 112.331 14.996 111.667 14.996ZM110.829 13.6473H111.606C111.972 13.6473 112.282 13.5855 112.536 13.4619C112.792 13.3362 112.985 13.1327 113.115 12.8514C113.247 12.5681 113.313 12.1909 113.313 11.72C113.313 11.2492 113.247 10.8742 113.115 10.595C112.982 10.3138 112.787 10.1114 112.53 9.9878C112.274 9.86209 111.959 9.79924 111.584 9.79924H110.829V13.6473ZM115.822 8.45052H117.781L119.443 12.5031H119.519L121.181 8.45052H123.14V14.996H121.6V10.9754H121.546L119.973 14.9544H118.989L117.416 10.953H117.362V14.996H115.822V8.45052ZM125.709 8.45052V14.996H124.127V8.45052H125.709ZM132.273 8.45052V14.996H130.931L128.326 11.2183H128.284V14.996H126.702V8.45052H128.064L130.64 12.2218H130.694V8.45052H132.273Z"
      fill="#3F1E74"
    />
  </svg>
</template>

<script>
    export default {
        name: "LogoMob"
    }
</script>

<style scoped>

</style>