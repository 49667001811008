<script>
export default {
    name: "AlertProgress",
    props: {
        value: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            color: ''
        }
    },
    watch: {
        value() {
            this.color = this.colorSwitcher()
        }
    },
    methods: {
        colorSwitcher() {
            let val = this.value
            let color = ''
            if (val <= 25) {
                color = '#FFE247'
            } else if (val > 25 && val <= 50) {
                color = '#FFB800'
            } else if (val > 50 && val <= 75) {
                color = '#F90'
            } else if (val > 75 && val <= 100) {
                color = '#FF5C00'
            }

            return color
        },
    }
}
</script>

<template>
  <div class="alert-progress">
    <div
      v-if="value > 0"
      class="alert-progress--item"
      :style="{ background: colorSwitcher() }"
    />
    <div
      v-if="value > 25"
      class="alert-progress--item"
      :style="{ background: colorSwitcher() }"
    />
    <div
      v-if="value > 50"
      class="alert-progress--item"
      :style="{ background: colorSwitcher() }"
    />
    <div
      v-if="value > 75"
      class="alert-progress--item"
      :style="{ background: colorSwitcher() }"
    />
  </div>
</template>


<style lang="scss">
.alert-progress {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 48px;
  height: 5px;
  border-radius: 2px;
  overflow: hidden;
  background: #F0F1F7;

  &--item {
    width: 25%;
    height: 5px;
  }
}
</style>
