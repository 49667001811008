<script>
import UserService from "@/services/user.service";
import Avatar from "@/components/admin/AvatarComponent.vue";
import QrCodeGen from "@/components/QrCodeGen.vue";
import {mapGetters} from "vuex";

export default {
  name: 'UserProfile',
  components: {QrCodeGen, Avatar},
  data() {
    return {
      userData: null,
      isMenuOpen: false,
      isGetQr: false,
      QRdata: ''
    }
  },
  computed: {
    ...mapGetters({
      UserProfileData: 'getUserProfileData'
    })
  },
  mounted() {
    this.getUserProfile();
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    this.$store.commit('setUserProfileData', null)
  },
  methods: {
    openSchedule() {
      this.emitter.emit('schedule-open-popup', true)
    },
    handleClickOutside(event) {
      if (this.$refs.userMenu && !this.$refs.userMenu.contains(event.target)) {
        this.isMenuOpen = false;
      }
    },
    getUserProfile() {
      UserService.getProfile().then((res) => {
        if (res) {
          this.userData = res.data.result;
          this.$store.commit('setUserProfileData', res.data.result)

          // if (res.data.result.optSecret) {
          //   this.QRdata = 'otpauth://totp/Swapuz Admin:' + this.userData.login + '?secret=' + this.userData.optSecret;
          // }
        }
      })
    },
    logout() {
      this.$store.dispatch('logout');
    },
    startShift(id) {
      const userId = parseInt(id, 10)
      const query = '?userId=' + userId

      UserService.startShiftUser(query).then((res) => {
        if (res.status === 200) {
          this.getUserProfile();
        }

      })
    },
    qrGenerate() {
      if (this.userData.login && !this.userData.optSecret) {
        this.generateQRAndUserProfile();
      } else {
        this.QRdata = 'otpauth://totp/Swapuz Admin:' + this.userData.login + '?secret=' + this.userData.optSecret;
      }
    },
    async generateQRAndUserProfile() {
      try {
        const res = await UserService.generateQr();
        if (res) {
          await this.getUserProfile();
          this.QRdata = 'otpauth://totp/Swapuz Admin:' + this.userData.login + '?secret=' + this.userData.optSecret;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    finishShift(id) {
      const userId = parseInt(id, 10)
      const query = '?userId=' + userId

      UserService.finishShiftUser(query).then((res) => {
        if (res.status === 200) {
          this.getUserProfile();
        }
      })
    },
    getQr() {
      this.isGetQr = !this.isGetQr
      this.isMenuOpen = true;
      this.qrGenerate();
    },
  }
}
</script>

<template>
  <div
    v-if="userData"
    ref="userMenu"
    class="account"
  >
    <div
      class="profile"
      @click="isMenuOpen = !isMenuOpen"
    >
      <div
        class="profile-text"
      >
        <div class="profile-name">
          <svg
            v-if="!userData.isOnShiftNow"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4571_1498)">
              <path
                d="M8.0026 1.33337C4.3226 1.33337 1.33594 4.32004 1.33594 8.00004C1.33594 11.68 4.3226 14.6667 8.0026 14.6667C11.6826 14.6667 14.6693 11.68 14.6693 8.00004C14.6693 4.32004 11.6826 1.33337 8.0026 1.33337ZM10.0026 10.6667H6.0026C5.63594 10.6667 5.33594 10.3667 5.33594 10V6.00004C5.33594 5.63337 5.63594 5.33337 6.0026 5.33337H10.0026C10.3693 5.33337 10.6693 5.63337 10.6693 6.00004V10C10.6693 10.3667 10.3693 10.6667 10.0026 10.6667Z"
                fill="#FF783E"
              />
            </g>
            <defs>
              <clipPath id="clip0_4571_1498">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.0026 1.33337C4.3226 1.33337 1.33594 4.32004 1.33594 8.00004C1.33594 11.68 4.3226 14.6667 8.0026 14.6667C11.6826 14.6667 14.6693 11.68 14.6693 8.00004C14.6693 4.32004 11.6826 1.33337 8.0026 1.33337ZM6.66927 10.3334V5.66671C6.66927 5.39337 6.9826 5.23337 7.2026 5.40004L10.3159 7.73337C10.4959 7.86671 10.4959 8.13337 10.3159 8.26671L7.2026 10.6C6.9826 10.7667 6.66927 10.6067 6.66927 10.3334Z"
              fill="#02C076"
            />
          </svg>
          {{ userData.login }}
        </div>
        <div class="profile-shift">
          <span v-if="!userData.isOnShiftNow">Shift not started</span>
          <span v-else>Shift in progress</span>
        </div>
      </div>
      <Avatar :img="userData.avatar" />
      <div class="shift-status">
        <div
          v-if="!userData.isOnShiftNow"
          class="active"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4185_69494)">
              <path
                d="M10.0052 1.66699C5.40521 1.66699 1.67188 5.40033 1.67188 10.0003C1.67188 14.6003 5.40521 18.3337 10.0052 18.3337C14.6052 18.3337 18.3385 14.6003 18.3385 10.0003C18.3385 5.40033 14.6052 1.66699 10.0052 1.66699Z"
                fill="white"
              />
              <path
                d="M10.0013 1.66699C5.4013 1.66699 1.66797 5.40033 1.66797 10.0003C1.66797 14.6003 5.4013 18.3337 10.0013 18.3337C14.6013 18.3337 18.3346 14.6003 18.3346 10.0003C18.3346 5.40033 14.6013 1.66699 10.0013 1.66699ZM12.5013 13.3337H7.5013C7.04297 13.3337 6.66797 12.9587 6.66797 12.5003V7.50033C6.66797 7.04199 7.04297 6.66699 7.5013 6.66699H12.5013C12.9596 6.66699 13.3346 7.04199 13.3346 7.50033V12.5003C13.3346 12.9587 12.9596 13.3337 12.5013 13.3337Z"
                fill="#FF783E"
              />
            </g>
            <defs>
              <clipPath id="clip0_4185_69494">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          v-else
          class="not-active"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0052 1.66602C5.40521 1.66602 1.67188 5.39935 1.67188 9.99935C1.67188 14.5993 5.40521 18.3327 10.0052 18.3327C14.6052 18.3327 18.3385 14.5993 18.3385 9.99935C18.3385 5.39935 14.6052 1.66602 10.0052 1.66602Z"
              fill="white"
            />
            <path
              d="M10.0013 1.66699C5.4013 1.66699 1.66797 5.40033 1.66797 10.0003C1.66797 14.6003 5.4013 18.3337 10.0013 18.3337C14.6013 18.3337 18.3346 14.6003 18.3346 10.0003C18.3346 5.40033 14.6013 1.66699 10.0013 1.66699ZM8.33464 12.917V7.08366C8.33464 6.74199 8.7263 6.54199 9.0013 6.75033L12.893 9.66699C13.118 9.83366 13.118 10.167 12.893 10.3337L9.0013 13.2503C8.7263 13.4587 8.33464 13.2587 8.33464 12.917Z"
              fill="#02C076"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      v-if="isMenuOpen"
      class="profile-menu"
    >
      <div class="desc-mob">
        <span v-if="!userData.isOnShiftNow">
          Shift not started
        </span>
        <span v-else>
          Shift in progress
        </span>
      </div>
      <div
        v-if="!userData.isOnShiftNow"
        class="item"
        @click="startShift(userData.id)"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3869_9454)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM9.5 16.5L16.5 12L9.5 7.5V16.5Z"
              fill="#02C076"
            />
          </g>
          <defs>
            <clipPath id="clip0_3869_9454">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        Start shift
      </div>
      <div
        v-else
        class="item"
        @click="finishShift(userData.id)"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3869_9337)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM16 16H8V8H16V16Z"
              fill="#FF783E"
            />
          </g>
          <defs>
            <clipPath id="clip0_3869_9337">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        Stop shift
      </div>
      <div
        class="item"
        @click="openSchedule()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4414_1020)">
            <path
              d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM19 8H5V6H19V8ZM9 14H7V12H9V14ZM13 14H11V12H13V14ZM17 14H15V12H17V14ZM9 18H7V16H9V18ZM13 18H11V16H13V18ZM17 18H15V16H17V18Z"
              fill="#0A68F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_4414_1020">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>


        Schedule
      </div>
      <div class="item">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3869_9343)">
            <path
              d="M4 18V17.35C4 17.01 4.16 16.69 4.41 16.54C6.1 15.53 8.03 15 10 15C10.03 15 10.05 15 10.08 15.01C10.18 14.31 10.38 13.64 10.67 13.03C10.45 13.01 10.23 13 10 13C7.58 13 5.32 13.67 3.39 14.82C2.51 15.34 2 16.32 2 17.35V20H11.26C10.84 19.4 10.51 18.72 10.29 18H4Z"
              fill="#0A68F7"
            />
            <path
              d="M10 12C12.21 12 14 10.21 14 8C14 5.79 12.21 4 10 4C7.79 4 6 5.79 6 8C6 10.21 7.79 12 10 12ZM10 6C11.1 6 12 6.9 12 8C12 9.1 11.1 10 10 10C8.9 10 8 9.1 8 8C8 6.9 8.9 6 10 6Z"
              fill="#0A68F7"
            />
            <path
              d="M20.7519 16C20.7519 15.78 20.7219 15.58 20.6919 15.37L21.8319 14.36L20.8319 12.63L19.3819 13.12C19.0619 12.85 18.7019 12.64 18.3019 12.49L18.0019 11H16.0019L15.7019 12.49C15.3019 12.64 14.9419 12.85 14.6219 13.12L13.1719 12.63L12.1719 14.36L13.3119 15.37C13.2819 15.58 13.2519 15.78 13.2519 16C13.2519 16.22 13.2819 16.42 13.3119 16.63L12.1719 17.64L13.1719 19.37L14.6219 18.88C14.9419 19.15 15.3019 19.36 15.7019 19.51L16.0019 21H18.0019L18.3019 19.51C18.7019 19.36 19.0619 19.15 19.3819 18.88L20.8319 19.37L21.8319 17.64L20.6919 16.63C20.7219 16.42 20.7519 16.22 20.7519 16ZM17.0019 18C15.9019 18 15.0019 17.1 15.0019 16C15.0019 14.9 15.9019 14 17.0019 14C18.1019 14 19.0019 14.9 19.0019 16C19.0019 17.1 18.1019 18 17.0019 18Z"
              fill="#0A68F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_3869_9343">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>

        Profile
      </div>
      <div
        class="item"
        @click="logout"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3869_9472)">
            <path
              d="M4 18V17.35C4 17.01 4.16 16.69 4.41 16.54C6.1 15.53 8.03 15 10 15C10.03 15 10.05 15 10.08 15.01C10.18 14.31 10.38 13.64 10.67 13.03C10.45 13.01 10.23 13 10 13C7.58 13 5.32 13.67 3.39 14.82C2.51 15.34 2 16.32 2 17.35V20H11.26C10.84 19.4 10.51 18.72 10.29 18H4Z"
              fill="#0A68F7"
            />
            <path
              d="M10 12C12.21 12 14 10.21 14 8C14 5.79 12.21 4 10 4C7.79 4 6 5.79 6 8C6 10.21 7.79 12 10 12ZM10 6C11.1 6 12 6.9 12 8C12 9.1 11.1 10 10 10C8.9 10 8 9.1 8 8C8 6.9 8.9 6 10 6Z"
              fill="#0A68F7"
            />
            <path
              d="M17.01 20L21 16L17.01 12V15H12V17H17.01V20Z"
              fill="#0A68F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_3869_9472">
              <rect
                width="24"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>

        Logout
      </div>

      <div
        v-if="UserProfileData && UserProfileData.roles.includes('admin')"
        class="item-qr"
      >
        <span>MFA</span>
        <div
          v-if="!isGetQr"
          class="qr"
        >
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M66.6667 100H55.5556V88.8889H66.6667V100ZM55.5556 61.1111H44.4444V88.8889H55.5556V61.1111ZM100 50H88.8889V72.2222H100V50ZM88.8889 38.8889H77.7778V50H88.8889V38.8889ZM22.2222 50H11.1111V61.1111H22.2222V50ZM11.1111 38.8889H0V50H11.1111V38.8889ZM50 11.1111H61.1111V0H50V11.1111ZM8.33333 8.33333V25H25V8.33333H8.33333ZM33.3333 33.3333H0V0H33.3333V33.3333ZM8.33333 75V91.6667H25V75H8.33333ZM33.3333 100H0V66.6667H33.3333V100ZM75 8.33333V25H91.6667V8.33333H75ZM100 33.3333H66.6667V0H100V33.3333ZM88.8889 88.8889V72.2222H66.6667V83.3333H77.7778V100H100V88.8889H88.8889ZM77.7778 50H55.5556V61.1111H77.7778V50ZM55.5556 38.8889H22.2222V50H33.3333V61.1111H44.4444V50H55.5556V38.8889ZM61.1111 33.3333V22.2222H50V11.1111H38.8889V33.3333H61.1111ZM20.8333 12.5H12.5V20.8333H20.8333V12.5ZM20.8333 79.1667H12.5V87.5H20.8333V79.1667ZM87.5 12.5H79.1667V20.8333H87.5V12.5Z"
              fill="#1B1A1C"
              fill-opacity="0.06"
            />
          </svg>
          <div
            class="button"
            @click="getQr"
            @click.stop="handleClickOutside"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3869_9432)">
                <path
                  d="M2.5 9.16667H9.16667V2.5H2.5V9.16667ZM4.16667 4.16667H7.5V7.5H4.16667V4.16667Z"
                  fill="white"
                />
                <path
                  d="M2.5 17.4997H9.16667V10.833H2.5V17.4997ZM4.16667 12.4997H7.5V15.833H4.16667V12.4997Z"
                  fill="white"
                />
                <path
                  d="M10.8359 2.5V9.16667H17.5026V2.5H10.8359ZM15.8359 7.5H12.5026V4.16667H15.8359V7.5Z"
                  fill="white"
                />
                <path
                  d="M17.5026 15.833H15.8359V17.4997H17.5026V15.833Z"
                  fill="white"
                />
                <path
                  d="M12.5026 10.833H10.8359V12.4997H12.5026V10.833Z"
                  fill="white"
                />
                <path
                  d="M14.1667 12.5H12.5V14.1667H14.1667V12.5Z"
                  fill="white"
                />
                <path
                  d="M12.5026 14.167H10.8359V15.8337H12.5026V14.167Z"
                  fill="white"
                />
                <path
                  d="M14.1667 15.833H12.5V17.4997H14.1667V15.833Z"
                  fill="white"
                />
                <path
                  d="M15.8307 14.167H14.1641V15.8337H15.8307V14.167Z"
                  fill="white"
                />
                <path
                  d="M15.8307 10.833H14.1641V12.4997H15.8307V10.833Z"
                  fill="white"
                />
                <path
                  d="M17.5026 12.5H15.8359V14.1667H17.5026V12.5Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3869_9432">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
            <div>
              Get QR
            </div>
          </div>
        </div>

        <div
          v-else
          class="qr-code"
        >
          <QrCodeGen :data="QRdata" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/styles/variables';

.account {
  position: relative;

  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    cursor: pointer;
    padding: 4px 4px 4px 6px;
    box-sizing: border-box;
    transition: all .3s ease-in-out;
    position: relative;

    @media (min-width: 1080px) {
      /*background: #E9F1FC;*/

      &:hover {
        background: $light-gray-hover-color;
      }
    }

    .shift-status {
      position: absolute;
      right: 0;
      top: 0;

      @media (min-width: 1079px) {
        display: none;
      }
    }

    &-name {
      color: $black-color1;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: -0.28px;
      text-align: right;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
    }

    &-text {
      @media (max-width: 1079px) {
        display: none;
      }
    }

    &-shift {
      color: $gray-color;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      font-family: 'Inter', sans-serif;
    }

    .avatar {
      border-radius: 50%;
      overflow: hidden;

      img {
        border-radius: 50%;
        width: 100%;
        max-width: 40px;
      }

      svg {
        width: 40px;
        height: 40px;
      }
    }

    &-menu {
      position: absolute;
      z-index: 9;
      top: calc(100% + 2px);
      right: -4px;
      width: 160px;
      border-radius: 12px;
      background: var(--grayscale-white, #FFF);
      box-shadow: 0px 4px 24px 0px rgba(15, 0, 58, 0.12);
      padding: 8px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      @media (max-width: 1079px) {
        right: 0;
      }

      .desc-mob {
        display: none;

        @media (max-width: 1079px) {
          display: flex;
          padding-top: 2px;
          padding-left: 16px;
          padding-bottom: 2px;
          box-sizing: border-box;

          span {
            color: $gray-color;
            font-family: 'Inter', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }

      .item {
        width: 100%;
        height: 32px;
        padding: 6px 8px;
        box-sizing: border-box;
        transition: all .3s ease-in-out;
        color: $black-color1;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        cursor: pointer;

        &-qr {
          margin-top: 8px;
          padding: 8px;
          border-top: 1px solid #EAEFF4;
          height: 168px;
          display: grid;
          place-items: center;
          position: relative;
          box-sizing: border-box;

          @media (max-width: 1079px) {
            display: none;
          }

          span {
            position: relative;
            color: var(--grayscale-gray, #9FA2B4);
            font-family: 'Inter', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }

          .qr {
            padding: 10px;
            position: relative;
            box-sizing: border-box;

            .button {
              position: absolute;
              width: max-content;
              top: 44px;
              left: 0;
              right: 0;
              margin: 0 auto;
              border-radius: 6px;
              background: linear-gradient(0deg, #0A68F7 0%, #0A68F7 100%), #0A68F7;
              color: #FFF;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
              padding: 6px 7px 6px 6px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;
              transition: all .3s ease-in-out;
              cursor: pointer;

              @media (min-width: 1080px) {
                &:hover {
                  background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), #0A68F7;

                }
              }
            }
          }
        }

        @media (min-width: 1080px) {
          &:hover {
            background: rgba(27, 26, 28, 0.03);
          }
        }
      }
    }
  }

  .qr-block {
    margin-top: 16px;
    padding: 10px;
    background: white;
    border-radius: 8px;
    display: flex;
    box-sizing: border-box;

    @media (max-width: 1079px) {
      padding: 0;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
</style>
