export const TRADE_ACCOUNTS_TABLE_TH = [
    {
        name: '',
        sort: false,
        key: 'logo',
    },
    {
        name: 'Market',
        sort: false,
        key: 'market',
    },
    {
        name: 'Account',
        sort: false,
        key: 'account',
    },
    {
        name: 'Acc ID',
        sort: false,
        key: 'accId',
    },
    {
        name: 'Type',
        sort: false,
        key: 'type',
    },
    {
        name: 'Partner',
        sort: false,
        key: 'partner',
    },
    {
        name: 'Last activity',
        sort: false,
        key: 'activity',
    },
    {
        name: 'Status',
        sort: false,
        key: 'status',
    },
];
