<script>

import AdminSchedule from "@/pages/dashboard/partials/schedule-admin/AdminSchedule.vue";
import CloseIcon from "@/icons/closeIcon.vue";

export default {
  name: "SchedulePopup",
  components: {
    AdminSchedule, CloseIcon
  },
  methods: {
    closeSchedule() {
      this.emitter.emit('schedule-open-popup', false)
    }
  }
}
</script>

<template>
  <div class="schedule-mobile">
    <div class="schedule-popup">
      <div class="title">
        Schedule
      </div>
      <AdminSchedule />
      <div
        class="close"
        @click="closeSchedule()"
      >
        <close-icon />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .schedule-mobile {
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100vh;
    background: rgba(67, 40, 164, 0.24);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    max-width: 100vw;

    .schedule-popup {
      background: white;
      position: relative;
      border-radius: 16px 16px 0px 0px;

      .title {
        color: var(--black, #1B1A1C);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        padding: 20px 20px 15px;
      }

      .close {
        position: absolute;
        z-index: 999;
        right: 5px;
        top: 5px;
        display: grid;
        place-items: center;
        width: 40px;
        height: 40px;
      }
    }
  }
</style>
