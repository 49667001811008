<script>
import CalendarService from "@/services/calendar.service";

export default {
  name: "AdminSchedule",
  data() {
    return {
      schedule: null,
      days: [
        {
          id: 1,
          name: 'Mon'
        },
        {
          id: 2,
          name: 'Tue'
        },
        {
          id: 3,
          name: 'Wed'
        },
        {
          id: 4,
          name: 'Thu'
        },
        {
          id: 5,
          name: 'Fri'
        },
        {
          id: 6,
          name: 'Sat'
        },
        {
          id: 0,
          name: 'Sun'
        }
      ]
    }
  },
  mounted() {
    this.getCalendarData();
  },
  methods: {
    getCalendarData() {
      CalendarService.getCalendarList().then((res) => {
        this.schedule = res.data.result;
      })
    }
  }
}

</script>

<template>
  <transition
    name="schedule"
    appear
  >
    <div
      v-if="schedule"
      class="schedule-table"
    >
      <div
        v-for="(day, index) in days"
        :key="index"
        class="schedule-table--item"
      >
        <div class="day">
          {{ day.name }}
        </div>

        <div class="shifts">
          <div
            v-for="(admin, id) in schedule.filter((item) => item.dayOfWeek === day.id)"
            :key="id"
            class="shift"
            :class="{'shift_active': admin.isShift}"
          >
            <div class="login">
              {{ admin.login }}
            </div>

            <div
              v-if="admin.shift === 1"
              class="date"
            >
              08:00-20:00
            </div>

            <div
              v-if="admin.shift === 2"
              class="date"
            >
              20:00-08:00
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
  .schedule-table {
    display: flex;
    flex-direction: row;
    gap: 4px;
    padding: 16px 16px 19px ;
    box-sizing: border-box;
    overflow: hidden;

    @media (max-width: 1079px) {
      flex-direction: column;
      padding: 0 0 21px ;
      gap: 0;
    }

    &--item {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media (max-width: 1079px) {
        flex-direction: row;
        align-items: center;
        border-top: 1px solid #DFE0EB;
        padding-left: 20px;
        padding-top: 4px;
        padding-bottom: 3px;
        gap: 36px;
      }

      .day {
        padding-left: 8px;
        color: #9FA2B4;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */

        @media (max-width: 1079px) {
          box-sizing: border-box;
          min-width: 32px;
          padding-left: 0;
        }
      }

      .shifts {
        display: flex;
        flex-direction: column;
        gap: 4px;


        @media (max-width: 1079px) {
          flex-direction: row;
          gap: 32px;
        }

        .shift {
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 5px 8px;
          box-sizing: border-box;
          width: 100px;

          .login {
            color: var(--black, #1B1A1C);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            letter-spacing: 0.2px;
          }

          .date {
            color: #9FA2B4;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 114.286% */
          }

          &_active {
            background: rgba(27, 26, 28, 0.04);

            .login {
              color: var(--black, #1B1A1C);
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px; /* 157.143% */
              letter-spacing: 0.2px;
            }
          }
        }
      }
    }
  }
</style>
