<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2649_19946)">
      <path
        d="M5.83333 9.1665H7.5V10.8332H5.83333V9.1665ZM17.5 4.99984V16.6665C17.5 17.5832 16.75 18.3332 15.8333 18.3332H4.16667C3.24167 18.3332 2.5 17.5832 2.5 16.6665L2.50833 4.99984C2.50833 4.08317 3.24167 3.33317 4.16667 3.33317H5V1.6665H6.66667V3.33317H13.3333V1.6665H15V3.33317H15.8333C16.75 3.33317 17.5 4.08317 17.5 4.99984ZM4.16667 6.6665H15.8333V4.99984H4.16667V6.6665ZM15.8333 16.6665V8.33317H4.16667V16.6665H15.8333ZM12.5 10.8332H14.1667V9.1665H12.5V10.8332ZM9.16667 10.8332H10.8333V9.1665H9.16667V10.8332Z"
        fill="#0A68F7"
      />
    </g>
    <defs>
      <clipPath id="clip0_2649_19946">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CalendarIcon"
}
</script>

<style scoped>

</style>
