<template>
  <div class="changeModal">
    <div class="changeModal-timer">
      <div class="single-chart">
        <circleIcon
          :timer-count="timer"
          :stroke="stroke"
        />
      </div>
    </div>
    <div class="changeModal-text">
      {{ NotificationNode.name }}
      <b v-if="NotificationNode.value">
        "{{ NotificationNode.value }}"
      </b>
    </div>
  </div>
</template>

<script>
import CircleIcon from "../../../icons/circleIcon";
import {mapGetters} from 'vuex';
import helperMixin from "@/mixins/helper.mixin";

export default {
  name: "SuccessNotificationNode",
  components: {CircleIcon},
  mixins: [
    helperMixin
  ],
  data() {
    return {
      timer: 0,
      stroke: '0, 100'
    }
  },
  computed: {
    ...mapGetters({
      NotificationNode: 'getNotificationNode',
    }),
  },
  mounted() {
    this.updateHandler();
  },
  methods: {
    updateHandler() {
      let strokeCount = 0;

      const counter = setInterval(() => {
        this.timer++;
        this.stroke = `${strokeCount + 33.33}, 100`;
        strokeCount += 33.33;

        if (this.timer === 4) {
          this.emitter.emit('update-notification-node');
          clearInterval(counter);
          this.cancelUpdate()
        }
      }, 1000);



    },
    cancelUpdate() {
      this.$store.commit('setNotificationNode', {});
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/notifications/status-popup";
@import "../../../assets/styles/notifications/status-change";
</style>
