<template>
  <div class="table-widget">
    <div class="table-widget_head">
      <div class="title">
        Requests by pairs {{ dateFormatPicker(date[0]) }} - {{ dateFormatPicker(date[1]) }}
      </div>
      <div class="filters-block">
        <div class="filters">
          <div class="dates_range">
            <date-picker
              v-model:value="date"
              :clearable="false"
              :editable="false"
              default-panel="day"
              separator=" - "
              value-type="format"
              :format="dateFormatUrl"
              placeholder="Reporting period"
              :month-format="'MMMM'"
              range
              input-class="dates-item"
              :class="{'dates_filled': date}"
              @change="getRateList"
            >
              {{ dateText }}
              <template #icon-calendar>
                <calendar-icon />
              </template>
            </date-picker>
          </div>
          <div class="separator" />
          <div class="filters_block">
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'hour'}"
              @click="setDateHandler('hour')"
            >
              1H
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'last24hours'}"
              @click="setDateHandler('last24hours')"
            >
              24H
            </div>
          </div>
          <div class="separator" />
          <div class="filters_block">
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'day'}"
              @click="setDateHandler('day')"
            >
              Today
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'yesterday'}"
              @click="setDateHandler('yesterday')"
            >
              Yesterday
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'week'}"
              @click="setDateHandler('week')"
            >
              Week
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'month'}"
              @click="setDateHandler('month')"
            >
              Month
            </div>
          </div>
          <div class="separator" />
          <div class="filters_block">
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'q1'}"
              @click="setDateHandler('q1')"
            >
              Q1
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'q2'}"
              @click="setDateHandler('q2')"
            >
              Q2
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'q3'}"
              @click="setDateHandler('q3')"
            >
              Q3
            </div>
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'q4'}"
              @click="setDateHandler('q4')"
            >
              Q4
            </div>
          </div>
          <div class="separator" />
          <div class="filters_block">
            <div
              class="filters--item"
              :class="{'filters--item-active': currentDate === 'year'}"
              @click="setDateHandler('year')"
            >
              Year
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table table-scroll">
      <div
        v-if="rateListData"
        class="table-block"
      >
        <div class="table-main">
          <div class="table-head">
            <div class="table-row">
              <div class="th-partner td-login">
                <div class="th-partner_text">
                  Pair
                </div>
              </div>
              <div
                v-for="(th, index) in loginList"
                :key="index"
                class="th-cell th-pair"
                @click="sortTable(th.partnerLogin)"
              >
                {{ th.partnerLogin }}
                <component
                  :is="getSortIcon(th.partnerLogin)"
                  v-if="th.partnerLogin === currentSortPartner.login"
                />
                <sort-passive v-else />
              </div>
              <div
                class="th-total"
                @click="sortTableTotal('total')"
              >
                Total
                <sort-a-z v-if="currentSortPartner.login === 'total' && currentSortPartner.sort === 'desc'" />
                <sort-z-a v-if="currentSortPartner.login === 'total' && currentSortPartner.sort === 'asc'" />
                <sort-passive v-if="currentSortPartner.login !== 'total'" />
              </div>
            </div>
          </div>
          <div class="table-body">
            <div
              v-for="(pair, index) in displayedListPair"
              :key="index"
              class="table-row table-row-pairs"
            >
              <div class="td-login">
                <div class="td-login_block">
                  {{ pair.pair }}
                </div>
              </div>
              <div
                v-for="(login, id) in loginList"
                :key="id"
                class="td-cell td-pair"
              >
                {{ filterEl(pair.pair, login.partnerLogin) }}
              </div>
              <div class="td-total">
                {{ totalCalc(pair.pair) }}
              </div>
            </div>
            <div
              v-if="displayedListPair.length < pairList.length"
              class="table-row table-row-button"
            >
              <div class="td-login" />
              <div
                v-if="canLoadMore"
                class="button"
                @click="loadMore"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4110_61318)">
                    <path
                      d="M17.5 8.33333V2.5L15.3 4.7C13.95 3.34167 12.075 2.5 10 2.5C5.85833 2.5 2.5 5.85833 2.5 10C2.5 14.1417 5.85833 17.5 10 17.5C14.1417 17.5 17.5 14.1417 17.5 10H15.8333C15.8333 13.2167 13.2167 15.8333 10 15.8333C6.78333 15.8333 4.16667 13.2167 4.16667 10C4.16667 6.78333 6.78333 4.16667 10 4.16667C11.6083 4.16667 13.0667 4.825 14.125 5.875L11.6667 8.33333H17.5Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4110_61318">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                Load more
              </div>
            </div>
            <div class="table-row table-last-row table-row-pairs">
              <div class="td-login">
                <div class="td-login_block">
                  Total
                </div>
              </div>
              <div
                v-for="(login, id) in loginList"
                :key="id"
                class="td-cell td-pair cell-total"
              >
                {{ calcByLogin(login.partnerLogin) }}
              </div>
              <div
                v-if="rateListData"
                class="td-total"
              >
                {{ totalCnt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!rateListData && !isLoading"
      class="empty-block"
    >
      <NoDataBlock />
    </div>
    <div
      v-if="isLoading"
      class="empty-block"
    >
      <trade-loader />
    </div>
  </div>
</template>

<script>

import RateService from "@/services/rate.service";
import dayjs from "dayjs";
import CalendarIcon from "@/icons/calendarIcon.vue";
import DatePicker from "vue-datepicker-next";
import SortPassive from "@/icons/sorting/sort-passive.vue";
import SortAZ from "@/icons/sorting/sort-a-z.vue";
import SortZA from "@/icons/sorting/sort-z-a.vue";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import TradeLoader from "@/components/trade/components/TradeLoader.vue";

export default {
  name: 'RateRequestTable',
  components: {TradeLoader, NoDataBlock, SortZA, SortAZ, SortPassive, DatePicker, CalendarIcon},
  data() {
    return {
      date: '',
      currentDate: '',
      startDate: '',
      endDate: '',
      modeDate: 'day',
      dateFormat: 'DD.MM.YYYY',
      dateFormatUrl: 'YYYY-MM-DD',
      dateFormatUrlHour: 'YYYY-MM-DD HH:mm:ss',
      loginList: null,
      pairList: null,
      sortDirections: {},
      currentSortPartner: {login: ''},
      rateListData: null,
      isLoading: false,
      currentCount: 0,
      batchSize: 50,
      canLoadMore: true,
      displayedList: [],
      displayedListPair: null,
      message: '',
    };
  },
  computed: {
    dateText() {
      if (this.date) {
        return this.date;
      } else {
        return "Custom date";
      }
    },
    totalCnt() {
      return this.rateListData.reduce((accumulator, item) => accumulator + item.cnt, 0);
    }
  },
  watch: {
    currentDate(val) {
      switch (val) {
        case 'hour':
          this.date = [
            dayjs().subtract(1, 'hour').startOf('hour').format(this.dateFormatUrlHour),
            dayjs().endOf('hour').format(this.dateFormatUrlHour)
          ];
          break;
        case 'last24hours':
          this.date = [
            dayjs().subtract(24, 'hour').format(this.dateFormatUrlHour),
            dayjs().endOf('hour').format(this.dateFormatUrlHour)
          ];
          break;
        case 'day':
          this.date = [dayjs().format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'yesterday':
          this.date = [dayjs().subtract(1, 'day').format(this.dateFormatUrl), dayjs().subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'week':
          this.date = [dayjs().startOf('week').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'month':
          this.date = [dayjs().startOf('month').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'year':
          this.date = [dayjs().startOf('year').format(this.dateFormatUrl), dayjs().format(this.dateFormatUrl)];
          break;
        case 'q1':
          this.date = [dayjs().startOf('year').format(this.dateFormatUrl), dayjs().startOf('year').add(3, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q2':
          this.date = [dayjs().startOf('year').add(3, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(6, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q3':
          this.date = [dayjs().startOf('year').add(6, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(9, 'month').subtract(1, 'day').format(this.dateFormatUrl)];
          break;
        case 'q4':
          this.date = [dayjs().startOf('year').add(9, 'month').format(this.dateFormatUrl), dayjs().startOf('year').add(11, 'month').endOf('month').format(this.dateFormatUrl)];
          break;
        default:
          break;
      }
      this.getRateList();
    }
  },
  mounted() {
    this.currentDate = 'last24hours';
  },
  methods: {
    async getRateList() {
      try {
        this.isLoading = true;
        this.rateListData = null;

        const query = `?start=${this.date[0]}&stop=${this.date[1]}`;
        const res = await RateService.getRateByPartner(query);

        if (res.data.result.length > 0) {
          this.rateListData = res.data.result;
          this.processData();
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        console.error("Помилка при отриманні даних:", error);
        this.isLoading = false;
      }
    },
    processData() {
      this.loginList = this.aggregateData(this.rateListData, 'partnerLogin');
      this.pairList = this.aggregateData(this.rateListData, 'pair');
      this.pairList.sort((a, b) => b.totalCnt - a.totalCnt);

      this.displayedListPair = this.pairList.slice(0, this.batchSize);
      this.currentCount = this.displayedListPair.length;
      this.isLoading = false;
    },
    aggregateData(data, field) {
      const cntByField = {};

      data.forEach(item => {
        const fieldValue = item[field];
        const cnt = item.cnt;

        if (!cntByField[fieldValue]) {
          cntByField[fieldValue] = cnt;
        } else {
          cntByField[fieldValue] += cnt;
        }
      });

      return Object.keys(cntByField).map(fieldValue => ({
        [field]: fieldValue,
        totalCnt: cntByField[fieldValue]
      })).sort((a, b) => b.totalCnt - a.totalCnt);
    },
    loadMore() {
      const startIndex = this.currentCount;
      const endIndex = startIndex + this.batchSize;

      if (startIndex < this.pairList.length) {
        const nextBatch = this.pairList.slice(startIndex, endIndex);

        if (endIndex >= this.pairList.length) {
          this.displayedListPair = this.displayedListPair.concat(nextBatch);
        } else {
          this.displayedListPair = this.displayedListPair.concat(nextBatch.slice(0, this.batchSize));
        }

        this.currentCount = this.displayedListPair.length;
      }
    },
    filterEl(pair, login) {
      const result = this.rateListData.find((element) => element.partnerLogin === login && element.pair === pair);

      if (result) {
        return result.cnt
      } else {
        return '-'
      }
    },
    totalCalc(pair) {
      return this.rateListData
          .filter((item) => item.pair === pair)
          .reduce((accumulator, item) => accumulator + item.cnt, 0);
    },
    calcByLogin(login) {
      return this.rateListData
          .filter((item) => item.partnerLogin === login)
          .reduce((accumulator, item) => accumulator + item.cnt, 0);
    },
    sortTable(partnerLogin) {
      if (this.currentSortPartner.login === partnerLogin) {
        this.pairList.reverse();
        this.currentSortPartner.sort = this.currentSortPartner.sort === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSortPartner.login = partnerLogin;
        this.currentSortPartner.sort = 'desc';
        const cntTotals = {};

        this.rateListData.forEach(item => {
          if (item.partnerLogin === partnerLogin) {
            const pair = item.pair;
            cntTotals[pair] = (cntTotals[pair] || 0) + item.cnt;
          }
        });

        this.pairList.sort((a, b) => (cntTotals[b.pair] || 0) - (cntTotals[a.pair] || 0));
      }

      this.displayedListPair = this.pairList.slice(0, this.batchSize);
      this.currentCount = this.displayedListPair.length;
    },
    sortTableTotal(total) {
      if (this.currentSortPartner.login !== total && this.currentSortPartner.sort !== 'asc') {
        this.currentSortPartner.login = total;
        this.currentSortPartner.sort = 'desc';
        this.pairList.sort((a, b) => a.totalCnt - b.totalCnt);
        this.displayedListPair = this.pairList.slice(0, this.batchSize);
        this.currentCount = this.displayedListPair.length;
      } else {
        this.currentSortPartner.login = total;
        this.currentSortPartner.sort = 'asc';
        this.pairList.sort((a, b) => b.totalCnt - a.totalCnt);
        this.displayedListPair = this.pairList.slice(0, this.batchSize);
        this.currentCount = this.displayedListPair.length;
      }

    },
    getSortIcon(partnerLogin) {
      if (partnerLogin === this.currentSortPartner.login) {
        return this.currentSortPartner.sort === 'desc' ? 'sort-z-a' : 'sort-a-z';
      }
      return 'sort-passive';
    },
    dateFormatter(value) {
      return dayjs(value).format(this.dateFormatUrl)
    },
    dateFormatPicker(value) {
      return dayjs(value).format(this.dateFormat)
    },
    setDateHandler(date) {
      this.currentDate = date
    },
    syncScroll(event) {
      const activeDivId = event.target.id;

      const passiveDiv = activeDivId === "div1" ? document.getElementById("div2") : document.getElementById("div1");

      passiveDiv.scrollTop = event.target.scrollTop;
      passiveDiv.scrollLeft = event.target.scrollLeft;
    },
  }
}
</script>

<style lang="scss">
.table-widget {
  overflow: hidden;
  width: 100%;
  background: white;
  border-radius: 16px;
  border: 1px solid var(--grayscale-divider, #DFE0EB);

  thead {
    tr {
      position: sticky;
      top: 0;
      z-index: 99;

      .th-partner, .th-pair {
        background: white;
      }
    }
  }

  &_head {
    padding: 32px 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      color: #1B1A1C;
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
    }

    .filters {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      box-sizing: border-box;

      .dates {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 4px;

        @media (max-width: 1079px) {
          overflow: auto;
          scrollbar-width: none;
        }

        &-item {
          padding: 6px 12px;
          font-size: 14px;
          font-weight: 500;
          background: #FCFDFE;
          border: 1px solid #F0F1F7;
          box-sizing: border-box;
          border-radius: 6px;
          color: #0A68F7;
          cursor: pointer;
          transition: all .3s ease-in-out;

          &:hover {
            border: 1px solid #0A68F7;
          }
        }

        .active {
          color: #FCFDFE;
          background: #0A68F7;
        }

        &_filled {
          .mx-input-wrapper {
            input {
              width: 25ch !important;
              height: 40px;
            }
          }
        }

        &_range {
          background: none !important;

          .mx {
            &-datepicker {
              width: unset;

              &-popup, &-main {
                box-shadow: 0 4px 24px rgba(55, 49, 72, 0.12);
                border-radius: 12px;
                overflow: hidden;
                border: none;
              }
            }

            &-icon-calendar, &-icon-clear {
              left: 9px;
              right: unset;

            }

            &-datepicker svg {
              fill: none;
            }

            &-input-wrapper {
              input {
                &::placeholder {
                  color: #0A68F7;
                  opacity: 1;
                }

                width: 14ch;
              }

              .dates-item {
                padding-left: 30px !important;
              }

              .mx-icon-calendar {
                svg {
                  path {
                    fill: #0A68F7;
                    fill-opacity: 100%;
                  }
                }
              }
            }
          }


          .dates-item {
            padding: 6px 8px;
          }

          &_active {
            .dates-item {
              color: #FCFDFE;
              background: #0A68F7;
            }

            .mx {
              &-icon-calendar, &-icon-clear {
              }

              &-datepicker svg path {
                fill: white !important;
              }

              &-input-wrapper {
                input {
                  &::placeholder {
                    color: white;
                    opacity: 1;
                  }

                  width: 14ch;
                }
              }
            }

            .mx-icon-calendar {
              svg {
                fill: white;
                fill-opacity: 100%;
              }
            }
          }
        }
      }

      .separator {
        width: 1px;
        height: 20px;
        background: #DFE0EB;
        border-radius: 4px;
      }

      &_block {
        display: flex;
        align-items: center;
        gap: 9px;
      }

      &--item {
        user-select: none;
        padding: 10px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0A68F7;
        background: #FCFDFE;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        transition: all .3s ease-in-out;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
          border: 1px solid #0A68F7;
        }

        &-active {
          background: #0A68F7;
          border: 1px solid #0A68F7;
          color: white;
          cursor: default;
        }
      }
    }
  }

  table {
    border-collapse: collapse;

    thead {
      tr {
        border-bottom: 3px solid #DFE0EB;
      }
    }
  }

  .table {
    overflow-x: auto;
    scrollbar-width: none;
    margin-bottom: 0;

    &-body {
      display: grid;
      position: relative;
    }

    &-head {
      position: sticky;
      top: 0;
      left: 0;
      background: white;
      z-index: 9;


      .table-row {
        min-height: 34px;
        min-width: 100%;
        border-bottom: 1px solid #DFE0EB;

        .th-pair {
          padding-bottom: 12px;
          box-sizing: border-box;
        }

        .th-cell {
          justify-content: flex-end;
          align-items: flex-end;
        }

        .td {
          display: flex;
          flex-direction: row;
        }
      }
    }

    &-block {
      max-height: 70vh;
      position: relative;
      box-sizing: border-box;

      tbody {
        tr {
          border-bottom: 1px solid #DFE0EB;
        }

        td {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
        }
      }

      .th-pair {
        color: #9FA2B4;
        text-align: right;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.2px;
        box-sizing: border-box;
        cursor: pointer;
        word-wrap: anywhere;
      }

      .th-total {
        color: var(--grayscale-gray, #9FA2B4);
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.2px;
        box-sizing: border-box;
        text-align: right;
        padding-right: 40px;
        background: white;
        cursor: pointer;
      }

      .th-partner {
        color: var(--grayscale-gray, #9FA2B4) !important;
        font-family: 'Inter', sans-serif;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.2px;
        padding-top: 8px;
        padding-bottom: 12px;
        position: sticky;
        box-sizing: border-box;
        text-align: start;
        left: 0;
        background: white;

        &_text {
          padding-left: 32px;
        }
      }

      .td-pair {
        min-width: 149px;
        color: var(--black, #1B1A1C);
        text-align: right;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        box-sizing: border-box;
        padding: 0;
      }

      .td-login {
        padding: 0 16px 0 0;
        box-sizing: border-box;
        color: var(--black, #1B1A1C);
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        min-width: 136px;
        position: sticky;
        background: white;
        left: 0;

        &_block {
          padding-left: 32px;
          box-sizing: border-box;
          height: 57px;
          background: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }

      .td-full-width {
        width: 100%;
      }

      .td-total {
        min-width: 136px;
        box-sizing: border-box;
        color: var(--black, #1B1A1C);
        text-align: right;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        padding-right: 40px;
        background: white;

        &-first {
          color: var(--black, #1B1A1C);
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.2px;
          padding-left: 32px;
          padding-top: 38px;
          padding-bottom: 38px;
          position: sticky;
          bottom: 0;
          left: 0;
        }
      }

      .cell-total {
        min-width: 140px;
        color: var(--black, #1B1A1C);
        text-align: right;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
      }

      .row-total {
        position: sticky;
        bottom: 0;
        left: 0;
        background: white;
        width: 100%;
        display: flex;
        flex-direction: column;

        .last-row {
          border-top: 1px solid #DFE0EB;
          display: flex;
          flex-direction: row;
          align-items: center;
          background: white;


          .td-login {
            min-width: 152px;
            box-sizing: border-box;
            background: white;
            padding: 0;
            height: 57px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }

          .cell-total {
            min-width: 149px;
            background: white;
            padding: 0;
            height: 57px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
          }

          .td-total {
            height: 57px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;

          }
        }
      }
    }

    &-row {
      display: inline-flex;
      flex-direction: row;
      gap: 20px;
      background: white;

      .th {
        &-cell {
          width: 100%;
          min-width: 128px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;

        }

        &-partner {
          min-width: 152px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

        }

        &-total {
          min-width: 136px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .td {
        &-login {
          min-width: 152px;
          padding: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

        }

        &-cell {
          width: 100%;
          min-width: 128px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;

        }

        &-total {
          min-width: 136px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
        }
      }

      &-button {
        height: 72px;
        align-items: center;

        .td-login {
          height: 100%;
        }

        .button {
          transform: translateX(-65px);
          padding: 10px 16px;
          gap: 8px;
          display: flex;
          flex-direction: row;
          border-radius: 8px;
          background: #0A68F7;
          color: #FFF;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          cursor: pointer;
          position: sticky;
          left: 50%;
        }
      }

      &-pairs:not(:first-child) {
        border-top: 1px solid #DFE0EB;
      }
    }

    &-last-row {
      position: sticky;
      bottom: 0;
      left: 0;
      background: white;
      height: 96px;
    }
  }

  .empty-block {
    height: 500px;
  }

  .sticky {
    &::after {
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.00) 106.25%);
      z-index: -1;
    }
  }
}
</style>
