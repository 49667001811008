<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2H14V0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H14V16H2V2ZM18 9L14 5V8H6V10H14V13L18 9Z"
      fill="white"
    />
  </svg>
</template>

<script>
    export default {
        name: "LogOut"
    }
</script>

<style scoped>

</style>