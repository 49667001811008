<template>
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.971003 0.899902H7.029C7.44809 0.899902 7.65763 1.43533 7.36097 1.74912L4.33315 4.95422C4.14951 5.14846 3.85049 5.14846 3.66685 4.95422L0.639026 1.74912C0.342365 1.43533 0.551911 0.899902 0.971003 0.899902Z"
      fill="#FF783E"
    />
  </svg>
</template>

<script>
    export default {
        name: "DownIcon"
    }
</script>

<style scoped>

</style>