<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="32"
      height="32"
      rx="8"
      fill="#0A68F7"
      fill-opacity="0.06"
    />
    <g clip-path="url(#clip0_2246_33004)">
      <path
        d="M12.6654 6.83325H22.6654C23.582 6.83325 24.332 7.58325 24.332 8.49992V20.1666H22.6654V8.49992H12.6654V6.83325ZM10.1654 10.1666H19.332C20.2487 10.1666 20.9987 10.9166 20.9987 11.8333V23.4999C20.9987 24.4166 20.2487 25.1666 19.332 25.1666H10.1654C9.2487 25.1666 8.4987 24.4166 8.4987 23.4999V11.8333C8.4987 10.9166 9.2487 10.1666 10.1654 10.1666ZM10.1654 23.4999H19.332V11.8333H10.1654V23.4999Z"
        fill="#0A68F7"
      />
    </g>
    <defs>
      <clipPath id="clip0_2246_33004">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="matrix(-1 0 0 1 26 6)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CopyIcon"
}
</script>

<style scoped>

</style>
